import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import {Provider} from "react-redux";
import store from "./store";

import {transitions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-mui'

import * as serviceWorker from './serviceWorker';
import App from "./App";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
// ** Import custom components for redux**

// optional configuration
const options = {
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE
}

const queryClient = new QueryClient()

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <AlertProvider template={AlertTemplate} {...options}>
                <App/>
            </AlertProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    </Provider>
    ,
    document.getElementById("root")
);


serviceWorker.unregister();

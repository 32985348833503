import moment from "moment/moment";

export const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const daysOfWeekObject = () => {
  return daysOfWeek.map(value => {
      return {
          value,
          label: value
      }
  })
}
export const nowDayOfWeek = () => moment().format("dddd")
export const orderMap = daysOfWeek.reduce((accum, val, i) => { accum[val] = i; return accum; }, {});

export const getMaxDateTime = () => {
    return getMaxDate() + `T23:59`;
}

export const getMaxDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}

export const changeTimezoneDayTime = (time, day_of_week, timezone) => {
    return moment.tz(day_of_week + " " + time, "dddd H:mm:ss", timezone).utc().format('dddd H:mm:ss')
}

export const startOfLastWeek = () => moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
export const endOfLastWeek = () => moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
export const startOfLastMonth = () => moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
export const endOfLastMonth = () => moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

export const timeSubtractSecond = (time) => {
    return moment(time, "H:mm:ss").subtract(1, 'seconds').format('H:mm:ss')
}
import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import AffiliateService
    from "../../../services/affiliate.service";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const ModalEditColumns = (props) => {
    const {columns, affiliate} = props
    const {register, handleSubmit} = useForm();
    const {t} = useTranslation();
    const [affiliateColumns, setAffiliateColumns] = useState([])

    useEffect(() => {
       setAffiliateColumns(affiliate.columns?.map(el => el.id))
    }, [affiliate?.columns])

    const onSubmit = (data) => {
        AffiliateService.updateReportColumns(data.columns, affiliate.id).then( () => {
            let res_columns = data.columns.map(el => Number(el))
            setAffiliateColumns(res_columns)
            toast.success('Success')
        }).catch(error => {
            toast.error(error.response.data.message)
        })
    }

    const setDefault = () =>{
        AffiliateService.setDefaultReportColumns(affiliate.id).then(res => {
           setAffiliateColumns(res.data)
            props.showmodal(false)
            toast.success('Success')
        }).catch(err => {
            console.log(err)
        })
    }

    const handleCheck = (column_id) => {
        let columns = [...affiliateColumns];
        if(!columns.includes(column_id)){          //checking weather array contain the id
            columns.push(column_id);               //adding to array because value doesnt exists
        }else{
            columns.splice(columns.indexOf(column_id), 1);  //deleting
        }
        setAffiliateColumns(columns)
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Edit Report Columns"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <b>Leads Report:</b>
                                    <ul>
                                        {columns && columns.filter(el => el.type === 'Leads').map((column, i) => (
                                            <li key={i}>
                                                <input id={`checkbox-${column.id}`} ref={register} value={column.id} type="checkbox" name="columns" onChange={() => handleCheck(column.id)} checked={affiliateColumns?.includes(column.id)} />
                                                <label htmlFor={`checkbox-${column.id}`} className="ml-2">{t(column.title)}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={"col-md-6"}>
                                    <b>Conversions Report:</b>
                                    <ul>
                                        {columns && columns.filter(el => el.type === 'Conversions').map((column, i) => (
                                            <li key={i}>
                                                <input id={`checkbox-${column.id}`} ref={register} value={column.id} type="checkbox" name="columns" onChange={() => handleCheck(column.id)} checked={affiliateColumns?.includes(column.id)} />
                                                <label htmlFor={`checkbox-${column.id}`} className="ml-2">{t(column.title)}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setDefault()}>Set default</Button>
                    <Button type={"submit"}>Save</Button>
                </Modal.Footer>
            </form>
            </Modal>
        </>
    )
}

export default ModalEditColumns
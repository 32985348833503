import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/distributions`;

class DistributionService {
    getAll(pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {headers: authHeader()});
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    getByWords(words) {
        return axios.get(`${baseUrl}?filter[words]=${words}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    updateStatus(id, data) {
        return axios.put(`${baseUrl}/${id}/status`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

}

export default new DistributionService();
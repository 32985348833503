import React, {useState, useEffect, useCallback} from 'react'
import {ChevronDown, ChevronRight} from 'react-feather';
import {connect} from "react-redux";
import affiliatePerformanceReportsService
    from "../../../services/affiliate-performance.service";
import InnerTableAdvertisers
    from "./innerTableAdvertisers";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useAffiliateReportColumn} from "../../../store/useAffiliateReportColumn";

const APPEND_HEADERS = [
    {
        // Make an expander cell
        header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()} className="chevron">
                            {row.isExpanded ? <ChevronDown/> : <ChevronRight/>}
                        </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
        width: 50
    },
    {
        header: 'Country',
        accessor: 'country.title',
        visible: true
    },
]
const InnerTableCountry = ({row, affiliate, period, advertiser, country, manager}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = useAffiliateReportColumn((state) => state.columns)

    useEffect(() => {
            setLoading(true)
            affiliatePerformanceReportsService.getAffiliatePerformanceReportsById(manager?.id, country, period, affiliate, advertiser, row.original.affiliate.id).then( res => {
                setData(res.data.data)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setLoading(false)

            })

    }, [])

    const renderRowSubComponent = useCallback(({row: countryRow}) => (
        <InnerTableAdvertisers
            row={row}
            countryRow={countryRow}
        />
    ), [])

    return (
       <ReportTableExpanded
           data={data}
           columns={columns}
           appendHeaders={APPEND_HEADERS}
           renderRowSubComponent={renderRowSubComponent}
           loading={loading}
       />
    )

}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.affiliateReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}

export default connect(mapStateToProps)(InnerTableCountry);
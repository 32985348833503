import {
    DASHBOARD_FILTER_FAIL,
    DASHBOARD_FILTER_SUCCESS, SET_DASHBOARD_COUNTRY_SUCCESS,
    SET_DASHBOARD_FILTERING_SUCCESS,
    SET_DASHBOARD_PERIOD_SUCCESS,
} from "./types";
import DashboardService from "../services/dashboard.service";
import {applyTimezone, refreshPeriod} from "../utils/period/period";


export const dashboardSearch = (country, period, affiliate, advertiser, filtering, timezone) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period, timezone)
    return DashboardService.getFilter(country?.iso, applyTimezone(updatedPeriod, timezone), affiliate?.id, advertiser?.id, filtering).then(
        (data) => {
            dispatch({
                type: DASHBOARD_FILTER_SUCCESS,
                payload: {
                    filtering: filtering,
                    statics: data.data.data,
                    country: country,
                    affiliate: affiliate,
                    period: updatedPeriod,
                    advertiser: advertiser,
                },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: DASHBOARD_FILTER_FAIL,
            });



            return Promise.reject();
        }
    );
};

export const setDashboardPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

export const setDashboardFiltering = (filtering) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD_FILTERING_SUCCESS,
        payload: {
            filtering,
        },
    });

    return Promise.resolve();
}


export const setDashboardCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD_COUNTRY_SUCCESS,
        payload: {
            country,
        },
    });

    return Promise.resolve();
}

export const setDashboardAffiliate = (affiliate) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD_COUNTRY_SUCCESS,
        payload: {
            affiliate,
        },
    });

    return Promise.resolve();
}

export const setDashboardAdvertiser = (advertiser) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD_COUNTRY_SUCCESS,
        payload: {
            advertiser,
        },
    });

    return Promise.resolve();
}

import React, {Fragment, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";
import AffiliateService from "../../../../services/affiliate.service";
import {toast} from "react-toastify";
import ReportAffiliatesSelected from "../../ReportAffiliatesSelected";

const SearchAffiliate = (props) => {
    const {handleSubmit, reset, control} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
                AffiliateService.getById(data["affiliate"].id).then(
                    response => {

                        if (response.data) {
                            props.setAffiliate(response.data?.data);
                        }

                    },
                    error => {
                        const message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        toast.error(message);

                    }
                )
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">

                            <div className="col-md-4">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>

                            <div className="col-md-2">
                                <div className="d-flex">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>

                                </div>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </Fragment>

    );

}

export default SearchAffiliate;

import React from "react";

const ReportTableEmptyBody = ({message = 'No data To Display'}) => {
    return (
        <tbody>
        <tr>
            <td colSpan="20" className="text-center">
                <div className="p-2">{message}</div>
            </td>
        </tr>
        </tbody>
    )
}

export default ReportTableEmptyBody
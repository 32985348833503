import {
    DASHBOARD_FILTER_FAIL,
    DASHBOARD_FILTER_SUCCESS,
    SET_DASHBOARD_ADVERTISER_SUCCESS,
    SET_DASHBOARD_AFFILIATE_SUCCESS,
    SET_DASHBOARD_COUNTRY_SUCCESS,
    SET_DASHBOARD_FILTERING_SUCCESS,
    SET_DASHBOARD_PERIOD_SUCCESS,
} from "../actions/types";
import moment from 'moment';

export const DEFAULT_DASHBOARD_FILTERING = {
    is_test: 2
}

const initialState = {
    statics: null,
    country: null,
    affiliate: null,
    advertiser: null,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },
    filtering: DEFAULT_DASHBOARD_FILTERING
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case DASHBOARD_FILTER_SUCCESS:
            return {
                ...state,
                statics: payload.statics,
                country: payload.country,
                affiliate: payload.affiliate,
                period: payload.period,
                advertiser: payload.advertiser,

            };

        case SET_DASHBOARD_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };

        case DASHBOARD_FILTER_FAIL:
            return {
                ...state,
                statics: null,
                country: null,
                affiliate: null,
                advertiser: null,
                period: null,
            };
        case SET_DASHBOARD_FILTERING_SUCCESS:
            return {
                ...state,
                filtering: payload.filtering
            }

        case SET_DASHBOARD_AFFILIATE_SUCCESS:
            return {
                ...state,
                affiliate: payload.affiliate,
            };

        case SET_DASHBOARD_ADVERTISER_SUCCESS:
            return {
                ...state,
                advertiser: payload.advertiser,
            };

        case SET_DASHBOARD_COUNTRY_SUCCESS:
            return {
                ...state,
                country: payload.country,
            };



        default:
            return state;
    }
}

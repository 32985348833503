import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import {apiRequestSearch, setApiRequestPeriod} from "../../../actions/api-request";
import ManagerSelected
    from "../../manager/ManagerSelected";
import PeriodFilter from "../../../utils/period/PeriodFilter";
import AdvertiserSelected from "../../advertiser/AdvertiserSelected";

const SearchApiRequest = ({dispatch, country, period, filtering, advertiser, affiliate, manager, pageSize}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    useEffect( () => {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("advertiser", advertiser);
        setValue("manager", manager);
        setValue("advertiser", advertiser);

    }, [country, advertiser, affiliate, manager, advertiser]);


    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(apiRequestSearch(data?.manager, data?.country, period, data?.affiliate, 1, data, pageSize, data?.advertiser));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">

                    <PeriodFilter
                        setPeriod={(data) => dispatch(setApiRequestPeriod(data))}
                        period={period}
                    />

                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-2 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <div className="col-md-2">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>
                            <div className="col-md-2">
                                <AdvertiserSelected control={control} error={errors?.advertiser_id} label={false}/>
                            </div>
                            <div className="col-md-2">
                                <ManagerSelected control={control} error={errors?.manager_id}/>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input type="text" name="email" placeholder="Email" className="form-control"
                                           ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input type="text" name="ip_filter" placeholder="IP" className="form-control"
                                           ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <input type="text" name="api_key" placeholder="Api Key" className="form-control"
                                           ref={register}
                                    />
                                </div>
                            </div>

                            <div className=" col-md-2">
                                <div className="form-group">
                                    <select name="status_code" className="custom-select"
                                            ref={register} >
                                        <option value="" disabled selected>All Status Code</option>
                                        <option value="">All</option>
                                        <option value="200">Succeed</option>
                                        <option value="400">Failed</option>
                                    </select>
                                    <span>{errors?.status_code}</span>
                                </div>
                            </div>
                            <div className=" col-md-2">
                                <div className="form-group">
                                    <select name="request_type" className="custom-select"
                                            ref={register} >
                                        <option value="" disabled selected>All Request Types</option>
                                        <option value="">All</option>
                                        <option value="Registration">Registration</option>
                                        <option value="Fetching Deposits">Fetching Deposits</option>
                                        <option value="Fetching Leads">Fetching Leads</option>
                                    </select>
                                    <span>{errors?.request_type}</span>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="d-flex">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>

                                </div>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, period, filtering, affiliate, manager, pageSize, advertiser} = state.apiRequest;
    return {
        country,
        period,
        affiliate,
        filtering,
        manager,
        pageSize,
        advertiser
    };
}

export default connect(mapStateToProps)(SearchApiRequest);

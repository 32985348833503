import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `manual-conversion`;

class ManualConversionService {

    getFilter(geo = "", period = {},  affiliate_id = "", email = "", pageNumber = 1) {

        return axios.get(`${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo,
                    email,
                    affiliate: affiliate_id,
                    period: {
                        from: period.from_date,
                        to: period.to_date
                    },
                },
            }
        });
    }

    create(lead_id, data) {
        return axios.post(`leads/${lead_id}/${baseUrl}`, data, {headers: authHeader()});
    }



}

export default new ManualConversionService();

import {
    BUCKET_FAIL,
    BUCKET_SUCCESS,
    SET_PERIOD_SUCCESS,
    SET_STREAM_FILTER_SUCCESS, STREAM_AFFILIATE_FAIL, STREAM_AFFILIATE_SUCCESS,
    STREAM_FILTER_FAIL,
    STREAM_FILTER_SUCCESS
} from "./types";
import StreamService from "../services/stream.service";
import bucketService from "../services/bucket.service";
import AffiliateService from "../services/affiliate.service";
import {refreshPeriod} from "../utils/period/period";


export const streamSearch = (country, period, affiliate, adBlock) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return StreamService.getFilter(country?.iso, updatedPeriod, affiliate?.id).then(
        (data) => {
            dispatch({
                type: STREAM_FILTER_SUCCESS,
                payload: {
                    streamFilters: data.data
                        .data?.sort((a,b) => {
                            return b.distribution.traffic - a.distribution.traffic
                        }),
                    country: country,
                    affiliate: affiliate,
                    period: updatedPeriod,
                },
            });

            return Promise.resolve();
        },
        (error) => {
            if (adBlock.isAdBlock(error)) {
                adBlock.showMessage()
            }

            dispatch({
                type: STREAM_FILTER_FAIL,
                payload: {
                    country: country,
                    affiliate: affiliate,
                    period: period,
                }
            });
            
            return Promise.reject(error);
        }
    );
};
export const setStream = (streamFilters) => (dispatch) => {
    dispatch({
        type: SET_STREAM_FILTER_SUCCESS,
        payload: {
            streamFilters: streamFilters,
        },
    });

    return Promise.resolve();


};
export const setStreamPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();


};

export const getBucketData = (geo, affiliate_id) => (dispatch) => {
    return bucketService.getAll(geo, affiliate_id).then(
        response => {
            dispatch({
                type: BUCKET_SUCCESS,
                payload: {
                    buckets: response.data.data,
                },
            });

            return Promise.resolve();

        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: BUCKET_FAIL,
            });
            


            return Promise.reject();
        }
    )
};

export const getAffiliateData = (affiliate_id) => (dispatch) => {
    return AffiliateService.getById(affiliate_id).then(
        response => {
            dispatch({
                type: STREAM_AFFILIATE_SUCCESS,
                payload: {
                    affiliate: response.data.data,
                },
            });

            return Promise.resolve();

        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: STREAM_AFFILIATE_FAIL,
            });



            return Promise.reject();
        }
    )
};

export const setBuckets = (buckets) => (dispatch) => {
    dispatch({
        type: BUCKET_SUCCESS,
        payload: {
            buckets: buckets,
        },
    });

    return Promise.resolve();


};
import axios from "../../utils/api";
import authHeader from "../auth-header";

const baseUrl = `/downtimes`;

class DowntimeService {
    getAll(day = 'Monday',advertiser_id, geo ,pageNumber = 1) {
        return axios.get(`advertisers/${advertiser_id}${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo,
                    day
                },
            }
        });
    }

    create(stream_id, data) {
        return axios.post(`advertisers/${stream_id}${baseUrl}`, data, {headers: authHeader()});
    }

    update(downtime_id, data) {
        return axios.put(`${baseUrl}/${downtime_id}`, data, {headers: authHeader()});
    }

    delete(downtime_id) {
        return axios.delete(`${baseUrl}/${downtime_id}`, {headers: authHeader()});
    }

}

export default new DowntimeService();
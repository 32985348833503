import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {ShowForPermission} from "../../../../helpers/ShowForPermission";
import {CAN_FILTER_ADVERTISER} from "../../../../constant/permissions";
import {EmailSelect} from "../../../select/email/EmailSelect";
import {setDashboardFiltering} from "../../../../actions/dashboard";

const ModalFilter = props => {
    const {dispatch, filtering} = props;
    const {register, handleSubmit, control, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(setDashboardFiltering(data));
            props.onHide();

        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500)
        }

    }

    useEffect(() => {
        const fields = [
            'advertiser_payout_down',
            'advertiser_payout_up',
            'click_id',
            'custom',
            'custom1',
            'custom2',
            'custom3',
            'custom4',
            'custom5',
            'customer_id',
            'deposit_id',
            'email',
            'failed_registration_count_down',
            'failed_registration_count_up',
            'id',
            'ip_filter',
            'name',
            'phone',
            'live_lead',
            'is_test',
        ];

        fields.forEach(field => setValue(field, filtering[field]));

    }, [props.show, filtering]);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Advanced Filters"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <Controller
                                                    name="email"
                                                    defaultValue={[]}
                                                    control={control}
                                                    render={({onChange, value}) =>
                                                        <EmailSelect
                                                            className="form-control h-auto p-0"
                                                            placeholder="Emails"
                                                            value={value}
                                                            onChange={onChange}
                                                            isMulti
                                                        />
                                                    }
                                                />
                                                <span>{errors?.email}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="name" ref={register}
                                                       placeholder="Name"/>
                                                <span>{errors?.name}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>

                                </div>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="id" ref={register}
                                                       placeholder="Lead ID"/>
                                                <span>{errors?.id}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="click_id"
                                                       ref={register}
                                                       placeholder="Click ID"/>
                                                <span>{errors?.click_id}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>

                                </div>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="customer_id"
                                                       ref={register}
                                                       placeholder="Customer ID"/>
                                                <span>{errors?.customer_id}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="deposit_id"
                                                       ref={register}
                                                       placeholder="Deposit ID"/>
                                                <span>{errors?.deposit_id}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>


                                </div>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <select name="live_lead" className="custom-select" id="" ref={register}
                                                        placeholder={"Live Lead"}>
                                                    <option value="" disabled selected>Live Lead</option>
                                                    <option value="">All</option>
                                                    <option value="1">Yes</option>
                                                    <option value="2">No</option>
                                                </select>
                                                <span>{errors?.live_lead}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <select name="is_test" className="custom-select" id="" ref={register}>
                                                    <option value="3">All</option>
                                                    <option value="2">Real</option>
                                                    <option value="1">Test</option>
                                                </select>
                                                <span>{errors?.is_test}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                </div>
                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="phone" ref={register}
                                                       placeholder="Phone"/>
                                                <span>{errors?.phone}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="ip_filter"
                                                       ref={register}
                                                       placeholder="IP"/>
                                                <span>{errors?.ip_filter}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                </div>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="custom" ref={register}
                                                       placeholder="custom"/>
                                                <span>{errors?.custom}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <div className=" col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="custom1" ref={register}
                                                   placeholder="custom1"/>
                                            <span>{errors?.custom1}</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-row">
                                    <div className=" col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="custom2" ref={register}
                                                   placeholder="custom2"/>
                                            <span>{errors?.custom2}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="custom3" ref={register}
                                                   placeholder="custom3"/>
                                            <span>{errors?.custom3}</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-row">
                                    <div className=" col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="custom4" ref={register}
                                                   placeholder="custom4"/>
                                            <span>{errors?.custom4}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="custom5" ref={register}
                                                   placeholder="custom5"/>
                                            <span>{errors?.custom5}</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="number"
                                                       name="failed_registration_count_up" ref={register}
                                                       placeholder="Failed Registration Count >="/>
                                                <span>{errors?.failed_registration_count_up}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="number"
                                                       name="failed_registration_count_down" ref={register}
                                                       placeholder="Failed Registration Count <"/>
                                                <span>{errors?.failed_registration_count_down}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                </div>

                                <div className="form-row">
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="number"
                                                       name="advertiser_payout_up"
                                                       ref={register}
                                                       placeholder="Advertiser Payout >="/>
                                                <span>{errors?.advertiser_payout_up}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                    <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                        <div className=" col-md-6">
                                            <div className="form-group">
                                                <input className="form-control" type="number"
                                                       name="advertiser_payout_down"
                                                       ref={register}
                                                       placeholder="Advertiser Payout <="/>
                                                <span>{errors?.advertiser_payout_down}</span>
                                            </div>
                                        </div>
                                    </ShowForPermission>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{"Apply filter"}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}

function mapStateToProps(state) {
    const {filtering} = state.dashboard;
    return {
        filtering,
    };
}

export default connect(mapStateToProps)(ModalFilter);

import React, {Fragment, useEffect, useState} from "react";
import {EmailAddress, Name, NewPassword, Save} from "../../constant";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ProfileService from "../../services/profile.service";
import {FileText} from "react-feather";
import { saveAs } from 'file-saver';
import {toast} from "react-toastify";

const AffiliateSettingsEdit = ({affiliate}) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [showToken, setShowToken] = useState(false);

    const {register, handleSubmit, reset, setValue} = useForm();

    useEffect(() => {
        const fields = [
            'name',
            'email',
        ];
        fields.forEach(field => setValue(field, affiliate[field]));
    })

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        ProfileService.update(data)
            .then(
                response => {
                    toast.success('Affiliate updated');

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }


                })
            .finally(() => {
                setLoading(false);

            })


    }


    const affiliateApiDocDownload = async (e) => {
        e.preventDefault();
        await ProfileService.downloadAPIDoc().then((response) => {
            saveAs(response.data, affiliate?.name + '-API.pdf');
        });
    }

    const showAffiliateToken = () => {
        setShowToken(!showToken)
    }

    return (
        <Fragment>
            <div className="row justify-content-center">

                <div className="col-md-12">
                    <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)}
                          onReset={reset}>
                        <div className="card-header">
                            <p className={"mb-0"}>
                                ID: {affiliate?.id} <br/>
                                <div>
                                    <span>
                                        Api Token: <i onClick={showAffiliateToken} aria-hidden="true" className={showToken ? 'fa fa-eye' : 'fa fa-eye-slash'}/> <span>{showToken && affiliate?.token}</span>
                                    </span>
                                    <button className="btn btn-primary btn-sm ml-2"
                                            onClick={event => affiliateApiDocDownload(event)}>
                                        <FileText size={"16"}/>
                                    </button>
                                </div>
                                {affiliate?.manager && (
                                    <>
                                        Manager: {affiliate.manager?.name} ({affiliate.manager?.email})
                                    </>
                                )}
                            </p>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">{Name}:</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" type="text" name="name"
                                                   ref={register}
                                                   placeholder={Name}/>
                                            <span>{errors?.name}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">{EmailAddress}:</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" type="text" name="email"
                                                   ref={register}
                                                   placeholder={EmailAddress}/>
                                            <span>{errors?.email}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">{NewPassword}:</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" type="password" name="password"
                                                   ref={register} autoComplete="new-password"
                                                   placeholder={NewPassword}/>
                                            <span>{errors?.password}</span>

                                        </div>
                                    </div>


                                </div>
                                <div className="col-md-3 text-right">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Save}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        affiliate: user.data
    };
}

export default connect(mapStateToProps)(AffiliateSettingsEdit);

import React, {Fragment} from 'react';
import AsyncSelect from 'react-select/async';
import MakeAnimated from 'react-select/animated';
import AdvertiserService from "../../services/advertiser.service";
import {Advertiser} from "../../constant";
import {Controller} from "react-hook-form";

function AdvertiserSelected(props) {
    const {control, error, label = true, rules = {}} = props;
    const animatedComponent = MakeAnimated()

    const fetchData = (inputValue) => {
        return AdvertiserService.getByWords(inputValue).then(result => {
            return result.data.data;
        });
    }

    return (
        <Fragment>
            <div className="form-group">
                {label && (
                    <label className="form-label">{Advertiser}</label>
                )}

                <Controller
                    {...props}
                    as={AsyncSelect}
                    defaultValue={null}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    placeholder={"Advertiser"}
                    defaultOptions
                    componets={animatedComponent}
                    cacheOptions
                    name="advertiser"
                    isClearable
                    control={control}
                    rules={rules}
                />
                <span className={'text-danger-light'}>{error}</span>

            </div>
        </Fragment>

    );
}

export default AdvertiserSelected
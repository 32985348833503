import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import ReactJson from "react-json-view";
import {useAlert} from "react-alert";
import TableReact from "react-bootstrap/Table";

const ModalRegistrationHistory = ({lead}) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const alert = useAlert();

    const showJson = (json = null) => {
        return alert.info(<ReactJson enableClipboard={false} src={json}/>, {timeout: 0,});
    }

    return (
        <>
            <Button
                onClick={handleShow}
                className='btn btn-primary'>
                <i className="fa fa-info-circle"></i>
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Registration History"} - {lead?.email}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <TableReact bordered striped size="sm">
                                    <thead>
                                    <tr>
                                        <th scope="col">{"Advertiser"}</th>
                                        <th scope="col">{"Percent Traffic"}</th>
                                        <th scope="col">{"Status"}</th>
                                        <th scope="col">{"Info"}</th>
                                        <th scope="col">{"Date & Time"}</th>
                                        <th scope="col">{"Request & Response"}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lead && lead.registration_histories &&
                                        lead.registration_histories.map((registration_history, index) => (
                                            <>
                                                {registration_history.status != "Warning" && (
                                                    <tr key={index}
                                                        className={
                                                        registration_history.status == "Succeeded" ? 'bg-success-table'
                                                            : registration_history.status == "Failed" ? 'bg-danger-table'
                                                            : 'bg-info-table'
                                                    }>
                                                        <td>
                                                            {registration_history.advertiser.name}
                                                        </td>
                                                        <td>
                                                            {registration_history.percent_traffic}
                                                        </td>
                                                        <td>
                                                            {registration_history.status ? registration_history.status : "-"}
                                                        </td>
                                                        <td>
                                                            {registration_history.info ? registration_history.info : "-"}
                                                        </td>
                                                        <td>
                                                            {registration_history.api_request?.date ?? "-"}
                                                        </td>
                                                        <td>
                                                            {registration_history.api_request ? (
                                                                <div className="d-flex">
                                                                    <div className="mr-3">
                                                                        <button
                                                                            className="btn btn-primary "
                                                                            onClick={() => showJson(registration_history.api_request?.response_broker)}>
                                                                            View  Response
                                                                        </button>
                                                                    </div>
                                                                    <div className="">
                                                                        <button
                                                                            className="btn btn-primary "
                                                                            onClick={() => showJson(registration_history.api_request?.request_post)}>
                                                                            View Request
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : "-" }

                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                    </tbody>
                                </TableReact>
                            </div>

                        </div>
                        <div className="col-md-12 mt-4">
                            <h5>
                                Inactive Advertisers at the time of registration
                            </h5>
                            <div className="table-responsive  ">
                                <TableReact bordered striped size="sm">

                                    <thead>
                                    <tr>
                                        <th scope="col">{"Advertiser"}</th>
                                        <th scope="col">{"Percent Traffic"}</th>
                                        <th scope="col">{"Info"}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lead && lead.registration_histories &&
                                        lead.registration_histories.map((registration_history, index) => (
                                            <>
                                                {registration_history.status == "Warning" && (
                                                    <tr key={index}
                                                        className={'bg-warning-table'}>
                                                        <td>
                                                            {registration_history.advertiser.name}
                                                        </td>
                                                        <td>
                                                            {registration_history.percent_traffic}
                                                        </td>
                                                        <td>
                                                            {registration_history.info ?? "-"}
                                                        </td>

                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                    </tbody>
                                </TableReact>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

function mapStateToProps(state) {
    const {country, affiliate, buckets, filtering, periodTime} = state.lead;
    return {
        country,
        buckets,
        affiliate,
        filtering,
        periodTime,
    };
}

export default connect(mapStateToProps)(ModalRegistrationHistory);

import React, {Fragment} from 'react';
import {Home} from 'react-feather';
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {updateMenu} from "../../actions/sidebar";
import {MENUITEMS} from "../../constant/menu";


const Breadcrumb = (props) => {
    const breadcrumb = props;
    const {sidebar} = props;
    const parent_url = props.parentUrl

    function Parent(props) {
        if (!props.parent) {
            return null;
        }

        return (
            // <Link to={parent_url}>
                <li className="breadcrumb-item">
                    {parent_url ? <Link onClick={() => {updateReduxMenu(props.parent)}} to={parent_url}>{props.parent}</Link> : props.parent}
                </li>
            // </Link>
        );
    }

    const updateReduxMenu = (title) => {
        sidebar.forEach(a => {
            a.active = false
            if (!a.children) return false
            a.children.forEach(b => {
                    b.active = false
                if (!b.children) return false
                b.children.forEach(c => {
                        c.active = false
                })
            })
        });
        let menuitems = sidebar.map(el => {
            if (el.title === title){
                el.active = true;
            }
            return el;
        })

        updateMenu(menuitems);
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <div className="page-header-left">
                                <h3>{breadcrumb.title}</h3>
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link onClick={() => updateReduxMenu('Dashboard')} to="/dashboard">
                                            <Home/>
                                        </Link>
                                    </li>
                                    <Parent parent={breadcrumb.parent} />
                                    <li className="breadcrumb-item active">{breadcrumb.title}</li>
                                </ol>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const menuitems = state.sidebar.menuitems;

    return {
        sidebar : menuitems
    };
}

export default connect(mapStateToProps)(Breadcrumb)

import React, {Fragment, useCallback} from 'react'
import {ChevronRight, ChevronDown} from 'react-feather';
import {connect} from "react-redux";
import InnerTableCountry from './innerTableCountry'
import VisibleColumns
    from "./VisibleColumns";
import ExportReport
    from "./ExportReport";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useAdvertiserReportColumn} from "../../../store/useAdvertiserReportColumn";

const APPEND_HEADERS = [
    {
        // Make an expander cell
        header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()} className="chevron">
                            {row.isExpanded ? <ChevronDown/> : <ChevronRight/>}
                        </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
        width: 50
    },
    {
        header: 'Advertiser',
        accessor: 'advertiser.name',
        is_visible: 1,
        Footer: () => 'Total'
    }
];

const Table = ({data}) => {
    const columns = useAdvertiserReportColumn((state) => state.columns)

    const renderRowSubComponent = useCallback(({row}) => (
        <InnerTableCountry
            row={row}
        />
    ), [])

    return (
        <Fragment>
            <div className={"card-header"}>
                <div className={"action-btns d-flex justify-content-between"}>
                    <div className={"d-flex gap-2"}>
                        {
                            data && data.length ?
                                <>
                                    <div className={"d-flex text-center flex-column"}>
                                        <ExportReport type = "advertiser-performance"/>
                                        <small>adv</small>
                                    </div>
                                    <div className={"d-flex text-center flex-column"}>
                                        <ExportReport type = "advertiser-countries-performance"/>
                                        <small>adv+geo</small>
                                    </div>
                                    <div className={"d-flex text-center flex-column"}>
                                        <ExportReport type = "advertiser-countries-affiliate-performance"/>
                                        <small>adv+geo+aff</small>
                                    </div>
                                </>
                                : <></>
                        }
                    </div>
                    <VisibleColumns/>
                </div>
            </div>
            <div className="table-responsive">
               <ReportTableExpanded
                   data={data}
                   columns={columns}
                   appendHeaders={APPEND_HEADERS}
                   visibleTotal={true}
                   renderRowSubComponent={renderRowSubComponent}
               />
            </div>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.advertiserReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}

export default connect(mapStateToProps)(Table);

import {BookOpen, Feather, Globe, Home, User, Users, Shield, Settings} from 'react-feather';
import {
    CAN_GET_CONVERSIONS,
    CAN_INDEX_ADMIN,
    CAN_INDEX_ADVERTISER,
    CAN_INDEX_ADVERTISER_PERFORMANCE_REPORTS,
    CAN_INDEX_AFFILIATE,
    CAN_INDEX_AFFILIATE_PERFORMANCE_REPORTS,
    CAN_INDEX_AFFILIATE_SETTINGS,
    CAN_INDEX_API_REQUEST,
    CAN_INDEX_DAILY_REPORTS,
    CAN_INDEX_DASHBOARD,
    CAN_INDEX_DISTRIBUTION,
    CAN_INDEX_INVOICE,
    CAN_INDEX_LEAD,
    CAN_INDEX_MANAGER,
    CAN_INDEX_MANUAL_CONVERSION,
    CAN_INDEX_MODERATION,
    CAN_INDEX_PERMISSIONS,
    CAN_INDEX_REPORTS
} from "./permissions";

export const MENUITEMS = [

    {
        title: 'Dashboard', icon: Home, type: 'link', path: '/dashboard', active: true, permission: CAN_INDEX_DASHBOARD
    },
    {
        title: 'Affiliate settings', icon: Users, type: 'link', path:'/affiliate-settings', active: false, permission: CAN_INDEX_AFFILIATE_SETTINGS,
    },
    {
        title: 'Affiliates', icon: Users, type: 'sub', active: false, permission: CAN_INDEX_AFFILIATE , children: [
            {
                title: 'Affiliate Details',  type: 'link', path: '/affiliates/details', active: false
            },
            {
                title: 'Manage Affiliates',  type: 'link', path: '/affiliates', active: false
            },
            {
                title: 'Affiliate Restriction',  type: 'link', path: '/affiliates/advertiser-restriction', active: false
            },
            {
                title: 'Invoice',  type: 'link', path: '/invoices/affiliate', active: false, permission: CAN_INDEX_INVOICE
            },
        ]
    },
    {
        title: 'Advertisers', icon: Feather, type: 'sub', active: false, permission: CAN_INDEX_ADVERTISER, children: [
            {
                title: 'Manage Advertisers',  type: 'link', path: '/advertisers', active: false, permission: CAN_INDEX_ADVERTISER
            },
            {
                title: 'Invoice', type: 'link', path: '/invoices/advertiser', active: false, permission: CAN_INDEX_INVOICE
            },
        ]
    },

    {
        title: 'Leads distribution', icon: Globe, type: 'link', path: '/advertisers/distribution', active: false, permission: CAN_INDEX_DISTRIBUTION
    },
    {
        title: 'Reports', icon: BookOpen, type: 'sub', active: false, showModerationPendingCount: true, permission:CAN_INDEX_REPORTS, children: [

            {
                title: 'API requests', type: 'link', path: '/api-request', active: false, permission:CAN_INDEX_API_REQUEST
            },
            {
                title: 'Leads', type: 'link', path: '/leads', active: false, permission:CAN_INDEX_LEAD
            },
            {
                title: 'Conversions', type: 'link', path: '/conversions', active: false, permission:CAN_GET_CONVERSIONS
            },


            {
                title: 'Moderation', type: 'link', path: '/moderation', active: false, permission: CAN_INDEX_MODERATION, showModerationPendingCount: true,
            },
            {
                title: 'Daily Reports', type: 'link', path: '/reports/daily', active: false, permission: CAN_INDEX_DAILY_REPORTS
            },
            {
                title: 'Affiliate Performance', type: 'link', path: '/reports/affiliate-performance', active: false, permission: CAN_INDEX_AFFILIATE_PERFORMANCE_REPORTS
            },
            {
                title: 'Advertiser Performance', type: 'link', path: '/reports/advertiser-performance', active: false, permission: CAN_INDEX_ADVERTISER_PERFORMANCE_REPORTS
            },
            // {
            //     title: 'Generate', type: 'link', path: '/leads', active: false
            // },
        ]
    },

    {
        title: 'Admins', icon: User, type: 'link', path: '/admins', active: false, permission: CAN_INDEX_ADMIN
    },
    {
        title: 'Managers', icon: User, type: 'link', path: '/managers', active: false, permission: CAN_INDEX_MANAGER
    },
    {
        title: 'Permissions', icon: Shield, type: 'sub', active: false, permission: CAN_INDEX_PERMISSIONS, children: [

            {
                title: 'Groups', type: 'link', path: '/permissions/groups', active: false
            },
            {
                title: 'Admins', type: 'link', path: '/permissions/admins', active: false
            },
            {
                title: 'Managers', type: 'link', path: '/permissions/managers', active: false
            }
        ]
    },
    {
        title: 'Tools', icon: Settings, type: 'sub', active: false, permission: CAN_INDEX_MANUAL_CONVERSION, children: [
            {
                title: 'Optimize', type: 'link', path: '/optimize', active: false
            },
            {
                title: 'Manual Conversion', type: 'link', path: '/manual-conversion', active: false
            },
            {
                title: 'Register Test Lead', type: 'link', path: '/register-test-lead', active: false
            },
            {
                title: 'Import', type: 'link', path: '/import', active: true
            }
        ]
    },

]


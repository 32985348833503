import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Create} from '../../../../../constant';

import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import AffiliateWhiteListIpService from "../../../../../services/affiliate.white-list-ip.service";

const ModalCreateWhiteListIp = ({ whiteListIps, affiliate, setWhiteListIps, affiliateView = false}) => {
    const {register, handleSubmit, reset} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);
        let func = null;

        if(affiliateView){
            func = AffiliateWhiteListIpService.createAffiliate(data)
        } else {
            func = AffiliateWhiteListIpService.create(affiliate.id, data)

        }

            func.then(
                response => {
                    setWhiteListIps([...whiteListIps, response.data.data]);
                    handleClose()
                    toast.success("WhiteListIp Created");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })
    }

    return (
        <>
            <button className="btn btn-primary"
                    onClick={handleShow}
            >add
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create White List Ip"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">
                               
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="ip"  ref={register}
                                                   placeholder="127.0.0.1 or 2001:db8:abcd:0012:0000:0000:0000:0000"/>
                                            <span>{errors?.ip}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}



export default ModalCreateWhiteListIp;

import React, {useState, useEffect, useCallback} from 'react'
import {ChevronRight, ChevronDown} from 'react-feather';
import {connect} from "react-redux";
import advertiserPerformanceReportsService
    from "../../../services/advertiser-performance.service";
import InnerTableAffiliate
    from "./innerTableAffiliate";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useAdvertiserReportColumn} from "../../../store/useAdvertiserReportColumn";

const APPEND_HEADERS = [
    {
        // Make an expander cell
        header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()} className="chevron">
                            {row.isExpanded ? <ChevronDown/> : <ChevronRight/>}
                        </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
        width: 50
    },
    {
        header: 'Country',
        accessor: 'country.title',
        visible: true
    }
]
const InnerTableCountry = ({row, affiliate, period, advertiser, country, manager}) => {
    const columns = useAdvertiserReportColumn((state) => state.columns)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        advertiserPerformanceReportsService.getAdvertiserPerformanceReportsById(manager?.id, country, period, affiliate, advertiser, row.original.advertiser.id).then(res => {
            setData(res.data.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, []);

    const renderRowSubComponent = useCallback(({row: countryRow}) => (
        <InnerTableAffiliate
            row={row}
            countryRow={countryRow}
        />
    ), [])

    return (
       <ReportTableExpanded
           data={data}
           columns={columns}
           appendHeaders={APPEND_HEADERS}
           loading={loading}
           renderRowSubComponent={renderRowSubComponent}
       />
    )
}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.advertiserReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}

export default connect(mapStateToProps)(InnerTableCountry);
import {
    LEAD_FILTER_FAIL,
    LEAD_FILTER_SUCCESS,
    SET_LEAD_ADVERTISER_SUCCESS,
    SET_LEAD_AFFILIATE_SUCCESS,
    SET_LEAD_COUNTRY_SUCCESS,
    SET_LEAD_FILTERING_SUCCESS,
    SET_LEAD_HISTORY_SUCCESS,
    SET_LEAD_MANAGER_SUCCESS,
    SET_LEAD_PERIOD_SUCCESS,
    SET_LEAD_PERIODTIME_SUCCESS,
    SET_LEAD_SUCCESS,
    SET_RE_REGISTER_LEAD_IDS_SUCCESS
} from "../actions/types";
import moment from "moment/moment";

export const LEAD_DEFAULT_FILTERING = {
    is_test: 2,
}

const initialState = {
    leads: null,
    meta: null,
    country: null,
    affiliate: null,
    advertiser: null,
    pageSize: 25,
    pageNumber: null,
    reRegisterLeadIDs: [],
    manager: null,
    periodTime: {
        selectedDays: ['1','2','3','4','5','6','7'],
        fromTime: "00:00",
        toTime: "23:59",
        status: false,
    },
    filtering: LEAD_DEFAULT_FILTERING,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },
    history: []
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case LEAD_FILTER_SUCCESS:
            return {
                ...state,
                leads: payload.leads,
                meta: payload.meta,
                country: payload.country,
                affiliate: payload.affiliate,
                manager: payload.manager,
                period: payload.period,
                advertiser: payload.advertiser,
                filtering: payload.filtering,
                periodTime: payload.periodTime,
                pageSize: payload.pageSize,
                pageNumber: payload.pageNumber,
                reRegisterLeadIDs: [],
            };

        case SET_LEAD_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };

        case SET_LEAD_AFFILIATE_SUCCESS:
            return {
                ...state,
                affiliate: payload.affiliate,
            };

        case SET_LEAD_ADVERTISER_SUCCESS:
            return {
                ...state,
                advertiser: payload.advertiser,
            };

        case SET_LEAD_COUNTRY_SUCCESS:
            return {
                ...state,
                country: payload.country,
            };

        case SET_LEAD_MANAGER_SUCCESS:
            return {
                ...state,
                manager: payload.manager,
            };

        case SET_LEAD_PERIODTIME_SUCCESS:
            return {
                ...state,
                periodTime: payload.periodTime,
            };

        case LEAD_FILTER_FAIL:
            return {
                ...state,
                leads: null,
                meta: null,
                country: null,
                manager: null,
                affiliate: null,
                advertiser: null,
                filtering: {},
                period: {},
                periodTime: {},

            };

        case SET_LEAD_FILTERING_SUCCESS:
            return {
                ...state,
                filtering: payload.filtering,
            };

        case SET_LEAD_SUCCESS:
            return {
                ...state,
                leads: payload.leads,
            };

        case SET_RE_REGISTER_LEAD_IDS_SUCCESS:
            return {
                ...state,
                reRegisterLeadIDs: payload.reRegisterLeadIDs,
            };
        case SET_LEAD_HISTORY_SUCCESS:
            return {
                ...state,
                history: [...state.history, ...[payload.history]]
            }


        default:
            return state;
    }
}

export const formatPercent = (value) => new Intl.NumberFormat('ua', {minimumFractionDigits: 2}).format(value) + '%';

export const formatCurrency = (value, currency = '$') => new Intl.NumberFormat('ua', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value) + currency

export const calculateDividePercent = (divisor, denominator) => {
    if (denominator && divisor) {
        return (divisor / denominator * 100).toFixed(2);
    }

    if (divisor && !denominator) {
        return 100;
    }

    return 0;
}

export const compareNumericString = (rowA, rowB, id, desc) => {
    let a = Number.parseFloat(rowA.values[id]);
    let b = Number.parseFloat(rowB.values[id]);
    if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
        a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
        b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}
import {
    CONVERSION_FILTER_FAIL,
    CONVERSION_FILTER_SUCCESS,
    SET_CONVERSION_PERIOD_SUCCESS,
    SET_CONVERSION_FILTERING_SUCCESS,
    SET_CONVERSION_SUCCESS,
    SET_CONVERSION_COUNTRY_SUCCESS,
    SET_CONVERSION_ADVERTISER_SUCCESS,
    SET_CONVERSION_AFFILIATE_SUCCESS,
    SET_CONVERSION_PERIODTIME_SUCCESS,
    SET_CONVERSION_MANAGER_SUCCESS,
    SET_CONVERSION_HISTORY_SUCCESS
} from "./types";
import conversionService from "../services/lead.service";
import {applyPeriodTimeTimezone, applyTimezone, refreshPeriod} from "../utils/period/period";

export const conversionSearch = (manager, country, period, periodTime, affiliate, advertiser, pageNumber, filtering, pageSize, timezone) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period, timezone)
    return conversionService.getAllConversions(manager?.id, country?.iso, applyTimezone(updatedPeriod, timezone), applyPeriodTimeTimezone(periodTime, timezone), affiliate?.id, advertiser?.id,  filtering, pageNumber, pageSize).then(
        (response) => {

            dispatch({
                type: CONVERSION_FILTER_SUCCESS,
                payload: {
                    conversions: response.data.data,
                    meta: response.data.meta,
                    country,
                    affiliate,
                    period: updatedPeriod,
                    manager,
                    advertiser,
                    filtering,
                    periodTime,
                    pageSize,
                    pageNumber
                },
            });

            return Promise.resolve();
        },
        () => {
            dispatch({
                type: CONVERSION_FILTER_FAIL,
            });

            return Promise.reject();
        }
    );
};


export const setConversionPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

export const setConversionCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_COUNTRY_SUCCESS,
        payload: {
            country: country,
        },
    });

    return Promise.resolve();
}

export const setConversionPeriodTime = (periodTime) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_PERIODTIME_SUCCESS,
        payload: {
            periodTime: periodTime,
        },
    });

    return Promise.resolve();
}

export const setConversionAdvertiser = (advertiser) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_ADVERTISER_SUCCESS,
        payload: {
            advertiser: advertiser,
        },
    });

    return Promise.resolve();
}

export const setConversionAffiliate = (affiliate) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_AFFILIATE_SUCCESS,
        payload: {
            affiliate: affiliate,
        },
    });

    return Promise.resolve();
}

export const setConversionManager = (manager) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_MANAGER_SUCCESS,
        payload: {
            manager: manager,
        },
    });

    return Promise.resolve();
}

export const setConversionFiltering = (filtering) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_FILTERING_SUCCESS,
        payload: {
            filtering: filtering,
        },
    });

    return Promise.resolve();
}

export const setConversions = (conversions) => (dispatch) => {
    dispatch({
        type: SET_CONVERSION_SUCCESS,
        payload: {
            conversions: conversions,
        },
    });

    return Promise.resolve();
}
export const conversionHistory = (leadId) => (dispatch) => {
    return conversionService.history(leadId).then(
        (response) => {

            dispatch({
                type: SET_CONVERSION_HISTORY_SUCCESS,
                payload: {
                    history: {
                        id: leadId,
                        data: response.data.data
                    }
                },
            });

            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
};



import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../common/breadcrumb";
import EventBus from "../../../common/EventBus";
import AdminService from "../../../services/admin.service";
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useAlert} from "react-alert";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import AdminsPermissionEdit
    from "./AdminsPermissionEdit";
import TableReact from "react-bootstrap/Table";
import {toast} from "react-toastify";
import Title from "../../common/custom-title";

const AdminsPermissionList = (props) => {


    const [admins, setAdmins] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const alert = useAlert();
    const { t, ready } = useTranslation();


    useEffect(() => {
        getAdminsData();
    }, [])


    const getPageUrl = (pageNumber) => {
        return `${process.env.PUBLIC_URL}/admins?page=${pageNumber}`
    }

    const getAdminsData = (pageNumber) => {
        AdminService.getAll(pageNumber).then(
            response => {
                if(response.data){
                    let admins = response.data?.data
                    if (props.user.data && !props.user.data.roles.includes('Super-Admin')){
                       admins = admins.filter(el => {
                            return !el.roles.includes('Super-Admin')
                        })
                    }
                    setAdmins(admins);
                    setMeta(response.data?.meta);
                }

            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);

                
            }
        )
    };

    return (
        <Fragment>

            <Title title="Admins"/>
            <Breadcrumb title="Admins"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="table-responsive">
                                <TableReact bordered striped>
                                    <thead>
                                    <tr>
                                        <th scope="col">{"#"}</th>
                                        <th scope="col">{"Name"}</th>
                                        <th scope="col">{"Email"}</th>
                                        <th scope="col">{"Roles"}</th>
                                        <th scope="col">{"Action"}</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {admins &&
                                    admins.map((admin, index) => (
                                        <tr key={index}>
                                            <td scope="row">{admin.id}</td>
                                            <td scope="row">{admin.name}</td>
                                            <td scope="row">{admin.email}</td>
                                            <td scope="row">{admin.roles && admin.roles.map(el => t(el)).join(',')}</td>
                                            <td scope="row">
                                                <div className="d-flex">
                                                    <div className="ml-3">
                                                        <Link to={`/permissions/admins/${admin.id}`}>
                                                            <i className="fa fa-pencil text-success fa-2x"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {!admins &&
                                    <tr>
                                        <td colSpan="13" className="text-center">
                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                        </td>
                                    </tr>
                                    }
                                    {admins && !admins.length &&
                                    <tr>
                                        <td colSpan="13" className="text-center">
                                            <div className="p-2">No Admins To Display</div>
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </TableReact>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    {meta &&
                                    <Pagination
                                        activePage={meta.current_page}
                                        totalItemsCount={meta.total}
                                        itemsCountPerPage={meta.per_page}
                                        onChange={(pageNumber) => getAdminsData(pageNumber)}
                                        getPageUrl={(pageNumber) => getPageUrl(pageNumber)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const { user, token } = state.auth;
    return {
        user,
        token,
    };
}



export default connect(mapStateToProps)(AdminsPermissionList);

import { combineReducers } from 'redux';
import Customizer from './customizer.reducer';
import auth from "./auth";
import stream from "./stream";
import dashboard from "./dashboard";
import lead from "./lead";
import conversion from "./conversion";
import apiRequest from "./api-request";
import optimize from "./optimize";
import moderation from "./moderation";
import dailyReports from "./daily-reports";
import affiliateReports from "./affiliate-performance";
import advertiserReports from "./advertiser-performance";
import manualConversion from "./manual-conversion";
import affiliateAdvertiserRestriction from "./affiliate/advertiser-restriction";
import sidebar from "./sidebar";
import invoice from "./invoice";

const reducers = combineReducers({
    Customizer,
    auth,
    stream,
    optimize,
    dashboard,
    lead,
    conversion,
    apiRequest,
    moderation,
    dailyReports,
    affiliateReports,
    advertiserReports,
    manualConversion,
    affiliateAdvertiserRestriction,
    sidebar,
    invoice,
});

export default reducers;
import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/advertisers`;

class AdvertiserService {
    getAll(pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {headers: authHeader()});
    }

    getAllFiltering(filter, pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params : {
                filter: {
                    ...filter,
                }
            }
        });
    }
    export(filter) {
        return axios.get(`${baseUrl}/export`, {
            headers: authHeader(),
            params : {
                filter: {
                    ...filter,
                }
            }
        });
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    getByWords(words) {
        return axios.get(`${baseUrl}?filter[words]=${words}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    getPlatform() {
        return axios.get(`${baseUrl}/platform`, {headers: authHeader()})
    }

}

export default new AdvertiserService();
import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/api-request`;

class ApiRequestService {


    getAll(manager = "", geo = "", period = {},  affiliate_id = "",  filtering = {}, pageNumber = 1, pageSize = 25, advertiser_id = "",) {
        return axios.get(`${baseUrl}?page=${pageNumber}&per_page=${pageSize}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                    geo: geo,
                    manager: manager,
                    affiliate: affiliate_id,
                    advertiser: advertiser_id,
                    period: {
                        from: period.from_date,
                        to: period.to_date
                    },
                },
            }
        });
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    reRegister(data) {
        return axios.post(`${baseUrl}/re-register`, data, {headers: authHeader()});
    }
}

export default new ApiRequestService();

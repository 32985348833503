import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import {manualConversionSearch, setManualConversionPeriod} from "../../../actions/manual-conversion";
import PeriodFilter from "../../../utils/period/PeriodFilter";

const SearchManualConversion = ({dispatch, country, period, email, affiliate}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    useEffect(async () => {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("email", email);

    }, [country,  affiliate, email]);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(manualConversionSearch(data?.country, period,  data?.affiliate, data.email ));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">

                    <PeriodFilter
                        setPeriod={(data) => dispatch(setManualConversionPeriod(data))}
                        period={period}
                    />

                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-3 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <div className="col-md-3">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>
                            <div className=" col-md-3">
                                <div className="form-group">
                                    <input className="form-control" type="text" name="email" ref={register}
                                           placeholder="Emails"/>
                                    <span>{errors?.email}</span>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="d-flex">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>

                                </div>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, period, email,  affiliate} = state.manualConversion;
    return {
        country,
        period,
        affiliate,
        email
    };
}

export default connect(mapStateToProps)(SearchManualConversion);

import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from "react-redux";
import StreamTable from "./stream/StreamTable";

const DefaultBucketStreamList = ({buckets, streamFilters}) => {

    const checkStream = (id) => {
        let distributions = [];
        for(let bucket of buckets){
            distributions = distributions.concat(bucket.distributions);
        }
        return distributions.map(function (e) {
            return e?.stream_id;
        }).indexOf(id) == -1
    }

    return (
        <>
            {streamFilters &&
                <tr key={"01"}>
                    <th colSpan={12}>
                        <div className="my-3">
                            <h3>Default Bucket</h3>
                        </div>
                    </th>
                </tr>
            }

            {streamFilters && buckets &&
                streamFilters.map((stream, index) => (
                    <Fragment key={"default-stream-" + index}>
                        {checkStream(stream.id) &&
                            <Fragment key={"stream-" + index}>
                                <StreamTable stream={stream}/>
                            </Fragment>
                        }
                    </Fragment>
                ))}
        </>

    );

}

function mapStateToProps(state) {
    const {streamFilters, buckets} = state.stream;
    return {
        streamFilters,
        buckets
    };
}

export default connect(mapStateToProps)(DefaultBucketStreamList);

import React, {useState, Fragment} from "react";
import {Button, Modal} from "react-bootstrap";
import ModerationService
    from "../../../services/moderation.service";
import {setModerations} from "../../../actions/moderation";
import EventBus from "../../../common/EventBus";
import {connect} from "react-redux";
import moment from "moment";
import {toast} from "react-toastify";
import {getMaxDateTime} from "../../../constant/date";

const ModalModerationUpdateStatus = (props) => {
    const [loading, setLoading] = useState(false);
    const [customDateTrigger, setCustomDateTrigger] = useState(false);
    const [customDate, setCustomDate] = useState(null);
    const {moderation, moderations, dispatch} = props;
    const [errors, setErrors] = useState(undefined);

    const onChange = (value) => {

        setCustomDate(null)
        if (value == 2)
            setCustomDateTrigger(true)
        else
            setCustomDateTrigger(false)
    }

    const onSubmit = async () => {
            try {
                let data = {status: 1}
                if (customDate)
                    data.custom_date = moment(customDate).format('YYYY-MM-DD H:mm:ss');
                ModerationService.update(moderation.id, data)
                    .then(
                        response => {
                            dispatch(setModerations(moderations.map(x => {
                                if (x.id === moderation.id) {
                                    x.status = response.data.data.status;
                                }
                                return x;
                            })));

                        }, error => {
                            const message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            toast.error(message);

                            if (error.response && error.response.status === 422) {
                                setErrors(error.response.data.errors);
                            }

                            if (error.response && error.response.status === 401) {
                                EventBus.dispatch("logout");
                            }
                        })
                    .finally(() => {
                        props.onHide();
                        setLoading(false);

                    })
            } finally {
                setLoading(false);
            }
    }

    const setDate = (value) => {
        setCustomDate(value)
    }



    return (<Fragment>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Moderation #{moderation.id} - {moderation.lead.email}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className={"col-md-12"}>
                        <div className={"form-group"}>
                            <label>Select date</label>
                            <select onChange={(e)=> onChange(e.target.value)} name="date" defaultValue={""} className="custom-select" id="">
                                <option value="1">Real date</option>
                                <option value="2">Custom date</option>
                            </select>
                            {customDateTrigger &&
                                <div className="form-group row pt-2">
                                        <div className="col-md-12">
                                            <input onChange={(e) => setDate(e.target.value)} className="form-control digits" name="from" id="from-datetime"  max={getMaxDateTime()}
                                                   type="datetime-local"/>
                                        </div>
                            </div>}
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onSubmit()}>Approve</Button>
            </Modal.Footer>

        </Modal>
    </Fragment>)
}

function mapStateToProps(state) {
    const {moderations} = state.moderation;
    return {
        moderations
    };
}

export default  connect(mapStateToProps)(ModalModerationUpdateStatus);

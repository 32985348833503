import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Name, Update} from '../../../../../constant';
import BucketService from "../../../../../services/bucket.service";
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {setBuckets} from "../../../../../actions/stream";
import {toast} from "react-toastify";

const ModalCreateBucket = props => {
    const {bucket, buckets, dispatch} = props;
    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        setValue("name", bucket?.name);
    },[show])

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);

        BucketService.update(bucket?.id, data)
            .then(
                response => {
                    dispatch(setBuckets(buckets.map(x => {
                        if (x.id === bucket.id) {
                            x.name = response.data.data.name;
                        }
                        return x;
                    })));

                    handleClose()

                    toast.success("Bucket Updated")
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message)

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

                })
            .finally(() => {
                setLoading(false);

            })

    }


    return (
        <>
            <button
                onClick={handleShow}
                className="btn btn-link p-0"
            >
                <i className="fa fa-pencil text-success fa-2x"></i>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Bucket
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Name}</label>
                                            <input className="form-control" type="text" name="name" ref={register}
                                                   placeholder="Name"/>
                                            <span>{errors?.name}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Update}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}

function mapStateToProps(state) {
    const { buckets} = state.stream;
    return {
        buckets,
    };
}

export default connect(mapStateToProps)(ModalCreateBucket);

import React, {Fragment} from 'react';
import Breadcrumb from "../common/breadcrumb";
import 'react-confirm-alert/src/react-confirm-alert.css';
import SearchManualConversion from "./parts/SearchManualConversion";
import Table from "./parts/table";
import Title from "../common/custom-title";

const ManualConversionList = () => {

    return (
        <Fragment>
            <Title title="Manual Conversion"/>
            <Breadcrumb title="Manual Conversion"/>
            <div className="container-fluid">
                <SearchManualConversion/>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <Table/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default (ManualConversionList);
import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/leads`;

class LeadService {

    getFilter(manager = "",geo = "", period = {}, periodTime = "", affiliate_id = "", advertiser_id = "", filtering = {}, pageNumber = 1, pageSize = 25) {

        return axios.get(`${baseUrl}?page=${pageNumber}&per_page=${pageSize}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                    email: filtering.email && filtering.email.length ? filtering.email.map((el) => el.value) : undefined,
                    geo: geo,
                    affiliate: affiliate_id,
                    manager: manager,
                    advertiser: advertiser_id,
                    period: {
                        from: period.from_date,
                        to: period.to_date
                    },
                    periodTime: periodTime,
                },
            }
        });
    }
    getAllConversions(manager = "",geo = "", period = {}, periodTime = "", affiliate_id = "", advertiser_id = "", filtering = {}, pageNumber = 1, pageSize = 25) {

        return axios.get(`${baseUrl}/conversions?page=${pageNumber}&per_page=${pageSize}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                    email: filtering.email && filtering.email.length ? filtering.email.map((el) => el.value) : undefined,
                    geo: geo,
                    affiliate: affiliate_id,
                    advertiser: advertiser_id,
                    manager: manager,
                    periodConversion: {
                        from: period.from_date,
                        to: period.to_date
                    },
                    periodTimeConversion: periodTime,
                },
            }
        });
    }


    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    export(manager = "", geo = "", period = {}, periodTime = "", affiliate_id = "", advertiser_id = "", filtering = {}, columns = null){
        return axios.get(`${baseUrl}/export`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                    email: filtering.email && filtering.email.length ? filtering.email.map((el) => el.value) : undefined,
                    geo: geo,
                    affiliate: affiliate_id,
                    manager: manager,
                    advertiser: advertiser_id,
                    period: {
                        from: period.from_date,
                        to: period.to_date
                    },
                    periodTime: periodTime,
                },
                visibleColumns: columns
            }
        });
    }

    exportConversions(manager = "",geo = "", period = "",  periodTime = "", affiliate_id = "", advertiser_id = "", filtering = {}, columns = null){
        return axios.get(`${baseUrl}/conversions/export`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                    email: filtering.email && filtering.email.length ? filtering.email.map((el) => el.value) : undefined,
                    geo: geo,
                    affiliate: affiliate_id,
                    advertiser: advertiser_id,
                    manager: manager,
                    periodConversion: {
                        from: period.from_date,
                        to: period.to_date
                    },
                    periodTimeConversion: periodTime,
                },
                visibleColumns: columns
            }
        });
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    updateAffPayout(id, data) {
        return axios.put(`${baseUrl}/${id}/aff-payout`, data, {headers: authHeader()});
    }

    updateAdvPayout(id, data) {
        return axios.put(`${baseUrl}/${id}/adv-payout`, data, {headers: authHeader()});
    }

    updateIsTest(data) {
        return axios.put(`${baseUrl}/test`, data, {headers: authHeader()});
    }

    reRegister(data) {
        return axios.post(`${baseUrl}/re-register`, data, {headers: authHeader()});
    }

    history(id) {
        return axios.get(`${baseUrl}/history/${id}`, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

}

export default new LeadService();

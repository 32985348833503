import React, {Fragment} from 'react';
import AsyncSelect from 'react-select/async';
import MakeAnimated from 'react-select/animated';
import {Affiliate} from "../../constant";
import {Controller} from "react-hook-form";
import AffiliateService from "../../services/affiliate.service";

function ReportAffiliatesSelected(props) {
    const {control, error, label = false} = props;
    const animatedComponent = MakeAnimated()



    const fetchData = (inputValue) => {
        return AffiliateService.getByWords(inputValue).then(result => {
            return result.data.data;
        });
    }

    return (
        <Fragment>
            <div className="form-group">
                {label && (
                    <label className="form-label">{Affiliate}</label>
                )}

                <Controller
                    {...props}
                    as={AsyncSelect}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.name + ' - ' + e.email }
                    getOptionValue={e => e.id}
                    placeholder={"Affiliate"}
                    defaultOptions
                    componets={animatedComponent}
                    cacheOptions
                    name="affiliate"
                    isClearable
                    control={control}
                />
                <span className={'text-danger-light'}>{error}</span>

            </div>
        </Fragment>

    );
}

export default ReportAffiliatesSelected
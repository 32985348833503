import React, {Fragment, useState} from 'react';
import AsyncSelect from 'react-select/async';
import MakeAnimated from 'react-select/animated';
import platformService from "../../../services/advertiser/platform.service";
import {Country, Geo} from "../../../constant";
import {Controller} from "react-hook-form";

function PlatformSelected(props) {
    const {  error, showTitle = false} = props;
    const animatedComponent = MakeAnimated()

    const fetchData = (inputValue) => {
        return platformService.getByWords(inputValue).then(result => {
            return result.data.data;
        });
    }

    return (
        <Fragment>

            <div className="form-group">
                {showTitle && (
                    <label className="form-label">{"Platform"}:</label>
                )}

                <Controller
                    {...props}
                    as={AsyncSelect}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.title}
                    getOptionValue={e => e.title}
                    defaultOptions
                    componets={animatedComponent}
                    cacheOptions
                    name="platformObject"
                    isClearable
                />
                <span className={'text-danger-light'}>{error}</span>
            </div>

        </Fragment>

    );
}

export default PlatformSelected
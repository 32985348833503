import React, {Fragment, useCallback} from 'react'
import {ChevronRight, ChevronDown} from 'react-feather';
import VisibleColumns
    from "./VisibleColumns";
import InnerTableCountry
    from "./innerTableCountry";
import ExportReport
    from "./ExportReport";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useDailyReportColumn} from "../../../store/useDailyReportColumn";

const APPEND_HEADERS = [
    {
        // Make an expander cell
        header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()} className="chevron">
                            {row.isExpanded ? <ChevronDown/> : <ChevronRight/>}
                        </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
        width: 50
    },
    {
        header: 'Date',
        accessor: 'date',
        is_visible: 0,
        Footer: () => 'Total'
    },
];

const Table = ({data}) => {
    const columns = useDailyReportColumn((state) => state.columns)

    const renderRowSubComponent = useCallback(({row}) => (
        <InnerTableCountry
            row={row}
        />
    ), [])

    console.log(data)

    return (
        <Fragment>
            <div className={"card-header"}>
                <div className={"action-btns d-flex justify-content-between"}>
                    <div className={"d-flex gap-2"}>
                        <div className={"d-flex text-center flex-column"}>
                            <ExportReport type="daily"/>
                            <small>date</small>
                        </div>
                        <div className={"d-flex text-center flex-column"}>
                            <ExportReport type="daily-countries"/>
                            <small>date+geo</small>
                        </div>
                    </div>
                    <VisibleColumns/>
                </div>
            </div>
            <div className="table-responsive">
                <ReportTableExpanded
                    data={data}
                    columns={columns}
                    appendHeaders={APPEND_HEADERS}
                    visibleTotal={true}
                    renderRowSubComponent={renderRowSubComponent}
                />
            </div>
        </Fragment>
    )
}


export default Table;

import React from "react";

export const PaginationTitle = ({from, to, total}) => {
    return (
        <div className="d-flex">
            <p className="m-0">
                <span className="font-weight-bold">{from}</span> of <span className="font-weight-bold">
                                        {to}</span> - <span className="font-weight-bold">{total}</span>
            </p>
        </div>
    )
}

import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = '/tools/import'

class ImportService {
    sheet() {
        return axios.post(`${baseUrl}/sheet`, {}, {headers: authHeader()})
            .then((res) => res.data.data)
    }

    import(data) {
        return axios.post(`${baseUrl}`, data, {headers: authHeader()})
            .then((res) => res.data)
    }

    leads() {
        return axios.get(`${baseUrl}`, {headers: authHeader()})
            .then((res) => res.data.data)
    }

    deleteSheet() {
        return axios.delete(`${baseUrl}/sheet`, {headers: authHeader()})
            .then((res) => res.data.data)
    }
}

export default new ImportService()
import React, {
    Fragment,
    useEffect,
    useMemo, useState
} from 'react';
import Breadcrumb from "../common/breadcrumb";
import 'react-confirm-alert/src/react-confirm-alert.css';
import SearchLeads from "./parts/SearchLeads";
import {connect} from "react-redux";
import Table from "./parts/table";
import {useTranslation} from "react-i18next";
import ReportColumnsService
    from "../../services/report-columns.service";
import Title from "../common/custom-title";
import {TimezoneSelectStore} from "../select/timezone/TimezoneSelectStore";

const LeadsList = ({user, leads}) => {
    const { t } = useTranslation();
    const [adminColumns, setAdminColumns] = useState([]);

    useEffect(() => {
        if (!user.data.roles.includes('affiliate')) {
            ReportColumnsService.getAll().then(res => {
                let adminColumns = res.data.data.filter(el => el.type === 'Leads' && el.title !== 'aff_conversion_date').map(el => {
                    return {
                        Header: t(el.title),
                        accessor: el.title,
                        id: el.title,
                        id_num: el.id
                    }
                })
                adminColumns.push({
                    Header: 'Action',
                    accessor: 'btns',
                    id: 'btns'
                })
                setAdminColumns(adminColumns)
            }).catch(err => console.log(err))
        }
    }, [])

    let affiliateColumns = user.data.columns?.filter(el => el.type === 'Leads').map(el => {
        return {Header: t(el.title), accessor : el.title, id: el.title, id_num: el.id}
    })

    let data = useMemo(() => leads, [leads])

    let columns = [];
    if (user.data.roles.includes('affiliate')) {
        columns = affiliateColumns
    } else columns = adminColumns

    let reports_columns = useMemo(() => {
        return columns
    }, [columns])

    return (<Fragment>
            <Title title="Leads"/>
            <Breadcrumb title="Leads"/>
            <TimezoneSelectStore/>


            <div className="container-fluid">
                <SearchLeads/>

                <div className="row">
                    <div className="col-sm-12">
                        {leads && (<Table columns={reports_columns} data={data}/>)}
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {leads} = state.lead;
    const {user} = state.auth
    return {
        user, leads
    };
}

export default connect(mapStateToProps)(LeadsList);

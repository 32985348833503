import React, {useEffect} from "react";
import {BrowserRouter, Router, Switch} from "react-router-dom";

import {history} from './helpers/history';

import {ScrollContext} from "react-router-scroll-4";
import {renderRoutes} from "react-router-config-guard";
import routes from "./routes";
import {getMe, logout} from "./actions/auth";
import EventBus from "./common/EventBus";
import {compose} from "redux";
import configDB from './data/customizer/config'
import {connect} from "react-redux";
import "./i18n";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css"
import {moderationPendingCount} from "./actions/moderation";

// Auth
function App(props) {

    const logOut = () => {
        props.dispatch(logout());
        let loginUrl = localStorage.getItem('loginUrl')
        let redirectUrl = '/login';
        if (loginUrl){
            let s = new URL(loginUrl)
            redirectUrl  = s.pathname
        }
        history.push(redirectUrl);
        window.location.reload();
    }

    useEffect(() => {

        if (props.token) {
            props.dispatch(getMe());
            props.dispatch(moderationPendingCount());
        }

        EventBus.on("logout", () => {
            logOut();
        });

        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        document.body.classList.add(layout);
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color-6.css`);

        return () => {
            EventBus.remove("logout");
        }
    }, [props.user]);

    return (
        <>
            <Router history={history}>

                <div className="App">
                    <BrowserRouter basename={'/'}>
                        <ScrollContext>
                            <Switch>
                                {renderRoutes(routes)}
                            </Switch>
                        </ScrollContext>
                    </BrowserRouter>
                </div>
            </Router>
            <ToastContainer
                theme="light"
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

function mapStateToProps(state) {
    const {token} = state.auth;
    return {
        token
    };
}

const withProps = connect(mapStateToProps);

export default compose(
    withProps,
)(App);

import {
    MODERATION_FILTER_FAIL,
    MODERATION_FILTER_SUCCESS,
    SET_MODERATION_PERIOD_SUCCESS,
    SET_MODERATION_SUCCESS,
    MODERATION_PENDING_COUNT_SUCCESS,
    MODERATION_PENDING_COUNT_FAIL
} from "./types";
import ModerationService from "../services/moderation.service";
import { refreshPeriod } from "../utils/period/period";


export const moderationSearch = (manager, country, period, affiliate, status, pageNumber) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return ModerationService.getFilter(manager?.id, country?.iso, updatedPeriod,  affiliate?.id ,status, pageNumber).then(
        (response) => {
            dispatch({
                type: MODERATION_FILTER_SUCCESS,
                payload: {
                    moderations: response.data.data,
                    meta: response.data.meta,
                    country: country,
                    manager: manager,
                    status: status,
                    affiliate: affiliate,
                    period: updatedPeriod,
                },
            });

            return Promise.resolve();
        },
        (error) => {

            dispatch({
                type: MODERATION_FILTER_FAIL,
            });



            return Promise.reject();
        }
    );
};
export const moderationPendingCount = () => (dispatch) => {
    return ModerationService.pendingCount().then(
        (response) => {
            dispatch({
                type: MODERATION_PENDING_COUNT_SUCCESS,
                payload: {
                    pendingCount: response.data.moderation_count,
                },
            });

            return Promise.resolve();
        },
        (error) => {

            dispatch({
                type: MODERATION_PENDING_COUNT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setModerations = (moderations) => (dispatch) => {
    dispatch({
        type: SET_MODERATION_SUCCESS,
        payload: {
            moderations: moderations,
        },
    });

    return Promise.resolve();
}

export const setModerationPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_MODERATION_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

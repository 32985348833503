import React from "react";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../constant";
import DowntimeService from "../../../../services/advertiser/downtime.service";

const ModalDeleteDowntime = ({downtime, setDowntimes, downtimes}) => {

    console.log(downtimes)
    const deleteDowntime = (id) => {
        DowntimeService.delete(id).then(() => {
            setDowntimes(downtimes.filter(x => x.id !== id));
        });
    }

    const submitDeleteDowntime = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteDowntime(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button onClick={() => submitDeleteDowntime(downtime.id)}
                    className="btn btn-link p-0" disabled={downtime.isDeleting}>
                {downtime.isDeleting
                    ?
                    <span className="spinner-border spinner-border-lg "></span>
                    : <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    )
}

export default ModalDeleteDowntime;
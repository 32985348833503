import {calculateDividePercent, compareNumericString, formatCurrency, formatPercent} from "../utils/number";

export const REPORT_COLUMNS = [
    {
        header: 'Leads',
        accessor: 'count_leads',
        is_visible: 1,
        Footer: info => info.rows.reduce((sum, row) => row.values.count_leads + sum, 0),
        sortType: compareNumericString
    },
    {
        header: 'All Conversions',
        accessor: 'count_conversions',
        is_visible: 1,
        Footer: info => info.rows.reduce((sum, row) => row.values.count_conversions + sum, 0),
        sortType: compareNumericString
    },
    {
        header: 'Pending Conversions',
        accessor: 'count_pending',
        is_visible: 1,
        Footer: info => info.rows.reduce((sum, row) => row.values.count_pending + sum, 0),
        sortType: compareNumericString
    },
    {
        header: 'Reject Conversions',
        accessor: 'count_reject_conversion',
        is_visible: 1,
        Footer: info => info.rows.reduce((sum, row) => row.original.count_reject_conversion + sum, 0),
        sortType: compareNumericString
    },
    {
        header: 'Conversions',
        accessor: 'count_finish_conversions',
        is_visible: 1,
        Footer: info => info.rows.reduce((sum, row) => row.values.count_finish_conversions + sum, 0),
        sortType: compareNumericString
    },
    {
        header: 'CR',
        accessor: 'cr',
        is_visible: 1,
        Cell: ({row}) => formatPercent(row.original.cr),
        Footer: info => formatPercent(calculateDividePercent(info.rows.reduce((sum, row) => row.values.count_conversions + sum, 0), info.rows.reduce((sum, row) => row.values.count_leads + sum, 0))),
        sortType: compareNumericString
    },
    {
        header: 'CR Aff',
        accessor: 'cr_aff',
        is_visible: 1,
        Cell: ({row}) => formatPercent(row.original.cr_aff),
        Footer: info => formatPercent(calculateDividePercent(info.rows.reduce((sum, row) => row.values.count_finish_conversions + sum, 0), info.rows.reduce((sum, row) => row.values.count_leads + sum, 0))),
        sortType: compareNumericString
    },
    {
        header: 'Income',
        accessor: 'income',
        is_visible: 1,
        Cell: ({row}) => formatCurrency(row.original.income),
        Footer: info => formatCurrency(info.rows.reduce((sum, row) => row.values.income + sum, 0)),
        sortType: compareNumericString
    },
    {
        header: 'Cost',
        accessor: 'cost',
        is_visible: 1,
        Cell: ({row}) => formatCurrency(row.original.cost),
        Footer: info => formatCurrency(info.rows.reduce((sum, row) => row.values.cost + sum, 0)),
        sortType: compareNumericString
    },
    {
        header: 'Profit',
        accessor: 'profit',
        is_visible: 1,
        Cell: ({row}) => formatCurrency(row.original.profit),
        Footer: info => formatCurrency(info.rows.reduce((sum, row) => row.values.profit + sum, 0)),
        sortType: compareNumericString
    },
    {
        header: 'Margin',
        accessor: 'performance',
        is_visible: 1,
        Cell: ({row}) => formatPercent(row.original.performance),
        Footer: info => formatPercent(calculateDividePercent(info.rows.reduce((sum, row) => row.original.profit + sum, 0), info.rows.reduce((sum, row) => row.original.income + sum, 0))),
        sortType: compareNumericString
    },
];

export const PERFORMANCE_REPORT_COLUMNS = [
    {
        header: 'Sale EPL',
        accessor: 'sale_epl',
        is_visible: 1,
        Cell: ({row}) => formatCurrency(row.original.sale_epl),
        Footer: info => formatCurrency(info.rows.reduce((sum, row) => row.original.profit + sum, 0) / info.rows.reduce((sum, row) => row.original.count_leads + sum, 0)),
        sortType: compareNumericString
    }
]

export const ADVERTISER_REPORT_COLUMNS = [
    {
        header: 'ADV EPL',
        accessor: 'adv_epl',
        is_visible: 1,
        Cell: ({row}) => formatCurrency(row.original.adv_epl),
        Footer: info => formatCurrency(info.rows.reduce((sum, row) => row.original.income + sum, 0) / info.rows.reduce((sum, row) => row.original.count_leads + sum, 0)),
        sortType: compareNumericString
    }
];

export const AFFILIATE_REPORT_COLUMNS = [
    {
        header: 'AFF EPL',
        accessor: 'aff_epl',
        is_visible: 1,
        Cell: ({row}) => formatCurrency(row.original.aff_epl),
        Footer: info => formatCurrency(info.rows.reduce((sum, row) => row.original.cost + sum, 0) / info.rows.reduce((sum, row) => row.original.count_leads + sum, 0)),
        sortType: compareNumericString
    }
]
import {
    OPTIMIZE_FILTER_FAIL, OPTIMIZE_FILTER_SUCCESS, SET_OPTIMIZE_SUCCESS
} from "../actions/types";

const initialState = {
    optimizes: null,
    meta: null,
    country: null,
    affiliate: null,
    advertiser: null,

};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case OPTIMIZE_FILTER_SUCCESS:
            return {
                ...state,
                optimizes: payload.optimizes,
                meta: payload.meta,
                country: payload.country,
                affiliate: payload.affiliate,
                advertiser: payload.advertiser,
            };

        case OPTIMIZE_FILTER_FAIL:
            return {
                ...state,
                optimizes: null,
                meta: null,
                country: null,
                affiliate: null,
                advertiser: null,
            };

        case SET_OPTIMIZE_SUCCESS:
            return {
                ...state,
                optimizes: payload.optimizes,

            };

        default:
            return state;
    }
}

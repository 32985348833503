import React, {Fragment, useEffect, useMemo, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import {
    dashboardSearch, setDashboardAdvertiser,
    setDashboardAffiliate,
    setDashboardCountry,
    setDashboardFiltering
} from "../../../actions/dashboard";
import AdvertiserSelected from "../../advertiser/AdvertiserSelected";
import {Search} from "../../../constant";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_FILTER_AFFILIATE, CAN_FILTER_ADVERTISER} from "../../../constant/permissions";
import {setDashboardPeriod} from "../../../actions/dashboard";
import PeriodFilter from "../../../utils/period/PeriodFilter";
import ModalFilter from "./modal/ModalFilter";
import {Button} from "react-bootstrap";
import {DEFAULT_DASHBOARD_FILTERING} from "../../../reducers/dashboard";
import {useTimezoneStore} from "../../../store/timezone";

const SearchDashboard = ({dispatch, country, period, filtering, advertiser, affiliate}) => {
    const {handleSubmit, reset, control, setValue, watch} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const {timezone} = useTimezoneStore()

    useEffect( () => {
        setValue("country", country);

    }, [country]);

    useEffect(() => {
        onSubmit({country, affiliate, advertiser}).catch()
    }, [timezone])

    const isClear = useMemo(
        () => {
            const {country: wCountry, manager: wManager, affiliate: wAffiliate, advertiser: wAdvertiser} = watch()
            return !!wCountry || !!wManager || !!wAffiliate || !!wAdvertiser ||
                JSON.stringify(filtering) !== JSON.stringify(DEFAULT_DASHBOARD_FILTERING)
        },
        [filtering, watch]
    )

    const clearFilter = async () => {
        //clear form
        setValue("country", null);
        setValue("affiliate", null);
        setValue("advertiser", null);

        //clear state
        await dispatch(setDashboardFiltering(DEFAULT_DASHBOARD_FILTERING));
        await dispatch(setDashboardCountry(null));
        await dispatch(setDashboardAffiliate(null));
        await dispatch(setDashboardAdvertiser(null));
    }

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(dashboardSearch(data?.country, period, data?.affiliate, data?.advertiser, filtering, timezone)).catch(error => {
                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }

            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>

                        <PeriodFilter
                            setPeriod={(data) => dispatch(setDashboardPeriod(data))}
                            period={period}
                        />

                        <div className="form-row">
                            <div className="col-md-3 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <ShowForPermission permission={CAN_FILTER_AFFILIATE}>
                                <div className="col-md-3">
                                    <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                                </div>
                            </ShowForPermission>
                            <ShowForPermission permission={CAN_FILTER_ADVERTISER}>
                                <div className="col-md-3">
                                    <AdvertiserSelected label={false} control={control} error={errors?.advertiser_id}/>
                                </div>
                            </ShowForPermission>
                            <div className="col-md-2">
                                <div className="d-flex">
                                <button className="btn btn-primary " type="submit"
                                        disabled={loading}
                                >
                                    <span>{Search}&nbsp;</span>
                                    {loading && (<span
                                        className="spinner-border spinner-border-sm"></span>)}
                                </button>
                                <Button onClick={() => setShowModalFilter(true)}
                                        className='btn btn-primary ml-2'>
                                    <i className="fa fa-bars" aria-hidden="true"></i>
                                </Button>
                                <Button onClick={() => clearFilter()}
                                        className={`btn btn-primary ml-2 ${isClear ? "" : "d-none"}`}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <ModalFilter
                        show={showModalFilter}
                        onHide={() => setShowModalFilter(false)}
                    />
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, filtering} = state.dashboard;
    return {
        country, period, filtering, affiliate, advertiser
    };
}

export default connect(mapStateToProps)(SearchDashboard);

import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import LeadService from "../../../../services/lead.service";
import {connect} from "react-redux";
import {leadSearch} from "../../../../actions/lead";
import {toast} from "react-toastify";
import {useTimezoneStore} from "../../../../store/timezone";

const ModalEditLeadIsTest = props => {
    const {
        lead,
        dispatch,
        country,
        period,
        periodTime,
        affiliate,
        advertiser,
        pageNumber,
        filtering,
        manager,
        pageSize
    } = props;
    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);
    const {timezone} = useTimezoneStore()

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {

        setValue('is_test', "" + lead?.is_test);
    }, [lead, show]);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        data.leads = [lead.id]

        LeadService.updateIsTest(data)
            .then( async () => {
                await dispatch(leadSearch(manager, country, period, periodTime, affiliate, advertiser, pageNumber, filtering, pageSize, timezone));

                handleClose()
            }, error => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                toast.error(message);

                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }


            })
            .finally(() => {
                setLoading(false);

            })

    }

    return (
        <>

            <Button
                onClick={handleShow}
                className='btn btn-primary ml-2'>
                <i className="fa fa-edit"></i>
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Edit Lead"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{"Status"}</label>
                                            <select name="is_test" className="custom-select" id="" ref={register}>
                                                <option value="0">Real</option>
                                                <option value="1">Test</option>
                                            </select>
                                            <span>{errors?.is_test}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{"Save"}&nbsp;</span>
                                        {loading && (<span
                                                className="spinner-border spinner-border-sm"></span>)}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}

function mapStateToProps(state) {
    const {country, period, periodTime, affiliate, advertiser, pageNumber, filtering, pageSize, manager} = state.lead;
    return {
        country, period, periodTime, affiliate, advertiser, pageNumber, filtering, pageSize, manager
    };
}

export default connect(mapStateToProps)(ModalEditLeadIsTest);

import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/role`;

class RoleService {
    getAll() {
        return axios.get(`${baseUrl}`, {headers: authHeader()});
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    attachPermission(role,data){
        return axios.put(`${baseUrl}/${role}/attach-permission`, data, {headers: authHeader()});
    }

    detachPermission(role, data){
        return axios.put(`${baseUrl}/${role}/detach-permission`, data, {headers: authHeader()});
    }


}

export default new RoleService();
import {ShowForPermission} from "../../../../helpers/ShowForPermission";
import {CAN_VIEW_HISTORY_LEADS} from "../../../../constant/permissions";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import {ConvertTimezoneDate} from "../../../select/timezone/ConvertTimezoneDate";

export const SaleStatusRaw = ({cell, key, history, dispatchFn}) => {
    const [focusedHistoryId, setFocusedHistoryId] = useState()
    const [loadingHistory, setLoadingHistory] = useState(false)
    const historyData = useMemo(() => {
        return history.find((el) => el.id === focusedHistoryId)
    }, [history, focusedHistoryId])

    const showHistory = async (cell) => {
        setFocusedHistoryId(cell.row.values.id)
        const item = history.find((el) => el.id === cell.row.values.id);
        if (!item) {
            try {
                setLoadingHistory(true)
                await dispatchFn()
            } finally {
                setLoadingHistory(false)
            }
        }
    }


    return (
        <>
            <ShowForPermission permission={CAN_VIEW_HISTORY_LEADS}>
                {cell.value ?
                    <OverlayTrigger
                        key={key}
                        onToggle={() => showHistory(cell)}
                        placement="right"
                        overlay={
                            <Popover id="popover-positioned-right">
                                <Popover.Content>
                                    {
                                        loadingHistory ?
                                            <span className="spinner-border spinner-border-sm"></span> :
                                            historyData?.data.length ? <table
                                                    className="table table-borderless table-striped"
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Date</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {historyData.data.map((el) =>
                                                        <tr>
                                                            <th>{el.status}</th>
                                                            <th>
                                                                <ConvertTimezoneDate
                                                                    date={el.date}
                                                                    />
                                                            </th>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                                : <></>
                                    }
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <Button variant={'link'} className={'p-0'} style={{color: 'black'}}>
                            {cell.value}
                        </Button>
                    </OverlayTrigger>
                    : <></>
                }
            </ShowForPermission>
            <ShowForPermission permission={CAN_VIEW_HISTORY_LEADS} isNotPermission={true}>
                {cell.value}
            </ShowForPermission>
        </>
    );
}

import {
    AFFILIATE_PERFORMANCE_REPORT_FILTER_FAIL,
    AFFILIATE_PERFORMANCE_REPORT_FILTER_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_ADVERTISER_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_AFFILIATE_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_COUNTRY_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_MANAGER_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_PERIOD_SUCCESS,

} from "./types";
import affiliatePerformanceReportService from "../services/affiliate-performance.service";
import {refreshPeriod} from "../utils/period/period";


export const AffiliatePerformanceReportSearch = (manager, country, period, affiliate, advertiser) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return affiliatePerformanceReportService.getAffiliatePerformanceReports(manager?.id, country, updatedPeriod, affiliate, advertiser).then(
        (response) => {
            dispatch({
                type:AFFILIATE_PERFORMANCE_REPORT_FILTER_SUCCESS,
                payload: {
                    affiliateReports: response.data,
                    country: country,
                    manager: manager,
                    advertiser: advertiser,
                    affiliate: affiliate,
                    period: updatedPeriod,
                },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type:AFFILIATE_PERFORMANCE_REPORT_FILTER_FAIL,
            });




            return Promise.reject();
        }
    );
};

export const setAffiliatePerformancePeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_AFFILIATE_PERFORMANCE_REPORT_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

export const setAffiliatePerformanceCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_AFFILIATE_PERFORMANCE_REPORT_COUNTRY_SUCCESS,
        payload: {
            country: country,
        },
    });

    return Promise.resolve();
}

export const setAffiliatePerformanceAdvertiser = (advertiser) => (dispatch) => {
    dispatch({
        type: SET_AFFILIATE_PERFORMANCE_REPORT_ADVERTISER_SUCCESS,
        payload: {
            advertiser: advertiser,
        },
    });

    return Promise.resolve();
}

export const setAffiliatePerformanceAffiliate = (affiliate) => (dispatch) => {
    dispatch({
        type: SET_AFFILIATE_PERFORMANCE_REPORT_AFFILIATE_SUCCESS,
        payload: {
            affiliate: affiliate,
        },
    });

    return Promise.resolve();
}

export const setAffiliatePerformanceManager = (manager) => (dispatch) => {
    dispatch({
        type: SET_AFFILIATE_PERFORMANCE_REPORT_MANAGER_SUCCESS,
        payload: {
            manager: manager,
        },
    });

    return Promise.resolve();
}


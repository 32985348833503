import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/affiliates`;

class AffiliateService {
    getAll(pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {headers: authHeader()});
    }

    getAllFiltering(filter = {}, pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filter,
                }
            }
        });
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    getByWords(words) {
        return axios.get(`${baseUrl}?filter[words]=${words}`, {headers: authHeader()});
    }

    getAllManager(pageNumber = 1) {
        return axios.get(`${baseUrl}-manager?page=${pageNumber}`, {headers: authHeader()});
    }

    getAllManagerFiltering(filter, pageNumber = 1) {
        return axios.get(`${baseUrl}-manager?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filter,
                }
            }
        });
    }

    getByWordsManager(words) {
        return axios.get(`${baseUrl}-manager?filter[words]=${words}`, {headers: authHeader()});
    }

    downloadAPIDoc(id){
        return axios.get(`${baseUrl}/${id}/download/pdf`, {headers: authHeader(), responseType: 'blob' });
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    updateReportColumns(columns, affiliate_id){
        return axios.put(`${baseUrl}/${affiliate_id}/report-columns`, columns, {headers:authHeader()})
    }

    setDefaultReportColumns(affiliate_id){
        return axios.get(`${baseUrl}/${affiliate_id}/report-columns/set-default`,{headers:authHeader()})
    }
}

export default new AffiliateService();
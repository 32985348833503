import axios from "../../utils/api";
import authHeader from "../auth-header";

const baseUrl = `advertisers/platforms`;

class PlatformService {
    getAll() {
        return axios.get(`${baseUrl}`, {headers: authHeader(),});
    }

    show(platform_id, data) {
        return axios.post(`${baseUrl}${platform_id}`, data, {headers: authHeader()});
    }

    getByWords(words) {
        return axios.get(`${baseUrl}`, {
            headers: authHeader(),
            params: {
                filter: {
                    words,
                }
            }
        });
    }
}

export default new PlatformService();
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
    AreYouSure,
    ConfirmAddWorkingHours,
    Create,  No, Yes
} from '../../../../constant';
import {Button, Modal} from "react-bootstrap";
import IsoSelected from "../../../country/IsoSelected";
import DowntimeService from "../../../../services/advertiser/downtime.service";
import {daysOfWeekObject} from "../../../../constant/date";
import TimezoneSelect from 'react-timezone-select'
import Select from 'react-select';
import MakeAnimated from "react-select/animated";
import { toast } from 'react-toastify';
import {confirmAlert} from "react-confirm-alert";
import moment from "moment/moment";

const ModalCreateDowntime = ({advertiserID, isoList, getDowntimesData}) => {
    const {register, handleSubmit, reset, control} = useForm();
    const animatedComponent = MakeAnimated()

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = (isConfirmAlert = true) => {
        if(!isConfirmAlert){
            setShow(false)
            return true;
        }

        confirmAlert({
            title: ConfirmAddWorkingHours,
            message: AreYouSure,
            buttons: [
                {
                    label: Yes,
                    onClick: () => setShow(false)
                },
                {
                    label: No,
                }
            ]
        });

    }

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.day_of_weeks = data.day_of_weeks?.map(e => e.value)
        data.countries_iso = data.country?.map(e => e.iso);
        data.timezone = selectedTimezone === "Etc/GMT" ? "UTC" : selectedTimezone;
        data.from = moment(data.from, "H:mm:ss").format("HH:mm:ss")
        data.to = moment(data.to, "H:mm:ss").format("HH:mm:ss")

        DowntimeService.create(advertiserID, data)
            .then(
                async response => {
                    getDowntimesData()

                    handleClose(false)

                    toast.success("Advertiser Working Hours Created");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);
                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }
                })
            .finally(() => {
                setLoading(false);

            })
    }

    return (
        <>
            <button className="btn btn-primary btn-sm"
                    onClick={handleShow}
            >
                Add Working Hours
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Advertiser Working Hours
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">


                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="from">From Time:</label>
                                            <input className="form-control" type="time" name="from" id={"from"}
                                                   ref={register} step="1"/>
                                            <span>{errors?.from}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="to">To Time:</label>
                                            <input className="form-control" type="time" name="to" id="to" ref={register}
                                                   step="1"/>
                                            <span>{errors?.to}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="to">Day of Week:</label>

                                            <Controller
                                                as={Select}
                                                componets={animatedComponent}
                                                name="day_of_weeks"
                                                control={control}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                options={daysOfWeekObject()}
                                            />
                                            <span className={"text-danger-light"}>{errors?.day_of_weeks}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="to">GTM Timezone:</label>

                                            <div className="select-wrapper">
                                                <TimezoneSelect
                                                    value={selectedTimezone}
                                                    onChange={(data) => setSelectedTimezone(data.value)}
                                                />
                                            </div>
                                            <span className="text-danger-light">{errors?.timezone}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-10 ">
                                        <IsoSelected control={control} error={errors?.countries_iso} showTitle={true}
                                                     isMulti closeMenuOnSelect={false} onlyIsoList={isoList}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


export default ModalCreateDowntime;

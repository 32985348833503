import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import AffiliatesSelected from "../../../affiliate/AffiliatesSelected";
import AdvertiserSelected from "../../../advertiser/AdvertiserSelected";
import LeadService from "../../../../services/lead.service";
import {toast} from "react-toastify";
import {setReRegisterLeadIDs} from "../../../../actions/lead";

const ModalReRegisterLeads = props => {
    const {reRegisterLeadIDs, dispatch, show} = props;
    const {register, handleSubmit, reset, control, watch} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        if(!data?.agree_terms){
            setErrors({
                "agree_terms": "Please agree with Terms And Conditions"
            })
            setLoading(false);
            return;
        }
        data.affiliate_id = data.affiliate?.id;
        data.advertiser_id = data.advertiser?.id;
        data.lead_ids = reRegisterLeadIDs;

        LeadService.reRegister(data)
            .then(
                async response => {
                    await dispatch(setReRegisterLeadIDs([]));
                    await props.onHide();
                    await toast.success("Re-register success!");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }


                })
            .finally(() => {
                setLoading(false);

            })

    }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Re-Register Leads
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <div className="col-md-12 ">
                                        <AffiliatesSelected control={control}
                                                            error={errors?.affiliate_id}
                                                            showTitle={true}
                                        />
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   name="re_register" ref={register}
                                                   id="re_register1" value="1"/>
                                            <label className="form-check-label" htmlFor="re_register1">
                                                Re-register According To Rotation
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   name="re_register" ref={register}  defaultChecked={true}
                                                   id="re_register2" value="2"/>
                                            <label className="form-check-label" htmlFor="re_register2">
                                                Re-register To the same advertiser
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   name="re_register" ref={register}
                                                   id="re_register3" value="3"/>

                                            <label className="form-check-label" htmlFor="re_register3">
                                                Re-register To another advertiser
                                            </label>
                                        </div>
                                    </div>

                                    {watch("re_register") === "3" && (
                                        <div className="col-md-12 ">
                                            <AdvertiserSelected control={control}
                                                                error={errors?.advertiser_id}
                                            />
                                        </div>
                                    )}

                                    <div className="col-md-12 mt-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                   name="get_custom_params" ref={register} value="1"
                                                   id="get_custom_params" />
                                            <label className="form-check-label" htmlFor="get_custom_params">
                                                Get custom parameters from selected Leads
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                   name="ignore_user_restrictions" ref={register} value="1"
                                                   id="ignore_user_restrictions"/>
                                            <label className="form-check-label" htmlFor="ignore_user_restrictions">
                                                Ignore affiliate restrictions
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                   name="ignore_user_limits" ref={register} value="1"
                                                   id="ignore_user_limits" />
                                            <label className="form-check-label" htmlFor="ignore_user_limits">
                                                Ignore affiliate geos
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="agree_terms"
                                                   ref={register} value="1"
                                                   id="agree_terms" />
                                            <label className="form-check-label" htmlFor="agree_terms">
                                                I agree with <a href="#"> Terms And Conditions</a>
                                            </label>

                                        </div>
                                        <span className="text-danger-light">{errors?.agree_terms}</span>
                                    </div>


                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary ml-1" type="submit"
                                            disabled={loading}
                                    >
                                        <span>{"Submit"}&nbsp;</span>
                                        {loading && (<span
                                                className="spinner-border spinner-border-sm"></span>)}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}

function mapStateToProps(state) {
    const {reRegisterLeadIDs} = state.lead;
    return {
        reRegisterLeadIDs
    };
}

export default connect(mapStateToProps)(ModalReRegisterLeads);

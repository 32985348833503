import {Tab, Tabs} from "react-bootstrap";
import StreamsList from "../../stream/StreamsList";
import React from "react";
import AdvertiserEditTab from "./AdvertiserEditTab";
import StreamDowntimeList
    from "../downtime/StreamDowntimeList";
import CommissionList from "../commission/CommissionList";

const AdvertiserTabs = ({advertiser, setAdvertiser}) => {

    const setKey = (key) => {
        setAdvertiser({
            ...advertiser,
            key,
            showTabs: key !== "close"
        })
    }

    return (
        <>
            {advertiser?.showTabs && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-12">

                        <Tabs activeKey={advertiser?.key}
                              onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="geos" title="GEOS">
                                <StreamsList
                                    advertiser={advertiser}
                                    setAdvertiser={(data) => setAdvertiser(data)}
                                    showAffiliateCaps={true}
                                />
                            </Tab>

                            <Tab eventKey="commissions" title="COMMISSIONS">
                                <CommissionList
                                    advertiser={advertiser}
                                    setAdvertiser={(data) => setAdvertiser(data)}
                                />
                            </Tab>

                            <Tab eventKey="working_hours" title='WORKING HOURS'>
                                <StreamDowntimeList
                                    advertiser={advertiser}
                                />
                            </Tab>
                            <Tab eventKey="edit" title='EDIT'>
                                <AdvertiserEditTab
                                    advertiser={advertiser}
                                    setAdvertiser={(data) => setAdvertiser(data)}
                                />
                            </Tab>
                            <Tab eventKey={"close"} title={
                                <><i className='fa fa-close mr-1'></i>CLOSE</>
                            }/>
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    );
}

export default AdvertiserTabs;
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import BucketSelected from "../BucketSelected";
import {Button} from "react-bootstrap";
import ModalEditDistribution from "../../parts/ModalEditDistribution";
import {CAN_UPDATE_DISTRIBUTION, CAN_UPDATE_ROTATION_BUCKET} from "../../../../../constant/permissions";
import {ShowForPermission} from "../../../../../helpers/ShowForPermission";
import {connect} from "react-redux";
import moment from "moment";

const StreamTable = ({stream, affiliate, country}) => {
    const [modalShow, setModalShow] = useState(false);
    const [commissionAffiliate, setCommissionAffiliate] = useState(undefined);

    useEffect(() => {
        setCommissionAffiliate(affiliate?.commissions?.find((item) => item.country_iso === country.iso))

        if (!affiliate || !affiliate.commissions) setCommissionAffiliate(undefined)

    }, [affiliate])

    const downtime = useMemo(() => {
        return stream.advertiser?.downtimes?.find((item) => item.day_of_week ===
            new Date().toLocaleDateString('en', { weekday: 'long'} ))
    }, [stream.advertiser])

    return (

        <Fragment>

            <tr>
                <th scope="row">
                    {!stream?.info ?
                        stream?.advertiser.name
                        :
                        <del>
                            {stream?.advertiser.name}
                        </del>
                    }

                </th>
                <td>
                    <div>
                        {downtime ? `${downtime.from} - ${downtime.to} (${moment.tz(downtime.timezone).format('Z')})` : ''}
                    </div>
                </td>
                <td>{stream?.info}</td>
                <td>{stream.commissions}$</td>
                <td>
                    {affiliate && !affiliate.commissions && (
                        <span className="spinner-border spinner-border-sm"></span>)}
                    {commissionAffiliate ? commissionAffiliate.commissions + "$" : ""}
                </td>
                <td>{stream.leadsAndConversionsData.leadsCount ?? 0}/{stream.leadsAndConversionsData.conversionsCount ?? 0}</td>
                <td>
                    {stream.failedCount  && stream.failedCount > 0 ? stream?.failedCount : '' }
                </td>
                <td>
                    <ShowForPermission permission={CAN_UPDATE_ROTATION_BUCKET}>
                        <BucketSelected distribution={stream.distribution}/>
                    </ShowForPermission>
                    <ShowForPermission permission={CAN_UPDATE_ROTATION_BUCKET} isNotPermission={true}>
                        <strong>
                            {stream.distribution.rotation?.name ? stream.distribution.rotation?.name : "Default" }
                        </strong>
                    </ShowForPermission>
                </td>
                <td>
                    <div>
                        {stream.count_gtm_daily_conversions ?? 0}/{stream.limit_daily_conversions ?? 'unlim.'} FTDs
                    </div>
                    <div>
                        {stream.count_gtm_daily_leads ?? 0}/{stream.limit_daily_leads ?? 'unlim.'} leads
                    </div>
                </td>
                <td>
                    <div>
                        {stream.count_daily_conversions ?? 0}/{stream.limit_daily_conversions ?? 'unlim.'} FTDs
                    </div>
                    <div>
                        {stream.count_daily_leads ?? 0}/{stream.limit_daily_leads ?? 'unlim.'} leads
                    </div>
                </td>
                <td>
                    <div>
                        {stream.count_conversions ?? 0}/{stream.limit_conversions ?? 'unlim.'} FTDs
                    </div>
                    <div>
                        {stream.count_leads ?? 0}/{stream.limit_leads ?? 'unlim.'} leads
                    </div>

                </td>
                <td>
                    <div className="d-flex align-items-end ">
                        <div>
                            {stream.distribution.traffic}%/{stream.distribution.effective}%
                        </div>
                        <ShowForPermission permission={CAN_UPDATE_DISTRIBUTION}>
                            <div className="ml-2">
                                <Button className="p-0" onClick={() => setModalShow(true)} variant="link">
                                    <i className="fa fa-pencil text-success fa-2x "></i>
                                </Button>

                                <ModalEditDistribution
                                    distribution={stream.distribution}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </div>
                        </ShowForPermission>
                    </div>

                </td>
            </tr>

        </Fragment>

    );

}

function mapStateToProps(state) {
    const {affiliate, country} = state.stream;
    return {
        affiliate,
        country
    };
}

export default connect(mapStateToProps)(StreamTable);

import React from "react";
import AffiliateCapService from "../../../../services/stream-affiliate-cap.service";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../constant";

const ModalDeleteAffiliateCap = ({affiliateCap, setStreams, setAffiliateCaps, stream, affiliateCaps}) => {

    const deleteAffiliateCap = (id) => {
        setAffiliateCaps(affiliateCaps.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        AffiliateCapService.delete(id).then(() => {
            setStreams(streams => streams.map(x => {
                if (x.id === stream.id) {
                    x.affiliate_caps = x.affiliate_caps.filter(x => x.id !== id)
                }
                return x;
            }));
        });
    }

    const submitDeleteAffiliateCap = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteAffiliateCap(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button onClick={() => submitDeleteAffiliateCap(affiliateCap.id)}
                    className="btn btn-link p-0" disabled={affiliateCap.isDeleting}>
                {affiliateCap.isDeleting
                    ?
                    <span className="spinner-border spinner-border-lg "></span>
                    : <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    )
}

export default ModalDeleteAffiliateCap;

import React, {Fragment, useEffect, useState} from 'react';
import {ConfirmDelete, DeleteIrreversible, No, Yes} from '../../constant';

import StreamService from "../../services/stream.service";

import {confirmAlert} from "react-confirm-alert";
import {
    CAN_CREATE_STREAM,
    CAN_DELETE_STREAM,
    CAN_UPDATE_STREAM
} from "../../constant/permissions";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import AffiliateCapList from "../advertiser/affiliate-cap/AffiliateCapList";
import TableReact from "react-bootstrap/Table";
import ModalStreamEdit
    from "./parts/ModalStreamEdit";
import {toast} from "react-toastify";
import Pagination from "react-js-pagination";
import ModalStreamCreate from "./parts/ModalStreamCreate";
import SearchStream from "./parts/SearchStream";
import {AffiliatesInfo} from "../affiliate/AffiliateInfo";
import {ModalStreamDelete} from "./parts/ModalStreamDelete";

const StreamsList = ({advertiser, setAdvertiser, showAffiliateCaps = false}) => {

    const [streams, setStreams] = useState(undefined);
    const [filter, setFilter] = useState({})
    const [meta, setMeta] = useState(undefined)


    const updateStatusStream = (stream, status = false) => {
        let data = {
            ...stream,
            is_active: !status,
        }
        StreamService.update(stream.id, data).then((response) => {
            setStreams(streams.map(x => {
                if (x.id === stream.id) {
                    x = response.data.data;
                }
                return x;
            }));
        });
    }


    const getStreamsData = async (filter, pageNumber = 1) => {
        setFilter(filter);

        await StreamService.getAll(advertiser?.id, filter, pageNumber).then(
            response => {
                if (response.data) {
                    setStreams(response.data?.data);
                    setMeta(response.data?.meta);
                }

            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);


            }
        )
    };

    useEffect(() => {
        if(advertiser){
            getStreamsData();
        }

    }, [advertiser]);

    return (
        <Fragment>

            <div className="row">

                <div className="col-md-12">
                    <div className="card">

                        <div className="card-header">

                            <div className={"row  mx-3"}>

                                <div className={"col-md-8"}>
                                    <SearchStream
                                        filter={filter}
                                        getStreamsData = {getStreamsData}
                                    />
                                </div>

                                <div className="col-md-3 d-flex justify-content-end">
                                    <div>
                                        <ShowForPermission permission={CAN_CREATE_STREAM}>
                                            <ModalStreamCreate
                                                advertiser = {advertiser}
                                                streams={streams}
                                                getStreamsData = {getStreamsData}

                                            />
                                        </ShowForPermission>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <TableReact bordered striped size="sm">
                                    <thead>
                                    <tr>
                                        <th scope="col">{"Affiliate"}</th>
                                        <th scope="col">{"Country"}</th>
                                        <th scope="col ">
                                            <div className="text-center">
                                                {"Leads"}<br/> {"Daily/Total"}
                                            </div>
                                        </th>
                                        <th scope="col ">
                                            <div className="text-center">
                                                {"Conv"}<br/> {"Daily/Total"}
                                            </div>
                                        </th>
                                        <th scope="col">{"Status"}</th>
                                        <ShowForPermission permission={[CAN_DELETE_STREAM, CAN_UPDATE_STREAM]}>
                                            <th scope="col">{"Action"}</th>
                                        </ShowForPermission>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {streams &&
                                        streams.map((stream, index) => (
                                            <Fragment key={index}>

                                                <tr>
                                                    <td scope="row">
                                                        <AffiliatesInfo affiliate={stream?.affiliate} otherText={"All"}/>
                                                    </td>
                                                    <td scope="row">
                                                        {stream.country?.title}
                                                    </td>
                                                    <td scope="row">
                                                        <div className="text-center">
                                                            {stream.limit_daily_leads > 0 ? stream.limit_daily_leads : "Unlim"}
                                                            /
                                                            {stream.limit_leads > 0 ? stream.limit_leads : "Unlim"}
                                                        </div>
                                                    </td>
                                                    <td scope="row">
                                                        <div className="text-center">
                                                            {stream.limit_daily_conversions > 0 ? stream.limit_daily_conversions : "Unlim"}
                                                            /
                                                            {stream.limit_conversions > 0 ? stream.limit_conversions : "Unlim"}
                                                        </div>
                                                    </td>
                                                    <td scope="row">
                                                        <label className="switch-green">
                                                            <input type="checkbox" name="is_active"
                                                                   checked={stream.is_active}
                                                                   onChange={(e) => updateStatusStream(stream, stream.is_active)}

                                                            />
                                                            <div className="slider"></div>
                                                        </label>
                                                    </td>
                                                    <ShowForPermission permission={[CAN_DELETE_STREAM, CAN_UPDATE_STREAM]}>
                                                        <td scope="row">
                                                            <div className="d-flex">
                                                                <ShowForPermission permission={CAN_DELETE_STREAM}>
                                                                    <div className="mr-3">
                                                                        <ModalStreamDelete
                                                                            streams={streams}
                                                                            stream={stream}
                                                                            advertiser={advertiser}
                                                                            setStreams={setStreams}
                                                                            setAdvertiser={setAdvertiser}
                                                                        />
                                                                    </div>
                                                                </ShowForPermission>

                                                                <ShowForPermission permission={CAN_UPDATE_STREAM}>
                                                                    <div className="">
                                                                        <ModalStreamEdit
                                                                            setStreams={setStreams}
                                                                            streams={streams}
                                                                            stream={stream}
                                                                        />
                                                                    </div>
                                                                </ShowForPermission>
                                                            </div>
                                                        </td>
                                                    </ShowForPermission>

                                                </tr>
                                                {showAffiliateCaps && stream.isOpen && (

                                                    <AffiliateCapList
                                                        stream={stream}
                                                        streams={streams}
                                                        setStreams={setStreams}
                                                    />

                                                )}
                                            </Fragment>

                                        ))
                                    }
                                    {!streams &&
                                        <tr>
                                            <td colSpan="13" className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    { (streams && !streams.length) &&
                                        <tr>
                                            <td colSpan="13" className="text-center">
                                                <div className="p-2">No Streams To Display</div>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </TableReact>
                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="d-flex justify-content-center">
                                {(meta && meta?.last_page > 1) &&
                                    <Pagination
                                        activePage={meta.current_page}
                                        totalItemsCount={meta.total}
                                        itemsCountPerPage={meta.per_page}
                                        onChange={(pageNumber) => getStreamsData(filter, pageNumber)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}


export default StreamsList;

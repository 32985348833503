import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Create, From} from '../../../../constant';

import {useAlert} from "react-alert";
import {Button, Modal, Form} from "react-bootstrap";
import PostbackTypeService from "../../../../services/postback-type.service";
import PostbackService from "../../../../services/postback.service";
import {optionalParameters} from "../../../../constant/affiliatePostbacks";
import {toast} from "react-toastify";

const ModalCreatePostbacks = props => {
    const {affiliate_id, dispatch} = props;
    const {register, handleSubmit, reset, control, setValue, getValues} = useForm();
    const alert = useAlert();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const [postbackTypes, setPostbackTypes] = useState(undefined);

    const urlPush = (string) => {
        setValue("url", getValues("url") + string);
    }

    useEffect(() => {
        getPostbackTypeData();
    }, [props.show])

    const getPostbackTypeData = () => {
        PostbackTypeService.getAll().then(
            response => {
                if (response.data) {
                    setPostbackTypes(response.data?.data);
                }

            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);

                
            }
        )
    };

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        PostbackService.create(affiliate_id, data)
            .then(
                response => {
                    props.postbackPush(response.data?.data);
                    props.onHide();
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

                })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Add"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="col-form-label">{"Title:"}</label>
                                            <input className="form-control" type="text" name="title" ref={register}/>
                                            <span className="text-danger-light">{errors?.title}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group mb-0">
                                            <label className="col-form-label">{"Type:"}</label>
                                            <div className="d-flex">
                                                {postbackTypes && postbackTypes.map((postbackType, index) => (
                                                    <Form.Check
                                                        defaultChecked={index == 0}
                                                        type="radio"
                                                        className="mr-sm-2"
                                                        name="type_id"
                                                        ref={register}
                                                        id={`typeID-${postbackType.id}`}
                                                        value={postbackType.id}
                                                        label={postbackType.title}
                                                    />
                                                ))}
                                            </div>
                                            <span className="text-danger-light">{errors?.type_id}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="col-form-label">{"Url:"}</label>
                                            <textarea className="form-control" name="url" id="url" cols="30" rows="3"
                                                      ref={register}></textarea>
                                            <span className="text-danger-light">{errors?.url}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        {optionalParameters && optionalParameters.map((optionalParameter) => (
                                            <button type="button"
                                                    className="btn btn-sm btn-outline-primary mr-1 mb-1 px-1"
                                                    onClick={() => urlPush(`{${optionalParameter}}`)}>{`{${optionalParameter}}`}</button>

                                        ))}
                                    </div>
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="col-form-label">{"Send Param Through:"}</label>
                                            <div className="d-flex">
                                                <Form.Check
                                                    defaultChecked={true}
                                                    ref={register}
                                                    type="radio"
                                                    className="mr-sm-2"
                                                    name="param_option"
                                                    id={`param_option-1`}
                                                    value="1"
                                                    label={"GET"}
                                                />
                                                <Form.Check
                                                    ref={register}
                                                    type="radio"
                                                    className="mr-sm-2"
                                                    name="param_option"
                                                    id={`param_option-2`}
                                                    value="2"
                                                    label={"POST"}
                                                />
                                                <Form.Check
                                                    ref={register}
                                                    type="radio"
                                                    className="mr-sm-2"
                                                    name="param_option"
                                                    id={`param_option-3`}
                                                    value="3"
                                                    label={"PATCH"}
                                                />
                                            </div>
                                            <span className="text-danger-light">{errors?.param_option}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-10">
                                        <Form.Check
                                            ref={register}
                                            type="checkbox"
                                            className="mb-2 mr-sm-2"
                                            name="is_manual"
                                            id={`is_manual`}
                                            // value="1"
                                            label={"Manual Postback"}
                                        />
                                        <span className="text-danger-light">{errors?.is_manual}</span>
                                    </div>
                                    <div className=" col-md-10">
                                        <Form.Check
                                            ref={register}
                                            defaultChecked={true}
                                            type="checkbox"
                                            className="mb-2 mr-sm-2"
                                            name="is_exclude_failed"
                                            id={`is_exclude_failed`}
                                            // value="1"
                                            label={"Exclude Failed Registration Leads"}
                                        />
                                        <span className="text-danger-light">{errors?.is_exclude_failed}</span>
                                    </div>

                                    <div className=" col-md-10 ">
                                        <div className="form-group">
                                            <label className="col-form-label">{"Send Param Through:"}</label>
                                            <div className="d-flex">
                                                    <Form.Check
                                                        ref={register}
                                                        defaultChecked={true}
                                                        type="radio"
                                                        className="mb-2 mr-sm-2"
                                                        name="is_active"
                                                        id={`is_active`}
                                                        value="1"
                                                        label={"Active"}
                                                    />
                                                    <Form.Check
                                                        ref={register}
                                                        type="radio"
                                                        className="mb-2 mr-sm-2"
                                                        name="is_active"
                                                        id={`Inactive`}
                                                        value="0"
                                                        label={"Inactive"}
                                                    />
                                            </div>
                                            <span className="text-danger-light">{errors?.is_active}</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


export default ModalCreatePostbacks;

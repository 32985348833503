import TableReact from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import ModalCreateCommission from "./parts/ModalCreateCommission";
import ModalEditCommission from "./parts/ModalEditCommission";
import ModalDeleteCommission from "./parts/ModalDeleteCommission";
import {ShowForPermission} from "../../../../helpers/ShowForPermission";
import {
    CAN_CREATE_AFFILIATE_COMMISSION,
    CAN_DELETE_AFFILIATE_COMMISSION,
    CAN_UPDATE_AFFILIATE_COMMISSION
} from "../../../../constant/permissions";

const AffiliateCommissionList = ({affiliate}) => {

    const [commissions, setCommissions] = useState(undefined);

    useEffect(() => {
        setCommissions(affiliate?.commissions)
    }, [affiliate])

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h3>Commissions:</h3>

                        <ShowForPermission permission={CAN_CREATE_AFFILIATE_COMMISSION}>
                            <ModalCreateCommission
                                setCommissions={(data) => setCommissions(data)}
                                affiliate={affiliate}
                                commissions={commissions}
                            />
                        </ShowForPermission>

                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <TableReact bordered striped size={"sm"}>
                            <thead>
                                <tr>
                                    <th>
                                        Geo
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Advertiser
                                    </th>
                                    <ShowForPermission permission={[CAN_UPDATE_AFFILIATE_COMMISSION, CAN_DELETE_AFFILIATE_COMMISSION]}>
                                        <th>

                                        </th>
                                    </ShowForPermission>
                                </tr>
                            </thead>
                            <tbody>
                            { commissions &&
                                commissions.map((commission, index) => (
                                    <tr key={index}>
                                        <td>{commission.country_iso}</td>
                                        <td>{commission.commissions} USD/{commission.is_conversions ? "conv" : "lead"}</td>
                                        <td>{commission.advertiser ? commission.advertiser.name : 'All'} </td>

                                        <ShowForPermission permission={[CAN_UPDATE_AFFILIATE_COMMISSION, CAN_DELETE_AFFILIATE_COMMISSION]}>

                                            <td scope="row">
                                                <div className="d-flex">
                                                    <div className="">
                                                        <ShowForPermission permission={CAN_UPDATE_AFFILIATE_COMMISSION}>
                                                            <ModalEditCommission
                                                                setCommissions={(data) => setCommissions(data)}
                                                                commissions={commissions}
                                                                commission={commission}
                                                            />
                                                        </ShowForPermission>

                                                    </div>
                                                    <div className="ml-3">
                                                        <ShowForPermission permission={CAN_DELETE_AFFILIATE_COMMISSION}>
                                                           <ModalDeleteCommission
                                                               setCommissions={(data) => setCommissions(data)}
                                                               commissions={commissions}
                                                               commission={commission}
                                                               />
                                                        </ShowForPermission>
                                                    </div>

                                                </div>
                                            </td>
                                        </ShowForPermission>

                                    </tr>
                                ))}

                            {commissions && !commissions.length &&
                                <tr key='affiliate-not-found'>
                                    <td colSpan="3" className="text-center">
                                        <div className="p-2">No Commissions To Display</div>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </TableReact>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AffiliateCommissionList;
import React, {useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import {Create} from "../../../../constant";
import {useForm} from "react-hook-form";
import IsoSelected from "../../../country/IsoSelected";
import ReportAffiliatesSelected from "../../../affiliate/ReportAffiliatesSelected";
import RestrictionService from "../../../../services/affiliate/advertiser-restriction.service";
import {restrictionSearch} from "../../../../actions/restriction";
import AdvertiserSelected from "../../../advertiser/AdvertiserSelected";
import {connect} from "react-redux";
import {toast} from "react-toastify";

const ModalCreateRestriction = props => {
    const {dispatch, affiliate, country, advertiser, status, pageNumber} = props;
    const {register, handleSubmit, reset,  control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        data.countries_iso = data.country?.map(e => e.iso);
        data.affiliate_ids = data.affiliate?.map(e => e.id);
        data.advertiser_ids = data.advertiser?.map(e => e.id);

        delete data.country;
        delete data.affiliate;
        delete data.advertiser;

        RestrictionService.create(data)
            .then(
                async response => {
                    await dispatch(restrictionSearch(country, affiliate, advertiser, status, pageNumber));
                    handleClose()

                    toast.success("Affiliate Restriction Created Or Updated");

                }, error => {
                    toast.error("Affiliate Restriction Error");

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }


                })
            .finally(() => {
                setLoading(false);

            })

    }

    return (
        <>
            <Button onClick={handleShow}>
                Add new
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create Restriction"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="form-row justify-content-center">

                                    <div className="col-md-10">
                                        <ReportAffiliatesSelected control={control} error={errors?.affiliate_ids} isMulti closeMenuOnSelect={false}
                                                                  label={true}/>
                                    </div>

                                    <div className="col-md-10">
                                        <h5>Restrictions status:</h5>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="status" ref={register}
                                                   id="include" value="1" defaultChecked={true}/>
                                                <label className="form-check-label" htmlFor="include">
                                                    Include
                                                </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="status" ref={register}
                                                   id="exclude" value="2"/>
                                                <label className="form-check-label" htmlFor="exclude">
                                                    Exclude
                                                </label>
                                        </div>
                                        <p>Please note, you cannot create both include and exclude records for the same affiliate.</p>
                                    </div>

                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="comment" className="form-label">{"Comment:"}</label>

                                            <textarea className="form-control" id="comment" name="comment"
                                                      ref={register}
                                                      rows="3"></textarea>

                                            <span>{errors?.comment}</span>
                                        </div>
                                    </div>

                                    <h2>
                                        Conditions:
                                    </h2>
                                    <div className="col-md-10">
                                        <AdvertiserSelected control={control} error={errors?.advertiser_ids} isMulti closeMenuOnSelect={false} />
                                    </div>

                                    <div className="col-md-10">
                                        <IsoSelected control={control} error={errors?.countries_iso} isMulti closeMenuOnSelect={false} showTitle={true}/>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="is_active" name='is_active' defaultChecked={true}
                                                   value="1" ref={register} />
                                                <label className="form-check-label" htmlFor="is_active">Active</label>
                                        </div>
                                        <div className={"text-danger-light"}>{errors?.is_active}</div>

                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

);

}

function mapStateToProps(state)
    {
        const { country, affiliate, advertiser, status, pageNumber} = state.affiliateAdvertiserRestriction;
        return {
            country,
            affiliate,
            advertiser,
            status,
            pageNumber
        };
    }

export default connect(mapStateToProps)(ModalCreateRestriction);

import React, {Fragment, useCallback} from 'react'
import {ChevronRight, ChevronDown} from 'react-feather';
import InnerTableCountry from './innerTableCountry'
import VisibleColumns from './VisibleColumns'
import ExportReport from "./ExportReport";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useAffiliateReportColumn} from "../../../store/useAffiliateReportColumn";

const APPEND_HEADERS = [
    {
        // Make an expander cell
        header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()} className="chevron">
                            {row.isExpanded ? <ChevronDown/> : <ChevronRight/>}
                        </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
        width: 50
    },
    {
        header: 'Affiliate',
        accessor: 'affiliate.name',
        isVisible: true,
        Footer: () => 'Total'
    }
];
const Table = ({data}) => {
    const columns = useAffiliateReportColumn((state) => state.columns)

    const renderRowSubComponent = useCallback(({row}) => (
        <InnerTableCountry
            row={row}
        />
    ), [])


    return (
        <Fragment>
            <div className={"card-header"}>
                <div className={"action-btns d-flex justify-content-between"}>
                    <div className={"d-flex gap-2"}>
                        {
                            data && data.length ?
                                <>
                                    <div className={"d-flex text-center flex-column"}>
                                        <ExportReport type="affiliate-performance"/>
                                        <small>aff</small>
                                    </div>
                                    <div className={"d-flex text-center flex-column"}>
                                        <ExportReport type="affiliate-countries-performance"/>
                                        <small>aff+geo</small>
                                    </div>
                                    <div className={"d-flex text-center flex-column"}>
                                        <ExportReport type="affiliate-countries-advertisers-performance"/>
                                        <small>aff+geo+adv</small>
                                    </div>
                                </>
                                : <></>
                        }
                    </div>
                    <VisibleColumns/>
                </div>
            </div>
            <div className="table-responsive">
                <ReportTableExpanded
                    data={data}
                    columns={columns}
                    visibleTotal={true}
                    renderRowSubComponent={renderRowSubComponent}
                    appendHeaders={APPEND_HEADERS}
                />
            </div>
        </Fragment>
    )
}

export default Table;

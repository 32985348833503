import {
    LEAD_FILTER_FAIL,
    LEAD_FILTER_SUCCESS,
    SET_LEAD_PERIOD_SUCCESS,
    SET_LEAD_FILTERING_SUCCESS,
    SET_LEAD_COUNTRY_SUCCESS,
    SET_LEAD_ADVERTISER_SUCCESS,
    SET_LEAD_AFFILIATE_SUCCESS,
    SET_LEAD_PERIODTIME_SUCCESS,
    SET_LEAD_SUCCESS,
    SET_RE_REGISTER_LEAD_IDS_SUCCESS,
    SET_LEAD_MANAGER_SUCCESS,
    SET_LEAD_HISTORY_SUCCESS
} from "./types";
import leadService from "../services/lead.service";
import {applyPeriodTimeTimezone, applyTimezone, refreshPeriod} from "../utils/period/period";


export const leadSearch = (manager, country, period, periodTime, affiliate, advertiser, pageNumber, filtering, pageSize, timezone) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period, timezone)
    return leadService.getFilter(manager?.id, country?.iso, applyTimezone(updatedPeriod, timezone), applyPeriodTimeTimezone(periodTime, timezone), affiliate?.id, advertiser?.id,  filtering, pageNumber, pageSize).then(
        (response) => {

            dispatch({
                type: LEAD_FILTER_SUCCESS,
                payload: {
                    leads: response.data.data,
                    meta: response.data.meta,
                    country: country,
                    affiliate: affiliate,
                    manager: manager,
                    period: updatedPeriod,
                    advertiser: advertiser,
                    filtering: filtering,
                    periodTime: periodTime,
                    pageNumber,
                    pageSize,
                },
            });

            return Promise.resolve();
        },
        () => {
            dispatch({
                type: LEAD_FILTER_FAIL,
            });

            return Promise.reject();
        }
    );
};
export const setLeadPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_LEAD_PERIOD_SUCCESS,
        payload: {
            period,
        },
    });

    return Promise.resolve();
}

export const setLeadCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_LEAD_COUNTRY_SUCCESS,
        payload: {
            country,
        },
    });

    return Promise.resolve();
}

export const setLeadPeriodTime = (periodTime) => (dispatch) => {
    dispatch({
        type: SET_LEAD_PERIODTIME_SUCCESS,
        payload: {
            periodTime,
        },
    });

    return Promise.resolve();
}

export const setLeadAdvertiser = (advertiser) => (dispatch) => {
    dispatch({
        type: SET_LEAD_ADVERTISER_SUCCESS,
        payload: {
            advertiser,
        },
    });

    return Promise.resolve();
}

export const setLeadManager = (manager) => (dispatch) => {
    dispatch({
        type: SET_LEAD_MANAGER_SUCCESS,
        payload: {
            manager,
        },
    });

    return Promise.resolve();
}

export const setLeadAffiliate = (affiliate) => (dispatch) => {
    dispatch({
        type: SET_LEAD_AFFILIATE_SUCCESS,
        payload: {
            affiliate,
        },
    });

    return Promise.resolve();
}

export const setLeadFiltering = (filtering) => (dispatch) => {
    dispatch({
        type: SET_LEAD_FILTERING_SUCCESS,
        payload: {
            filtering,
        },
    });

    return Promise.resolve();
}

export const setLeads = (leads) => (dispatch) => {
    dispatch({
        type: SET_LEAD_SUCCESS,
        payload: {
            leads,
        },
    });

    return Promise.resolve();
}

export const setReRegisterLeadIDs = (reRegisterLeadIDs) => (dispatch) => {
    dispatch({
        type: SET_RE_REGISTER_LEAD_IDS_SUCCESS,
        payload: {
            reRegisterLeadIDs,
        },
    });

    return Promise.resolve();
}

export const leadHistory = (leadId) => (dispatch) => {
    return leadService.history(leadId).then(
        (response) => {

            dispatch({
                type: SET_LEAD_HISTORY_SUCCESS,
                payload: {
                    history: {
                        id: leadId,
                        data: response.data.data
                    }
                },
            });

            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
};

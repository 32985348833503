import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import {EditDistribution, Save, Traffic} from "../../../constant";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import EventBus from "../../../common/EventBus";
import DistributionService from "../../../services/distribution.service";
import {connect} from "react-redux";
import {streamSearch} from "../../../actions/stream";
import IsoSelected from "../../country/IsoSelected";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import OptimizeService from "../../../services/optimize.service";
import {optimizeSearch} from "../../../actions/optimize";
import AdvertiserSelected from "../../advertiser/AdvertiserSelected";
import {toast} from "react-toastify";

const ModalCreateOptimizes = props => {
    const {dispatch, affiliate, country, show, advertiser} = props;
    const {register, handleSubmit, reset, setValue, control} = useForm();
    const alert = useAlert();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    useEffect(()=> {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("advertiser", advertiser);
    }, [show])

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        data.country_iso = data.country?.iso;
        data.affiliate_id = data.affiliate?.id;
        data.advertiser_id = data.advertiser?.id;

        OptimizeService.create(data)
            .then(
                async response => {
                    await dispatch(optimizeSearch(country, affiliate, advertiser));

                    props.onHide();
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })

    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create Optimize"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="form-row justify-content-center">
                                    <div className="col-md-10">
                                        <IsoSelected control={control} error={errors?.country_iso}/>
                                    </div>
                                    <div className="col-md-10">
                                        <AdvertiserSelected label={false} control={control} error={errors?.advertiser_id}/>
                                    </div>
                                    <div className="col-md-10">
                                        <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="form-label">{"Set optimization in %"}</label>
                                            <input className="form-control" type="number" name="percent" ref={register}
                                                   placeholder="%"/>
                                            <span>{errors?.percent}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Save}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}

function mapStateToProps(state) {
    const {optimizes, affiliate, country, advertiser} = state.optimize;
    return {
        optimizes,
        affiliate,
        country,
        advertiser,
    };
}

export default connect(mapStateToProps)(ModalCreateOptimizes);

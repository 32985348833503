import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import affiliatePerformanceReportsService from "../../../services/affiliate-performance.service";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useAffiliateReportColumn} from "../../../store/useAffiliateReportColumn";

const APPEND_HEADERS = [
    {
        header: 'Advertiser',
        accessor: 'advertiser.name',
        visible: true
    },
]
const InnerTableAdvertisers = ({row, countryRow, manager, country, period, affiliate, advertiser}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = useAffiliateReportColumn((state) => state.columns)

    useEffect(() => {
            setLoading(true)
            affiliatePerformanceReportsService.getAffiliatePerformanceReportsByAffiliateIdAndCountryId(manager?.id, country, period, affiliate, advertiser, row.original.affiliate.id, countryRow.original.country.id).then(res => {
                setData(res.data.data)
            }).catch((e) => {
                console.error(e)
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    return (
       <ReportTableExpanded
           data={data}
           columns={columns}
           appendHeaders={APPEND_HEADERS}
           loading={loading}
       />
    )
}

function mapStateToProps(state) {
    const {columns, manager, country, period, affiliate, advertiser} = state.affiliateReports;
    return {
        columns,
        manager,
        country,
        period,
        affiliate,
        advertiser
    };
}

export default connect(mapStateToProps)(InnerTableAdvertisers);
import React, {Fragment, useMemo, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import Countup from 'react-countup';
import SearchDashboard from "./parts/SearchDashboard";
import {Table} from "../../utils/table";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import {CAN_SEE_COST, CAN_SEE_INCOME} from "../../constant/permissions";
import Title from "../common/custom-title";
import {connect} from "react-redux";
import {TimezoneSelectStore} from "../select/timezone/TimezoneSelectStore";

const Default = ({statics, user}) => {

    const [changeData, setChangeData] = useState("country");
    const balance = useMemo(() => user.data?.balance, [user])
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Leads',
                accessor: 'count_leads',

            },
            {
                Header: 'FTDs',
                accessor: 'count_conversions',

            },
            {
                Header: 'CR',
                accessor: 'cr',

            },
        ],
        []
    )
    const isAffiliate = user.data.roles.includes('affiliate')
    let data = useMemo(() => {

        return statics?.items[changeData]

    }, [statics, changeData])


    return (
        <Fragment>
            <Title title="Dashboard"/>
            <Breadcrumb title="Dashboard"/>

            <TimezoneSelectStore/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <SearchDashboard/>
                    </div>
                    <div className="col-sm-12">

                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-3 col-md-6 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media">
                                            <h5 className="mb-0">{"Leads:"}</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={statics?.count_leads ?? 0}/>
                                            </h1>
                                            <h6 className="mb-0">{"Lead"}</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6  ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media">
                                            <h5 className="mb-0">{"FTDs:"}</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={statics?.count_conversions ?? 0}/>
                                            </h1>
                                            <h6 className="mb-0">{"FTD"}</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="card pb-3 finance">
                                    <div className="card-body">
                                        <div className="media">
                                            <h5 className="mb-0">{"Finance:"}</h5>
                                        </div>
                                        <ShowForPermission permission={[CAN_SEE_INCOME, CAN_SEE_COST]}>
                                            <div className={"d-flex justify-content-between"}>
                                                    <div className="project-widgets text-center">
                                                        <h2 className="font-primary counter">
                                                            <Countup prefix="$"
                                                                     duration={2.75}
                                                                     decimals={2}
                                                                     end={statics?.financials?.income ?? 0}/>
                                                        </h2>
                                                        <h6 className="mb-0">{"Income"}</h6>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h2 className="font-primary counter">
                                                            <Countup prefix="$"
                                                                     duration={2.75}
                                                                     decimals={2}
                                                                     end={statics?.financials?.cost ?? 0}/>
                                                        </h2>
                                                        <h6 className="mb-0">{"Cost"}</h6>
                                                    </div>
                                                <div className="project-widgets text-center">
                                                    <h2 className="font-primary counter">
                                                        <Countup prefix="$"
                                                                 duration={2.75}
                                                                 decimals={2}
                                                                 end={statics?.financials?.profit ?? 0}/>
                                                    </h2>
                                                    <h6 className="mb-0">{"Profit"}</h6>
                                                </div>

                                            </div>
                                        </ShowForPermission>
                                        {isAffiliate && (
                                            <div className="project-widgets text-center">
                                                <h2 className="font-primary counter">
                                                    <Countup prefix="$"
                                                             duration={2.75}
                                                             decimals={2}
                                                             end={statics?.financials?.profit ?? 0}/>
                                                </h2>
                                                <h6 className="mb-0">{"Profit"}</h6>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        {isAffiliate && (
                            <>
                                <div className="row">
                                    <div className="col-md-12 col-xl-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="media">
                                                    <h5 className="mb-0">{"Balance:"}</h5>
                                                </div>
                                                <div className="d-flex justify-content-between">

                                                    <div className="project-widgets text-center">
                                                        <h2 className="font-primary counter">
                                                            <Countup prefix="$"
                                                                     duration={2.75}
                                                                     decimals={2}
                                                                     end={balance.balance ?? 0}/>
                                                        </h2>
                                                        <h6 className="mb-0">{"Balance"}</h6>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h2 className="font-primary counter">
                                                            <Countup prefix="$"
                                                                     duration={2.75}
                                                                     decimals={2}
                                                                     end={balance.paid ?? 0}/>
                                                        </h2>
                                                        <h6 className="mb-0">{"Paid"}</h6>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h2 className="font-primary counter">
                                                            <Countup prefix="$"
                                                                     duration={2.75}
                                                                     decimals={2}
                                                                     end={balance.remain ?? 0}/>
                                                        </h2>
                                                        <h6 className="mb-0">{"Remains"}</h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        )}

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">

                                    <div className="card-body">
                                        {statics && (
                                            <Table columns={columns} data={data}
                                                   changeData={(slug) => setChangeData(slug)}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const {statics} = state.dashboard;
    const {user} = state.auth;
    return {
        statics,
        user
    };
}

export default connect(mapStateToProps)(Default);
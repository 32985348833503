import React, {useState} from 'react'
import {usePagination, useSortBy, useTable} from 'react-table'
import Pagination from "react-js-pagination";
import {Button} from 'reactstrap';
import styled from 'styled-components'
import ReactJson from "react-json-view";
import {useAlert} from "react-alert";
import TableReact from "react-bootstrap/Table";
import {connect} from "react-redux";
import {
    apiRequestSearch,
    setReRegisterIDs
} from "../../../actions/api-request";
import ReportColumnsService
    from "../../../services/report-columns.service";
import {getMe} from "../../../actions/auth";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_API_REQUEST_RE_REGISTER} from "../../../constant/permissions";
import {Dropdown} from "react-bootstrap";
import ModalReRegister from "./modal/ModalReRegister";
import {PaginationTitle} from "../../table/pagination/PaginationTitle";

const Styles = styled.div`
  .onhover-show-div {
    top:50px
  }
`

export const IndeterminateCheckbox = React.forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
})


const Table = ({columns, data, user,advertiser, apiRequests, meta, country, period, affiliate, filtering, reRegisterIDs, dispatch, manager}) => {
 
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        allColumns,
        getToggleHideAllColumnsProps,
    } = useTable({
        columns, data, initialState: {pageIndex: 0, pageSize: meta?.per_page, hiddenColumns: user?.data?.selectedColumns.filter(el => el.type === 'API Requests').map(el => {
                return el.title
            })},
    }, useSortBy, usePagination,)
    const alert = useAlert();

    const [selectedPageSize, setSelectedPageSize] = useState(meta?.per_page);
    const [showModalReRegister, setShowModalReRegister] = useState(false);

    const setPageSizeRedux = (pageSize) => {
        setSelectedPageSize(pageSize)
        setPageSize(pageSize)
        dispatch(apiRequestSearch(manager, country, period, affiliate, 1, filtering, pageSize))
    }
    const showJson = (json = null) => {
        return alert.info(<ReactJson enableClipboard={false} src={json}/>, {timeout: 0,});
    }

    const setColumns = (column) => {
        ReportColumnsService.toggleSelectedColumn(column.id_num).then(async res => {
            dispatch(await getMe())
        })
    }

    const toggleAll = (e) => {
        let ids = allColumns.filter(el => el.id_num && !el.isVisible).map(el => el.id_num)
        if (!e.target.checked){
            ids = allColumns.filter(el => el.id_num).map(el => el.id_num)
        }
        ReportColumnsService.toggleAll(ids).then(async res => {
            dispatch(await getMe())
        })
    }

    const handleCheckReRegisterIDs = (api_register_id) => {
        var updatedReRegisterIDs = [...reRegisterIDs];
        if (!updatedReRegisterIDs.includes(api_register_id)) {
            updatedReRegisterIDs.push(api_register_id);
        } else {
            updatedReRegisterIDs.splice(updatedReRegisterIDs.indexOf(api_register_id), 1);
        }
        dispatch(setReRegisterIDs(updatedReRegisterIDs));
    };

    const handleIndeterminateCheckReRegisterIDs = (event) => {
        var updatedList = [...reRegisterIDs];
        if (event.target.checked) {
            updatedList = apiRequests.map(apiRequest => apiRequest.id);
        } else {
            updatedList = [];
        }
        dispatch(setReRegisterIDs(updatedList));
    };

    const compare =  (a1, a2) => {
        return a1?.length !== 0 && a1.length === a2.length
    }

    return (
        <>

            <Styles>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-end m-0 d-flex justify-content-between">

                            <div className="d-flex">
                                <ShowForPermission
                                    permission={CAN_API_REQUEST_RE_REGISTER}>

                                    {(reRegisterIDs?.length > 0 && page.length > 0)  && (
                                        <div className="ml-2">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" id="dropdown-selected-lead" >
                                                    Selected Leads: {reRegisterIDs.length}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => setShowModalReRegister(true)}>Re-register</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <ModalReRegister
                                                show={showModalReRegister}
                                                onHide={() => setShowModalReRegister(false)}
                                            />
                                        </div>
                                    )}
                                </ShowForPermission>
                            </div>

                            <div className='row'>

                                <div className='onhover-dropdown'>
                                    <Button className="dropbtn" color={'primary'}>Visible columns<span><i
                                        className="icofont icofont-arrow-down"></i></span></Button>
                                    <ul className="onhover-show-div p-20 top-50">
                                        <li>
                                            <label className="d-flex">
                                                <IndeterminateCheckbox onClick={(e) => {toggleAll(e)}} {...getToggleHideAllColumnsProps()} />&nbsp;
                                                <span>Toggle&nbsp;All</span>
                                            </label>
                                        </li>
                                        {allColumns.map(column => (
                                            <li key={column.id}>
                                                <label>
                                                    <input type="checkbox" onClick={() => {setColumns(column)}} {...column.getToggleHiddenProps()} />{' '}
                                                    {column.Header}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="pagination ml-2">
                                    <select className="custom-select"
                                            value={meta?.per_page}
                                            onChange={e => {
                                                setPageSizeRedux(Number(e.target.value))
                                            }}
                                    >
                                        {[5, 10, 15, 25, 50, 100, 200].map(pageSize => (<option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>))}
                                    </select>
                                </div>
                            </div>

                        </div>
                        {(meta && data.length) ? (
                            <div className="mt-4">
                                <PaginationTitle
                                    total={meta.total}
                                    from={meta?.from ?? 0}
                                    to={meta?.to ?? 0}
                                />
                            </div>
                        ) : <></>}
                    </div>

                    <div className="">
                        <div className="table-responsive">

                            <TableReact bordered striped className={"mt-3"} size="sm" {...getTableProps()}>
                                <thead>
                                {headerGroups.map(headerGroup => (<tr {...headerGroup.getHeaderGroupProps()}>

                                    {/*-------- START ReRegisterLeads --------*/}

                                    <ShowForPermission
                                        permission={CAN_API_REQUEST_RE_REGISTER}>
                                        <th>
                                            <input  type="checkbox" onChange={handleIndeterminateCheckReRegisterIDs} checked={compare(reRegisterIDs, apiRequests.map(apiRequest => apiRequest.id))}/>
                                        </th>
                                    </ShowForPermission>

                                    {/*-------- END ReRegisterLeads --------*/}

                                    {headerGroup.headers.map(column => (// Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <br/>
                                            <span> &nbsp;
                                                {column.isSorted ? column.isSortedDesc ?
                                                    <i className="fa fa-chevron-down"></i> :
                                                    <i className="fa fa-chevron-up"></i> : ''}
                                        </span>
                                        </th>))}
                                </tr>))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (<tr {...row.getRowProps()}>

                                        {/*-------- START ReRegisterLeads --------*/}
                                            <ShowForPermission
                                                permission={CAN_API_REQUEST_RE_REGISTER}>
                                                <td>
                                                    {row.original.request_type === "Registration" && (
                                                        <input value={row.original.id} type="checkbox" onChange={() => handleCheckReRegisterIDs(row.original.id)} checked={reRegisterIDs?.includes(row.original.id)}/>
                                                    )}
                                                </td>
                                            </ShowForPermission>
                                        {/*-------- END ReRegisterLeads --------*/}

                                        {row.cells.map(cell => {
                                            return (<td {...cell.getCellProps()}>
                                                {cell.column.id === "request_post" || cell.column.id === "response" || cell.column.id === "response_broker" ? (
                                                    <div className="mr-3">
                                                        {cell.value && (
                                                            <button  onClick={() => showJson(cell.value)}
                                                                     className="btn btn-primary ">
                                                                View
                                                            </button>
                                                        )}
                                                    </div>
                                                ) : (
                                                    cell.render('Cell')
                                                )}

                                            </td>)
                                        })}
                                    </tr>)
                                })}
                                </tbody>
                            </TableReact>
                        </div>
                    </div>
                    <div className="card-footer">
                        {(meta && data.length) ? (
                            <PaginationTitle
                                total={meta.total}
                                from={meta?.from ?? 0}
                                to={meta?.to ?? 0}
                            />
                        ) : <></>}
                        {(meta && meta?.last_page > 1) && (
                            <div className="d-flex  justify-content-center mt-2">
                                <Pagination
                                    activePage={meta?.current_page}
                                    totalItemsCount={meta?.total}
                                    itemsCountPerPage={Number(meta?.per_page)}
                                    onChange={(pageNumber) => dispatch(apiRequestSearch(manager ,country, period, affiliate, pageNumber, filtering, selectedPageSize, advertiser))}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        )}
                    </div>

                </div>

            </Styles>
        </>
    )
}

function mapStateToProps(state) {
    const {apiRequests, meta, country, period, affiliate, advertiser, filtering, periodTime, reRegisterIDs, manager} = state.apiRequest;
    const {user} = state.auth
    return {
        user, apiRequests, meta, country, period, affiliate, filtering, periodTime, advertiser, reRegisterIDs, manager
    };
}

export default connect(mapStateToProps)(Table);
import React, {useEffect, useState} from 'react';

import {Modal} from "react-bootstrap";

import PostbackHistoriesService from "../../../../services/postback-histories.service";
import {useAlert} from "react-alert";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";

const ModalShowPostbacks = (props) => {
    const {postbacks, postbackID, dispatch} = props;
    const [postback, setPostback] = useState(undefined);
    const [histories, setHistories] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [filtering, setFiltering] = useState({});

    const alert = useAlert();
    //
    useEffect(() => {
        if (postbackID) {
            setPostback(postbacks?.find(obj => {
                return obj.id === postbackID;
            }))
            getPostbackHistoriesData();
        }


    }, [props.show])

    const getPostbackHistoriesData = (pageNumber) => {
        setLoading(true);

        try{
            PostbackHistoriesService.getAll(postbackID, pageNumber, filtering).then(
                response => {
                    if (response.data) {
                        setHistories(response.data?.data);
                        setMeta(response.data?.meta);
                    }

                },
                error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

              
                }
            )
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                {...props}
                // size="lg"
                dialogClassName="modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Postbaks:"} {postback?.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"
                                               onChange={(event) => setFiltering({
                                                   ...filtering,
                                                   words: event.target.value
                                               })}
                                               name="url"
                                               placeholder="All Urls"
                                               className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                        <button className="btn btn-primary " type="submit"
                                                onClick={ ()=> getPostbackHistoriesData(1)}
                                                disabled={loading}
                                        >
                                            <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                            {loading && (<span
                                                className="spinner-border spinner-border-sm"></span>)}
                                        </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">{"ID"}</th>
                                        <th scope="col">{"Url"}</th>
                                        <th scope="col">{"Response"}</th>
                                        <th scope="col">{"Date"}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {histories && histories.map((history) => (
                                        <tr>
                                            <th scope="row">{history.id}</th>
                                            <th scope="row">{history.url}</th>
                                            <th scope="row">{history.response}</th>
                                            <th scope="row">{history.created_at}</th>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex justify-content-center">
                                {meta && meta.total > 1 &&
                                    <Pagination
                                        activePage={meta.current_page}
                                        totalItemsCount={meta.total}
                                        itemsCountPerPage={meta.per_page}
                                        onChange={(pageNumber) => getPostbackHistoriesData(pageNumber)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>);

}


export default ModalShowPostbacks;

import {Button} from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";

const LoginMain = () => {

    const history = useHistory();
    const login = (type) => {
        history.push('/login/'+type)
    }

    return (
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                {/* <!-- login page start--> */}
                <div className="authentication-main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    {/*<div className="text-center"><img src={logo} alt="" /></div>*/}
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <Button onClick={() => login("affiliate")} size={"lg"}>Login as Affiliate</Button>
                                                <div className={"d-block auth-admin mt-2"}>
                                                    <span onClick={() => login("admin")}>admin</span>
                                                    <span onClick={() => login("manager")}>manager</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- login page end--> */}
            </div>
        </div>
    )
}

export default LoginMain
import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {
    REPORT_COLUMNS
} from "../constant/reportColumns";

const AFFILIATE_COLUMNS = [
    'count_leads',
    'count_conversions',
    'cr',
    'profit'
]

export const useDailyReportColumn = create()(
    devtools(
        persist(
            (set, get) => ({
                columns: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.data?.roles.includes('affiliate') ?
                    REPORT_COLUMNS.filter((column) => AFFILIATE_COLUMNS.includes(column.accessor)) : REPORT_COLUMNS,
                changeVisible: (accessor) => set({columns: get().columns.map((el) => el.accessor === accessor ? {...el, ...{is_visible: !el.is_visible}} : el)}),
            }),
            {
                name: 'daily-report-column',
                merge: (persistedState, currentState) => ({
                    ...persistedState, ...currentState, ...{
                        columns: persistedState.columns.map((el) => {
                            const findItem = currentState.columns.find((item) => item.accessor === el.accessor)
                            return findItem ? {...findItem, ...{is_visible: el.is_visible}} : el
                        })
                    }
                })
            }
        )
    )
)
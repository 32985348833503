import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import advertiserPerformanceReportsService from "../../../services/advertiser-performance.service";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useAdvertiserReportColumn} from "../../../store/useAdvertiserReportColumn";

const APPEND_HEADERS = [
    {
        header: 'Affiliate',
        accessor: 'affiliate.name',
        visible: true
    },
]

const InnerTableAffiliate = ({ row, countryRow, manager, country, period, affiliate, advertiser, }) => {
    const columns = useAdvertiserReportColumn((state) => state.columns)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
            setLoading(true)
            advertiserPerformanceReportsService.getAdvertiserPerformanceReportsByAdvertiserIdAndCountryId(manager?.id, country, period, affiliate, advertiser, row.original.advertiser.id, countryRow.original.country.id).then( res => {
                setData(res.data.data)
            }).catch((e) => {
                console.error(e)
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    return (
       <ReportTableExpanded
           data={data}
           columns={columns}
           appendHeaders={APPEND_HEADERS}
           loading={loading}
       />
    )

}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.advertiserReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}


export default connect(mapStateToProps)(InnerTableAffiliate);
import React from "react";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../constant";
import CommissionService from "../../../../services/advertiser/commission.service";

const ModalDeleteCommission = ({commission, setCommissions, commissions}) => {


    const deleteCommission = (id) => {
        setCommissions(commissions.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        CommissionService.delete(id).then(() => {
            setCommissions(commissions => commissions.filter(x => x.id !== id));
        });
    }

    const submitDeleteCommission = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteCommission(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button onClick={() => submitDeleteCommission(commission.id)}
                    className="btn btn-link p-0" disabled={commission.isDeleting}>
                {commission.isDeleting
                    ?
                    <span className="spinner-border spinner-border-lg "></span>
                    : <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    )
}

export default ModalDeleteCommission;
import {
    INVOICE_FILTER_FAIL,
    INVOICE_FILTER_SUCCESS,
    SET_INVOICE_PERIOD_SUCCESS,
    SET_INVOICE_SUCCESS
} from "../actions/types";
import moment from "moment";

const initialState = {
    invoices: null,
    meta: null,
    filter: null,
    total: null,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },

};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case INVOICE_FILTER_SUCCESS:
            return {
                ...state,
                invoices: payload.invoices,
                meta: payload.meta,
                filter: payload.filter,
                total: payload.total,
            };

        case INVOICE_FILTER_FAIL:
            return {
                ...state,
                invoices: null,
                meta: null,
            };

        case SET_INVOICE_SUCCESS:
            return {
                ...state,
                invoices: payload.invoices,

            };

        case SET_INVOICE_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,

            };

        default:
            return state;
    }
}

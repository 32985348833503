import React, {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Create, CreateAdvertiser, Name, Status, Token} from '../../constant';
import Breadcrumb from "../common/breadcrumb";
import AdvertiserService from "../../services/advertiser.service";
import PlatformSelected from "./platform/PlatformSelected";
import {toast} from "react-toastify";
import Title from "../common/custom-title";
import TimezoneSelect from "react-timezone-select";

const AdvertisersCreate = ({ history}) => {
    const {register, handleSubmit, reset, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)


    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);

        data.platform = data.platformObject?.title;
        data.timezone = selectedTimezone === "Etc/GMT" ? "UTC" : selectedTimezone;

        AdvertiserService.create(data)
            .then(
                response => {

                    toast.success('Advertiser created');

                    history.push('.');
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })

    }


    return (
        <Fragment>
            <Title title="Create Advertiser"/>
            <Breadcrumb parent="Advertisers" title="Create Advertiser" parentUrl={"/advertisers"}/>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{CreateAdvertiser}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input className="form-control" type="text" name="name" ref={register}
                                                       placeholder="Name"/>
                                                <span>{errors?.name}</span>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <span>{Status}:</span>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="switch-green">
                                                            <input type="checkbox" name="is_active"
                                                                   ref={register} defaultChecked={1}/>

                                                            <div className="slider"></div>
                                                        </label>
                                                        <div className={"text-danger"}>{errors?.is_active}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 ">
                                            <PlatformSelected control={control} error={errors?.platform} showTitle={true}
                                            />
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Timezone:</label>
                                                <TimezoneSelect
                                                    value={selectedTimezone}
                                                    onChange={(data) => setSelectedTimezone(data.value)}
                                                />
                                                <span>{errors?.timezone}</span>
                                            </div>
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Token}</label>
                                                <input className="form-control" type="text" name="token" ref={register}
                                                       placeholder="Token"/>
                                                <span>{errors?.token}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Url Domain"}</label>
                                                <input className="form-control" type="text" name="url" ref={register}
                                                       placeholder="https://domen.com"/>
                                                <span>{errors?.url}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Custom"}</label>
                                                <input className="form-control" type="text" name="custom" ref={register}
                                                       placeholder=""/>
                                                <span>{errors?.custom}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Custom1"}</label>
                                                <input className="form-control" type="text" name="custom1" ref={register}
                                                       placeholder=""/>
                                                <span>{errors?.custom1}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Custom2"}</label>
                                                <input className="form-control" type="text" name="custom2" ref={register}
                                                       placeholder=""/>
                                                <span>{errors?.custom2}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Custom3"}</label>
                                                <input className="form-control" type="text" name="custom3" ref={register}
                                                       placeholder=""/>
                                                <span>{errors?.custom3}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Custom4"}</label>
                                                <input className="form-control" type="text" name="custom4" ref={register}
                                                       placeholder=""/>
                                                <span>{errors?.custom4}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{"Custom5"}</label>
                                                <input className="form-control" type="text" name="custom5" ref={register}
                                                       placeholder=""/>
                                                <span>{errors?.custom5}</span>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default AdvertisersCreate;

import React, {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import { EditProfile, EmailAddress, Name, NewPassword, Update} from '../constant';
import Breadcrumb from "./common/breadcrumb";

import {connect} from "react-redux";
import ProfileService from "../services/profile.service";
import {getMe} from "../actions/auth";
import {GET_ME_SUCCESS} from "../actions/types";
import {toast} from "react-toastify";
import Title from "./common/custom-title";

const Profile = ({ history, dispatch, user, token}) => {

    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        ProfileService.update(data)
            .then(
                response => {

                    dispatch({
                        type: GET_ME_SUCCESS,
                        payload: {
                            user: {
                                data: {
                                    ...user,
                                    name: response.data.data?.name,
                                    email: response.data.data?.email,
                                }
                            }
                        },
                    });
                    setValue("password", "")
                    toast.success('Profile updated');

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })


    }

    useEffect(() => {
        if(token){
            dispatch(getMe());
        }

        const fields = [
            'name',
            'email',
        ];

        fields.forEach(field => setValue(field, user[field]));

    }, []);

    return (
        <Fragment>
            <Title title="Edit Profile"/>
            <Breadcrumb  title="Edit Profile"/>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row justify-content-center">

                        <div className="col-md-10">
                            <form autoComplete="off" noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                </div>
                                <div className="card-body">

                                    <div className="form-row">

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input className="form-control" type="text" name="name"
                                                       ref={register}
                                                       placeholder={Name}/>
                                                <span>{errors?.name}</span>
                                            </div>
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input className="form-control" type="text" name="email"
                                                       ref={register}
                                                       placeholder={EmailAddress}/>
                                                <span>{errors?.email}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{NewPassword}</label>
                                                <input className="form-control" type="password" name="password"
                                                       ref={register}
                                                       placeholder={NewPassword}/>

                                                <span>{errors?.password}</span>

                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                        {Update}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}
function mapStateToProps(state) {
    const { user, token } = state.auth;
    return {
        user: user.data,
        token,
    };
}

export default connect(mapStateToProps)(Profile);

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import {Redirect} from 'react-router-dom';
import {setRouterEach} from "react-router-config-guard";

import Default from './components/dashboard/default';
import AuthService from "./services/auth.service";

// pages
import Error404 from './pages/errors/error404';
import Login from './pages/auth/login';
import LoginMain from './pages/auth/login-main';
import Dashboard from "./layouts/Dashboard";
import CountryList from "./components/country/CountryList";
import LeadsList from "./components/lead/LeadsList";
import LeadsEdit from "./components/lead/LeadsEdit";
import AdvertisersList from "./components/advertiser/AdvertisersList";
import AdvertisersEdit from "./components/advertiser/AdvertisersEdit";
import AdvertisersCreate from "./components/advertiser/AdvertisersCreate";
import AffiliatesList from "./components/affiliate/AffiliatesList";
import AffiliatesCreate from "./components/affiliate/AffiliatesCreate";
import AffiliatesEdit from "./components/affiliate/AffiliatesEdit";
import AffiliatesAdvertiserRestriction from "./components/affiliate/advertiser-restriction/RestrictionList";
import AdminsList from "./components/admin/AdminsList";
import AdminsCreate from "./components/admin/AdminsCreate";
import AdminsEdit from "./components/admin/AdminsEdit";
import Profile from "./components/Profile";
import ManagersList from "./components/manager/ManagersList";
import ManagersCreate from "./components/manager/ManagersCreate";
import ManagersEdit from "./components/manager/ManagersEdit";
import ConversionsList from "./components/conversion/ConversionsList";
import ApiRequestsList from "./components/api-request/ApiRequestsList";
import AdvertisersDistributionList from "./components/advertiser/distribution/AdvertisersDistributionList";
import AffiliatesDetails from "./components/affiliate/AffiliatesDetails";
import AdminsPermissionList from './components/permissions/admin/AdminsPermissionList';
import AdminsPermissionEdit from './components/permissions/admin/AdminsPermissionEdit';
import ManagerPermissionList from './components/permissions/manager/ManagerPermissionList';
import ManagerPermissionEdit from './components/permissions/manager/ManagerPermissionEdit';
import GroupPermissionList from "./components/permissions/group/GroupPermissionList";
import GroupPermissionEdit from "./components/permissions/group/GroupPermissionEdit"
import OptimizeList from "./components/optimize/OptimizeList";
import ModerationList from "./components/moderation/ModerationList";
import DailyReportList
    from "./components/daily-reports/DailyReportList";
import AffiliatePerformanceReportList
    from "./components/affiliate-performance-reports/AffiliatePerformanceReportList";
import AdvertiserPerformanceReportList
    from "./components/advertiser-performance-reports/AdvertiserPerformanceReportList";
import ManualConversionList from "./components/manual-conversion/ManualConversionList";
import ErrorLogin from "./pages/errors/error-login";
import AffiliateSettings
    from "./components/affiliate-settings/AffiliateSettings";
import InvoicesList from "./components/invoice/InvoicesList";
import RegisterTestLead from "./components/test-lead/RegisterTestLead";
import Import from "./pages/Import";


const routes = [
    {
        path: '/',
        exact: true,
        component: LoginMain
    },
    {
      path: '/login',
      exact: true,
      component: LoginMain
    },
    {
        path: '/errors/error-404',
        component: Error404
    },
    {
        path: '/login/errors',
        component: ErrorLogin
    },
    {
        path: '/login/manager',
        exact: true,
        component: Login
    },
    {
        path: '/logout',
        exact: true,
        component: Login
    },
    {
        path: '/login/admin',
        exact: true,
        component: Login
    },
    {
        path: '/login/affiliate',
        exact: true,
        component: Login
    },
    {
        route: '*',
        component: Dashboard,
        meta: {
            auth: true
        },
        routes: [
            {
                path: '/dashboard',
                exact: true,
                component: Default
            },
            {
                path: '/profile',
                exact: true,
                component: Profile
            },
            {
                path: '/countries',
                exact: true,
                component: CountryList
            },
            {
                path: '/leads',
                exact: true,
                component: LeadsList
            },
            {
                path: '/leads/:id',
                exact: true,
                component: LeadsEdit
            },
            {
                path: '/api-request',
                exact: true,
                component: ApiRequestsList
            },
            {
                path: '/conversions',
                exact: true,
                component: ConversionsList
            },
            {
                path: '/optimize',
                exact: true,
                component: OptimizeList
            },
            {
                path: '/affiliates/advertiser-restriction',
                exact: true,
                component: AffiliatesAdvertiserRestriction
            },
            {
                path: '/moderation',
                exact: true,
                component: ModerationList
            },
            {
                path: '/affiliates',
                exact: true,
                component: AffiliatesList
            },
            {
                path: '/affiliates/create',
                exact: true,
                component: AffiliatesCreate
            },
            {
                path: '/affiliates/details',
                exact: true,
                component: AffiliatesDetails
            },
            {
                path: '/affiliates/:affiliate_id',
                exact: true,
                component: AffiliatesEdit
            },
            {
                path: '/invoices/:type',
                exact: true,
                component: InvoicesList
            },
            {
                path: '/advertisers',
                exact: true,
                component: AdvertisersList
            },
            {
                path: '/advertisers/create',
                exact: true,
                component: AdvertisersCreate
            },
            {
                path: '/advertisers/distribution',
                exact: true,
                component: AdvertisersDistributionList
            },
            {
                path: '/advertisers/:id',
                exact: true,
                component: AdvertisersEdit
            },
            {
                path: '/admins',
                exact: true,
                component: AdminsList
            },
            {
                path: '/admins/create',
                exact: true,
                component: AdminsCreate
            },
            {
                path: '/admins/:id',
                exact: true,
                component: AdminsEdit
            },
            {
                path: '/managers',
                exact: true,
                component: ManagersList
            },
            {
                path: '/managers/create',
                exact: true,
                component: ManagersCreate
            },
            {
                path: '/permissions/admins',
                exact: true,
                component: AdminsPermissionList
            },
            {
                path: '/permissions/admins/:id',
                exact: true,
                component: AdminsPermissionEdit
            },
            {
                path: '/permissions/managers',
                exact: true,
                component: ManagerPermissionList
            },
            {
                path: '/permissions/managers/:id',
                exact: true,
                component: ManagerPermissionEdit
            },
            {
                path: '/permissions/groups',
                exact: true,
                component: GroupPermissionList
            },
            {
                path: '/permissions/groups/:id',
                exact: true,
                component: GroupPermissionEdit
            },

            {
                path: '/managers/:id',
                exact: true,
                component: ManagersEdit
            },

            {
                path: '/reports/daily',
                exact: true,
                component: DailyReportList
            },
            {
                path: '/reports/affiliate-performance',
                exact: true,
                component: AffiliatePerformanceReportList
            },
            {
                path: '/reports/advertiser-performance',
                exact: true,
                component: AdvertiserPerformanceReportList
            },
            {
                path: '/manual-conversion',
                exact: true,
                component: ManualConversionList
            },
            {
                path: '/affiliate-settings',
                exact: true,
                component: AffiliateSettings
            },
            {
                path: '/register-test-lead',
                exact: true,
                component: RegisterTestLead
            },
            {
                path: '/import',
                exact: true,
                component: Import
            },
            {
                component: () => <Redirect to="/errors/error-404"/>
            }
        ]
    },
];

setRouterEach((to, from, next) => {
    let route = to.route || {};
    let title = route.title || "";
    if (title) {
        document.title = title;
    }
    if (to && to.meta && to.meta.auth) {
        if (!AuthService.authenticated()) {
            let loginUrl = localStorage.getItem('loginUrl')
            let redirectUrl = '/login';
            if (loginUrl){
                let s = new URL(loginUrl)
                redirectUrl  = s.pathname
            }
            next({
                path: redirectUrl,
                // will Redirect
                replace: true
            });
        } else {
            next();
        }
    } else {
        next();
    }

}, (to) => {

});

export default routes;

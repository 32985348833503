import {
    CONVERSION_FILTER_FAIL,
    CONVERSION_FILTER_SUCCESS,
    SET_CONVERSION_ADVERTISER_SUCCESS,
    SET_CONVERSION_AFFILIATE_SUCCESS,
    SET_CONVERSION_COUNTRY_SUCCESS,
    SET_CONVERSION_FILTERING_SUCCESS,
    SET_CONVERSION_SUCCESS,
    SET_CONVERSION_PERIOD_SUCCESS,
    SET_CONVERSION_PERIODTIME_SUCCESS,
    SET_CONVERSION_MANAGER_SUCCESS, SET_CONVERSION_HISTORY_SUCCESS,
} from "../actions/types";
import moment from "moment/moment";

export const CONVERSION_DEFAULT_FILTERING = {
    is_test: 2,
}

const initialState = {
    conversions: null,
    meta: null,
    country: null,
    affiliate: null,
    manager: null,
    advertiser: null,
    pageSize: 25,
    pageNumber: null,
    periodTime: {
        selectedDays: ['1','2','3','4','5','6','7'],
        fromTime: "00:00",
        toTime: "23:59",
        status: false,
    },
    filtering: CONVERSION_DEFAULT_FILTERING,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },
    history: []
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case CONVERSION_FILTER_SUCCESS:
            return {
                ...state,
                conversions: payload.conversions,
                meta: payload.meta,
                country: payload.country,
                affiliate: payload.affiliate,
                period: payload.period,
                advertiser: payload.advertiser,
                manager: payload.manager,
                filtering: payload.filtering,
                periodTime: payload.periodTime,
                pageNumber: payload.pageNumber,
                pageSize: payload.pageSize,

            };

        case SET_CONVERSION_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };

        case SET_CONVERSION_AFFILIATE_SUCCESS:
            return {
                ...state,
                affiliate: payload.affiliate,
            };

        case SET_CONVERSION_ADVERTISER_SUCCESS:
            return {
                ...state,
                advertiser: payload.advertiser,
            };

        case SET_CONVERSION_MANAGER_SUCCESS:
            return {
                ...state,
                manager: payload.manager,
            };

        case SET_CONVERSION_COUNTRY_SUCCESS:
            return {
                ...state,
                country: payload.country,
            };

        case SET_CONVERSION_PERIODTIME_SUCCESS:
            return {
                ...state,
                periodTime: payload.periodTime,
            };

        case CONVERSION_FILTER_FAIL:
            return {
                ...state,
                conversions: null,
                meta: null,
                country: null,
                affiliate: null,
                advertiser: null,
                filtering: {},
                period: {},
                periodTime: {},

            };

        case SET_CONVERSION_FILTERING_SUCCESS:
            return {
                ...state,
                filtering: payload.filtering,
            };

        case SET_CONVERSION_SUCCESS:
            return {
                ...state,
                conversions: payload.conversions,
            };
        case SET_CONVERSION_HISTORY_SUCCESS:
            return {
                ...state,
                history: [...state.history, ...[payload.history]]
            }


        default:
            return state;
    }
}

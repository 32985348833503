import React, { Fragment, useState } from 'react'
import {usePagination, useRowSelect, useSortBy, useTable} from 'react-table'
import Pagination from "react-js-pagination";
import styled from 'styled-components'
import ExportConversions from "./ExportConversions";
import TableReact from 'react-bootstrap/Table';
import {connect} from "react-redux";
import {
    conversionHistory,
    conversionSearch
} from "../../../actions/conversion";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {
    CAN_UPDATE_LEAD_ADV_PAYOUT,
    CAN_UPDATE_LEAD_AFF_PAYOUT, CAN_UPDATE_LEAD_IS_TEST
} from "../../../constant/permissions";
import ModalEditLeadAffPayout from "./modal/ModalEditConversionAffPayout";
import ModalEditLeadAdvPayout from "./modal/ModalEditConversionAdvPayout";
import ModalEditConversionIsTest from "./modal/ModalEditConversionIsTest";
import ReportColumnsService
    from "../../../services/report-columns.service";
import {getMe} from "../../../actions/auth";
import {Button, Dropdown} from "react-bootstrap";
import { SaleStatusRaw } from "../../table/columns/SaleStatusRaw/SaleStatusRaw";
import { PaginationTitle } from "../../table/pagination/PaginationTitle";
import {useTimezoneStore} from "../../../store/timezone";
import {ConvertTimezoneDate} from "../../select/timezone/ConvertTimezoneDate";
import {AffiliatesInfo} from "../../affiliate/AffiliateInfo";
import {ManagerInfo} from "../../manager/ManagerInfo";
import {useMutation} from "@tanstack/react-query";
import LeadService from "../../../services/lead.service";
import {toast} from "react-toastify";

const Styles = styled.div`
  .onhover-show-div {
    top:50px
  }
`

export const IndeterminateCheckbox = React.forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef,
        indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
})


const Table = ({
                   columns,
                   data,
                   user,
                   meta,
                   country,
                   period,
                   affiliate,
                   filtering,
                   periodTime,
                   advertiser,
                   dispatch,
                   manager,
                   history,
                   pageSize: pS,
                   pageNumber: pN
               }) => {
    // Use the state and functions returned from useTable to build your UI

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        allColumns,
        getToggleHideAllColumnsProps,
        selectedFlatRows
    } = useTable({
        columns, data, initialState: {pageIndex: 0, pageSize: meta?.per_page, hiddenColumns : user?.data?.selectedColumns.filter(el => el.type === 'Conversions').map(el => {
                return el.title
            })},
    }, useSortBy, usePagination, useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    ),
                },
                ...columns,
            ])
        })


    const [selectedPageSize, setSelectedPageSize] = useState(meta?.per_page);
    const {timezone} = useTimezoneStore()

    const {mutate: leadIsTest} = useMutation({
        mutationFn: LeadService.updateIsTest,
        onSuccess: async () => {
            toast.success('Conversion type changed')
            await dispatch(conversionSearch(manager, country, period, periodTime, affiliate, advertiser, pN, filtering, pS, timezone));
        },
        onError: (error) => toast.error((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
    })

    const setPageSizeRedux = (pageSize) => {
        setSelectedPageSize(pageSize)
        setPageSize(pageSize)
        dispatch(conversionSearch(manager, country, period, periodTime, affiliate, advertiser, 1, filtering, pageSize, timezone))
    }


    const toggleAll = (e) => {
        let ids = allColumns.filter(el => el.id_num && !el.isVisible).map(el => el.id_num)
        if (!e.target.checked){
            ids = allColumns.filter(el => el.id_num).map(el => el.id_num)
        }
        ReportColumnsService.toggleAll(ids).then(async res => {
            dispatch(await getMe())
        })
    }


    const setColumns = async (column) => {
        if (!columns.includes(column.id))
            columns.push(column.id)
        else
            columns.splice(columns.indexOf(column.id), 1)
        ReportColumnsService.toggleSelectedColumn(column.id_num).then(async res => {
            dispatch(await getMe())
        })
    }

    return (<>

            <Styles>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between m-0">
                            <div className="d-flex">
                                {
                                    data && data.length ?
                                        <ExportConversions columns={allColumns}/>
                                        : <></>
                                }

                            <ShowForPermission
                                permission={[CAN_UPDATE_LEAD_IS_TEST]}>

                                {selectedFlatRows?.length > 0 && (
                                    <div className="ml-2">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-selected-lead" >
                                                Selected Leads: {selectedFlatRows.length}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <ShowForPermission
                                                    permission={CAN_UPDATE_LEAD_IS_TEST}>
                                                    <Dropdown.Item onClick={() => leadIsTest({
                                                        is_test: '1',
                                                        leads: selectedFlatRows.map((item) => item.original.id)
                                                    })}>Mark As Test</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => leadIsTest({
                                                        is_test: '0',
                                                        leads: selectedFlatRows.map((item) => item.original.id)
                                                    })}>Mark As Real</Dropdown.Item>
                                                </ShowForPermission>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )}
                            </ShowForPermission>
                            </div>

                            <div className='row'>


                                <div className='onhover-dropdown'>
                                    <Button className="dropbtn" color={'primary'}>Visible columns<span><i
                                        className="icofont icofont-arrow-down"></i></span></Button>
                                    <ul className="onhover-show-div p-20 top-50">
                                        <li>
                                            <label className="d-flex">
                                                <IndeterminateCheckbox onClick={(e) => {toggleAll(e)}} {...getToggleHideAllColumnsProps()} />&nbsp;
                                                <span>Toggle&nbsp;All</span>
                                            </label>
                                        </li>
                                        {allColumns.map(column => (<li key={column.id}>
                                                <label>
                                                    <input onClick={() => {setColumns(column)}} type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                                    {column.Header}
                                                </label>
                                            </li>))}
                                    </ul>
                                </div>

                                <div className="pagination ml-2">
                                    <select className="custom-select"
                                            value={meta?.per_page}
                                            onChange={e => {
                                                setPageSizeRedux(Number(e.target.value))
                                            }}
                                    >
                                        {[5,
                                            10,
                                            15,
                                            25,
                                            50,
                                            100,
                                            200].map(pageSize => (<option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {(meta && data.length) ? (
                            <div className="mt-4">
                                <PaginationTitle
                                    total={meta.total}
                                    from={meta?.from ?? 0}
                                    to={meta?.to ?? 0}
                                />
                            </div>
                        ) : <></>}
                    </div>

                    <div className="">
                        <div className="table-responsive">

                            <TableReact bordered striped size="sm" {...getTableProps()}>
                                <thead>
                                {headerGroups.map(headerGroup => (<tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (// Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <br/>
                                            <span> &nbsp;
                                                {column.isSorted ? column.isSortedDesc ?
                                                    <i className="fa fa-chevron-down"></i> :
                                                    <i className="fa fa-chevron-up"></i> : ''}
                                        </span>
                                        </th>))}
                                </tr>))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (<tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            return (<td {...cell.getCellProps()}>
                                                {cell.column.id === "affiliate" ? (
                                                    <AffiliatesInfo affiliate={cell.row.original?.affiliate} />
                                                ) : (cell.column.id === "affiliate_manager" && cell.row.original.affiliate?.manager) ? (
                                                    <ManagerInfo manager={cell.row.original.affiliate?.manager} />
                                                ): cell.column.id === "is_conversions" || cell.column.id === "is_smart_click" || cell.column.id === "live_lead" || cell.column.id === "is_pending" ? (cell.value ? "Yes" : "No")  : cell.column.id === 'advertiser_payout' ? (
                                                    <>
                                                        <div>
                                                            {Number(cell.value)}
                                                        </div>
                                                        <ShowForPermission permission={CAN_UPDATE_LEAD_ADV_PAYOUT}>
                                                            <ModalEditLeadAdvPayout
                                                                conversion={cell.row.original}
                                                            />
                                                        </ShowForPermission>
                                                    </>

                                                ) : cell.column.id == "failed_registration_count" ? (
                                                        cell.value && cell.value !== 0 ? cell.value : ''
                                                    ) : cell.column.id == 'affiliate_payout' ? (
                                                    <>
                                                        <div>
                                                            {Number(cell.value)}
                                                        </div>
                                                        <ShowForPermission permission={CAN_UPDATE_LEAD_AFF_PAYOUT}>
                                                            <ModalEditLeadAffPayout
                                                                conversion={cell.row.original}
                                                            />

                                                        </ShowForPermission>
                                                    </>

                                                ) : cell.column.id === "details" ? (cell.value && Object.keys(cell.value).map((item, i) => (
                                                        <Fragment key={i}>
                                                            <span><nobr>{item}: {cell.value[item]}</nobr></span><br/>
                                                        </Fragment>))

                                                ): cell.column.id === 'btns' ? (<>
                                                            <div className="d-flex justify-content-end">
                                                                <ShowForPermission
                                                                    permission={CAN_UPDATE_LEAD_IS_TEST}>

                                                                    <ModalEditConversionIsTest
                                                                        conversion={cell.row.original}
                                                                    />

                                                                </ShowForPermission>
                                                            </div>
                                                        </>

                                                    ) : cell.column.id === 'sale_status_raw' ? (
                                                        <SaleStatusRaw
                                                            cell={cell}
                                                            key={index}
                                                            history={history}
                                                            dispatchFn={async () => await dispatch(conversionHistory(cell.row.values.id))}
                                                        />
                                                ) : ['actual_conversion_date', 'conversion_date', 'signup_date'].indexOf(cell.column.id)  > -1 ? (
                                                    <ConvertTimezoneDate
                                                        date={cell.value}
                                                    />
                                                ):  (cell.render('Cell'))}

                                            </td>)
                                        })}
                                    </tr>)
                                })}
                                </tbody>
                            </TableReact>




                        </div>
                    </div>
                    <div className="card-footer">
                        {(meta && data.length) ? (
                            <PaginationTitle
                                total={meta.total}
                                from={meta?.from ?? 0}
                                to={meta?.to ?? 0}
                            />
                        ) : <></>}
                        {(meta && meta?.last_page > 1) && (
                            <div className="d-flex  justify-content-center mt-2">
                                <Pagination
                                    activePage={meta?.current_page}
                                    totalItemsCount={meta?.total}
                                    itemsCountPerPage={Number(meta?.per_page)}
                                    onChange={(pageNumber) => dispatch(conversionSearch(manager, country, period, periodTime, affiliate, advertiser, pageNumber, filtering, selectedPageSize, timezone))}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        )}
                    </div>

                </div>

            </Styles>
        </>)
}

function mapStateToProps(state) {
    const {conversions, meta, country, period, affiliate, advertiser, filtering, periodTime, manager, history, pageSize, pageNumber} = state.conversion;
    const {user} = state.auth
    return {
        user, conversions, meta, country, period, affiliate, filtering, periodTime, advertiser, manager, history, pageSize, pageNumber
    };
}

export default connect(mapStateToProps)(Table);

import React, {Fragment, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";
import ManagerSelected from "../../../manager/ManagerSelected";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../ReportAffiliatesSelected";

const SearchAffiliate = ({getData, user}) => {
    const {register, handleSubmit, reset, control} = useForm();
    const [loading, setLoading] = useState(false);
    const isManager = user?.data?.roles?.includes('manager')

    const onSubmit = async (data) => {

        setLoading(true);
        try {
            await getData({
                id: data.affiliate?.id,
                manager_id: data.manager?.id,
                active: data?.active,
            });
        } finally {
            setLoading(false);
        }
    }

    return (<Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <form noValidate className="needs-validation"
                                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                                        <div className="form-row">

                                            <div className="col-md-4">
                                                <ReportAffiliatesSelected control={control} />
                                            </div>

                                            {!isManager && (
                                                <div className="col-md-4">
                                                    <ManagerSelected control={control} />
                                                </div>
                                            )}

                                            <div className=" col-md-2">
                                                <div className="form-group">
                                                    <select name="active" className="custom-select" id="" ref={register}>
                                                        <option value="" disabled selected>Status</option>
                                                        <option value="">All</option>
                                                        <option value="1">Active</option>
                                                        <option value="2">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-flex">
                                                    <button className="btn btn-primary " type="submit"
                                                            disabled={loading}
                                                    >
                                                    <span><i className="fa fa-search"
                                                             aria-hidden="true"></i>&nbsp;</span>
                                                        {loading && (<span
                                                            className="spinner-border spinner-border-sm"></span>)}
                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(SearchAffiliate);

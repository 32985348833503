import React from 'react'
import {usePagination, useSortBy, useTable} from 'react-table'
import Pagination from "react-js-pagination";
import {Button} from 'reactstrap';
import styled from 'styled-components'
import {
    CAN_FILTER_ADVERTISER, CAN_FILTER_AFFILIATE,
} from "../constant/permissions";
import {ShowForPermission} from "../helpers/ShowForPermission";
import TableReact from "react-bootstrap/Table";

const Styles = styled.div`
  .onhover-show-div {
    top:50px
  }
`

export const IndeterminateCheckbox = React.forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
})


export const Table = ({columns, data, changeData}) => {
    // Use the state and functions returned from useTable to build your UI

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        allColumns,
        getToggleHideAllColumnsProps,
        state: {pageIndex, pageSize},
    } = useTable({
        columns, data, initialState: {pageIndex: 0},
    }, useSortBy, usePagination,)


    return (
        <>

            <Styles>
                <div className="d-flex justify-content-end m-0 d-flex justify-content-between">
                    <div className='row'>
                        <div className={"col-12"}>
                            <select className="custom-select"
                                    onChange={(e) => changeData(e.target.value)}
                            >
                                <ShowForPermission permission = {CAN_FILTER_ADVERTISER}>
                                    <option value="advertiser">Top Advertisers</option>
                                </ShowForPermission>
                                <ShowForPermission permission = {CAN_FILTER_AFFILIATE}>
                                    <option value="affiliate">Top Affiliates</option>
                                </ShowForPermission>
                                <option value="country" selected="selected">Top Countries</option>
                            </select>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='onhover-dropdown'>
                            <Button className="dropbtn" color={'primary'}>Visible columns<span><i
                                className="icofont icofont-arrow-down"></i></span></Button>
                            <ul className="onhover-show-div p-20 top-50">
                                <li>
                                    <label className="d-flex">
                                        <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />&nbsp;
                                        <span>Toggle&nbsp;All</span>
                                    </label>
                                </li>
                                {allColumns.map(column => (
                                    <li key={column.id}>
                                        <label>
                                            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                            {column.Header}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="pagination ml-2">
                            <select className="custom-select"
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                            >
                                {[10, 25, 50, 100].map(pageSize => (<option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>))}
                            </select>
                        </div>
                    </div>

                </div>


                <TableReact bordered striped className={"mt-3"} size="sm" {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (<tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (// Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span> &nbsp;
                                    {column.isSorted ? column.isSortedDesc ?
                                        <i className="fa fa-chevron-down"></i> :
                                        <i className="fa fa-chevron-up"></i> : ''}
                  </span>
                            </th>))}
                    </tr>))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (<tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                            })}
                        </tr>)
                    })}
                    </tbody>
                </TableReact>
                {pageCount > 1 && (<div className="d-flex  justify-content-center mt-2">
                    <Pagination
                        activePage={pageIndex + 1}
                        totalItemsCount={pageCount * pageSize}
                        itemsCountPerPage={pageSize}
                        onChange={(pageNumber) => {
                            const page = pageNumber ? Number(pageNumber) - 1 : 0
                            gotoPage(page)
                        }}
                        itemClass="page-item"
                        linkClass="page-link"
                    />

                </div>)}
            </Styles>
        </>
    )
}
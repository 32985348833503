import {
    ADVERTISER_PERFORMANCE_REPORT_FILTER_FAIL,
    ADVERTISER_PERFORMANCE_REPORT_FILTER_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_ADVERTISER_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_AFFILIATE_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_COUNTRY_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_PERIOD_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_MANAGER_SUCCESS,
} from "../actions/types";
import moment from "moment/moment";

const initialState = {
    advertiserReports: null,
    country: null,
    affiliate: null,
    manager: null,
    advertiser: null,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm:ss'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm:ss'),
        time: "today",
    },
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case ADVERTISER_PERFORMANCE_REPORT_FILTER_SUCCESS:
            return {
                ...state,
                advertiserReports: payload.advertiserReports,
                country: payload.country,
                affiliate: payload.affiliate,
                manager: payload.manager,
                advertiser: payload.advertiser,
                period: payload.period,
            };

        case ADVERTISER_PERFORMANCE_REPORT_FILTER_FAIL:
            return {
                ...state,
                advertiserReports: null,
                country: null,
                manager: null,
                affiliate: null,
                period: {},
                advertiser: null

            };

        case SET_ADVERTISER_PERFORMANCE_REPORT_AFFILIATE_SUCCESS:
            return {
                ...state,
                affiliate: payload.affiliate,
            };

        case SET_ADVERTISER_PERFORMANCE_REPORT_ADVERTISER_SUCCESS:
            return {
                ...state,
                advertiser: payload.advertiser,
            };

        case SET_ADVERTISER_PERFORMANCE_REPORT_COUNTRY_SUCCESS:
            return {
                ...state,
                country: payload.country,
            };

        case SET_ADVERTISER_PERFORMANCE_REPORT_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };

        case SET_ADVERTISER_PERFORMANCE_REPORT_MANAGER_SUCCESS:
            return {
                ...state,
                manager: payload.manager,
            };

        default:
            return state;
    }
}

import {
    Fragment,
} from "react";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import AdvertiserService from "../../../services/advertiser.service";

const ExportAdvertiser = ({filter}) => {


    const sendExport = () => {
        AdvertiserService.export(filter).then(res => {
            toast.success('The export has been generated successfully. You will receive an email with a download link.');
        })
    }

    return (
        <Fragment>
            <Button size="md" onClick={() => sendExport()}>
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                csv
            </Button>
        </Fragment>
    )
}


export default ExportAdvertiser;

import React, {useEffect, useMemo, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {daysOfWeekObject, nowDayOfWeek} from "../../../constant/date";
import DowntimesList from "./DowntimesList";
import SearchDowntime from "./parts/SearchDowntime";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_CREATE_ADVERTISER_DOWNTIME} from "../../../constant/permissions";
import ModalCreateDowntime from "./parts/ModalCreateDowntime";
import DowntimeService from "../../../services/advertiser/downtime.service";

const StreamDowntimeList = ({advertiser}) => {
    const daysOfWeek = daysOfWeekObject();
    const [eventKey, setEventKey] = useState(nowDayOfWeek)
    const [countries, setCountries] = useState(undefined)
    const [downtimesDaysOfWeek, seDowntimesDaysOfWeek] = useState([]);

    const addDowtimes = (data, meta = {}) => {
        if(!downtimesDaysOfWeek?.find((el) => el?.dayOfWeek === eventKey)){
            seDowntimesDaysOfWeek([
                    ...downtimesDaysOfWeek,
                {
                    dayOfWeek: eventKey,
                    data : {
                        data,
                        meta,
                    }
                }
            ])
            return;
        }

        seDowntimesDaysOfWeek(downtimesDaysOfWeek.map((el) => {
            if(el.dayOfWeek === eventKey){
                el.data.data = data;

                if(meta && meta.length > 0){
                    el.data.meta = meta;
                }
            }

            return el;
        }));
    }

    useEffect(() => {
        getDowntimesData(1, countries?.map(el => el.iso))
    }, [eventKey])

    const getDowntimesData = (page = 1, geo = '') => {
        if(geo === ''){
            setCountries(null)
        }
        DowntimeService.getAll(eventKey, advertiser?.id, geo, page).then(res => addDowtimes(res.data.data, res.data.meta))
    }

    const downtimeData = useMemo(() =>  downtimesDaysOfWeek?.find((el) => el?.dayOfWeek === eventKey)?.data, [downtimesDaysOfWeek, eventKey])


    return (
        <>
            <div className="card">
                <div className={"card-header"}>
                    <div className={"row d-flex justify-content-between mx-0"}>

                        <div className={"col-md-9"}>
                            <SearchDowntime
                                getDowntimesData={getDowntimesData}
                                countries={countries}
                                setCountries={setCountries}
                            />
                        </div>

                        <div className={"col-md-3"}>
                            <ShowForPermission permission={CAN_CREATE_ADVERTISER_DOWNTIME}>
                                <ModalCreateDowntime
                                    getDowntimesData={getDowntimesData}
                                    advertiserID={advertiser?.id}
                                />
                            </ShowForPermission>
                        </div>


                    </div>
                </div>
                <div className="card-body">
                    <Tabs
                        defaultActiveKey={nowDayOfWeek}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onSelect={(key) => {
                            setEventKey(key)
                        }}
                    >
                        {daysOfWeek && daysOfWeek.map((el, i) => (
                            <Tab key={i} eventKey={el.value} title={el.value}>
                                <DowntimesList
                                    setDowntimes={addDowtimes}
                                    downtimes={downtimeData?.data}
                                    meta={downtimeData?.meta}
                                    getDowntimesData={getDowntimesData}
                                    countries={countries}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </div>

        </>
    )
}

export default StreamDowntimeList;
import React, {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
    Advertiser,
    Affiliate,
    ClickID,
    Date,
    EditLead,
    EmailAddress,
    EventID,
    EverflowID,
    EverflowOfferID,
    FirstName,
    Ip,
    LastName,
    Offer,
    Result, Status,
    Sub2,
    SubCampaign,
    Telephone,
    UpdateLead
} from '../../constant';
import Breadcrumb from "../common/breadcrumb";

import LeadService from "../../services/lead.service";

import EventBus from "../../common/EventBus";

import IsoSelected from "../country/IsoSelected";
import {useAlert} from "react-alert";
import {toast} from "react-toastify";
import Title from "../common/custom-title";

const LeadsEdit = ({ history, match, dispatch}) => {
    const {id} = match.params;
    const {register, handleSubmit, reset, setValue, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const [lead, setLead] = useState(undefined);
    const alert = useAlert();

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        data.country_iso = data.country?.iso;

        LeadService.update(id, data)
            .then(
                response => {

                    toast.success('Lead updated');

                    history.push('.');
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }
              
                })
            .finally(() => {
                setLoading(false);

            })


    }

    useEffect(() => {


        LeadService.getById(id).then((res) => {
            let lead = res.data.data;
            setLead(lead);
            const fields = [
                'first_name',
                'last_name',
                'email',
                'phone',
                'date',
                'click_id',
                'country',
                'ip',
                'sub_campaign',
            ];
            fields.forEach(field => setValue(field, lead[field]));
        });
    }, []);

    return (
        <Fragment>
            <Title title="Edit Lead"/>
            <Breadcrumb parent="Leads" title="Edit Lead"/>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-2">{Affiliate}</h4>
                                </div>
                                <div className="card-body">

                                    {lead && lead.affiliate &&
                                        <form>
                                            <div className="row mb-2">
                                                <div className="col">
                                                    <h3 className="mb-1">{lead.affiliate.name}</h3>
                                                    <p className="mb-1">{lead.affiliate.email}</p>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="col-md-12">
                                                        <span>{Status}:</span>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="switch-green">
                                                            <input type="checkbox" name="is_active" defaultChecked={lead.affiliate.is_active} disabled={true}
                                                                   ref={register}/>
                                                            <div className="slider"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    }

                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header m-0">
                                    <h4 className="card-title mb-2">{Advertiser}</h4>
                                </div>
                                <div className="card-body">

                                    {lead && lead.advertiser &&
                                        <form>
                                            <div className="row mb-2">
                                                <div className="col">
                                                    <h3 className="mb-1">{lead.advertiser.name}</h3>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="col-md-12">
                                                        <span>{Status}:</span>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="switch-green">
                                                            <input type="checkbox" name="is_active" defaultChecked={lead.advertiser.is_active} disabled={true}
                                                                   ref={register}/>
                                                            <div className="slider"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{EditLead}</h4>
                                </div>
                                <div className="card-body">

                                    <div className="form-row">

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input className="form-control" type="email" name="email" ref={register}
                                                       placeholder="Email"/>
                                                <span>{errors?.email}</span>

                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{FirstName}</label>
                                                <input className="form-control" type="text" name="first_name"
                                                       ref={register} placeholder="First Name"/>
                                                <span>{errors?.first_name}</span>

                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{LastName}</label>
                                                <input className="form-control" type="text" name="last_name"
                                                       ref={register} placeholder="Last Name"/>
                                                <span>{errors?.last_name}</span>

                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Telephone}</label>
                                                <input className="form-control" type="text" name="phone" ref={register}
                                                       placeholder="Telephone"/>
                                                <span>{errors?.phone}</span>

                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Date}</label>
                                                <input className="form-control" type="date-time" name="date" ref={register} disabled={true}
                                                       placeholder="Date"/>
                                                <span>{errors?.date}</span>

                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Ip}</label>
                                                <input className="form-control" type="text" name="ip" ref={register}
                                                       placeholder="Ip"/>
                                                <span>{errors?.ip}</span>

                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <IsoSelected control={control} error={errors?.country_iso}/>
                                        </div>

                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{SubCampaign}</label>
                                                <input className="form-control" type="text" name="sub_campaign"
                                                       ref={register} placeholder="Sub Campaign"/>
                                                <span>{errors?.sub_campaign}</span>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                        {UpdateLead}
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}



export default LeadsEdit;

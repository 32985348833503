import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/managers`;

class ManagerService {
    getAll(pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {headers: authHeader()});
    }

    getByWords(words) {
        return axios.get(`${baseUrl}?filter[words]=${words}`, {headers: authHeader()});
    }
    
    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    attachRole(id, data){
        return axios.put(`${baseUrl}/${id}/attach-role`, data,{headers: authHeader()});
    }

    detachRole(id, data){
        return axios.put(`${baseUrl}/${id}/detach-role`, data,{headers: authHeader()});
    }

}

export default new ManagerService();
import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/distributions/buckets`;

class BucketService {
    getAll(geo = "all", affiliate_id = "default") {
        return axios.get(`${baseUrl}?filter[geo]=${geo}&filter[affiliate]=${affiliate_id}`, {headers: authHeader()});
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    getByWords(affiliate_id = "default", geo, words) {
        return axios.get(`${baseUrl}?filter[words]=${words}&filter[geo]=${geo}&filter[affiliate]=${affiliate_id}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    updateOrderUp(id) {
        return axios.put(`${baseUrl}/${id}/order/up`,{}, {headers: authHeader()});
    }

    updateOrderDown(id) {
        return axios.put(`${baseUrl}/${id}/order/down`,{}, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

}

export default new BucketService();
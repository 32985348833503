import {Modal} from "react-bootstrap";

import {
    Custom1,
    Custom2,
    Custom3,
    Custom4,
    Custom5,
    LimitConversions,
    LimitDailyConversions,
    LimitDailyLeads,
    LimitLeads,
    Save,
    Status
} from "../../../constant";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import StreamService from "../../../services/stream.service";
import {toast} from "react-toastify";

const ModalStreamEdit = ({streams, stream, setStreams}) => {
    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (stream) {
            const fields = [
                'is_active',
                'country',
                'limit_daily_leads',
                'limit_daily_conversions',
                'limit_leads',
                'limit_conversions',
                'custom1',
                'custom2',
                'custom3',
                'custom4',
                'custom5',
            ];
            fields.forEach(field => setValue(field, stream[field]));
        }
    }, [stream, show]);


    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        data.country_iso = data.country?.iso;
        data.affiliate_id = stream?.affiliate_id;

        StreamService.update(stream?.id, data)
            .then(response => {
                toast.success('Stream updated');
                const stream = response.data.data
                setStreams(streams.map(el => {
                    if (el.id === stream.id) el = stream;
                    return el;
                }));
                handleClose()
            }, error => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                toast.error(message)

                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }


            })
            .finally(() => {
                setLoading(false);

            })


    }

    return (
        <>
            <a className={"p-0"} onClick={handleShow}>
                <i className="fa fa-pencil text-success fa-2x"></i>
            </a>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Stream [{stream?.country_iso}]
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">

                        <div className="col-lg-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="form-row">

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{LimitConversions}</label>
                                            <input className="form-control" type="text" name="limit_conversions"
                                                   ref={register}
                                                   placeholder="limit conversions"/>
                                            <span>{errors?.limit_conversions}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{LimitDailyConversions}</label>
                                            <input className="form-control" type="text" name="limit_daily_conversions"
                                                   ref={register}
                                                   placeholder="limit daily conversions"/>
                                            <span>{errors?.limit_daily_conversions}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{LimitLeads}</label>
                                            <input className="form-control" type="text" name="limit_leads"
                                                   ref={register}
                                                   placeholder="limit leads"/>
                                            <span>{errors?.limit_leads}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{LimitDailyLeads}</label>
                                            <input className="form-control" type="text" name="limit_daily_leads"
                                                   ref={register}
                                                   placeholder="limit daily leads"/>
                                            <span>{errors?.limit_daily_leads}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Custom1}</label>
                                            <input className="form-control" type="text" name="custom1"
                                                   ref={register}
                                                   placeholder="custom1"/>
                                            <span>{errors?.custom1}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Custom2}</label>
                                            <input className="form-control" type="text" name="custom2"
                                                   ref={register}
                                                   placeholder="custom2"/>
                                            <span>{errors?.custom2}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Custom3}</label>
                                            <input className="form-control" type="text" name="custom3"
                                                   ref={register}
                                                   placeholder="custom3"/>
                                            <span>{errors?.custom3}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Custom4}</label>
                                            <input className="form-control" type="text" name="custom4"
                                                   ref={register}
                                                   placeholder="custom4"/>
                                            <span>{errors?.custom4}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Custom5}</label>
                                            <input className="form-control" type="text" name="custom5"
                                                   ref={register}
                                                   placeholder="custom5"/>
                                            <span>{errors?.custom5}</span>

                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="col-md-12">
                                                <span>{Status}:</span>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="switch-green">
                                                    <input type="checkbox" name="is_active" defaultChecked={true}
                                                           ref={register}/>
                                                    <div className="slider"></div>
                                                </label>
                                                <span>{errors?.is_active}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Save}&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>);
}
export default ModalStreamEdit;
import {GET_ME_FAIL, GET_ME_SUCCESS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT,} from "./types";

import AuthService from "../services/auth.service";

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {token: data},
            });

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: LOGIN_FAIL,
            });

            return Promise.reject(error);
        }
    );
};

export const getMe = () => (dispatch) => {
    return AuthService.getMe().then(
        (data) => {
            dispatch({
                type: GET_ME_SUCCESS,
                payload: {user: data},
            });

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: GET_ME_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};

export const impersonate = (id) => (dispatch) => {
    return AuthService.impersonate(id).then(
        (data) => {
            dispatch({

                type: LOGIN_SUCCESS,
                payload: {token: data},
            });

            return Promise.resolve();
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";
import AffiliateService from "../../services/affiliate.service";
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../constant";
import {CAN_DELETE_AFFILIATE, CAN_LOGIN_VIA_AFF} from "../../constant/permissions";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import {connect} from "react-redux";
import TableReact from "react-bootstrap/Table";
import {getMe, impersonate} from "../../actions/auth";
import {toast} from "react-toastify";
import SearchAffiliates from "./parts/list/SearchAffiliates";
import Title from "../common/custom-title";
import {AffiliatesInfo} from "./AffiliateInfo";
import {ManagerInfo} from "../manager/ManagerInfo";

const AffiliatesList = ({location, dispatch}) => {

    const [affiliates, setAffiliates] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        getAffiliatesData();
    }, [])


    const getPageUrl = (pageNumber) => {
        return `${process.env.PUBLIC_URL}/affiliates?page=${pageNumber}`
    }

    const deleteAffiliate = (id) => {
        setAffiliates(affiliates.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        AffiliateService.delete(id).then(() => {
            setAffiliates(affiliates => affiliates.filter(x => x.id !== id));
        });
    }

    const submitDeleteAffiliate = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteAffiliate(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    const getAffiliatesData = async (filter, pageNumber) => {
        setFilter(filter);

        await AffiliateService.getAllFiltering(filter, pageNumber).then(
            response => {
                if (response.data) {
                    setAffiliates(response.data?.data);
                    setMeta(response.data?.meta);
                }

            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);


            }
        )
    };

    const updateStatusAffiliate = (affiliate, status = false) => {
        let data = {
            ...affiliate,
            is_active: !status,
        }
        AffiliateService.update(affiliate.id, data).then((response) => {
            setAffiliates(affiliates.map(x => {
                if (x.id === affiliate.id) {
                    x.is_active = response.data.data.is_active;
                }
                return x;
            }));
        });
    }

    const impersonateAffiliate = async (id) => {
        try {
            dispatch(impersonate(id)).then(async res => {
                dispatch(getMe()).then(() => {
                    window.location.href = '/dashboard';
                })
            }).catch(error => {
                toast.error(error.message)
            })
        } catch (e) {

        }
    }

    const getRestrictions = (affiliate, type = 1) => {
        let checkStart = false;
        let title = type === 1 ? "Included: ": "Excluded: ";
        return (<>
            {affiliate.geo_restrictions?.filter(e => e.is_included === type).length > 0 && title}

            {affiliate.geo_restrictions?.map((geo_restriction) => (<>
                {geo_restriction.is_included === type && (<>
                    {checkStart && (<>, </>)}
                    {geo_restriction.country_iso}

                    {checkStart = true}
                </>)}
            </>))}
        </>)
    }

    return (<Fragment>

            <Title title="Manage Affiliates"/>
            <Breadcrumb title="Manage Affiliates"/>
            <div className="container-fluid">

                <SearchAffiliates
                    getData={getAffiliatesData}
                />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">

                                    <Link to={'/affiliates/create'} className={'btn btn-primary'}>
                                        Add new
                                    </Link>

                            </div>
                            <div className="table-responsive">
                                <TableReact bordered striped size={"sm"}>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Details"}</th>
                                            <th scope="col">{"Geos"}</th>
                                            <th scope="col">{"Manager"}</th>
                                            <th scope="col">{"Status"}</th>
                                            <th scope="col">{"Action"}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                    {affiliates &&
                                        affiliates.map((affiliate, index) => (
                                            <tr key={affiliate.id}>
                                                <td scope="row">
                                                   <AffiliatesInfo affiliate={affiliate}/>
                                                </td>
                                                <td scope="row">
                                                    {affiliate.geo_restrictions?.length > 0 ? (
                                                        <>
                                                            <span>
                                                                 {getRestrictions(affiliate, 1)}
                                                            </span> <br/>
                                                                    <span>
                                                                {getRestrictions(affiliate, 0)}
                                                            </span>
                                                        </>
                                                    ): <> All </>}

                                                </td>
                                                <td scope="row">
                                                    <ManagerInfo manager={affiliate?.manager} otherText="Not manager" />
                                                </td>
                                                <td scope="row">
                                                    <label className="switch-green">
                                                        <input type="checkbox" name="is_active"
                                                               checked={affiliate.is_active}
                                                               onChange={(e) => updateStatusAffiliate(affiliate, affiliate.is_active)}

                                                        />
                                                        <div className="slider"></div>
                                                    </label>

                                                </td>
                                                <td scope="row">
                                                    <div className="d-flex">
                                                        <ShowForPermission permission ={CAN_DELETE_AFFILIATE}>
                                                        <div className="mr-3">
                                                            <a onClick={() => submitDeleteAffiliate(affiliate.id)}
                                                                    className="p-0"
                                                                    disabled={affiliate.isDeleting}>
                                                                {affiliate.isDeleting
                                                                    ? <span
                                                                        className="spinner-border spinner-border-lg "></span>
                                                                    : <i className="fa fa-trash text-danger fa-2x "></i>
                                                                }
                                                            </a>
                                                        </div>
                                                        </ShowForPermission>
                                                        <div className="mr-3">
                                                            <Link
                                                                to={{
                                                                    pathname: `/affiliates/${ affiliate.id }`,
                                                                    state: { from: location.pathname, label: 'Manage Affiliates'}
                                                                }}
                                                            >
                                                                <i className="fa fa-pencil text-success fa-2x"></i>
                                                            </Link>
                                                        </div>
                                                        <ShowForPermission permission ={CAN_LOGIN_VIA_AFF}>
                                                            <div className="">
                                                                <a href={"#"}><i onClick={() => impersonateAffiliate(affiliate.id)} className="fa fa-sign-in text-muted fa-2x"></i></a>
                                                            </div>
                                                        </ShowForPermission>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    {!affiliates &&
                                        <tr key={'affiliate-loader'}>
                                            <td colSpan="13" className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {affiliates && !affiliates.length &&
                                        <tr key='affiliate-not-found'>
                                            <td colSpan="13" className="text-center">
                                                <div className="p-2">No Affiliates To Display</div>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </TableReact>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    {meta &&
                                        <Pagination
                                            activePage={meta.current_page}
                                            totalItemsCount={meta.total}
                                            itemsCountPerPage={meta.per_page}
                                            onChange={(pageNumber) => getAffiliatesData(filter, pageNumber)}
                                            getPageUrl={(pageNumber) => getPageUrl(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliatesList);

import {
    ADVERTISER_PERFORMANCE_REPORT_FILTER_FAIL,
    ADVERTISER_PERFORMANCE_REPORT_FILTER_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_ADVERTISER_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_AFFILIATE_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_COUNTRY_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_MANAGER_SUCCESS,
    SET_ADVERTISER_PERFORMANCE_REPORT_PERIOD_SUCCESS

} from "./types";
import advertiserPerformanceReportService from "../services/advertiser-performance.service";
import {refreshPeriod} from "../utils/period/period";


export const advertiserPerformanceReportSearch = (manager, country, period, affiliate, advertiser) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return advertiserPerformanceReportService.getAdvertiserPerformanceReports(manager?.id, country, updatedPeriod, affiliate, advertiser).then(
        (response) => {
            dispatch({
                type:ADVERTISER_PERFORMANCE_REPORT_FILTER_SUCCESS,
                payload: {
                    advertiserReports: response.data,
                    country: country,
                    manager: manager,
                    advertiser: advertiser,
                    affiliate: affiliate,
                    period: updatedPeriod,
                },
            });

            return Promise.resolve();
        },
        () => {

            dispatch({
                type: ADVERTISER_PERFORMANCE_REPORT_FILTER_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setAdvertiserPerformanceCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_ADVERTISER_PERFORMANCE_REPORT_COUNTRY_SUCCESS,
        payload: {
            country: country,
        },
    });

    return Promise.resolve();
}

export const setAdvertiserPerformanceAdvertiser = (advertiser) => (dispatch) => {
    dispatch({
        type: SET_ADVERTISER_PERFORMANCE_REPORT_ADVERTISER_SUCCESS,
        payload: {
            advertiser: advertiser,
        },
    });

    return Promise.resolve();
}

export const setAdvertiserPerformanceAffiliate = (affiliate) => (dispatch) => {
    dispatch({
        type: SET_ADVERTISER_PERFORMANCE_REPORT_AFFILIATE_SUCCESS,
        payload: {
            affiliate: affiliate,
        },
    });

    return Promise.resolve();
}

export const setAdvertiserPerformanceManager = (manager) => (dispatch) => {
    dispatch({
        type: SET_ADVERTISER_PERFORMANCE_REPORT_MANAGER_SUCCESS,
        payload: {
            manager: manager,
        },
    });

    return Promise.resolve();
}

export const setAdvertiserPerformancePeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_ADVERTISER_PERFORMANCE_REPORT_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

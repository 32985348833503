import {ChevronDown, ChevronUp} from "react-feather";
import React from "react";

const ReportTableHeader = ({headerGroups}) => {
    return (
        <thead>
        {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('header')}
                        <span className="chevron">
                            {column.isSorted
                                ? column.isSortedDesc
                                    ? <ChevronDown/>
                                    : <ChevronUp/>
                                : ''}
                        </span>
                    </th>
                ))}
            </tr>
        ))}
        </thead>
    )
}

export default ReportTableHeader
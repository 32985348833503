import {
    AFFILIATE_PERFORMANCE_REPORT_FILTER_FAIL,
    AFFILIATE_PERFORMANCE_REPORT_FILTER_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_ADVERTISER_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_AFFILIATE_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_COUNTRY_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_PERIOD_SUCCESS,
    SET_AFFILIATE_PERFORMANCE_REPORT_MANAGER_SUCCESS
} from "../actions/types";
import moment from "moment/moment";

const initialState = {
    affiliateReports: null,
    country: null,
    affiliate: null,
    advertiser: null,
    manager: null,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm:ss'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm:ss'),
        time: "today",
    },
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case AFFILIATE_PERFORMANCE_REPORT_FILTER_SUCCESS:
            return {
                ...state,
                affiliateReports: payload.affiliateReports,
                country: payload.country,
                manager: payload.manager,
                affiliate: payload.affiliate,
                advertiser: payload.advertiser,
                period: payload.period,
            };

        case SET_AFFILIATE_PERFORMANCE_REPORT_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };


        case AFFILIATE_PERFORMANCE_REPORT_FILTER_FAIL:
            return {
                ...state,
                affiliateReports: null,
                country: null,
                affiliate: null,
                period: {},
                advertiser: null,
            };

        case SET_AFFILIATE_PERFORMANCE_REPORT_AFFILIATE_SUCCESS:
            return {
                ...state,
                affiliate: payload.affiliate,
            };

        case SET_AFFILIATE_PERFORMANCE_REPORT_ADVERTISER_SUCCESS:
            return {
                ...state,
                advertiser: payload.advertiser,
            };

        case SET_AFFILIATE_PERFORMANCE_REPORT_MANAGER_SUCCESS:
            return {
                ...state,
                manager: payload.manager,
            };

        case SET_AFFILIATE_PERFORMANCE_REPORT_COUNTRY_SUCCESS:
            return {
                ...state,
                country: payload.country,
            };

        default:
            return state;
    }
}

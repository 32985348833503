import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../common/breadcrumb";

import RoleService from "../../../services/role.service";

import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useAlert} from "react-alert";
import TableReact from "react-bootstrap/Table";
import Title from "../../common/custom-title";

const GroupPermissionList = () => {

    const [roles, setRoles] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const { t, ready } = useTranslation();

    useEffect(() => {
        getRolesData()
    }, [])


    const getRolesData = () => {
        RoleService.getAll().then((res) => {
            let roles = res.data.data;
            setRoles(roles);
        });
    }

    return (
        <Fragment>
            <Title title="Groups"/>
            <Breadcrumb title="Groups"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="table-responsive">
                                <TableReact bordered striped>
                                    <thead>
                                    <tr>
                                        <th scope="col">{"Name"}</th>
                                        <th scope="col">{"Action"}</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {roles &&
                                    roles.map((group, index) => (
                                        <tr key={index}>
                                            <td scope="row">{t(group.name)}</td>
                                            <td scope="row">
                                                <div className="ml-3 d-flex">
                                                    <div className="">
                                                        <Link to={`/permissions/groups/${group.id}`}>
                                                            <i className="fa fa-pencil text-success fa-2x"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {!roles &&
                                    <tr>
                                        <td colSpan="13" className="text-center">
                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </TableReact>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}



export default GroupPermissionList;

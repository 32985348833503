import React from 'react';

export const ManagerInfo = ({manager, otherText = null}) => {

    return (
      <>
          {manager ? (
              <>
                  ID:&nbsp;{manager.id} <br/>
                  Name:&nbsp;{manager.name} <br/>
                  Email:&nbsp;{manager.email} <br/>
              </>
          ) : otherText
          }
      </>

    );
}



import Breadcrumb from "../common/breadcrumb";
import React, {Fragment, useEffect, useState} from "react";
import AffiliateSettingsEdit
    from "./AffiliateSettingsEdit";
import AffiliateSettingsCommissions
    from "./AffiliateSettingsCommissions";
import AffiliateSettingsGeoRestrictions
    from "./AffiliateSettingsGeoRestrictions";
import AffiliateSettingsPostbacks
    from "./AffiliateSettingsPostbacks";
import Title from "../common/custom-title";
import AffiliateWhiteListIpList from "../affiliate/parts/white-list-ip/AffiliateWhiteListIpList";
import {connect} from "react-redux";

const AffiliateSettings = ({affiliate}) => {


return (
    <Fragment>
        <Title title="Edit Settings"/>
        <Breadcrumb parent="Dashboard" title="Edit Settings"/>
        <AffiliateSettingsEdit/>
        <div className={"row"}>
            <div className={"col-md-6"}>
                <AffiliateSettingsGeoRestrictions/>
            </div>
            <div className={"col-md-6"}>
                <AffiliateSettingsCommissions/>
            </div>
        </div>
        <AffiliateSettingsPostbacks/>

        <AffiliateWhiteListIpList
            affiliate={affiliate}
            affiliateView={true}
        />
    </Fragment>
)
}
function mapStateToProps(state) {
    const {user} = state.auth;
    return {
       affiliate: user?.data
    };
}

export default connect(mapStateToProps)(AffiliateSettings)
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Update} from '../../../../constant';
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {setInvoices} from "../../../../actions/invoice";
import InvoiceService from "../../../../services/invoice.service";

const ModalEditInvoice = ({invoices, invoice, dispatch}) => {
    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    
    useEffect(( ) => {
     
        if (invoice) {
            const fields = [
                'note',
                'status',
            ];
            fields.forEach(field => setValue(field, invoice[field]));
        }

    }, [show])

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        InvoiceService.update(invoice?.id, data)
            .then(
                response => {
                    dispatch(setInvoices(invoices.map(x => {
                        if (x.id === invoice?.id) {
                            x = response.data.data;
                        }
                        return x;
                    })));
                    handleClose()
                    toast.success("Invoice Updated");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })
    }


    return (
        <>
            <button  onClick={handleShow}
                     className="btn btn-link p-0">
                <i className="fa fa-pencil text-success fa-2x"></i>
            </button>
            
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Update Invoice"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">

                                    <div className="col-md-10 ">
                                        <div className="form-group ">
                                            <label className=" col-form-label">Note</label>
                                            <textarea className="form-control" name="note" id="note"
                                                      ref={register}></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-10 ">
                                        <div className="form-group">
                                            <select name="status" className="custom-select" id="" ref={register}>
                                                <option value="" disabled selected>Status</option>
                                                <option value="1">Unpaid</option>
                                                <option value="2">Paid</option>
                                            </select>
                                            <span className="text-danger-light">{errors?.status}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Update}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


function mapStateToProps(state) {
    const {invoices} = state.invoice;
    return {
        invoices
    };
}

export default connect(mapStateToProps)(ModalEditInvoice);

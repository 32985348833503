import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import ConversionService from "../../../../services/lead.service";
import {connect} from "react-redux";
import {setConversions} from "../../../../actions/conversion";
import {toast} from "react-toastify";


const ModalEditConversionAdvPayout = props => {
    const {conversion, conversions, dispatch} = props;
    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        setValue('adv_payout', Number(conversion?.advertiser_payout));
    }, [show]);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);

        ConversionService.updateAdvPayout(conversion.id, data)
            .then(
                async response => {
                    await dispatch(setConversions(conversions.map(x => {
                        if (x.id === conversion.id) {
                            x.advertiser_payout = response.data.data.advertiser_payout;
                        }
                        return x;
                    })));

                    handleClose()
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })

    }

    return (
        <>
            <button onClick={handleShow}
                    className='btn btn-link p-0'>
                (edit)
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Edit Payout"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{"Adv Payout"}</label>
                                            <input className="form-control" type="text" name="adv_payout" ref={register}
                                                   placeholder=""/>
                                            <span>{errors?.adv_payout}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{"Save"}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}

function mapStateToProps(state) {
    const {conversions} = state.conversion;
    return {
        conversions
    };
}

export default connect(mapStateToProps)(ModalEditConversionAdvPayout);

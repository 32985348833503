import {
    API_REQUEST_FILTER_FAIL,
    API_REQUEST_FILTER_SUCCESS,
    SET_API_REQUEST_PERIOD_SUCCESS, SET_API_REQUEST_RE_REGISTER_IDS_SUCCESS,

} from "./types";
import apiRequestService from "../services/api-request.service";
import { refreshPeriod } from "../utils/period/period";


export const apiRequestSearch = (manager, country, period, affiliate, pageNumber, filtering, pageSize, advertiser) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return apiRequestService.getAll(manager?.id , country?.iso, updatedPeriod, affiliate?.id,  filtering, pageNumber, pageSize, advertiser?.id).then(
        (response) => {

            dispatch({
                type: API_REQUEST_FILTER_SUCCESS,
                payload: {
                    apiRequests: response.data.data,
                    meta: response.data.meta,
                    country,
                    manager,
                    advertiser,
                    affiliate,
                    period: updatedPeriod,
                    filtering,
                    pageSize,
                },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: API_REQUEST_FILTER_FAIL,
            });




            return Promise.reject();
        }
    );
};


export const setApiRequestPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_API_REQUEST_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}



export const setReRegisterIDs = (reRegisterIDs) => (dispatch) => {
    dispatch({
        type: SET_API_REQUEST_RE_REGISTER_IDS_SUCCESS,
        payload: {
            reRegisterIDs,
        },
    });

    return Promise.resolve();
}

import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../../constant";
import BucketService from "../../../../../services/bucket.service";
import {setBuckets, streamSearch} from "../../../../../actions/stream";
import ModalEditBucket from "./ModalEditBucket";
import BucketUpdateOrder from "./BucketUpdateOrder";
import {ShowForPermission} from "../../../../../helpers/ShowForPermission";
import {CAN_DELETE_ROTATION_BUCKET, CAN_UPDATE_ROTATION_BUCKET} from "../../../../../constant/permissions";
import {useAdBlock} from "../../../../../utils/hooks/useAdBlock";

const BucketElement = ({bucket, buckets, country, dispatch, affiliate, period }) => {
    const adBlock = useAdBlock();

    const deleteBucket = (id) => {
        dispatch(setBuckets(buckets.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        })));

        BucketService.delete(id).then(async () => {
            await dispatch(streamSearch(country, period, affiliate, adBlock));
        });
    }



    const submitDeleteBucket = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteBucket(id)
                },
                {
                    label: No,
                }
            ]
        });
    }


    return (
        <>
            <tr key={bucket.id}>
                <th colSpan={12}>

                    <div className="d-flex my-3">
                        <div>
                            <h3>{bucket.name}</h3>
                        </div>
                        <ShowForPermission permission={CAN_UPDATE_ROTATION_BUCKET}>
                            <div className="ml-2">

                                <ModalEditBucket
                                    bucket={bucket}
                                />

                            </div>
                        </ShowForPermission>

                        <ShowForPermission permission={CAN_DELETE_ROTATION_BUCKET}>
                            <div className="ml-2">
                                <button
                                    onClick={() => submitDeleteBucket(bucket.id)}
                                    disabled={bucket.isDeleting}
                                    className="btn btn-link p-0"
                                >
                                    {bucket.isDeleting
                                        ? <span
                                            className="spinner-border spinner-border-lg "></span>
                                        : <i className="fa fa-trash text-danger fa-2x "></i>
                                    }
                                </button>
                            </div>
                        </ShowForPermission>

                        <ShowForPermission permission={CAN_UPDATE_ROTATION_BUCKET}>
                            <div className="ml-2">
                                <BucketUpdateOrder
                                    bucket={bucket}
                                />
                            </div>
                        </ShowForPermission>

                    </div>

                </th>
            </tr>


        </>
    );

}

function mapStateToProps(state) {
    const {country, affiliate, buckets, period} = state.stream;
    return {
        country,
        period,
        affiliate,
        buckets
    };
}

export default connect(mapStateToProps)(BucketElement);

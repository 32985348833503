import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/dashboard`;

class DashboardService {

    getFilter( geo = "", period = {}, affiliate_id = "", advertiser_id = "", filtering = {}) {
        return axios.get(`${baseUrl}/filter`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                    email: filtering.email && filtering.email.length ? filtering.email.map((el) => el.value) : undefined,
                    geo,
                    affiliate: affiliate_id,
                    advertiser: advertiser_id,
                    period: {
                        from: period.from_date,
                        to: period.to_date
                    }
                }
            }
        });

    }

}

export default new DashboardService();

import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import {Search} from "../../../constant";
import {moderationSearch, setModerationPeriod} from "../../../actions/moderation";
import ManagerSelected
    from "../../manager/ManagerSelected";
import PeriodFilter from "../../../utils/period/PeriodFilter";

const SearchModeration = ({dispatch, country, affiliate, period, manager}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    useEffect(async () => {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("manager", manager);

    }, [country, affiliate, manager]);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(moderationSearch(data?.manager, data?.country, period, data?.affiliate, data?.status)).catch(error => {

                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }

            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">

                    <PeriodFilter
                        setPeriod={(data) => dispatch(setModerationPeriod(data))}
                        period={period}
                    />

                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-2 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <div className="col-md-2">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>
                            <div className="col-md-2">
                                <ManagerSelected control={control} error={errors?.manager_id}/>
                            </div>
                            <div className=" col-md-2">
                                <div className="form-group">
                                    <select name="status" className="custom-select"
                                            ref={register} >
                                        <option value="" >All Statuses</option>
                                        <option  value="1">Approved</option>
                                        <option value="2">Declined</option>
                                        <option value="3" selected>Pending</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary " type="submit"
                                        disabled={loading}
                                >
                                    <span>{Search}&nbsp;</span>
                                    {loading && (<span
                                        className="spinner-border spinner-border-sm"></span>)}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, affiliate, period, manager} = state.moderation;
    return {
        country, affiliate, period, manager
    };
}

export default connect(mapStateToProps)(SearchModeration);

import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/tools/test-leads`;

class TestLeadService {

    register(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

}

export default new TestLeadService();

import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Create} from '../../../../../constant';


import {Button, Modal} from "react-bootstrap";
import IsoSelected from "../../../../country/IsoSelected";
import AffiliateGeoRestrictionService from "../../../../../services/affiliate.geo-restriction.service";
import {toast} from "react-toastify";

const ModalCreateGeo = props => {
    const { geoRestrictions, affiliate} = props;
    const {register, handleSubmit, reset, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.countries_iso = data.country?.map(e => e.iso);

        AffiliateGeoRestrictionService.create(affiliate.id, data)
            .then(
                response => {
                    props.setGeoRestrictions([...geoRestrictions, ...response.data.data]);
                    handleClose()
                    toast.success("Geo Created");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })
    }


    return (
        <>
            <button className="btn btn-primary"
                    onClick={handleShow}
            >add
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create Geo"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">

                                    <div className="col-md-10">
                                        {affiliate ? (
                                            <IsoSelected control={control} error={errors?.countries_iso} showTitle={true}
                                                         isMulti closeMenuOnSelect={false} exceptIsoList={affiliate.geo_restrictions?.map(geoRestriction => geoRestriction.country_iso)}
                                            />
                                        ): (
                                            <div className="d-flex align-items-center my-3">
                                                <strong>Loading...</strong>
                                                <div className="spinner-border ml-auto" role="status"
                                                     aria-hidden="true"></div>
                                            </div>
                                        )}
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <select name="is_included" className="custom-select" id="" ref={register}>
                                                <option value="1">Included</option>
                                                <option value="0">Excluded</option>
                                            </select>
                                            <span>{errors?.is_included}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="limit">Daily limit</label>
                                            <input className="form-control" type="number" name="limit_day" id={"limit"} ref={register}
                                                   placeholder="Daily limit"/>
                                            <span>{errors?.limit_day}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


export default ModalCreateGeo;

import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../../affiliate/ReportAffiliatesSelected";
import {Search} from "../../../../constant";
import {restrictionSearch} from "../../../../actions/restriction";
import AdvertiserSelected from "../../../advertiser/AdvertiserSelected";

const SearchRestriction = ({dispatch, country, affiliate, advertiser, status}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    useEffect(async () => {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("advertiser", advertiser);
        setValue("status", status);

    }, [country, affiliate, advertiser, status]);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(restrictionSearch(data?.country, data?.affiliate, data?.advertiser, data?.status)).catch(error => {

                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }

            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-2 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <div className="col-md-2">
                                <AdvertiserSelected control={control} error={errors?.advertiser_id} label={false}/>
                            </div>
                            <div className="col-md-3">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>
                            <div className=" col-md-2">
                                <div className="form-group">
                                    <select name="status" className="custom-select" id="" ref={register}>
                                        <option value="" disabled selected>Status</option>
                                        <option value="">All</option>
                                        <option value="1">Included</option>
                                        <option value="2">Excluded</option>
                                    </select>
                                    <span className="text-danger-light">{errors?.status}</span>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary " type="submit"
                                        disabled={loading}
                                >
                                    <span>{Search}&nbsp;</span>
                                    {loading && (<span
                                        className="spinner-border spinner-border-sm"></span>)}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, affiliate, advertiser, status} = state.affiliateAdvertiserRestriction;
    return {
        country, affiliate, advertiser, status
    };
}

export default connect(mapStateToProps)(SearchRestriction);

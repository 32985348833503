import React, {
    Fragment,
    useMemo
} from 'react';
import Breadcrumb from "../common/breadcrumb";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from "react-redux";
import SearchReports
    from "./parts/SearchAdvertiserPerformanceReports";
import Table from "./parts/table";
import Title from "../common/custom-title";

const AdvertiserPerformanceReportList = ({advertiserReports}) => {

    let data = useMemo(() => {
        return advertiserReports

    }, [advertiserReports])

    return (
        <Fragment>
            <Title title="Advertiser Performance"/>
            <Breadcrumb title="Advertiser Performance"/>
            <div className="container-fluid">
                <SearchReports/>

                <div className="row">
                    <div className="col-sm-12">
                        <div className={"card"}>
                            {data && (
                                <Table data={data.data}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

function mapStateToProps(state) {
    const {advertiserReports} = state.advertiserReports;
    return {
       advertiserReports
    };
}

export default connect(mapStateToProps)(AdvertiserPerformanceReportList);

import React, {Fragment, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import {connect} from "react-redux";
import SearchOptimize from "./parts/SearchOptimize";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../constant";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import {confirmAlert} from "react-confirm-alert";
import {Link} from "react-router-dom";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import {CAN_DELETE_OPTIMIZE} from "../../constant/permissions";
import Pagination from "react-js-pagination";
import {Button} from "react-bootstrap";
import ModalCreateOptimizes from "./parts/ModalCreateOptimizes";
import {optimizeSearch, setOptimizes} from "../../actions/optimize";
import OptimizeService from "../../services/optimize.service";
import ModalEditOptimizes from "./parts/ModalEditOptimizes";
import TableReact from "react-bootstrap/Table";
import Title from "../common/custom-title";
import {AffiliatesInfo} from "../affiliate/AffiliateInfo";


const OptimizeList = ({dispatch, optimizes, meta, country, affiliate, advertiser}) => {

    const [showModalCreateOptimizes, setShowModalCreateOptimizes] = useState(false);
    const [editModalCreateOptimizes, setEditModalCreateOptimizes] = useState(false);
    const [optimizeID, setOptimizeID] = useState(false);

    const showEditModalCreateOptimizes  = ( id ) => {
        setEditModalCreateOptimizes(true);
        setOptimizeID(id);
    }

    const deleteOptimize = async (id) => {
        await dispatch(setOptimizes(optimizes.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        })));

        OptimizeService.delete(id).then(async () => {
            await dispatch(optimizeSearch(country, affiliate, advertiser));
        });
    }

    const submitDeleteOptimize = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteOptimize(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <Fragment>
            <Title title="Optimize"/>
            <Breadcrumb title="Optimize"/>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <SearchOptimize/>
                    </div>
                    {optimizes &&

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-end">
                                    <Button onClick={() => setShowModalCreateOptimizes(true)}>
                                        Add new
                                    </Button>

                                    <ModalCreateOptimizes
                                        optimizes={optimizes}
                                        show={showModalCreateOptimizes}
                                        onHide={() => setShowModalCreateOptimizes(false)}
                                    />
                                </div>
                                <div className="table-responsive">
                                    <TableReact bordered striped>
                                        <thead>
                                        <tr>
                                            <th scope="col">{"Affiliate"}</th>
                                            <th scope="col">{"Advertiser"}</th>
                                            <th scope="col">{"Country"}</th>
                                            <th scope="col">{"Percent"}</th>
                                            <th scope="col">{"Action"}</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {optimizes &&
                                            optimizes.map((optimize, index) => (
                                                <tr key={index}>
                                                    <td scope="row">
                                                        <AffiliatesInfo affiliate={optimize?.affiliate} otherText="All" />
                                                    </td>
                                                    <td scope="row">
                                                        {optimize.advertiser_id && optimize.advertiser ? (
                                                            <>
                                                                {optimize.advertiser?.name} (id {optimize.advertiser?.id})
                                                            </>
                                                        ): 'All'}
                                                    </td>
                                                    <td scope="row">{optimize.country ? optimize.country.title : 'All'}</td>
                                                    <td scope="row">{optimize.percent} %</td>


                                                    <td scope="row">
                                                        <div className="d-flex">
                                                            <ShowForPermission permission={CAN_DELETE_OPTIMIZE}>
                                                                <div className="mr-3">
                                                                    <button
                                                                        onClick={() => submitDeleteOptimize(optimize.id)}
                                                                        className="btn btn-link p-0"
                                                                        disabled={optimize.isDeleting}>
                                                                        {optimize.isDeleting
                                                                            ? <span
                                                                                className="spinner-border spinner-border-lg "></span>
                                                                            :
                                                                            <i className="fa fa-trash text-danger fa-2x "></i>
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </ShowForPermission>
                                                            <div className="">
                                                                <Button onClick={() => showEditModalCreateOptimizes(optimize.id)} variant="link" className="p-0">
                                                                    <i className="fa fa-pencil text-success fa-2x"></i>
                                                                </Button>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        {!optimizes &&
                                            <tr>
                                                <td colSpan="13" className="text-center">
                                                    <div
                                                        className="spinner-border spinner-border-lg align-center"></div>
                                                </td>
                                            </tr>
                                        }
                                        {optimizes && !optimizes.length &&
                                            <tr>
                                                <td colSpan="13" className="text-center">
                                                    <div className="p-2">No Optimizes To Display</div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </TableReact>
                                </div>
                                {meta && meta.total > 1 &&
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">

                                            <Pagination
                                                activePage={meta.current_page}
                                                totalItemsCount={meta.total}
                                                itemsCountPerPage={meta.per_page}
                                                onChange={(pageNumber) => dispatch(optimizeSearch(country, affiliate, advertiser, pageNumber))}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />

                                    </div>
                                </div>
                                }


                                <ModalEditOptimizes
                                    id={optimizeID}
                                    show={editModalCreateOptimizes}
                                    onHide={() => setEditModalCreateOptimizes(false)}
                                />

                            </div>
                        </div>
                    }

                </div>
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const {optimizes, meta, country, affiliate, advertiser} = state.optimize;
    return {
        optimizes,
        meta,
        country,
        affiliate,
        advertiser
    };
}

export default connect(mapStateToProps)(OptimizeList);

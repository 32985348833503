import React, { Fragment, useEffect, useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import AdvertiserSelected from "../../advertiser/AdvertiserSelected";

import {
    AffiliatePerformanceReportSearch,
    setAffiliatePerformanceAdvertiser,
    setAffiliatePerformanceAffiliate,
    setAffiliatePerformanceCountry,
    setAffiliatePerformanceManager,
    setAffiliatePerformancePeriod
} from "../../../actions/affiliate-performance";
import {Button} from "react-bootstrap";
import ManagerSelected
    from "../../manager/ManagerSelected";
import PeriodFilter from "../../../utils/period/PeriodFilter";

const SearchAffiliatePerformanceReports = ({dispatch, country, period,  advertiser, affiliate, manager}) => {
    const {handleSubmit, reset, control, setValue, watch} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    useEffect(() => {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("advertiser", advertiser);
        setValue("manager", manager);

    }, [country, advertiser, affiliate, manager]);

    const isClear = useMemo(
        () => {
            const {country: wCountry, manager: wManager, affiliate: wAffiliate, advertiser: wAdvertiser} = watch()
            return !!wCountry || !!wManager || !!wAffiliate || !!wAdvertiser
        },
        [watch]
    )

    const clearFilter = async () => {
        //clear form
        setValue("country", null);
        setValue("affiliate", null);
        setValue("advertiser", null);
        setValue("manager", null);

        //clear state
        await dispatch(setAffiliatePerformanceCountry(null));
        await dispatch(setAffiliatePerformanceAdvertiser(null));
        await dispatch(setAffiliatePerformanceAffiliate(null));
        await dispatch(setAffiliatePerformanceManager(null));
    }

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(AffiliatePerformanceReportSearch(data?.manager, data?.country, period, data?.affiliate, data?.advertiser));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <PeriodFilter
                        setPeriod={(data) => dispatch(setAffiliatePerformancePeriod(data))}
                        period={period}
                    />
                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-2 ">
                                <IsoSelected control={control} error={errors?.country_iso} isMulti/>
                            </div>
                            <div className="col-md-2">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id} isMulti/>
                            </div>
                            <div className="col-md-2">
                                <AdvertiserSelected label={false} control={control} error={errors?.advertiser_id} isMulti/>
                            </div>
                            <div className="col-md-2">
                                <ManagerSelected label={false} control={control} error={errors?.manager_id}/>
                            </div>
                            <div className="col-md-2">
                                <div className="d-flex">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>

                                    <Button onClick={() => clearFilter()}
                                            className={`btn btn-primary ml-2 ${isClear ? "" : "d-none"}`}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.affiliateReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}

export default connect(mapStateToProps)(SearchAffiliatePerformanceReports);

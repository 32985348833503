import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Login, LOGIN, Password, YourName} from '../../constant';

import {connect} from "react-redux";
import {getMe, login} from "../../actions/auth";
import {ArrowLeft} from "react-feather";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const Logins = (props) => {
    const {register, handleSubmit} = useForm(); // initialise the hook
    const {dispatch, history} = props;

    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const historyReact = useHistory();


    const backToLogin = () => {
        historyReact.push('/login')
    }

    const onSubmit = async () => {
        setLoading(true);
        setErrors(undefined);
        try {
            dispatch(login(email, password)).then(async res => {
                await dispatch(getMe())

                await history.push("/dashboard");
                await window.location.reload();
            }).catch(
                error => {
                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors)
                        toast.error(error.response.data.message)
                    }

                }
            ).finally(() => {
                setLoading(false);
            })
        } catch (e) {
            setLoading(false);
        }
    }


    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        {/*<div className="text-center"><img src={logo} alt="" /></div>*/}
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <ArrowLeft className={"pointer"} onClick={() => backToLogin()} />
                                                <div className="text-center">
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Enter your Username and Password"} </h6>
                                                </div>
                                                <form className="needs-validation" noValidate=""
                                                      onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-row">
                                                        <div className="col-12">
                                                            <label className="col-form-label pt-0">{YourName}</label>
                                                            <input className="form-control" type="email" name="email"
                                                                   value={email}
                                                                   ref={register({required: true})}
                                                                   onChange={e => setEmail(e.target.value)}
                                                                   placeholder="Email address"/>
                                                            <span>{errors?.email}</span>

                                                        </div>
                                                        <div className="col-12">
                                                            <label className="col-form-label">{Password}</label>
                                                            <input className="form-control" type="password"
                                                                   name="password"
                                                                   value={password}
                                                                   ref={register({required: true})}
                                                                   onChange={e => setPassword(e.target.value)}/>
                                                            <span>{errors?.password}</span>

                                                        </div>
                                                    </div>

                                                    {/*<div className="checkbox p-0">*/}
                                                    {/*    <input id="checkbox1" type="checkbox" />*/}
                                                    {/*    <label htmlFor="checkbox1">{RememberMe}</label>*/}
                                                    {/*</div>*/}
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit"
                                                                disabled={loading}
                                                        >
                                                            <span>{Login}&nbsp;</span>
                                                            {loading && (
                                                                <span
                                                                    className="spinner-border spinner-border-sm"></span>
                                                            )}
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );

}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    return {
        isLoggedIn,
    };
}

export default connect(mapStateToProps)(Logins);

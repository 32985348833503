import React, {Fragment} from 'react';
import AsyncSelect from 'react-select/async';
import MakeAnimated from 'react-select/animated';
import countryService from "../../services/country.service";
import {Country} from "../../constant";
import {Controller} from "react-hook-form";

function IsoSelected(props) {
    const { onlyIsoList, exceptIsoList, error, showTitle = false} = props;
    const animatedComponent = MakeAnimated()

    const fetchData = (inputValue) => {
        return countryService.getByWords(inputValue, onlyIsoList, exceptIsoList).then(result => {
            return result.data.data;
        });
    }

    return (
        <Fragment>

            <div className="form-group">
                {showTitle && (
                    <label className="form-label">{Country}:</label>
                )}

                <Controller
                    {...props}
                    as={AsyncSelect}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.title + ' - ' + e.iso}
                    getOptionValue={e => e.iso}
                    placeholder={"Geo"}
                    defaultOptions
                    // className={'form-control'}
                    componets={animatedComponent}
                    cacheOptions
                    name="country"
                    isClearable
                />
                <span className={'text-danger-light'}>{error}</span>
            </div>

        </Fragment>

    );
}

export default IsoSelected
import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";

import AdvertiserService from "../../services/advertiser.service";
import Title from "../common/custom-title";
import {connect} from "react-redux";
import StreamDowntimeList from "./downtime/StreamDowntimeList";

const AdvertisersEdit = ({ match}) => {
    const {id} = match.params;

    const [advertiser, setAdvertiser] = useState(undefined);
    useEffect(() => {
        AdvertiserService.getById(id).then((res) => {

            let advertiser = res.data.data;
            setAdvertiser(advertiser);
        }).catch(err => console.log(err));

    }, []);

    return (
        <Fragment>
            <Title title="Edit Advertiser"/>
            <Breadcrumb parent="Advertisers" title="Edit Advertiser" parentUrl={"/advertisers"}/>



        </Fragment>

    );

}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AdvertisersEdit);

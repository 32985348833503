import React, {Fragment, useEffect, useState} from 'react';
import Select from 'react-select';
import MakeAnimated from 'react-select/animated';
import {connect} from "react-redux";
import DistributionService from "../../../../services/distribution.service";
import {streamSearch} from "../../../../actions/stream";
import {useAdBlock} from "../../../../utils/hooks/useAdBlock";

function BucketSelected({country, distribution, affiliate, dispatch, period, buckets, streamFilters}) {
    const animatedComponent = MakeAnimated()

    const options = [{
        name: 'Default',
    }]
    const adblock = useAdBlock()

    const [defaultOptions, setDefaultOptions] = useState(options);

    const [inputValue, setInputValue] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const updateDistribution = (data) => {
        setInputValue(data);
        setLoading(true);

        distribution.rotation_id = data?.id;
        distribution.affiliate_id = affiliate?.id;

        DistributionService.update(distribution?.id, distribution).then(async (response) => {

            await dispatch(streamSearch(country, period, affiliate, adblock));
            setLoading(false);

        });
    }

    useEffect(( ) => {
        setDefaultOptions(options.concat(buckets));

        if(affiliate?.id == distribution?.affiliate_id){
            setInputValue(distribution?.rotation);
        }
    }, [streamFilters, affiliate, inputValue, buckets])

    return (
        <Fragment>
            <div className="form-group">
                <Select
                    isLoading={loading}
                    options={defaultOptions}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e?.id}
                    placeholder={"Default"}
                    defaultOptions
                    componets={animatedComponent}
                    value={inputValue}
                    onChange={updateDistribution}
                    name="bucket"
                    isClearable
                />

            </div>
        </Fragment>

    );
}

function mapStateToProps(state) {
    const {country, affiliate, period, buckets, streamFilters} = state.stream;
    return {
        streamFilters,
        country,
        period,
        affiliate,
        buckets
    };
}

export default connect(mapStateToProps)(BucketSelected)
import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from "react-redux";
import StreamTable from "./stream/StreamTable";
import BucketElement from "./parts/BucketElement";
import DefaultBucketStreamList from "./DefaultBucketStreamList";

const BucketStreamList = ({buckets, streamFilters }) => {

    return (
        <Fragment>
            <DefaultBucketStreamList/>

            {buckets &&
                buckets.map((bucket, index) => (
                    <Fragment key={"bucket-" + index}>

                        <BucketElement
                            bucket={bucket}
                        />

                        {streamFilters &&
                            streamFilters.map((stream, indexStream) => (
                                <Fragment key={"indexStream-" + indexStream}>
                                    {bucket.distributions?.map(function (e) {
                                            return e.stream_id;
                                        }).indexOf(stream.id) >= 0 &&
                                        <Fragment key={"stream-" + indexStream}>
                                            <StreamTable stream={stream}/>
                                        </Fragment>
                                    }
                                </Fragment>
                            ))}
                    </Fragment>

                ))}

        </Fragment>
    );

}

function mapStateToProps(state) {
    const {streamFilters, buckets} = state.stream;
    return {
        streamFilters,
        buckets
    };
}

export default connect(mapStateToProps)(BucketStreamList);

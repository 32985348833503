import TableReact from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import ModalCreateWhiteListIp from "./parts/ModalCreateWhiteListIp";
import ModalEditWhiteListIp from "./parts/ModalEditWhiteListIp";
import ModalDeleteWhiteListIp from "./parts/ModalDeleteWhiteListIp";
import {ShowForPermission} from "../../../../helpers/ShowForPermission";
import {
    CAN_CREATE_WHITE_LIST_IP,
    CAN_DELETE_WHITE_LIST_IP,
    CAN_UPDATE_WHITE_LIST_IP
} from "../../../../constant/permissions";

const AffiliateWhiteListIpList = ({affiliate, checkedManager = true, affiliateView = false}) => {

    const [whiteListIps, setWhiteListIps] = useState(undefined);

    useEffect(() => {
        setWhiteListIps(affiliate?.white_list_ips)
    }, [affiliate])

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h3>White List Ip:</h3>

                        {checkedManager && (
                            <ShowForPermission permission={CAN_CREATE_WHITE_LIST_IP}>
                                <ModalCreateWhiteListIp
                                    setWhiteListIps={(data) => setWhiteListIps(data)}
                                    affiliate={affiliate}
                                    whiteListIps={whiteListIps}
                                    affiliateView={affiliateView}
                                />
                            </ShowForPermission>
                        )}

                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <TableReact bordered striped size={"sm"}>
                            <thead>
                                <tr>
                                    <th>
                                        IP
                                    </th>
                                    <th>
                                        User Created
                                    </th>
                                    <th>
                                        Created Date
                                    </th>
                                    <th>
                                        User Updated
                                    </th>
                                    <th>
                                        Updated Date
                                    </th>
                                    {checkedManager && (

                                        <ShowForPermission permission={[CAN_UPDATE_WHITE_LIST_IP, CAN_DELETE_WHITE_LIST_IP]}>
                                        <th>

                                        </th>
                                    </ShowForPermission>
                                        )}
                                </tr>
                            </thead>
                            <tbody>
                            { whiteListIps &&
                                whiteListIps.map((whiteListIp, index) => (
                                    <tr key={index}>
                                        <td>{whiteListIp.ip}</td>
                                        <td>{whiteListIp.user_created}</td>
                                        <td>{whiteListIp.created_at}</td>
                                        <td>{whiteListIp.user_updated ?? "not updated"}</td>
                                        <td>{(whiteListIp.updated_at != whiteListIp.created_at) ? whiteListIp.updated_at :"not updated"}</td>

                                        {checkedManager && (

                                            <ShowForPermission permission={[CAN_UPDATE_WHITE_LIST_IP, CAN_DELETE_WHITE_LIST_IP]}>

                                                <td scope="row">
                                                    <div className="d-flex">
                                                        <div className="">
                                                            <ShowForPermission permission={CAN_UPDATE_WHITE_LIST_IP}>
                                                                <ModalEditWhiteListIp
                                                                    setWhiteListIps={(data) => setWhiteListIps(data)}
                                                                    whiteListIps={whiteListIps}
                                                                    whiteListIp={whiteListIp}
                                                                />
                                                            </ShowForPermission>

                                                        </div>
                                                        <div className="ml-3">
                                                            <ShowForPermission permission={CAN_DELETE_WHITE_LIST_IP}>
                                                               <ModalDeleteWhiteListIp
                                                                   setWhiteListIps={(data) => setWhiteListIps(data)}
                                                                   whiteListIps={whiteListIps}
                                                                   whiteListIp={whiteListIp}
                                                                   />
                                                            </ShowForPermission>
                                                        </div>

                                                    </div>
                                                </td>
                                            </ShowForPermission>
                                        )}
                                    </tr>
                                ))}

                            {whiteListIps && !whiteListIps.length &&
                                <tr key='white-list-ip-not-found'>
                                    <td colSpan="6" className="text-center">
                                        <div className="p-2">No WhiteListIps To Display</div>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </TableReact>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AffiliateWhiteListIpList;
import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../common/breadcrumb";

import AdminService from "../../../services/admin.service";
import RoleService from "../../../services/role.service";
import { useTranslation } from "react-i18next";
import Title from "../../common/custom-title";


const AdminsPermissionEdit = ({ history, match}) => {
    const {id} = match.params;

    const [loading, setLoading] = useState(false);

    const [admin, setAdmin] = useState(undefined);
    const [roles, setRoles] = useState(undefined);
    const [permissions, setPermissions] = useState([]);
    const { t, ready } = useTranslation();

    const changeRole = (e,role) => {
        setLoading(true);
        let data = {
            role: role
        }
        if (e.target.checked) {
           AdminService.attachRole(id, data).then(res => {
           }).catch(err => {
               console.log(err)
           }).finally(res => {
               getAdmin()
               setLoading(false)
           })
        } else {
            AdminService.detachRole(id, data).then(res => {

            }).catch(err => {
                console.log(err)
            }).finally(res => {
                getAdmin()
                setLoading(false)
            })
        }
    }

    const getAdmin = () => {
        AdminService.getById(id).then((res) => {
            let admin = res.data.data;
            setAdmin(admin);
            let permissions = admin.permissions.map(el => {
                return el.name;
            })
            setPermissions(permissions)
        }).catch(err => {
            console.log(err)
        })
    }

    const getAllRoles = () => {
        RoleService.getAll().then((res) => {
            let roles = res.data.data;
            setRoles(roles.filter(el => el.guard === 'admin'));
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect( () => {
        getAdmin()
    }, []);

    useEffect( () => {
         getAllRoles()

    }, []);



    return(
    <Fragment>

        <Title title="Edit Permissions"/>
        <Breadcrumb title="Edit Permissions"/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                        <div className="groups">
                            <h3>User Groups</h3>
                            <ul>
                            {roles && roles.map((role, i) => (
                                <li key={i}>
                                        <input onChange={(e) => changeRole(e,role.name)} type="checkbox" name="is_active" defaultChecked={admin.roles.includes(role.name)}
                                        />
                                    <label className="ml-2">{t(role.name)}</label>
                                </li>
                            ))}
                            </ul>
                        </div>
                        <div className="permissions mt-4">
                            <h3>Permissions</h3>
                            {loading &&  <div className="spinner-border spinner-border-lg align-center"></div>}
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">{"Permission"}</th>
                                        <th scope="col">{"Status"}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {permissions &&
                                    permissions.map((permission, index) => (
                                        <tr key={index}>
                                            <th scope="row">{t(permission)}</th>
                                            <th scope="row">
                                                ON
                                            </th>
                                        </tr>
                                    ))}
                                    {(!permissions) &&
                                    <tr>
                                        <td colSpan="13" className="text-center">
                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </Fragment>)
    ;

}


export default AdminsPermissionEdit;

import {
    DAILY_REPORT_FILTER_FAIL,
    DAILY_REPORT_FILTER_SUCCESS,
    SET_DAILY_REPORT_ADVERTISER_SUCCESS,
    SET_DAILY_REPORT_AFFILIATE_SUCCESS,
    SET_DAILY_REPORT_COUNTRY_SUCCESS,
    SET_DAILY_REPORT_MANAGER_SUCCESS,
    SET_DAILY_REPORT_PERIOD_SUCCESS,

} from "./types";
import dailyReportService from "../services/daily-reports.service";
import { refreshPeriod } from "../utils/period/period";

export const dailyReportSearch = (manager, country, period, affiliate, advertiser) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return dailyReportService.getAllReports(manager?.id, country, updatedPeriod, affiliate, advertiser).then(
        (response) => {
            dispatch({
                type:DAILY_REPORT_FILTER_SUCCESS,
                payload: {
                    dailyReports: response.data,
                    country: country,
                    manager: manager,
                    advertiser: advertiser,
                    affiliate: affiliate,
                    period: updatedPeriod,
                },
            });

            return Promise.resolve();
        },
        () => {

            dispatch({
                type:DAILY_REPORT_FILTER_FAIL,
            });
            return Promise.reject();
        }
    );
};

export const setDailyReportPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_DAILY_REPORT_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

export const setDailyReportCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_DAILY_REPORT_COUNTRY_SUCCESS,
        payload: {
            country: country,
        },
    });

    return Promise.resolve();
}

export const setDailyReportAdvertiser = (advertiser) => (dispatch) => {
    dispatch({
        type: SET_DAILY_REPORT_ADVERTISER_SUCCESS,
        payload: {
            advertiser: advertiser,
        },
    });

    return Promise.resolve();
}

export const setDailyReportManager = (manager) => (dispatch) => {
    dispatch({
        type: SET_DAILY_REPORT_MANAGER_SUCCESS,
        payload: {
            manager: manager,
        },
    });

    return Promise.resolve();
}

export const setDailyReportAffiliate = (affiliate) => (dispatch) => {
    dispatch({
        type: SET_DAILY_REPORT_AFFILIATE_SUCCESS,
        payload: {
            affiliate: affiliate,
        },
    });

    return Promise.resolve();
}

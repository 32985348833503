import Title from "../components/common/custom-title";
import Breadcrumb from "../components/common/breadcrumb";
import React, {useEffect, useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import AffiliatesSelected from "../components/affiliate/AffiliatesSelected";
import AdvertiserSelected from "../components/advertiser/AdvertiserSelected";
import {useMutation, useQuery} from "@tanstack/react-query";
import ImportService from "../services/import.service";
import {toast} from "react-toastify";
import ImportTable from "../components/import/ImportTable";

const Import = () => {
    const {register, errors, handleSubmit, reset, control, watch} = useForm();

    const {data: dataSheet, isLoading: loadingSheet, refetch: getSheet, isFetching: fetchingSheet} = useQuery({
        queryKey: ['import-sheet'],
        queryFn: () => ImportService.sheet(),
        refetchInterval: false
    })

    const {data: queues} = useQuery({
        queryKey: ['import-leads'],
        queryFn: () => ImportService.leads(),
        refetchInterval: 60000,
    })

    const {mutate: deleteSheet, isLoading: loadingDeleteSheet} = useMutation({
        mutationFn: ImportService.deleteSheet,
        onSuccess: async () => {
            await getSheet()
            toast.success('Sheet link update')
        },
        onError: () => toast.error('An error occurred while deleting sheet')
    })

    const {mutate: importLeads, isLoading: loadingImport} = useMutation({
        mutationFn: ImportService.import,
        onSuccess: (data) => toast.success(data.data.message),
        onError: (data) => toast.error('Leads not imported')
    })

    const onSubmit = ({affiliate, auto_signup, ignore_user_limits, ignore_user_restrictions, register, advertiser}) => {
        importLeads({
            affiliate: affiliate.id,
            advertiser: register === '1' ? null : advertiser.id,
            ignore_user_limits: !!ignore_user_limits,
            ignore_user_restrictions: !!ignore_user_restrictions,
            auto_signup: !!auto_signup
        })
    }

    const loadingButton = useMemo(() => !!(loadingDeleteSheet || loadingSheet || fetchingSheet || !dataSheet?.file_id), [
        loadingDeleteSheet, loadingSheet, fetchingSheet, dataSheet
    ]);

    return (
        <>
            <Title title="Import Leads"/>
            <Breadcrumb title="Import Leads"/>
            <div className="container-fluid">
                <div className="col-md-6">
                    <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                          onReset={reset}>

                        <div className="form-row">
                            <div className="col-md-12 ">
                                <AffiliatesSelected control={control}
                                                    error={errors?.affiliate_id}
                                                    showTitle={true}
                                                    rules={{required: true}}
                                />
                            </div>

                            <div className=" col-md-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           name="register" ref={register} defaultChecked={true}
                                           id="register2" value="1"/>
                                    <label className="form-check-label" htmlFor="register2">
                                        Register to whatever advertiser
                                    </label>
                                </div>
                            </div>
                            <div className=" col-md-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           name="register" ref={register}
                                           id="register3" value="2"/>

                                    <label className="form-check-label" htmlFor="register3">
                                        Register to advertiser
                                    </label>
                                </div>
                            </div>

                            {watch("register") === "2" && (
                                <div className="col-md-12 ">
                                    <AdvertiserSelected control={control}
                                                        error={errors?.advertiser_id}
                                                        rules={{required: true}}
                                    />
                                </div>
                            )}

                            <div className=" col-md-12 mt-2">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           name="ignore_user_restrictions" ref={register} value="true"
                                           id="ignore_user_restriction "/>
                                    <label className="form-check-label" htmlFor="ignore_user_restrictions">
                                        Ignore affiliate restrictions
                                    </label>
                                </div>
                            </div>
                            <div className=" col-md-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           name="ignore_user_limits" ref={register} value="true"
                                           id="ignore_user_limits"/>
                                    <label className="form-check-label" htmlFor="ignore_user_limits">
                                        Ignore affiliate geos
                                    </label>
                                </div>
                            </div>
                            <div className=" col-md-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           name="auto_signup" ref={register} value="true"
                                           id="auto_signup"/>
                                    <label className="form-check-label" htmlFor="auto_signup">
                                        Auto signup
                                    </label>
                                </div>
                            </div>
                            <div className=" col-md-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="agree_terms"
                                           ref={register} value="1"
                                           id="agree_terms"/>
                                    <label className="form-check-label" htmlFor="agree_terms">
                                        I agree with <a href="#"> Terms And Conditions</a>
                                    </label>

                                </div>
                                <span className="text-danger-light">{errors?.agree_terms}</span>
                            </div>


                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <Button className="btn btn-primary ml-1" type="submit"
                                    disabled={loadingButton || !watch('agree_terms')}
                            >
                                <span>{"Import leads"}&nbsp;</span>
                                {loadingImport && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </Button>
                            <Button className="btn btn-success ml-1" type="button"
                                    href={`https://docs.google.com/spreadsheets/d/${dataSheet?.file_id}/edit`}
                                    target="_blank"
                                    disabled={loadingButton}
                            >
                                <span>{"Open sheet"}&nbsp;</span>
                                {loadingSheet && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </Button>
                            <Button className="btn btn-danger ml-1" type="button"
                                    target="_blank"
                                    disabled={loadingButton}
                                    onClick={() => deleteSheet()}
                            >
                                <span>{"New sheet"}&nbsp;</span>
                                {(loadingDeleteSheet || loadingSheet) && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </Button>
                        </div>
                    </form>
                </div>
                <ImportTable
                    queues={queues ?? []}
                />
            </div>
        </>
    )
}

export default Import
import {useTimezoneStore} from "../../../store/timezone";
import {useMemo} from "react";
import moment from "moment";

export const ConvertTimezoneDate = ({date}) => {

    const {timezone} = useTimezoneStore()

    const convertTimezone = useMemo(() => date ? moment.tz(date, "UTC").tz(timezone).format("YYYY-MM-DD H:mm:ss") : "" , [date, timezone])

    return (
        <>
            {convertTimezone}
        </>
    )
}
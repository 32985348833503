import {connect} from "react-redux";

import {Fragment} from "react";
import {Button} from "react-bootstrap";
import advertiserPerformanceReportsService from '../../../services/advertiser-performance.service'
import {toast} from "react-toastify";
import {useAdvertiserReportColumn} from "../../../store/useAdvertiserReportColumn";

const ExportReport = ({type, country, period, advertiser, affiliate, manager}) => {
    const columns = useAdvertiserReportColumn((state) => state.columns)

    const sendExport = (type) => {
        advertiserPerformanceReportsService.export(manager?.id, country, period, affiliate, advertiser, type, columns).then(()=> {
            toast.success('The export has been generated successfully. You will receive an email with a download link.');
        }).catch(err => console.log(err))
    }

    const variants = {
        'advertiser-countries-affiliate-performance' : 'primary',
        'advertiser-countries-performance' : 'secondary',
        'advertiser-performance' : 'success',
    };

    return (
        <Fragment>
            <Button variant={variants[type]} size="sm" onClick={() => sendExport(type)}>
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                xls
            </Button>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.advertiserReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}

export default connect(mapStateToProps)(ExportReport);
import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";

export const useTimezoneStore = create()(
    devtools(
        persist(
            (set) => ({
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                setTimezone: (input) => set({timezone: input}),
            }),
            {
                name: 'timezone-storage'
            }
        )
    )
)
import React from "react";
import AffiliateCommissionService from "../../../../../services/affiliate.commission.service";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../../constant";

const ModalDeleteCommission = ({commission, commissions, setCommissions}) => {

    const deleteCommissions = (id) => {
        setCommissions(commissions.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        AffiliateCommissionService.delete(id).then(() => {
            setCommissions(commissions => commissions.filter(x => x.id !== id));
        });
    }

    const submitDeleteCommissions = (id) => {
        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteCommissions(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button
                onClick={() => submitDeleteCommissions(commission.id)}
                className="btn btn-link p-0"
                disabled={commission.isDeleting}>
                {commission.isDeleting
                    ? <span
                        className="spinner-border spinner-border-lg "></span>
                    :
                    <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    )
}

export default ModalDeleteCommission;
import React, {Fragment, useEffect, useState} from 'react';
import ModalCreateGeo from "./parts/ModalCreateGeo";
import ModalEditGeo from "./parts/ModalEditGeo";
import TableReact from "react-bootstrap/Table";
import ModalDeleteGeo from "./parts/ModalDeleteGeo";
import {ShowForPermission} from "../../../../helpers/ShowForPermission";
import {
    CAN_CREATE_AFFILIATE_GEO_RESTRICTION, CAN_DELETE_AFFILIATE_GEO_RESTRICTION, CAN_UPDATE_AFFILIATE_GEO_RESTRICTION
} from "../../../../constant/permissions";

const GeoRestrictionList = ({affiliate}) => {
    const [geoRestrictions, setGeoRestrictions] = useState(undefined);

    useEffect(() => {
        setGeoRestrictions(affiliate?.geo_restrictions)
    }, [affiliate])

    return (
        <Fragment>
          
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h3>GEO restrictions:</h3>

                        <ShowForPermission permission={CAN_CREATE_AFFILIATE_GEO_RESTRICTION} >
                            <ModalCreateGeo
                                setGeoRestrictions={(data) => setGeoRestrictions(data)}
                                affiliate={affiliate}
                                geoRestrictions={geoRestrictions}
                            />
                        </ShowForPermission>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <TableReact bordered striped size={"sm"}>
                            <thead>
                                <tr>
                                    <th>
                                        Geo
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Daily limit
                                    </th>
                                    <ShowForPermission permission={[CAN_UPDATE_AFFILIATE_GEO_RESTRICTION, CAN_DELETE_AFFILIATE_GEO_RESTRICTION]} >
                                        <th>

                                        </th>
                                    </ShowForPermission>
                                </tr>
                            </thead>
                            <tbody>
                            { geoRestrictions &&
                                geoRestrictions.map((geoRestriction, index) => (
                                    <tr key={index}>
                                        <td>{geoRestriction.country_iso}</td>
                                        <td>{geoRestriction.is_included ? "Included" : "Excluded"}</td>
                                        <td>{geoRestriction.limit_day == 0 ? "Unlimited" : (
                                            <>
                                                {geoRestriction.limit_day} day
                                            </>
                                        )} </td>
                                        <ShowForPermission permission={[CAN_UPDATE_AFFILIATE_GEO_RESTRICTION, CAN_DELETE_AFFILIATE_GEO_RESTRICTION]} >
                                            <td scope="row">
                                                <div className="d-flex">

                                                    <div className="">
                                                        <ShowForPermission permission={CAN_UPDATE_AFFILIATE_GEO_RESTRICTION} >
                                                            <ModalEditGeo
                                                                setGeoRestrictions={(data) => setGeoRestrictions(data)}
                                                                geoRestrictions={geoRestrictions}
                                                                geoRestriction={geoRestriction}
                                                            />
                                                        </ShowForPermission>
                                                    </div>

                                                    <div className="ml-3">
                                                        <ShowForPermission permission={CAN_DELETE_AFFILIATE_GEO_RESTRICTION} >
                                                            <ModalDeleteGeo
                                                                setGeoRestrictions={(data) => setGeoRestrictions(data)}
                                                                geoRestrictions={geoRestrictions}
                                                                geoRestriction={geoRestriction}
                                                            />
                                                        </ShowForPermission>
                                                    </div>
                                                </div>
                                            </td>
                                        </ShowForPermission>

                                    </tr>
                                ))}

                            {geoRestrictions && !geoRestrictions.length &&
                                <tr key='affiliate-not-found'>
                                    <td colSpan="4" className="text-center">
                                        <div className="p-2">No Geo Restrictions To Display</div>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </TableReact>
                    </div>
                </div>
            </div>
                      
        </Fragment>

    );

}


export default GeoRestrictionList;

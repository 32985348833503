import {connect} from "react-redux";
import React, {
    Fragment,
    useEffect,
    useState
} from 'react'


const AffiliateSettingsCommissions = ({user}) => {

    const [commissions, setCommissions] = useState(false)

    useEffect(()=> {
        setCommissions(user?.data?.commissions)
    }, [])

    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h3>Comissions:</h3>


                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Geo
                                </th>
                                <th>
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            { commissions &&
                            commissions.map((commission, index) => (
                                <tr key={index}>
                                    <th>{commission.country_iso}</th>
                                    <th>{typeof commission.commissions === 'object' && commission.commissions !== null ? commission.commissions.from + '-' + commission.commissions.to : commission.commissions} USD/{commission.is_conversions ? "conv" : "lead"}</th>
                                </tr>
                            ))}
                            {!commissions.length &&
                            <tr>
                                <td colSpan="20" className="text-center">
                                    <div className="p-2">No data To Display</div>
                                </td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliateSettingsCommissions)

import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/streams`;

class StreamService {
    getAll(advertiser_id, filter, pageNumber = 1) {
        return axios.get(`advertisers/${advertiser_id}${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filter,
                }
            }
        });
    }

    getById(stream_id) {
        return axios.get(`${baseUrl}/${stream_id}`, {headers: authHeader()});
    }

    getFilter( geo = "all", period = {}, affiliate_id = "") {
        return axios.get(`advertisers${baseUrl}/filter?filter[geo]=${geo}&filter[affiliate]=${affiliate_id}&filter[period][from]=${period.from_date}&filter[period][to]=${period.to_date}`, {headers: authHeader()});
    }

    create(advertiser_id, data) {
        return axios.post(`advertisers/${advertiser_id}${baseUrl}`, data, {headers: authHeader()});
    }

    update(stream_id, data) {
        return axios.put(`${baseUrl}/${stream_id}`, data, {headers: authHeader()});
    }

    delete(stream_id) {
        return axios.delete(`${baseUrl}/${stream_id}`, {headers: authHeader()});
    }

}

export default new StreamService();
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";

import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from "react-redux";
import SearchApiRequest from "./parts/SearchApiRequest";
import Table from "./parts/table";
import ReportColumnsService
    from "../../services/report-columns.service";
import {useTranslation} from "react-i18next";
import Title from "../common/custom-title";

const ApiRequestsList = ({apiRequests}) => {

    const [reportColumns, setReportColumns] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
            ReportColumnsService.getAll().then(res => {
                let adminColumns = res.data.data.filter(el => el.type === 'API Requests').map(el => {
                    return {
                        Header: t(el.title),
                        accessor: el.title,
                        id: el.title,
                        id_num: el.id
                    }
                })
                setReportColumns(adminColumns)
            }).catch(err => console.log(err))
    }, [])

    let data = useMemo(() => {

        return apiRequests

    }, [apiRequests])

    return (
        <Fragment>
            <Title title="Api Requests"/>
            <Breadcrumb title="Api Requests"/>
            <div className="container-fluid">

                <SearchApiRequest/>

                <div className="row">
                    <div className="col-sm-12">
                            {apiRequests && (
                                <Table columns={reportColumns} data={data}/>
                            )}
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {apiRequests} = state.apiRequest;
    return {
        apiRequests,

    };
}

export default connect(mapStateToProps)(ApiRequestsList);

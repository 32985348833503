import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment-timezone";

const UserPanel = props => {
    const { user: currentUser } = props;
    const [time, setTime] = useState(undefined);
    const [intervalId, setIntervalId] = useState(undefined);

    useEffect(() => {
        let intervalId = setInterval(timer, 1000);
        setIntervalId(intervalId);
    }, [])

    const timer = () => {
        setTime(moment.utc().format("DD-MMM-YYYY H:mm:ss [(]Z[)]"))
    }

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <h6 className="mt-3 f-14">{currentUser?.data?.name}</h6>
                <p>{time}</p>
                <p>{currentUser?.data?.email}</p>
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(UserPanel);
import moment from "moment/moment";

export const calculatePeriod = (type, date) => {
    let start_end = "day";

    if (type === "yesterday") {
        date.subtract(1, 'days')
    }

    if (type === "this_week" || type === "last_week") {
        start_end = 'isoWeek'
    }

    if (type === "last_week") {
        date.subtract(1, 'weeks')
    }

    if (type === "this_month" || type === "last_month") {
        start_end = 'month'
    }

    if (type === "last_month") {
        date.subtract(1, 'month')
    }

    return {
        from_date: date.startOf(start_end).format('YYYY-MM-DD HH:mm:ss'),
        to_date: date.endOf(start_end).format('YYYY-MM-DD HH:mm:ss'),
        time: type,
    }
}

export const applyTimezone = (period, timezone) => ({
    from_date: moment.tz(period.from_date, timezone).utc().format('YYYY-MM-DD H:mm:ss'),
    to_date: moment.tz(period.to_date, timezone).utc().format('YYYY-MM-DD H:mm:ss'),
    time: period.time
})
export const applyPeriodTimeTimezone = (periodTime, timezone) => ({
    fromTime: moment.tz(periodTime.fromTime, "H:mm", timezone).utc().format('H:mm'),
    toTime: moment.tz(periodTime.toTime, "H:mm", timezone).utc().format('H:mm'),
    selectedDays: periodTime.selectedDays,
    status: periodTime.status,
})

export const refreshPeriod = (period, timezone) => {
    //Bug-120 [Is not updated today (the date is set for yesterday) until you refresh the page]
    return period.time !== 'custom' ? calculatePeriod(period.time, timezone ? moment.tz(timezone) : moment().utc()) : period
}

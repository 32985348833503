import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/permission`;

class PermissionService {
    getAll() {
        return axios.get(`${baseUrl}`, {headers: authHeader()});
    }
}

export default new PermissionService();
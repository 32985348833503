import {OPTIMIZE_FILTER_FAIL, OPTIMIZE_FILTER_SUCCESS, SET_OPTIMIZE_SUCCESS} from "./types";
import OptimizeService from "../services/optimize.service";


export const optimizeSearch = (country, affiliate, advertiser, pageNumber) => (dispatch) => {
    return OptimizeService.getFilter(country?.iso,  affiliate?.id, advertiser?.id, pageNumber).then(
        (response) => {
            dispatch({
                type: OPTIMIZE_FILTER_SUCCESS,
                payload: {
                    optimizes: response.data.data,
                    meta: response.data.meta,
                    country: country,
                    affiliate: affiliate,
                    advertiser: advertiser,
                },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: OPTIMIZE_FILTER_FAIL,
            });
            


            return Promise.reject();
        }
    );
};

export const setOptimizes = (optimizes) => (dispatch) => {
    dispatch({
        type: SET_OPTIMIZE_SUCCESS,
        payload: {
            optimizes: optimizes,
        },
    });

    return Promise.resolve();
}



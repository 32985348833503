import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";
import advertiserService from "../../services/advertiser.service";

import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
    CAN_DELETE_ADVERTISER, CAN_CREATE_ADVERTISER, CAN_EXPORT_ADVERTISER
} from "../../constant/permissions";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import SearchAdvertisers from "./parts/SearchAdvertisers";
import {toast} from "react-toastify";
import Title from "../common/custom-title";
import ModalDeleteAdvertiser from "./parts/ModalDeleteAdvertiser";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import AdvertiserTabs from "./parts/AdvertiserTabs";
import {DollarSign} from "react-feather";
import ExportAdvertiser from "./parts/ExportAdvertiser";

const AdvertisersList = () => {

    const [advertisers, setAdvertisers] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        getAdvertisersData();
    }, [])


    const getPageUrl = (pageNumber) => {
        return `${process.env.PUBLIC_URL}/advertisers?page=${pageNumber}`
    }


    const setAdvertiserRow = (advertiser, data) => {
        setAdvertisers(advertisers.map(el => {
            if (el.id === advertiser.id) {
                el = data
            }
            return el
        }))
    }

    const updateStatusAdvertiser = (advertiser, status = false) => {
        let data = {
            ...advertiser,
            is_active: !status,
        }
        advertiserService.update(advertiser.id, data).then((response) => {
            setAdvertisers(advertisers.map(x => {
                if (x.id === advertiser.id) {
                    x.is_active = response.data.data.is_active;
                }
                return x;
            }));
        });
    }



    const getAdvertisersData = async (filter, pageNumber) => {
        setFilter(filter);

        await advertiserService.getAllFiltering(filter, pageNumber).then(
            response => {
                if (response.data) {
                    setAdvertisers(response.data?.data);
                    setMeta(response.data?.meta);
                }

            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);
            }
        )
    };

    const showAdvertiserTab = (advertiser, key) => {
        const data = {
            ...advertiser,
            key,
            showTabs:
                advertiser?.showTabs === true
                && (!advertiser?.key || advertiser?.key === key)
                ? false : true
            ,
        }

        setAdvertiserRow(advertiser, data);
    }

    return (
        <Fragment>
            <Title title="Advertisers"/>
            <Breadcrumb title="Advertisers"/>
            <div className="container-fluid">

                <SearchAdvertisers
                    getData={getAdvertisersData}
                />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <ShowForPermission permission={CAN_EXPORT_ADVERTISER}>
                                        {
                                            advertisers && advertisers.length ?
                                                <ExportAdvertiser
                                                    filter={filter}
                                                />
                                                : <></>
                                        }
                                    </ShowForPermission>

                                    <ShowForPermission permission={CAN_CREATE_ADVERTISER}>
                                        <Link to={'/advertisers/create'} className={'btn btn-primary'}>
                                            Add adv
                                        </Link>
                                    </ShowForPermission>

                                </div>
                            <div className="card-body">

                                {advertisers &&
                                    advertisers.map((advertiser, index) => (
                                        <Fragment key={index} >

                                            <div className={advertiser?.showTabs ? "bg-light text-dark" : "" }>

                                                <div className="d-flex justify-content-between mx-4  my-3 pt-2">
                                                    <div className="d-flex ">
                                                        <p className="m-0 font-weight-bold text-dark">
                                                            {advertiser.name} (id {advertiser.id})
                                                        </p>
                                                        <div className="ml-3">
                                                            <form noValidate className="needs-validation">
                                                                <label className="switch-green">
                                                                    <input type="checkbox" checked={advertiser.is_active}
                                                                           onChange={(e) => updateStatusAdvertiser(advertiser, advertiser.is_active)}
                                                                    />
                                                                    <div className="slider"></div>
                                                                </label>
                                                            </form>
                                                        </div>
                                                        {advertiser.balance &&
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>
                                                                    <dl>
                                                                        <dd>Balance: {Number(advertiser?.balance?.balance)}$</dd>
                                                                        <dd>Paid: {Number(advertiser?.balance?.paid)}$</dd>
                                                                        <dd>Remains : {Number(advertiser?.balance?.remain)}$</dd>
                                                                    </dl>

                                                                </Tooltip>}
                                                                placement={"top"}
                                                            >
                                                                <DollarSign></DollarSign>
                                                            </OverlayTrigger>
                                                        }

                                                    </div>


                                                    <div className="d-flex justify-content-end">

                                                        <div>
                                                            <button className="btn btn-link p-0"
                                                                    onClick={() => showAdvertiserTab(advertiser,'geos')}>
                                                                <i className="fa fa-globe fa-2x"></i>
                                                            </button>
                                                        </div>

                                                        <div className="ml-3">
                                                            <button className="btn btn-link p-0"
                                                                    onClick={() => showAdvertiserTab(advertiser, 'commissions')}>
                                                                <i className="fa fa-dollar text-info fa-2x"></i>
                                                            </button>
                                                        </div>

                                                        <div className="ml-3">
                                                            <button className="btn btn-link p-0"
                                                                    onClick={() => showAdvertiserTab(advertiser, 'working_hours')}>
                                                                <i className="fa fa-clock-o text-waiting fa-2x"></i>
                                                            </button>
                                                        </div>

                                                        <div className="ml-3">
                                                            <button className="btn btn-link p-0"
                                                                    onClick={() => showAdvertiserTab(advertiser, 'edit')}>
                                                                <i className="fa fa-pencil text-success fa-2x"></i>
                                                            </button>
                                                        </div>

                                                        <ShowForPermission permission={CAN_DELETE_ADVERTISER}>
                                                            <div className="ml-3">
                                                                <ModalDeleteAdvertiser
                                                                    advertiser={advertiser}
                                                                    advertisers={advertisers}
                                                                    setAdvertisers={setAdvertisers}
                                                                />
                                                            </div>
                                                        </ShowForPermission>
                                                    </div>
                                                </div>
                                                <div className="container-fluid">
                                                    <AdvertiserTabs

                                                        advertiser={advertiser}
                                                        setAdvertiser={(data) => setAdvertiserRow(advertiser, data)}
                                                    />
                                                </div>
                                            </div>

                                            <hr/>

                                        </Fragment>
                                    ))}
                                {!advertisers &&
                                    <div className="text-center">
                                        <div className="spinner-border spinner-border-lg align-center"></div>
                                    </div>
                                }
                                {advertisers && !advertisers.length &&
                                    <div className="text-center">
                                        <div className="p-2">No Advertisers To Display</div>
                                    </div>
                                }

                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    {meta &&
                                        <Pagination
                                            activePage={meta.current_page}
                                            totalItemsCount={meta.total}
                                            itemsCountPerPage={meta.per_page}
                                            onChange={(pageNumber) => getAdvertisersData(filter, pageNumber)}
                                            getPageUrl={(pageNumber) => getPageUrl(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default AdvertisersList;

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button, Modal} from "react-bootstrap";

const ModalPeriodTimeFilter = ({periodTime, setPeriodTime}) => {
    const {register, handleSubmit, reset, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        debugger;
        try {
            await setPeriodTime(data);
            handleClose();

        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500)
        }

    }

    useEffect(() => {
        const fields = [
            'status',
            'toTime',
            'fromTime',

        ];

        fields.forEach(field => setValue(field, periodTime[field]));

    }, [show, periodTime]);

    return (
        <>
            <button onClick={handleShow}
                    className={`btn ${periodTime?.status ? "btn-primary" : 'btn-grey'} ml-2 text-center`}>
                <i className="fa fa-cog m-0" aria-hidden="true"></i>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Additional Time Settings"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <div className=" col-md-12">
                                        <h6 className={"mb-2 "}>Active Days</h6>

                                        <div className="form-check form-check-inline ml-2">
                                            <input className="form-check-input" type="checkbox" id="selectedDays1"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("2") > -1}
                                                   value="2"/>
                                            <label className="form-check-label" htmlFor="selectedDays1">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="selectedDays2"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("3") > -1}
                                                   value="3"/>
                                            <label className="form-check-label" htmlFor="selectedDays2">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="selectedDays3"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("4") > -1}
                                                   value="4"/>
                                            <label className="form-check-label" htmlFor="selectedDays3">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="selectedDays4"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("5") > -1}
                                                   value="5"/>
                                            <label className="form-check-label" htmlFor="selectedDays4">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="selectedDays5"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("6") > -1}
                                                   value="6"/>
                                            <label className="form-check-label" htmlFor="selectedDays5">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="selectedDays6"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("7") > -1}
                                                   value="7"/>
                                            <label className="form-check-label" htmlFor="selectedDays6">Sat</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="selectedDays7"
                                                   name="selectedDays[]" ref={register} defaultChecked={periodTime?.selectedDays?.indexOf("1") > -1}
                                                   value="1"/>
                                            <label className="form-check-label" htmlFor="selectedDays7">Sun</label>
                                        </div>
                                    </div>

                                    <div className=" col-md-12 mt-3">
                                        <div className="form-group">
                                            <label htmlFor="fromTime">From:</label>
                                            <input className="form-control" type="time" name="fromTime"
                                                   id="fromTime" ref={register}
                                                   placeholder=""/>
                                            <span>{errors?.fromTime}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="toTime">To:</label>
                                            <input className="form-control" type="time" name="toTime"
                                                   id="toTime" ref={register}
                                                   placeholder=""/>
                                            <span>{errors?.toTime}</span>
                                        </div>
                                    </div>



                                    <div className=" col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="1" name="status" ref={register}
                                                   id="status"/>
                                                <label className="form-check-label" htmlFor="status">
                                                    Activate
                                                </label>
                                        </div>
                                    </div>


                                </div>

                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{"Apply filter"}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}

export default ModalPeriodTimeFilter;

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../country/IsoSelected";
import { useForm } from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import AdvertiserSelected from "../../advertiser/AdvertiserSelected";
import {
    leadSearch,
    setLeadAdvertiser,
    setLeadAffiliate,
    setLeadCountry,
    setLeadFiltering,
    setLeadManager, setLeadPeriod, setLeadPeriodTime
} from "../../../actions/lead";
import {Button} from "react-bootstrap";
import ModalFilter from "./modal/ModalFilter";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {
    CAN_FILTER_ADVERTISER,
    CAN_FILTER_AFFILIATE
} from "../../../constant/permissions";
import ManagerSelected
    from "../../manager/ManagerSelected";
import PeriodFilter from "../../../utils/period/PeriodFilter";
import { LEAD_DEFAULT_FILTERING } from "../../../reducers/lead";
import {useTimezoneStore} from "../../../store/timezone";

const SearchLeads = ({dispatch, country, period, filtering, advertiser, affiliate, periodTime, manager, pageSize}) => {
    const {handleSubmit, reset, control, watch, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [modalShowFilter, setModalShowFilter] = useState(false);
    const {timezone} = useTimezoneStore()


    const isClear = useMemo(
        () => {
            const {country: wCountry, manager: wManager, affiliate: wAffiliate, advertiser: wAdvertiser} = watch()
            return !!wCountry || !!wManager || !!wAffiliate || !!wAdvertiser ||
                JSON.stringify(filtering) !== JSON.stringify(LEAD_DEFAULT_FILTERING)
        },
        [filtering, watch]
    )

    useEffect(() => {
        setValue("country", country);
        setValue("affiliate", affiliate);
        setValue("advertiser", advertiser);
        setValue("manager", manager);

    }, [country, advertiser, affiliate, manager]);

    const clearFilter = async () => {
        //clear form
        setValue("country", null);
        setValue("affiliate", null);
        setValue("advertiser", null);
        setValue("manager", null);

        //clear state
        await dispatch(setLeadFiltering(LEAD_DEFAULT_FILTERING));
        await dispatch(setLeadCountry(null));
        await dispatch(setLeadAdvertiser(null));
        await dispatch(setLeadAffiliate(null));
        await dispatch(setLeadManager(null));
    }

    useEffect(() => {
        onSubmit({country, affiliate, advertiser, manager}).catch()
    }, [timezone])

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(leadSearch(data?.manager ,data?.country, period, periodTime, data?.affiliate, data?.advertiser, 1, filtering, pageSize, timezone));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">

                    <PeriodFilter
                        setPeriod={(data) => dispatch(setLeadPeriod(data))}
                        setPeriodTime={(data) => dispatch(setLeadPeriodTime(data))}
                        period={period}
                        periodTime={periodTime}
                        showPeriodTime={true}
                    />

                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-2 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <ShowForPermission permission = {CAN_FILTER_AFFILIATE} >
                            <div className="col-md-2">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>
                            </ShowForPermission>
                            <ShowForPermission permission = {CAN_FILTER_ADVERTISER} >
                                <div className="col-md-2">
                                    <AdvertiserSelected label={false} control={control} error={errors?.advertiser_id}/>
                                </div>
                            </ShowForPermission>
                            <ShowForPermission permission = {CAN_FILTER_ADVERTISER} >
                                <div className="col-md-2">
                                    <ManagerSelected label={false} control={control} error={errors?.manager_id}/>
                                </div>
                            </ShowForPermission>
                            <div className="col-md-2">
                                <div className="d-flex">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>

                                    <Button onClick={() => setModalShowFilter(true)}
                                            className='btn btn-primary ml-2'>
                                        <i className="fa fa-bars" aria-hidden="true"></i>
                                    </Button>

                                    <Button onClick={() => clearFilter()}
                                            className={`btn btn-primary ml-2 ${isClear ? "" : "d-none"}`}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </form>
                    <ModalFilter
                        show={modalShowFilter}
                        onHide={() => setModalShowFilter(false)}
                    />
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, period, filtering, advertiser, affiliate, periodTime, manager, pageSize} = state.lead;
    return {
        country,
        period,
        advertiser, affiliate, periodTime,
        filtering, manager,
        pageSize
    };
}

export default connect(mapStateToProps)(SearchLeads);

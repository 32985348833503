import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";
import IsoSelected
    from "../../../country/IsoSelected";

const SearchCommission = ({getCommissionsData, filter}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (filter.geo  === undefined) {
            setValue('country', null)
        }

    }, [filter])

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            let countries_iso = data?.country?.map(el => el.iso)
            let filterData = {
                geo: countries_iso,
                event: data?.event,
            }

            await getCommissionsData(1, filterData)

        } finally {
              setLoading(false)
        }
    }

    return (<Fragment>
            <form noValidate className="needs-validation"
                  onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <IsoSelected control={control}  showTitle={false}
                               isMulti closeMenuOnSelect={false}
                            />

                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <select name="event" className="custom-select" id="event" ref={register}>
                                    <option value="" disabled selected>Event</option>
                                    <option value="" >All</option>
                                    <option value="1" >First Conversion</option>
                                    <option value="2">Lead</option>
                                </select>
                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <button className="btn btn-primary " type="submit"
                                    disabled={loading}
                            >
                                <span><i className="fa fa-search"
                                         aria-hidden="true"></i>&nbsp;</span>
                                {loading && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </button>
                        </div>
                    </div>
            </form>
        </Fragment>

    );

}

export default SearchCommission;

import {connect} from "react-redux";

import React, {Fragment, useEffect, useState} from "react";

const AffiliateSettingsGeoRestrictions = ({user}) => {

    const [geoRestrictions, setGeoRestrictions] = useState(false)

    useEffect(()=> {
        setGeoRestrictions(user?.data?.geo_restrictions)
    }, [])

    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h3>GEO restrictions:</h3>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Geo
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Daily limit
                                </th>
                                <th>

                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            { geoRestrictions &&
                            geoRestrictions.map((geo_restriction, index) => (
                                <tr key={index}>
                                    <th>{geo_restriction.country_iso}</th>
                                    <th>{geo_restriction.is_included ? "Included" : "Excluded"}</th>
                                    <th>{geo_restriction.limit_day} day</th>
                                </tr>
                            ))}
                            {!geoRestrictions.length &&
                            <tr>
                                <td colSpan="20" className="text-center">
                                    <div className="p-2">No data To Display</div>
                                </td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliateSettingsGeoRestrictions)
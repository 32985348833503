import React from 'react';
import { renderRoutes } from 'react-router-config';
import Header from './components/common/header-component/header';
import Sidebar from './components/common/sidebar-component/sidebar';
import RightSidebar from './components/common/right-sidebar';
import Footer from './components/common/footer';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/common/loader';


const Dashboard = props => {
    const {route} = props;

    return (
        <div>
            <Loader/>
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header/>
                    <Sidebar/>
                    <RightSidebar/>
                    <div className="page-body">
                        {renderRoutes(route.routes)}
                    </div>
                    <Footer/>
                    {/*<ThemeCustomizer />*/}
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default Dashboard;
import { connect } from 'react-redux';

const ShowForPermissionComponent = ({permission, isNotPermission = false, user, children}) => {

    if (permission) {
        const permissionArray = [].concat(permission);

        const couldShow = permissionArray.filter((permission) => {
            return user?.data?.permissions.filter(el => el.name === permission).length > 0;
        }).length > 0;

        return (couldShow && !isNotPermission)
            || (!couldShow && isNotPermission)
            ? children : null;
    }


    return children
};


function mapStateToProps(state) {
    const { user} = state.auth;

    return {
        user
    };
}

export const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent);
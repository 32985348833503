import {connect} from "react-redux";

import {
    Fragment,
} from "react";
import {Button} from "react-bootstrap";
import leadService
    from "../../../services/lead.service";
import {toast} from "react-toastify";
import {useTimezoneStore} from "../../../store/timezone";
import {applyPeriodTimeTimezone, applyTimezone} from "../../../utils/period/period";

const ExportLeads = ({columns, period, country, advertiser, affiliate, manager, periodTime, filtering}) => {

    const {timezone} = useTimezoneStore()

    const sendExport = () => {
        let visibleColumns = columns.filter(el => el.isVisible === true && el.id !== 'btns').map(el => el.id)
        leadService.export(manager?.id, country?.iso, applyTimezone(period, timezone), applyPeriodTimeTimezone(periodTime, timezone), affiliate?.id, advertiser?.id, filtering, visibleColumns).then(res => {
            toast.success('The export has been generated successfully. You will receive an email with a download link.');
        }).catch(err => console.log(err))
    }

    return (
        <Fragment>
            <Button size="md" onClick={() => sendExport()}>
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                csv
            </Button>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {period, country, advertiser, affiliate, manager, periodTime, filtering} = state.lead;
    return {
        country,
        period,
        advertiser, affiliate, manager, periodTime, filtering
    };
}

export default connect(mapStateToProps)(ExportLeads);

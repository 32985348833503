import React from 'react';
import ModalEditDowntime from "./parts/ModalEditDowntime";
import moment from "moment-timezone";
import {CAN_DELETE_ADVERTISER_DOWNTIME, CAN_UPDATE_ADVERTISER_DOWNTIME} from "../../../constant/permissions";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import ModalDeleteDowntime from "./parts/ModalDeleteDowntime";
import Pagination from "react-js-pagination";
import {changeTimezoneDayTime, timeSubtractSecond} from "../../../constant/date";

const DowntimesList = ({countries, getDowntimesData, meta, downtimes, setDowntimes}) => {

    return (
        <>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{"Country"}</th>
                            <th scope="col">{"From Time - To Time"}</th>
                            <th scope="col">{"UTC From Time - UTC To Time"}</th>
                            <ShowForPermission
                                permission={[CAN_UPDATE_ADVERTISER_DOWNTIME, CAN_DELETE_ADVERTISER_DOWNTIME]}>
                                <th scope="col">{"Actions"}</th>
                            </ShowForPermission>
                        </tr>
                        </thead>
                        <tbody>
                        {downtimes &&
                            downtimes?.map((downtime, index) => (
                                <tr key={index}>
                                    <td>{downtime.country?.title}</td>
                                    <td>
                                        {downtime.day_of_week} {downtime.from}
                                        <span>  - </span>
                                        {downtime.day_of_week} {timeSubtractSecond(downtime.to)}
                                        (GTM {moment.tz(downtime.timezone).format('Z')})
                                    </td>
                                    <td>
                                        {changeTimezoneDayTime(downtime.from, downtime.day_of_week, downtime.timezone)}
                                        <span>  - </span>
                                        {changeTimezoneDayTime(timeSubtractSecond(downtime.to), downtime.day_of_week, downtime.timezone)}
                                        (GTM +00:00)
                                    </td>

                                    <ShowForPermission
                                        permission={[CAN_UPDATE_ADVERTISER_DOWNTIME, CAN_DELETE_ADVERTISER_DOWNTIME]}>
                                        <th scope="row">
                                            <div className="d-flex">

                                                <ShowForPermission permission={CAN_UPDATE_ADVERTISER_DOWNTIME}>
                                                    <div className="">

                                                        <ModalEditDowntime
                                                            setDowntimes={(data) => setDowntimes(data)}
                                                            downtime={downtime}
                                                            downtimes={downtimes}
                                                        />
                                                    </div>
                                                </ShowForPermission>

                                                <ShowForPermission permission={CAN_DELETE_ADVERTISER_DOWNTIME}>
                                                    <div className="ml-3">
                                                        <ModalDeleteDowntime
                                                            setDowntimes={setDowntimes}
                                                            downtime={downtime}
                                                            downtimes={downtimes}
                                                        />
                                                    </div>
                                                </ShowForPermission>
                                            </div>
                                        </th>
                                    </ShowForPermission>

                                </tr>
                            ))}
                        {!downtimes &&
                            <tr>
                                <td colSpan="13" className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </td>
                            </tr>
                        }
                        {downtimes && !downtimes.length &&
                            <tr>
                                <td colSpan="13" className="text-center">
                                    <div className="p-2">No Downtimes To Display</div>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className={"d-flex justify-content-center mt-3"}>
                    {(meta && meta?.last_page > 1) &&

                        <Pagination
                            activePage={meta?.current_page}
                            totalItemsCount={meta?.total}
                            itemsCountPerPage={meta?.per_page}
                            onChange={(pageNumber) => getDowntimesData(pageNumber, countries?.map(el => el.iso))}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    }
                </div>
            </div>


        </>

    );

}


export default DowntimesList;

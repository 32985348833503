import React, {Fragment, useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';
import {connect} from "react-redux";
import SearchModeration from "./parts/SearchModeration";
import Pagination from "react-js-pagination";
import {moderationPendingCount, moderationSearch} from "../../actions/moderation";
import ModerationUpdateStatus from "./parts/ModerationUpdateStatus";
import TableReact from "react-bootstrap/Table";
import Title from "../common/custom-title";
import {AffiliatesInfo} from "../affiliate/AffiliateInfo";


const ModerationList = ({dispatch, moderations, meta, country, period, affiliate, status, manager}) => {

    useEffect(() => {
        dispatch(moderationPendingCount());
    },[moderations])


    return (<Fragment>
        <Title title="Moderation"/>
            <Breadcrumb title="Moderation"/>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <SearchModeration/>
                    </div>
                    {moderations &&

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-end">

                                </div>
                                <div className="table-responsive">
                                    <TableReact bordered striped size="sm">
                                        <thead>
                                        <tr>
                                            <th scope="col">{"ID"}</th>
                                            <th scope="col">{"Status"}</th>
                                            <th scope="col">{"User Updated"}</th>
                                            <th scope="col">{"Sale Status (Raw)"}</th>
                                            <th scope="col">{"Reason"}</th>
                                            <th scope="col">{"Is FTD"}</th>
                                            <th scope="col">{"Advertiser Payout"}</th>
                                            <th scope="col">{"Affiliate"}</th>
                                            <th scope="col">{"Signup Date"}</th>
                                            <th scope="col">{"Deposit Date"}</th>
                                            <th scope="col">{"Email"}</th>
                                            <th scope="col">{"Country"}</th>
                                            <th scope="col">{"Account Name"}</th>
                                            <th scope="col">{"Customer ID"}</th>
                                            <th scope="col">{"Action"}</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {moderations && moderations.map((moderation, index) => (
                                            <>
                                                {(!status || status == moderation.status.id) && (
                                                    <>
                                                        <tr key={index}>

                                                            <td scope="row">{moderation.id}</td>
                                                            <td scope="row">
                                                                <div>
                                                                    {moderation.status.title}
                                                                </div>
                                                            </td>
                                                            <td scope="row">{moderation.user_updated}</td>
                                                            <td scope="row">{moderation.lead.sale_status_raw}</td>
                                                            <td scope="row">
                                                                {moderation.status.title === "Pending" ? "Conversion review set for affiliate" : moderation.status.title}
                                                            </td>
                                                            <td scope="row">{"Yes"}</td>
                                                            <td scope="row">${moderation.amount}</td>
                                                            <td scope="row">
                                                                <AffiliatesInfo affiliate={moderation?.affiliate} />
                                                            </td>
                                                            <td scope="row">{moderation.lead.signup_date}</td>
                                                            <td scope="row">{moderation.lead.conversion_date}</td>
                                                            <td scope="row">{moderation.lead.email}</td>
                                                            <td scope="row">{moderation.lead.country?.title}</td>
                                                            <td scope="row">{moderation.lead.advertiser?.name}</td>
                                                            <td scope="row">{moderation.lead.customer_id}</td>
                                                            <td scope="row"><ModerationUpdateStatus moderation={moderation}/>
                                                            </td>

                                                        </tr>
                                                    </>

                                                )}
                                            </>
                                        ))}
                                        {!moderations && <tr>
                                            <td colSpan="15" className="text-center">
                                                <div
                                                    className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>}
                                        {moderations && !moderations.length && <tr>
                                            <td colSpan="15" className="text-center">
                                                <div className="p-2">No Moderations To Display</div>
                                            </td>
                                        </tr>}
                                        </tbody>
                                    </TableReact>
                                </div>

                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        {meta && meta.total > 1 && <Pagination
                                            activePage={meta.current_page}
                                            totalItemsCount={meta.total}
                                            itemsCountPerPage={meta.per_page}
                                            onChange={(pageNumber) => dispatch(moderationSearch(manager, country, period, affiliate, status, pageNumber))}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />}
                                    </div>
                                </div>

                            </div>
                        </div>}

                </div>
            </div>
        </Fragment>);
};

function mapStateToProps(state) {
    const {moderations, meta, country, period, affiliate, status, manager} = state.moderation;
    return {
        moderations, meta, period, country, status, affiliate, manager
    };
}

export default connect(mapStateToProps)(ModerationList);
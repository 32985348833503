import React, {Fragment, useEffect, useState, } from 'react';
import Breadcrumb from "../../common/breadcrumb";
import {useStateIfMounted} from 'use-state-if-mounted';
import { useTranslation } from "react-i18next";

import PermissionService from "../../../services/permission.service";
import RoleService from "../../../services/role.service";

import {useAlert} from "react-alert";
import Title from "../../common/custom-title";



const GroupPermissionEdit = ({ history, match}) => {
    const {id} = match.params;
    const alert = useAlert();

    const [permissions, setPermissions] = useStateIfMounted(undefined);
    const [role, setRole] = useStateIfMounted(undefined);
    const { t, ready } = useTranslation();

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const getAllPermissions =  () => {
        PermissionService.getAll().then(res => {
                let permissions = res.data.data
                setPermissions(permissions.filter(el => el.guard === role.guard))
            }).catch(err => {
                console.log(err)
        })
    }

    const togglePermission = async (e, permission) => {
        let data = {
            permission: permission
        }
        if (e.target.checked) {
            RoleService.attachPermission(id, data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        } else {
            RoleService.detachPermission(id, data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const getRole = () => {
        RoleService.getById(id).then(res => {
            let role = res.data.data
            setRole(role)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(  () => {
        getRole()
    }, []);

    useEffect(  () => {
        if (role){
            getAllPermissions()
        }
    }, [role]);


    return(
        <Fragment>
            <Title title="Edit Permissions"/>
            <Breadcrumb  title="Edit Permissions"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="permissions">
                                <h3>Permissions</h3>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">{"Permission"}</th>
                                            <th scope="col">{"Status"}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                            {permissions &&
                            permissions.map((permission, index) => (
                                <tr key={index}>
                                    <th scope="row">{t(permission.name)}</th>
                                    <th scope="row">
                                        <label className="switch-green">
                                            <input onChange={(e) => togglePermission(e, permission.name)} type="checkbox" name="is_active" defaultChecked={role.permissions && (role.permissions.filter(el => el.name === permission.name).length > 0 || role.name === 'Super-Admin')}
                                                   disabled={role.name === 'Super-Admin'}
                                            />
                                            <div className="slider"></div>
                                        </label>
                                    </th>
                                </tr>
                            ))}
                            {!permissions &&
                            <tr>
                                <td colSpan="13" className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </td>
                            </tr>
                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>)
        ;

}


export default GroupPermissionEdit;

export const CAN_INDEX_ADMIN    = "admin-index";
export const CAN_SHOW_ADMIN     = "admin-show";
export const CAN_CREATE_ADMIN   = "admin-create";
export const CAN_UPDATE_ADMIN   = "admin-update";
export const CAN_DELETE_ADMIN   = "admin-delete";
export const CAN_CHANGE_ADMIN_ROLE   = "change-role";
export const CAN_CHANGE_ADMIN_PERMISSION   = "change-permission";

export const CAN_CREATE_AFFILIATE_COMMISSION = "affiliate-commission-create";
export const CAN_UPDATE_AFFILIATE_COMMISSION = "affiliate-commission-update";
export const CAN_DELETE_AFFILIATE_COMMISSION = "affiliate-commission-delete";

export const CAN_INDEX_WHITE_LIST_IP    = 'white-list-ip-index';
export const CAN_SHOW_WHITE_LIST_IP     = 'white-list-ip-show';
export const CAN_CREATE_WHITE_LIST_IP   = 'white-list-ip-create';
export const CAN_UPDATE_WHITE_LIST_IP   = 'white-list-ip-update';
export const CAN_DELETE_WHITE_LIST_IP   = 'white-list-ip-delete';

export const CAN_INDEX_AFFILIATE    = "affiliate-index";
export const CAN_SHOW_AFFILIATE     = "affiliate-show";
export const CAN_CREATE_AFFILIATE   = "affiliate-create";
export const CAN_UPDATE_AFFILIATE   = "affiliate-update";
export const CAN_DELETE_AFFILIATE   = "affiliate-delete";
export const CAN_DOWNLOAD_API_AFFILIATE     = 'affiliate-download-api';

export const CAN_CREATE_AFFILIATE_GEO_RESTRICTION = "affiliate-geo-restriction-create";
export const CAN_UPDATE_AFFILIATE_GEO_RESTRICTION = "affiliate-geo-restriction-update";
export const CAN_DELETE_AFFILIATE_GEO_RESTRICTION = "affiliate-geo-restriction-delete";

export const CAN_INDEX_AFFILIATE_RESTRICTION = "affiliate-restriction-index";
export const CAN_SHOW_AFFILIATE_RESTRICTION = "affiliate-restriction-show";
export const CAN_CREATE_AFFILIATE_RESTRICTION = "affiliate-restriction-create";
export const CAN_UPDATE_AFFILIATE_RESTRICTION = "affiliate-restriction-update";
export const CAN_DELETE_AFFILIATE_RESTRICTION = "affiliate-restriction-delete";

export const CAN_CREATE_STREAM_AFFILIATE_CAP = 'stream-affiliate-cap-create';
export const CAN_UPDATE_STREAM_AFFILIATE_CAP = 'stream-affiliate-cap-update';
export const CAN_DELETE_STREAM_AFFILIATE_CAP = 'stream-affiliate-cap-delete';

export const CAN_INDEX_COUNTRY = "country-index";
export const CAN_SHOW_COUNTRY  = "country-show";

export const CAN_INDEX_DASHBOARD = "dashboard-index";

export const CAN_INDEX_API_REQUEST = "api-request-index";
export const CAN_API_REQUEST_RE_REGISTER = 'api-request-re-register';

export const CAN_INDEX_DISTRIBUTION     = "distribution-index";
export const CAN_SHOW_DISTRIBUTION      = "distribution-show";
export const CAN_CREATE_DISTRIBUTION    = "distribution-create";
export const CAN_UPDATE_DISTRIBUTION    = "distribution-update";
export const CAN_DELETE_DISTRIBUTION    = "distribution-delete";

export const CAN_INDEX_LEAD         = "lead-index";
export const CAN_GET_CONVERSIONS    = "lead-conversions";
export const CAN_SHOW_LEAD          = "lead-show";
export const CAN_CREATE_LEAD        = "lead-create";
export const CAN_UPDATE_LEAD        = "lead-update";
export const CAN_UPDATE_LEAD_AFF_PAYOUT   = 'lead-update-aff-payout';
export const CAN_UPDATE_LEAD_ADV_PAYOUT   = 'lead-update-adv-payout';
export const CAN_RE_REGISTER_LEADS        = 'lead-re-register-leads';
export const CAN_VIEW_HISTORY_LEADS        = 'view-history-leads';
export const CAN_UPDATE_LEAD_IS_TEST   = 'lead-update-is-test';
export const CAN_DELETE_LEAD        = "lead-delete";
export const CAN_EXPORT_LEAD        = "lead-export";

export const CAN_INDEX_MANAGER  = "manager-index";
export const CAN_SHOW_MANAGER   = "manager-show";
export const CAN_CREATE_MANAGER = "manager-create";
export const CAN_UPDATE_MANAGER = "manager-update";
export const CAN_DELETE_MANAGER = "manager-delete";

export const CAN_INDEX_POSTBACK     = "postback-index";
export const CAN_SHOW_POSTBACK      = "postback-show";
export const CAN_CREATE_POSTBACK    = "postback-create";
export const CAN_UPDATE_POSTBACK    = "postback-update";
export const CAN_DELETE_POSTBACK    = "postback-delete";

export const CAN_INDEX_POSTBACK_HISTORY = "postback-history-index";

export const CAN_INDEX_POSTBACK_TYPE = "postback-type-index";

export const CAN_INDEX_ROTATION_BUCKET  = "rotation-bucket-index";
export const CAN_SHOW_ROTATION_BUCKET   = "rotation-bucket-show";
export const CAN_CREATE_ROTATION_BUCKET = "rotation-bucket-create";
export const CAN_UPDATE_ROTATION_BUCKET = "rotation-bucket-update";
export const CAN_DELETE_ROTATION_BUCKET = "rotation-bucket-delete";

export const CAN_INDEX_ADVERTISER  = "adv-index";
export const CAN_EXPORT_ADVERTISER  = "adv-export";
export const CAN_SHOW_ADVERTISER   = "adv-show";
export const CAN_CREATE_ADVERTISER = "adv-create";
export const CAN_UPDATE_ADVERTISER = "adv-update";
export const CAN_DELETE_ADVERTISER = "adv-delete";

export const CAN_INDEX_ADVERTISER_DOWNTIME  = 'advertiser-downtime-index';
export const CAN_CREATE_ADVERTISER_DOWNTIME = 'advertiser-downtime-create';
export const CAN_UPDATE_ADVERTISER_DOWNTIME = 'advertiser-downtime-update';
export const CAN_DELETE_ADVERTISER_DOWNTIME = 'advertiser-downtime-delete';

export const CAN_INDEX_ADVERTISER_COMMISSION  = 'advertiser-commission-index';
export const CAN_CREATE_ADVERTISER_COMMISSION = 'advertiser-commission-create';
export const CAN_UPDATE_ADVERTISER_COMMISSION = 'advertiser-commission-update';
export const CAN_DELETE_ADVERTISER_COMMISSION = 'advertiser-commission-delete';

export const CAN_INDEX_STREAM  = "stream-index";
export const CAN_SHOW_STREAM   = "stream-show";
export const CAN_CREATE_STREAM = "stream-create";
export const CAN_UPDATE_STREAM = "stream-update";
export const CAN_DELETE_STREAM = "stream-delete";

export const CAN_CHANGE_ROLE_PERMISSIONS = "change-role-permissions";
export const CAN_INDEX_ROLE = "role-index";
export const CAN_SHOW_ROLE = "role-show";

export const CAN_INDEX_PERMISSIONS = "permissions-index";

export const CAN_INDEX_OPTIMIZE  = "optimize-index";
export const CAN_SHOW_OPTIMIZE   = "optimize-show";
export const CAN_CREATE_OPTIMIZE = "optimize-create";
export const CAN_UPDATE_OPTIMIZE = "optimize-update";
export const CAN_DELETE_OPTIMIZE = "optimize-delete";

export const CAN_INDEX_MANUAL_CONVERSION  = "manual-conversion-index";
export const CAN_CREATE_MANUAL_CONVERSION   = "manual-conversion-create";

export const CAN_FILTER_AFFILIATE  = 'filter-affiliate';
export const CAN_FILTER_ADVERTISER = 'filter-advertiser';

export const CAN_SEE_INCOME     = 'can-see-income';
export const CAN_SEE_COST       = 'can-see-cost';

export const CAN_INDEX_REPORTS = 'reports-index';

export const CAN_INDEX_MODERATION  = 'moderation-index';

export const CAN_INDEX_DAILY_REPORTS                        = 'can-index-daily-reports';
export const CAN_INDEX_AFFILIATE_PERFORMANCE_REPORTS        = 'can-index-affiliate-performance-reports';
export const CAN_INDEX_ADVERTISER_PERFORMANCE_REPORTS       = 'can-index-advertiser-performance-reports';
export const CAN_INDEX_AFFILIATE_SETTINGS      = 'can-index-affiliate-settings';

export const CAN_LOGIN_VIA_AFF      = 'can-login-via-aff';

export const CAN_INDEX_INVOICE   = 'invoice-index';
export const CAN_SHOW_INVOICE     = 'invoice-show';
export const CAN_CREATE_INVOICE   = 'invoice-create';
export const CAN_UPDATE_INVOICE   = 'invoice-update';
export const CAN_DELETE_INVOICE   = 'invoice-delete';

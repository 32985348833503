import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {
    AFFILIATE_REPORT_COLUMNS,
    PERFORMANCE_REPORT_COLUMNS,
    REPORT_COLUMNS
} from "../constant/reportColumns";

export const useAffiliateReportColumn = create()(
    devtools(
        persist(
            (set, get) => ({
                columns: REPORT_COLUMNS.concat(PERFORMANCE_REPORT_COLUMNS).concat(AFFILIATE_REPORT_COLUMNS),
                changeVisible: (accessor) => set({columns: get().columns.map((el) => el.accessor === accessor ? {...el, ...{is_visible: !el.is_visible}} : el)}),
            }),
            {
                name: 'affiliate-report-column',
                merge: (persistedState, currentState) => ({
                    ...persistedState, ...currentState, ...{
                        columns: persistedState.columns.map((el) => {
                            const findItem = currentState.columns.find((item) => item.accessor === el.accessor)
                            return findItem ? {...findItem, ...{is_visible: el.is_visible}} : el
                        })
                    }
                })
            }
        )
    )
)
import React, {useState} from 'react';
import BucketService from "../../../../../services/bucket.service";
import {connect} from "react-redux";
import {setBuckets, streamSearch} from "../../../../../actions/stream";
import {toast} from "react-toastify";

const BucketUpdateOrder = props => {
    const {bucket, buckets, dispatch, country, period, affiliate} = props;
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data, action) => {
        setLoading(true);

        let func;

        if(action === "up"){
            func =  BucketService.updateOrderUp(bucket?.id);
        }else {
            func =  BucketService.updateOrderDown(bucket?.id);
        }

        func.then(async response => {
                const oldOrder = bucket.order;

                const newBuckets = [].concat(buckets).map(x => {
                    if (x.order === response.data.data.order) {
                        x.order = oldOrder;
                    }
                    return x;
                }).map(x => {
                    if (x.id === bucket.id) {
                        x.order = response.data.data.order;
                    }
                    return x;
                }).sort((a,b) => a.order - b.order);

                dispatch(setBuckets(newBuckets));

                toast.success("Bucket Updated")
            }, error => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                toast.error(message)
            })
            .finally(() => {
                setLoading(false);

            })

    }


    return (<>
            <div className="d-flex">
                { bucket.id != buckets[0].id && (
                    <div className="mr-2">
                        <button
                            onClick={() => onSubmit(bucket.id, "up")}
                            disabled={loading}
                            className="btn btn-light px-2"
                        >

                            <i className="fa fa-chevron-up"></i>
                        </button>
                    </div>
                )}

                { bucket.id != buckets[buckets.length - 1].id && (

                <div >
                    <button
                        onClick={() => onSubmit(bucket.id, "down")}
                        disabled={loading}
                        className="btn btn-light px-2"
                    >
                            <i className="fa fa-chevron-down"></i>
                    </button>
                </div>
                )}

            </div>
        </>);

}

function mapStateToProps(state) {
    const {buckets, country, period, affiliate} = state.stream;
    return {
        buckets, country, period, affiliate
    };
}

export default connect(mapStateToProps)(BucketUpdateOrder);

import React from 'react';
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../constant";
import InvoiceService from "../../../../services/invoice.service";
import {connect} from "react-redux";
import {setInvoices} from "../../../../actions/invoice";

const ModalDeleteInvoice = ({invoices, invoice, dispatch}) => {

    const deleteInvoice = (id) => {
        dispatch(setInvoices(invoices.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        })));
        InvoiceService.delete(id).then(() => {
            dispatch(setInvoices(invoices.filter(x => x.id !== id)));
        });
    }

    const submitDeleteInvoice = (id) => {
        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteInvoice(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button
                onClick={()=> submitDeleteInvoice(invoice.id)}
                className="btn btn-link p-0"
                disabled={invoice.isDeleting}>
                {invoice.isDeleting
                    ? <span
                        className="spinner-border spinner-border-lg "></span>
                    :
                    <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    );
}

function mapStateToProps(state) {
    const {invoices} = state.invoice;
    return {
        invoices
    };
}

export default connect(mapStateToProps)(ModalDeleteInvoice);
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../constant";
import {connect} from "react-redux";
import RestrictionService from "../../../../services/affiliate/advertiser-restriction.service";
import {restrictionSearch, setRestrictions} from "../../../../actions/restriction";
import {confirmAlert} from "react-confirm-alert";

const ModalDeleteRestriction = props => {
    const {dispatch, restrictions, restriction, affiliate, country, advertiser, status, pageNumber} = props;


    const deleteRestriction = async (id) => {
        await dispatch(setRestrictions(restrictions?.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        })));

        RestrictionService.delete(id).then(async () => {
            await dispatch(restrictionSearch(country, affiliate, advertiser, status, pageNumber));
        });
    }

    const submitDeleteRestriction = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteRestriction(id)
                },
                {
                    label: No,
                }
            ]
        });
    }


    return (
        <>
            <button
                onClick={() => submitDeleteRestriction(restriction.id)}
                className="btn btn-link p-0"
                disabled={restriction.isDeleting}>
                {restriction.isDeleting
                    ? <span
                        className="spinner-border spinner-border-lg "></span>
                    :
                    <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>

        </>

    );

}

function mapStateToProps(state) {
    const {restrictions, affiliate, country, advertiser, status, pageNumber} = state.affiliateAdvertiserRestriction;
    return {
        restrictions,
        affiliate,
        country,
        advertiser,
        status,
        pageNumber
    };
}

export default connect(mapStateToProps)(ModalDeleteRestriction);

import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";

import EventBus from "../../common/EventBus";
import ManagerService from "../../services/manager.service";

import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ConfirmDelete, DeleteIrreversible, No,  Yes} from "../../constant";
import {useAlert} from "react-alert";
import TableReact from "react-bootstrap/Table";
import {toast} from "react-toastify";
import Title from "../common/custom-title";

const ManagersList = (props) => {


    const [managers, setManagers] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const alert = useAlert();


    useEffect(() => {
        getManagersData();
    }, [])


    const getPageUrl = (pageNumber) => {
        return `${process.env.PUBLIC_URL}/managers?page=${pageNumber}`
    }

    const deleteManager = (id) => {
        setManagers(managers.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        ManagerService.delete(id).then(() => {
            setManagers(managers => managers.filter(x => x.id !== id));
        });
    }

    const submitDeleteManager = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteManager(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    const getManagersData = (pageNumber) => {
        ManagerService.getAll(pageNumber).then(
            response => {
                if(response.data){
                    setManagers(response.data?.data);
                    setMeta(response.data?.meta);
                }

            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);

                
            }
        )
    };

    return (
        <Fragment>

            <Title title="Managers"/>
            <Breadcrumb title="Managers"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">
                                <Link to={'/managers/create'} className={'btn btn-primary'}>
                                    Create
                                </Link>
                            </div>
                            <div className="table-responsive">
                                <TableReact size={"sm"} bordered striped>
                                    <thead>
                                    <tr>
                                        <th scope="col">{"#"}</th>
                                        <th scope="col">{"Name"}</th>
                                        <th scope="col">{"Email"}</th>
                                        <th scope="col">{"Action"}</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {managers &&
                                        managers.map((manager, index) => (
                                            <tr key={index}>
                                                <td scope="row">{manager.id}</td>
                                                <td scope="row">{manager.name}</td>
                                                <td scope="row">{manager.email}</td>
                                                <td scope="row">
                                                    <div className="d-flex">
                                                        <div className="mr-3">
                                                            <button onClick={() => submitDeleteManager(manager.id)} className="btn btn-link p-0" disabled={manager.isDeleting}>
                                                                {manager.isDeleting
                                                                    ? <span className="spinner-border spinner-border-lg "></span>
                                                                    : <i className="fa fa-trash text-danger fa-2x "></i>
                                                                }
                                                            </button>
                                                        </div>
                                                        <div className="">
                                                            <Link to={`/managers/${manager.id}`}>
                                                                <i className="fa fa-pencil text-success fa-2x"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    {!managers &&
                                        <tr>
                                            <td colSpan="13" className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {managers && !managers.length &&
                                        <tr>
                                            <td colSpan="13" className="text-center">
                                                <div className="p-2">No Managers To Display</div>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </TableReact>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    {meta &&
                                        <Pagination
                                            activePage={meta.current_page}
                                            totalItemsCount={meta.total}
                                            itemsCountPerPage={meta.per_page}
                                            onChange={(pageNumber) => getManagersData(pageNumber)}
                                            getPageUrl={(pageNumber) => getPageUrl(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}



export default ManagersList;

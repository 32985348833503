import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button, Modal} from "react-bootstrap";
import ManualConversionService from "../../../services/manual-conversion.service";
import {manualConversionSearch} from "../../../actions/manual-conversion";
import Form from 'react-bootstrap/Form';
import {connect} from "react-redux";
import {toast} from "react-toastify";
import moment from "moment";

const ModalCreateManualConversion = ({ lead, dispatch, country, period,  affiliate, email}) => {
    const {register, handleSubmit, reset} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.country_iso = data.country?.iso;

        ManualConversionService.create(lead?.id, data)
            .then(
                async response => {
                    await dispatch(manualConversionSearch(country, period,  affiliate, email));
                    handleClose()

                    toast.success("Fired test deposited");
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })
    }

    return (
        <>
            <Button onClick={handleShow}>Select</Button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create Conversion"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">


                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <h5>Do you want to fire the conversion postbacks?</h5>
                                            <Form.Check
                                                type="radio"
                                                id={`is_conversion_postback_yes`}
                                                name={`is_conversion_postback`}
                                                value={1}
                                                ref={register}
                                                label={`Yes`}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`is_conversion_postback_no`}
                                                name={`is_conversion_postback`}
                                                value={0}
                                                ref={register}
                                                label={`No`}
                                            />
                                            <span className="text-danger-light">{errors?.is_conversion_postback}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <h5>Do you want to fire affiliate commissions?</h5>
                                            <Form.Check
                                                type="radio"
                                                id={`is_aff_commission_yes`}
                                                name={`is_aff_commission`}
                                                value={1}
                                                ref={register}
                                                label={`Yes`}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`is_aff_commission_no`}
                                                name={`is_aff_commission`}
                                                value={0}
                                                ref={register}
                                                label={`No`}
                                            />
                                            <span className="text-danger-light">{errors?.is_aff_commission}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <h5>Do you want to fire advertiser commissions?</h5>
                                            <Form.Check
                                                type="radio"
                                                id={`is_adv_commission_yes`}
                                                name={`is_adv_commission`}
                                                value={1}
                                                ref={register}
                                                label={`Yes`}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`is_adv_commission_no`}
                                                name={`is_adv_commission`}
                                                value={0}
                                                ref={register}
                                                label={`No`}
                                            />
                                            <span className="text-danger-light">{errors?.is_adv_commission}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="depositAmount">Deposit Amount:*</label>
                                            <input className="form-control" type="number" name="deposit_amount"
                                                   id="depositAmount" ref={register}
                                                   placeholder="$"/>
                                            <span>{errors?.deposit_amount}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="depositAmount">Deposit Data:*</label>
                                            <input className="form-control" type="datetime-local" name="deposit_date"
                                                   id="depositAmount" ref={register} max={moment().format('YYYY-MM-DD HH:mm:ss')}/>
                                            <span>{errors?.deposit_date}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{"Confirm Manual Conversion"}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


function mapStateToProps(state) {
    const { country, period,  affiliate, email} = state.manualConversion;
    return {
        country,
        period,
        affiliate,
        email
    };
}

export default connect(mapStateToProps)(ModalCreateManualConversion);

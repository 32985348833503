import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/postback-types`;

class PostbackTypeService {
    getAll() {
        return axios.get(baseUrl, {headers: authHeader()});
    }


}

export default new PostbackTypeService();
import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/countries`;

class CountryService {
    getAll(pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {headers: authHeader()});
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    getByWords(words, onlyIsoList = "", exceptIsoList = "") {
        return axios.get(`${baseUrl}`, {
            headers: authHeader(),
            params: {
                filter: {
                    words,
                    onlyIsoList,
                    exceptIsoList
                }
            }
            });
    }

}

export default new CountryService();
import React, {Fragment, useEffect, useState} from 'react';


import PostbackService from "../../../../services/postback.service";

import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../constant";
import {useAlert} from "react-alert";
import ModalCreatePostbacks from "./ModalCreatePostbacks";
import ModalEditPostbacks from "./ModalEditPostbacks";
import ModalShowPostbacks from "./ModalShowPostbacks";
import TableReact from "react-bootstrap/Table";
import {toast} from "react-toastify";

const Postbacks = ({affiliateID}) => {

    const [postbacks, setPostbacks] = useState(undefined);
    const [postbackID, setPostbackID] = useState(undefined);
    const [modalCreatePostbacks, setModalCreatePostbacks] = useState(false);
    const [modalEditPostbacks, setModalEditPostbacks] = useState(false);
    const [modalShowPostbacks, setModalShowPostbacks] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        getPostbacksData(affiliateID);
    }, [affiliateID])

    const showModalEditPostbacks = (id) => {
        setPostbackID(id);
        setModalEditPostbacks(true);
    }

    const showModalShowPostbacks = (id) => {
        setPostbackID(id);
        setModalShowPostbacks(true);
    }

    const deletePostback = (id) => {
        setPostbacks(postbacks.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        PostbackService.delete(id).then(() => {
            setPostbacks(postbacks => postbacks.filter(x => x.id !== id));
        });
    }

    const submitDeletePostback = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [{
                label: Yes, onClick: () => deletePostback(id)
            }, {
                label: No,
            }]
        });
    }

    const getPostbacksData = () => {
        PostbackService.getAll(affiliateID).then(response => {
            if (response.data) {
                setPostbacks(response.data?.data);
            }

        }, error => {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            toast.error(message);
        })
    };


    return (
        <Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card-header d-flex justify-content-end">
                        <button onClick={() => setModalCreatePostbacks(true)} className={'btn btn-primary btn-sm'}>
                            Add
                        </button>
                        <ModalCreatePostbacks
                            affiliate_id={affiliateID}
                            postbackPush={(data) => postbacks.push(data)}
                            show={modalCreatePostbacks}
                            onHide={() => setModalCreatePostbacks(false)}
                        />
                    </div>
                    <div className="table-responsive">
                        <TableReact bordered striped size={"sm"}>
                            <thead>
                                <tr>
                                    <th scope="col">{"Title"}</th>
                                    <th scope="col">{"Type"}</th>
                                    <th scope="col">{"Url"}</th>
                                    <th scope="col">{"Date of Creation"}</th>
                                    <th scope="col">{"Status"}</th>
                                    <th scope="col">{"Actions"}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {postbacks &&
                                postbacks.map((postback, index) => (
                                    <tr key={index}>
                                        <td scope="row">
                                            {postback.title}
                                        </td>
                                        <td scope="row">{postback.type.title}</td>
                                        <td scope="row">
                                            {postback.url}
                                        </td>
                                        <td scope="row">
                                            {postback.created_at}
                                        </td>
                                        <td scope="row">
                                            <span
                                                className={`badge ${postback.is_active ? "badge-success" : "badge-danger"}`}>{postback.is_active ? "Active" : "Inactive"}</span>
                                        </td>
                                        <td scope="row">
                                            <div className="d-flex">
                                                <div>
                                                    <button onClick={() => showModalShowPostbacks(postback.id)}
                                                            className="btn btn-link p-0"
                                                    >
                                                        <i className="fa fa-eye text-success fa-2x"></i>
                                                    </button>
                                                </div>
                                                <div className="ml-3">
                                                    <button onClick={() => showModalEditPostbacks(postback.id)}
                                                            className="btn btn-link p-0"
                                                    >
                                                        <i className="fa fa-pencil text-warning fa-2x"></i>
                                                    </button>
                                                </div>
                                                <div className="ml-3">
                                                    <button onClick={() => submitDeletePostback(postback.id)}
                                                            className="btn btn-link p-0"
                                                            disabled={postback.isDeleting}>
                                                        {postback.isDeleting
                                                            ? <span
                                                                className="spinner-border spinner-border-lg "></span>
                                                            : <i className="fa fa-trash text-danger fa-2x "></i>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </TableReact>
                        <ModalEditPostbacks
                            setPostbacks={(data) => setPostbacks(data)}
                            postbackID={postbackID}
                            postbacks={postbacks}
                            show={modalEditPostbacks}
                            onHide={() => setModalEditPostbacks(false)}
                        />
                        <ModalShowPostbacks
                            postbackID={postbackID}
                            postbacks={postbacks}
                            show={modalShowPostbacks}
                            onHide={() => setModalShowPostbacks(false)}
                            />
                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default Postbacks;

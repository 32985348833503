import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Create} from '../../../../../constant';
import {Button, Modal} from "react-bootstrap";
import IsoSelected from "../../../../country/IsoSelected";
import AffiliateGeoRestrictionService from "../../../../../services/affiliate.geo-restriction.service";
import {toast} from "react-toastify";

const ModalEditGeo = props => {
    const {geoRestrictions, geoRestriction} = props;
    const {register, handleSubmit, reset, control, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    
    useEffect(( ) => {
     
        if (geoRestriction) {
            const fields = [
                'country',
                'is_included',
                'limit_day',
            ];
            fields.forEach(field => setValue(field, geoRestriction[field]));
        }

    }, [show])

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.country_iso = data.country?.iso;

        AffiliateGeoRestrictionService.update(geoRestriction?.id, data)
            .then(
                response => {
                    props.setGeoRestrictions(geoRestrictions.map(x => {
                        if (x.id === geoRestriction?.id) {
                            x = response.data.data;
                        }
                        return x;
                    }));
                    handleClose()
                    toast.success("Geo Updated");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })
    }


    return (
        <>
            <button  onClick={handleShow}
                     className="btn btn-link p-0">
                <i className="fa fa-pencil text-success fa-2x"></i>
            </button>
            
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create Geo"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">
                                    <div className="col-md-10 ">
                                        <IsoSelected control={control} error={errors?.country_iso}/>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <select name="is_included" className="custom-select" id="" ref={register}>
                                                <option value="1">Included</option>
                                                <option value="0">Excluded</option>
                                            </select>
                                            <span>{errors?.is_included}</span>
                                        </div>
                                    </div>
                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="limit">Daily limit</label>
                                            <input className="form-control" type="number" name="limit_day" id={"limit"}
                                                   ref={register}
                                                   placeholder="Daily limit"/>
                                            <span>{errors?.limit_day}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


export default ModalEditGeo;

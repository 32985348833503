import React, {useState} from "react";
import StreamService from "../../../services/stream.service";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../constant";

export const ModalStreamDelete = ({advertiser, stream, streams, setStreams, setAdvertiser}) => {

    const deleteStream = (id) => {
        setStreams(streams.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        StreamService.delete(id).then(() => {
            let data = {
                ...advertiser,
                streams: streams.filter(x => x.id !== id)
            }

            setAdvertiser(data);
        })
    }

    const submitDeleteStream = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteStream(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <a onClick={() => submitDeleteStream(stream.id)}
               className="p-0"
               disabled={stream.isDeleting}>
                {stream.isDeleting
                    ?
                    <span
                        className="spinner-border spinner-border-lg "></span>
                    :
                    <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </a>
        </>
    )
}
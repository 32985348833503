import React, {
    Fragment,
    useState
} from 'react'
import TableReact from 'react-bootstrap/Table';
import {usePagination, useSortBy, useTable} from 'react-table'
import Pagination from "react-js-pagination";
import { Button, Dropdown } from 'react-bootstrap';
import styled from 'styled-components'
import ExportLead from "./ExportLead";
import ModalRegistrationHistory from "./modal/ModalRegistrationHistory";
import {connect} from "react-redux";
import { leadHistory, leadSearch, setReRegisterLeadIDs } from "../../../actions/lead";
import ModalEditLeadAffPayout from "./modal/ModalEditLeadAffPayout";
import {
    CAN_RE_REGISTER_LEADS,
    CAN_UPDATE_LEAD_ADV_PAYOUT,
    CAN_UPDATE_LEAD_AFF_PAYOUT,
    CAN_UPDATE_LEAD_IS_TEST,
} from "../../../constant/permissions";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import ModalEditLeadAdvPayout from "./modal/ModalEditLeadAdvPayout";
import ModalEditLeadIsTest from "./modal/ModalEditLeadIsTest";
import ReportColumnsService
    from "../../../services/report-columns.service";
import {getMe} from "../../../actions/auth";
import ModalReRegisterLeads from "./modal/ModalReRegisterLeads";
import {SaleStatusRaw} from "../../table/columns/SaleStatusRaw/SaleStatusRaw";
import { PaginationTitle } from "../../table/pagination/PaginationTitle";
import {useTimezoneStore} from "../../../store/timezone";
import {ConvertTimezoneDate} from "../../select/timezone/ConvertTimezoneDate";
import {AffiliatesInfo} from "../../affiliate/AffiliateInfo";
import {ManagerInfo} from "../../manager/ManagerInfo";
import {useMutation} from "@tanstack/react-query";
import LeadService from "../../../services/lead.service";
import {toast} from "react-toastify";

const Styles = styled.div`
  .onhover-show-div {
    top:50px
  }
`

export const IndeterminateCheckbox = React.forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
})

const Table = ({columns, data, changeData, leads, reRegisterLeadIDs, meta, country, period, affiliate, advertiser, filtering, periodTime, dispatch, user, manager, history, pageSize: pS, pageNumber: pN}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        allColumns,
        getToggleHideAllColumnsProps,
    } = useTable({
        columns, data, initialState: {pageIndex: 0,  pageSize: meta?.per_page, hiddenColumns: user?.data?.selectedColumns.filter(el => el.type === 'Leads').map(el => {
                return el.title
            })},
    }, useSortBy, usePagination)

    const [selectedPageSize, setSelectedPageSize] = useState(meta?.per_page);
    const [showModalReRegisterLeads, setShowModalReRegisterLeads] = useState(false);
    const {timezone} = useTimezoneStore()

    const {mutate: leadIsTest} = useMutation({
        mutationFn: LeadService.updateIsTest,
        onSuccess: async () => {
            toast.success('Lead type changed')
            await dispatch(leadSearch(manager, country, period, periodTime, affiliate, advertiser, pN, filtering, pS, timezone));
        },
        onError: (error) => toast.error((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
    })

    const setPageSizeRedux = (pageSize) => {
        setSelectedPageSize(pageSize)
        setPageSize(pageSize)
        dispatch(leadSearch(manager, country, period, periodTime, affiliate, advertiser, 1, filtering, pageSize, timezone))
    }

    const setColumns = async (column) => {
        ReportColumnsService.toggleSelectedColumn(column.id_num).then(async res => {
            dispatch(await getMe())
        })
    }

    const toggleAll = (e) => {
        let ids = allColumns.filter(el => el.id_num && !el.isVisible).map(el => el.id_num)
        if (!e.target.checked){
            ids = allColumns.filter(el => el.id_num).map(el => el.id_num)
        }
        ReportColumnsService.toggleAll(ids).then(async res => {
            dispatch(await getMe())
        })
    }

    const handleCheckReRegisterLeadIDs = (lead_id) => {
        var updatedReRegisterLeadIDs = [...reRegisterLeadIDs];
        if (!updatedReRegisterLeadIDs.includes(lead_id)) {
            updatedReRegisterLeadIDs.push(lead_id);
        } else {
            updatedReRegisterLeadIDs.splice(updatedReRegisterLeadIDs.indexOf(lead_id), 1);
        }
        dispatch(setReRegisterLeadIDs(updatedReRegisterLeadIDs));
    };

    const handleIndeterminateCheckReRegisterLeadIDs = (event) => {
        var updatedList = [...reRegisterLeadIDs];
        if (event.target.checked) {
            updatedList = leads.map(lead => lead.id);
        } else {
            updatedList = [];
        }
        dispatch(setReRegisterLeadIDs(updatedList));
    };

    const compare =  (a1, a2) => {
        return a1.length !== 0 && a1.length === a2.length
    }

    return (
        <>

            <Styles>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between m-0 ">
                            <div className="d-flex">
                                <div className="">
                                    {
                                        data && data.length ?
                                            <ExportLead columns={allColumns}/> : <></>
                                    }
                                </div>
                                <ShowForPermission
                                    permission={[CAN_RE_REGISTER_LEADS, CAN_UPDATE_LEAD_IS_TEST]}>

                                {reRegisterLeadIDs?.length > 0 && (
                                    <div className="ml-2">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-selected-lead" >
                                                Selected Leads: {reRegisterLeadIDs.length}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <ShowForPermission
                                                    permission={CAN_RE_REGISTER_LEADS}>
                                                    <Dropdown.Item onClick={() => setShowModalReRegisterLeads(true)}>Re-register</Dropdown.Item>
                                                </ShowForPermission>
                                                <ShowForPermission
                                                    permission={CAN_UPDATE_LEAD_IS_TEST}>
                                                    <Dropdown.Item onClick={() => leadIsTest({
                                                        is_test: '1',
                                                        leads: reRegisterLeadIDs
                                                    })}>Mark As Test</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => leadIsTest({
                                                        is_test: '0',
                                                        leads: reRegisterLeadIDs
                                                    })}>Mark As Real</Dropdown.Item>
                                                </ShowForPermission>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <ModalReRegisterLeads
                                            show={showModalReRegisterLeads}
                                            onHide={() => setShowModalReRegisterLeads(false)}
                                        />
                                    </div>
                                )}
                                </ShowForPermission>
                            </div>


                            <div className='row'>


                                <div className='onhover-dropdown'>
                                    <Button className="dropbtn" color={'primary'}>
                                        Visible columns
                                        <span><i className="icofont icofont-arrow-down"></i></span>
                                    </Button>


                                    <ul className="onhover-show-div p-20 top-50">
                                        <li>
                                            <label className="d-flex">
                                                <IndeterminateCheckbox onClick={(e) => toggleAll(e)} {...getToggleHideAllColumnsProps()} />&nbsp;
                                                <span>Toggle&nbsp;All</span>
                                            </label>
                                        </li>
                                        {allColumns.map(column => (
                                            <li key={column.id}>
                                                <label>
                                                    <input onClick={() => {setColumns(column)}} type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                                    {column.Header}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="pagination ml-2">
                                    <select className="custom-select"
                                            value={meta?.per_page}
                                            onChange={e => {
                                                setPageSizeRedux(Number(e.target.value))
                                            }}
                                    >
                                        {[5, 10, 15, 25, 50, 100, 200].map(pageSize => (<option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>))}
                                    </select>
                                </div>
                            </div>

                        </div>
                        {(meta && data.length) ? (
                            <div className="mt-4">
                                <PaginationTitle
                                    total={meta.total}
                                    from={meta?.from ?? 0}
                                    to={meta?.to ?? 0}
                                />
                            </div>
                        ) : <></>}
                    </div>

                    <div className="">
                        <div className="table-responsive">

                            <TableReact bordered striped size="sm" {...getTableProps()}>
                                <thead>
                                {headerGroups.map(headerGroup => (<tr {...headerGroup.getHeaderGroupProps()}>
                                    {/*-------- START ReRegisterLeads --------*/}

                                    <ShowForPermission
                                        permission={CAN_RE_REGISTER_LEADS}>
                                    <th>
                                        <input  type="checkbox" onChange={handleIndeterminateCheckReRegisterLeadIDs} checked={compare(reRegisterLeadIDs, leads.map(lead=>lead.id))}/>
                                    </th>
                                    </ShowForPermission>

                                    {/*-------- END ReRegisterLeads --------*/}

                                    {headerGroup.headers.map(column => (// Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <br/>
                                            <span> &nbsp;
                                                {column.isSorted ? column.isSortedDesc ?
                                                    <i className="fa fa-chevron-down"></i> :
                                                    <i className="fa fa-chevron-up"></i> : ''}
                                        </span>
                                        </th>))}
                                </tr>))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (<tr {...row.getRowProps()}>
                                        {/*-------- START ReRegisterLeads --------*/}
                                        <ShowForPermission
                                            permission={CAN_RE_REGISTER_LEADS}>
                                        <td>
                                            <input value={row.original.id} type="checkbox" onChange={() => handleCheckReRegisterLeadIDs(row.original.id)} checked={reRegisterLeadIDs?.includes(row.original.id)}/>
                                        </td>
                                        </ShowForPermission>
                                        {/*-------- END ReRegisterLeads --------*/}
                                        {row.cells.map((cell, index) => {
                                            return (<td {...cell.getCellProps()}>
                                                {cell.column.id == "affiliate" ? (
                                                    <AffiliatesInfo affiliate={cell.row.original?.affiliate} />
                                                )  : (cell.column.id === "affiliate_manager" && cell.row.original.affiliate?.manager) ? (
                                                    <ManagerInfo manager={cell.row.original.affiliate?.manager} />
                                                ) : cell.column.id == "is_conversions" || cell.column.id == "is_smart_click" || cell.column.id == "live_lead" || cell.column.id === "is_pending" ? (
                                                        cell.value ? "Yes" : "No"
                                                    )
                                                        : cell.column.id == "failed_registration_count" ? (
                                                                cell.value && cell.value !== 0 ? cell.value : ''
                                                            )

                                                        : cell.column.id == "details" ? (
                                                                cell.value &&
                                                                Object.keys(cell.value).map((item, i) => (
                                                                    <Fragment key={i}>
                                                                        <span><nobr>{item}: {cell.value[item]}</nobr></span><br/>
                                                                    </Fragment>
                                                                ))

                                                            ) : cell.column.id == 'advertiser_payout' ? (
                                                                <>
                                                                    <div>
                                                                        {Number(cell.value)}
                                                                    </div>

                                                                    {cell.row.original?.is_test !== 1 && (
                                                                        <ShowForPermission permission={CAN_UPDATE_LEAD_ADV_PAYOUT}>

                                                                            <ModalEditLeadAdvPayout
                                                                                lead={cell.row.original}
                                                                            />
                                                                        </ShowForPermission>
                                                                    )}

                                                                </>

                                                            ): cell.column.id == 'affiliate_payout' ? (<>
                                                                <div>
                                                                    {Number(cell.value)}
                                                                </div>

                                                                {cell.row.original?.is_test !== 1 && (
                                                                    <ShowForPermission permission={CAN_UPDATE_LEAD_AFF_PAYOUT}>

                                                                        <ModalEditLeadAffPayout
                                                                            lead={cell.row.original}
                                                                        />
                                                                    </ShowForPermission>
                                                                )}

                                                            </>

                                                        ) : cell.column.id == 'btns' ? (<>
                                                                <div className="d-flex justify-content-end">

                                                                    <ModalRegistrationHistory
                                                                        lead={cell.row.original}
                                                                    />

                                                                    <ShowForPermission permission={CAN_UPDATE_LEAD_IS_TEST}>

                                                                        <ModalEditLeadIsTest
                                                                            lead={cell.row.original}
                                                                        />
                                                                    </ShowForPermission>
                                                                </div>
                                                            </>

                                                        ) : cell.column.id === 'sale_status_raw' ? (
                                                            <SaleStatusRaw
                                                                cell={cell}
                                                                history={history}
                                                                index={index}
                                                                dispatchFn={async () => await dispatch(leadHistory(cell.row.values.id))}
                                                            />
                                                        ) : ['actual_conversion_date', 'conversion_date', 'signup_date'].indexOf(cell.column.id)  > -1 ? (
                                                            <ConvertTimezoneDate
                                                                date={cell.value}
                                                            />
                                                        ) : (cell.render('Cell'))}

                                            </td>)
                                        })}
                                    </tr>)
                                })}
                                </tbody>
                            </TableReact>
                        </div>
                    </div>
                    <div className="card-footer">
                        {(meta && data.length) ? (
                            <PaginationTitle
                                total={meta.total}
                                from={meta?.from ?? 0}
                                to={meta?.to ?? 0}
                            />
                        ) : <></>}
                        {(meta && meta?.last_page > 1) && (
                            <div className="d-flex  justify-content-center mt-2">
                                <Pagination
                                    activePage={meta?.current_page}
                                    totalItemsCount={meta?.total}
                                    itemsCountPerPage={Number(meta?.per_page)}
                                    onChange={(pageNumber) => dispatch(leadSearch(manager, country, period, periodTime, affiliate, advertiser, pageNumber, filtering, selectedPageSize, timezone))}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        )}
                    </div>

                </div>

            </Styles>
        </>
    )
}

function mapStateToProps(state) {
    const {leads, meta, country, period, affiliate, advertiser, filtering, periodTime, reRegisterLeadIDs, manager, history, pageSize, pageNumber} = state.lead;
    const {user} = state.auth
    return {
        user, leads, meta, country, period, affiliate, filtering, periodTime, advertiser, reRegisterLeadIDs, manager, history, pageSize, pageNumber
    };
}

export default connect(mapStateToProps)(Table);

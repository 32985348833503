import React, {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {EditAdmin, EmailAddress, Name, NewPassword, UpdateAdmin} from '../../constant';
import Breadcrumb from "../common/breadcrumb";

import AdminService from "../../services/admin.service";

import EventBus from "../../common/EventBus";

import {useAlert} from "react-alert";
import {toast} from "react-toastify";
import Title from "../common/custom-title";

const AdminsEdit = ({ history, match}) => {
    const {id} = match.params;
    const {register, handleSubmit, reset, setValue} = useForm();
    const alert = useAlert();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const [admin, setAdmin] = useState(undefined);
    const [role, setRole] = useState(undefined);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        AdminService.update(id, data)
            .then(
                response => {

                    toast.success('Admin updated');
                    history.push('.');

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })


    }

    useEffect(() => {


        AdminService.getById(id).then((res) => {
            let admin = res.data.data;
            setAdmin(admin);
            let role = admin.roles.filter(el => el !== 'admin_jr');
            if (!role.length)
                setRole('admin_jr')
            else
                setRole(role[0])
            const fields = [
                'name',
                'email',
            ];
            fields.forEach(field => setValue(field, admin[field]));
        });
    }, []);

    return (
        <Fragment>
            <Title title="Edit Admin"/>
            <Breadcrumb parent="Admins" title="Edit Admin"/>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row justify-content-center">

                        <div className="col-md-10">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{EditAdmin}</h4>
                                </div>
                                <div className="card-body">

                                    <div className="form-row">

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input className="form-control" type="text" name="name"
                                                       ref={register}
                                                       placeholder={Name}/>
                                                <span>{errors?.name}</span>
                                            </div>
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input className="form-control" type="text" name="email"
                                                       ref={register} autoComplete="off"
                                                       placeholder={EmailAddress}/>
                                                <span>{errors?.email}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{NewPassword}</label>
                                                <input className="form-control" type="password" name="password"
                                                       ref={register} autoComplete="off"
                                                       placeholder={NewPassword}/>
                                                <span>{errors?.password}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Role</label>
                                                <select className="form-control" name={"role"}  ref={register}>
                                                    <option selected={role === 'admin'} value={"admin"}>Admin</option>
                                                    <option selected={role === 'admin_jr'} value={"admin_jr"}>Junior Admin</option>
                                                    <option selected={role === 'tech_admin'} value={"tech_admin"}>Tech Admin</option>
                                                </select>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                        {UpdateAdmin}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default AdminsEdit;

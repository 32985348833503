import axios from 'axios';
import authHeader from "./auth-header";

class AuthService {
    authenticated() {
        return !!localStorage.getItem('token');
    }
    login(email, password) {
        let baseUrl = ''

        let url = window.location.href
        let arr = {
            manager: process.env.REACT_APP_SERVER_MANAGER,
            admin: process.env.REACT_APP_SERVER_ADMIN,
            affiliate: process.env.REACT_APP_SERVER_AFFILIATE
        }
        for(let obj in arr){
            if (url.includes(obj))
                baseUrl += arr[obj]
        }
        return axios
            .post(baseUrl + '/login', { email, password })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('loginUrl', url);
                    localStorage.setItem('url', response.data.url)
                    localStorage.setItem('token', response.data.token);
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("admin_token");
        localStorage.removeItem("admin_url");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("url");
    }

    getMe() {
        let baseUrl = localStorage.getItem('url')
        return axios.get( baseUrl + '/auth/me',{ headers: authHeader() })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    impersonate(id){
        let baseUrl = localStorage.getItem('url')
        return axios
            .get(baseUrl + `/auth/impersonate/${id}`, { headers: authHeader() })
            .then((response) => {
                if (response.data.token) {
                    let admin_token = localStorage.getItem('token')
                    let admin_url = localStorage.getItem('url')
                    localStorage.setItem('admin_token', admin_token);
                    localStorage.setItem('admin_url', admin_url);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('url', '/api/affiliate/v0');
                }

                return response.data;
            }).catch(err => {
                return Promise.reject(err)
            })
    }
}

export default new AuthService();

import React, {Fragment} from 'react';
import man from '../../../../../assets/images/dashboard/user.png';
import {LogOut, User} from 'react-feather';
import {withRouter} from "react-router";
import {
    getMe,
    logout
} from "../../../../../actions/auth";
import EventBus from "../../../../../common/EventBus";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";

const UserMenu = (props) => {
    const Logout = () => {
        EventBus.dispatch("logout");
    }

    const {dispatch, history} = props



    const admin_key = localStorage.getItem('admin_token')

    const exitImpersonate = async () => {
        let admin_url = localStorage.getItem('admin_url')
        localStorage.removeItem('admin_token')
        localStorage.removeItem('admin_url')
        localStorage.setItem('token', admin_key);
        localStorage.setItem('url', admin_url);

        await history.push("/dashboard");
        await window.location.reload();


    }

    return (
        <Fragment>
            <li className="m-0 d-flex justify-content-end">
                <ul className="p-20">
                    {admin_key &&  <li>
                        <li className="mr-3"><a onClick={exitImpersonate} href="#"><LogOut/> Return to Admin</a></li>
                    </li>}
                    <li >
                        <Link to={'/profile'} >
                            <User/> Edit Profile
                        </Link>
                    </li>
                    <li className="ml-3"><a onClick={Logout} href="#"><LogOut/> Log out</a></li>
                </ul >
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man}
                         alt="header-user"/>

                </div>

            </li>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}


export default compose(withRouter, connect(mapStateToProps))(UserMenu);
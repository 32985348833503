export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_FAIL = "GET_ME_FAIL";
export const LOGOUT = "LOGOUT";
export const STREAM_FILTER_FAIL = "STREAM_FILTER_FAIL";
export const STREAM_FILTER_SUCCESS = "STREAM_FILTER_SUCCESS";
export const SET_STREAM_FILTER_SUCCESS = "SET_STREAM_FILTER_SUCCESS";
export const SET_PERIOD_SUCCESS = "SET_PERIOD_SUCCESS";
export const BUCKET_SUCCESS = "BUCKET_SUCCESS";
export const BUCKET_FAIL = "BUCKET_FAIL";
export const DASHBOARD_FILTER_FAIL = "DASHBOARD_FILTER_FAIL";
export const DASHBOARD_FILTER_SUCCESS = "DASHBOARD_FILTER_SUCCESS";
export const SET_DASHBOARD_PERIOD_SUCCESS = "SET_DASHBOARD_PERIOD_SUCCESS";
export const SET_DASHBOARD_FILTERING_SUCCESS = "SET_DASHBOARD_FILTERING_SUCCESS";
export const SET_DASHBOARD_AFFILIATE_SUCCESS = "SET_DASHBOARD_AFFILIATE_SUCCESS";
export const SET_DASHBOARD_ADVERTISER_SUCCESS = "SET_DASHBOARD_ADVERTISER_SUCCESS";
export const SET_DASHBOARD_COUNTRY_SUCCESS = "SET_DASHBOARD_COUNTRY_SUCCESS";
export const SET_LEAD_PERIOD_SUCCESS = "SET_LEAD_PERIOD_SUCCESS";
export const SET_LEAD_SUCCESS = "SET_LEAD_SUCCESS";
export const SET_RE_REGISTER_LEAD_IDS_SUCCESS = "SET_RE_REGISTER_LEAD_IDS_SUCCESS";
export const LEAD_FILTER_SUCCESS = "LEAD_FILTER_SUCCESS";
export const LEAD_FILTER_FAIL = "LEAD_FILTER_FAIL";
export const SET_LEAD_FILTERING_SUCCESS = "SET_LEAD_FILTERING_SUCCESS";
export const SET_LEAD_AFFILIATE_SUCCESS = "SET_LEAD_AFFILIATE_SUCCESS";
export const SET_LEAD_ADVERTISER_SUCCESS = "SET_LEAD_ADVERTISER_SUCCESS";
export const SET_LEAD_MANAGER_SUCCESS = "SET_LEAD_MANAGER_SUCCESS";
export const SET_LEAD_COUNTRY_SUCCESS = "SET_LEAD_COUNTRY_SUCCESS";
export const SET_LEAD_PERIODTIME_SUCCESS = "SET_LEAD_PERIODTIME_SUCCESS";
export const SET_LEAD_HISTORY_SUCCESS = "SET_LEAD_HISTORY_SUCCESS";
export const SET_CONVERSION_PERIOD_SUCCESS = "SET_CONVERSION_PERIOD_SUCCESS";
export const SET_CONVERSION_SUCCESS = "SET_CONVERSION_SUCCESS";
export const CONVERSION_FILTER_SUCCESS = "CONVERSION_FILTER_SUCCESS";
export const CONVERSION_FILTER_FAIL = "CONVERSION_FILTER_FAIL";
export const SET_CONVERSION_FILTERING_SUCCESS = "SET_CONVERSION_FILTERING_SUCCESS";
export const SET_CONVERSION_AFFILIATE_SUCCESS = "SET_CONVERSION_AFFILIATE_SUCCESS";
export const SET_CONVERSION_MANAGER_SUCCESS = "SET_CONVERSION_MANAGER_SUCCESS";
export const SET_CONVERSION_ADVERTISER_SUCCESS = "SET_CONVERSION_ADVERTISER_SUCCESS";
export const SET_CONVERSION_COUNTRY_SUCCESS = "SET_CONVERSION_COUNTRY_SUCCESS";
export const SET_CONVERSION_PERIODTIME_SUCCESS = "SET_CONVERSION_PERIODTIME_SUCCESS";
export const SET_CONVERSION_HISTORY_SUCCESS = "SET_CONVERSION_HISTORY_SUCCESS";
export const API_REQUEST_FILTER_FAIL = "API_REQUEST_FILTER_FAIL";
export const API_REQUEST_FILTER_SUCCESS = "API_REQUEST_FILTER_SUCCESS";
export const SET_API_REQUEST_PERIOD_SUCCESS = "SET_API_REQUEST_PERIOD_SUCCESS";
export const SET_API_REQUEST_RE_REGISTER_IDS_SUCCESS = "SET_API_REQUEST_RE_REGISTER_IDS_SUCCESS";
export const OPTIMIZE_FILTER_SUCCESS = "OPTIMIZE_FILTER_SUCCESS";
export const OPTIMIZE_FILTER_FAIL = "OPTIMIZE_FILTER_FAIL";
export const SET_OPTIMIZE_SUCCESS = "SET_OPTIMIZE_SUCCESS";
export const MODERATION_FILTER_SUCCESS = "MODERATION_FILTER_SUCCESS";
export const MODERATION_FILTER_FAIL = "MODERATION_FILTER_FAIL";
export const SET_MODERATION_SUCCESS = "SET_MODERATION_SUCCESS";
export const SET_MODERATION_PERIOD_SUCCESS = "SET_MODERATION_PERIOD_SUCCESS";
export const DAILY_REPORT_FILTER_FAIL = "DAILY_REPORT_FILTER_FAIL";
export const DAILY_REPORT_FILTER_SUCCESS = "DAILY_REPORT_FILTER_SUCCESS";
export const SET_DAILY_REPORT_PERIOD_SUCCESS = "SET_DAILY_REPORT_PERIOD_SUCCESS";
export const SET_DAILY_REPORT_AFFILIATE_SUCCESS = "SET_DAILY_REPORT_AFFILIATE_SUCCESS";
export const SET_DAILY_REPORT_ADVERTISER_SUCCESS = "SET_DAILY_REPORT_ADVERTISER_SUCCESS";
export const SET_DAILY_REPORT_MANAGER_SUCCESS = "SET_DAILY_REPORT_MANAGER_SUCCESS";
export const SET_DAILY_REPORT_COUNTRY_SUCCESS = "SET_DAILY_REPORT_COUNTRY_SUCCESS";
export const SET_AFFILIATE_PERFORMANCE_REPORT_PERIOD_SUCCESS = "SET_AFFILIATE_PERFORMANCE_REPORT_PERIOD_SUCCESS";
export const SET_AFFILIATE_PERFORMANCE_REPORT_AFFILIATE_SUCCESS = "SET_AFFILIATE_PERFORMANCE_REPORT_AFFILIATE_SUCCESS";
export const SET_AFFILIATE_PERFORMANCE_REPORT_MANAGER_SUCCESS = "SET_AFFILIATE_PERFORMANCE_REPORT_MANAGER_SUCCESS";
export const SET_AFFILIATE_PERFORMANCE_REPORT_ADVERTISER_SUCCESS = "SET_AFFILIATE_PERFORMANCE_REPORT_ADVERTISER_SUCCESS";
export const SET_AFFILIATE_PERFORMANCE_REPORT_COUNTRY_SUCCESS = "SET_AFFILIATE_PERFORMANCE_REPORT_COUNTRY_SUCCESS";
export const AFFILIATE_PERFORMANCE_REPORT_FILTER_FAIL = "AFFILIATE_PERFORMANCE_REPORT_FILTER_FAIL";
export const AFFILIATE_PERFORMANCE_REPORT_FILTER_SUCCESS = "AFFILIATE_PERFORMANCE_REPORT_FILTER_SUCCESS";
export const SET_ADVERTISER_PERFORMANCE_REPORT_ADVERTISER_SUCCESS = "SET_ADVERTISER_PERFORMANCE_REPORT_ADVERTISER_SUCCESS";
export const SET_ADVERTISER_PERFORMANCE_REPORT_COUNTRY_SUCCESS = "SET_ADVERTISER_PERFORMANCE_REPORT_COUNTRY_SUCCESS";
export const SET_ADVERTISER_PERFORMANCE_REPORT_AFFILIATE_SUCCESS = "SET_ADVERTISER_PERFORMANCE_REPORT_AFFILIATE_SUCCESS";
export const SET_ADVERTISER_PERFORMANCE_REPORT_MANAGER_SUCCESS = "SET_ADVERTISER_PERFORMANCE_REPORT_MANAGER_SUCCESS";
export const SET_ADVERTISER_PERFORMANCE_REPORT_PERIOD_SUCCESS = "SET_ADVERTISER_PERFORMANCE_REPORT_PERIOD_SUCCESS";
export const ADVERTISER_PERFORMANCE_REPORT_FILTER_FAIL = "ADVERTISER_PERFORMANCE_REPORT_FILTER_FAIL";
export const ADVERTISER_PERFORMANCE_REPORT_FILTER_SUCCESS = "ADVERTISER_PERFORMANCE_REPORT_FILTER_SUCCESS";
export const MANUAL_CONVERSION_FILTER_FAIL = "MANUAL_CONVERSION_FILTER_FAIL";
export const MANUAL_CONVERSION_FILTER_SUCCESS = "MANUAL_CONVERSION_FILTER_SUCCESS";
export const SET_MANUAL_CONVERSION_PERIOD_SUCCESS = "SET_MANUAL_CONVERSION_PERIOD_SUCCESS";
export const SET_MANUAL_CONVERSION_LEADS_SUCCESS = "SET_MANUAL_CONVERSION_LEADS_SUCCESS";
export const STREAM_AFFILIATE_SUCCESS = "STREAM_AFFILIATE_SUCCESS";
export const STREAM_AFFILIATE_FAIL = "STREAM_AFFILIATE_FAIL";
export const RESTRICTION_FILTER_FAIL = "RESTRICTION_FILTER_FAIL";
export const RESTRICTION_FILTER_SUCCESS = "RESTRICTION_FILTER_SUCCESS";
export const SET_RESTRICTION_SUCCESS = "SET_RESTRICTION_SUCCESS";
export const MODERATION_PENDING_COUNT_SUCCESS = "MODERATION_PENDING_COUNT_SUCCESS";
export const MODERATION_PENDING_COUNT_FAIL = "MODERATION_PENDING_COUNT_FAIL";
export const SET_MENU_SUCCESS = "SET_MENU_SUCCESS";

export const INVOICE_FILTER_FAIL = "INVOICE_FILTER_FAIL";
export const INVOICE_FILTER_SUCCESS = "INVOICE_FILTER_SUCCESS";
export const SET_INVOICE_PERIOD_SUCCESS = "SET_INVOICE_PERIOD_SUCCESS";
export const SET_INVOICE_SUCCESS = "SET_INVOICE_SUCCESS";

import {
    API_REQUEST_FILTER_FAIL,
    API_REQUEST_FILTER_SUCCESS,
    SET_API_REQUEST_PERIOD_SUCCESS, SET_API_REQUEST_RE_REGISTER_IDS_SUCCESS,
} from "../actions/types";
import moment from "moment/moment";

const initialState = {
    apiRequests: null,
    meta: null,
    country: null,
    manager: null,
    affiliate: null,
    advertiser: null,
    reRegisterIDs: [],
    filtering: {},
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },
    pageSize: 25
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case API_REQUEST_FILTER_SUCCESS:
            return {
                ...state,
                apiRequests: payload.apiRequests,
                meta: payload.meta,
                country: payload.country,
                manager: payload.manager,
                affiliate: payload.affiliate,
                advertiser: payload.advertiser,
                period: payload.period,
                filtering: payload.filtering,
                reRegisterIDs: [],
                pageSize: payload.pageSize
            };

        case SET_API_REQUEST_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };


        case API_REQUEST_FILTER_FAIL:
            return {
                ...state,
                apiRequests: null,
                meta: null,
                country: null,
                affiliate: null,
                advertiser: null,
                manager: null,
                filtering: {},
                period: {},
                reRegisterIDs: [],

            };

        case SET_API_REQUEST_RE_REGISTER_IDS_SUCCESS:
            return {
                ...state,
                reRegisterIDs: payload.reRegisterIDs,

            };


        default:
            return state;
    }
}

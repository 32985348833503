import ReportTableExpanded from "../table/report/ReportTableExpanded";
import React, {useMemo} from "react";

const ImportLeadTable = ({row}) => {
    const columns = useMemo(() => [
        {
            header: () => 'Lead data',
            id: 'lead_data',
            Cell: ({row}) => (
                <>
                    <div>First name: {row.original.lead_data.first_name}</div>
                    <div>Last name: {row.original.lead_data.last_name}</div>
                    <div>Email: {row.original.lead_data.email}</div>
                    <div>Phone: {row.original.lead_data.phone}</div>
                    <div>IP: {row.original.lead_data.ip}</div>
                    <div>Country ISO: {row.original.lead_data.country_iso}</div>
                    <div>Locale: {row.original.lead_data.locale}</div>
                    <div>Custom 1: {row.original.lead_data.custom1}</div>
                    <div>Custom 2: {row.original.lead_data.custom2}</div>
                    <div>Custom 3: {row.original.lead_data.custom3}</div>
                    <div>Custom 4: {row.original.lead_data.custom4}</div>
                    <div>Custom 5: {row.original.lead_data.custom5}</div>
                    <div>Offer name: {row.original.lead_data.offer_name}</div>
                    <div>Offer website: {row.original.lead_data.offer_website}</div>
                    <div>Comment: {row.original.lead_data.comment}</div>
                </>
            ),
            width: 50,
        },
        {
            header: 'Status',
            accessor: 'success',
            Cell: ({row}) => (
                <span>{row.original.success ? 'Success' : 'Failed'}</span>
            )
        },
        {
            header: 'Errors',
            accessor: 'errors',
            Cell: ({row}) => (
                row.original.errors && <code style={{width: '200px'}}>{JSON.stringify(row.original.errors)}</code>
            ),
            width: 100
        },
        {
            header: 'Lead ID',
            accessor: 'lead_id',
            Cell: ({row}) => (
                <span>{row.original.lead_id}</span>
            )
        }
    ], [])

    return <ReportTableExpanded
        data={row}
        columns={[]}
        appendHeaders={columns}
    />
}

export default ImportLeadTable
import {RESTRICTION_FILTER_FAIL, RESTRICTION_FILTER_SUCCESS, SET_RESTRICTION_SUCCESS} from "./types";
import RestrictionService from "../services/affiliate/advertiser-restriction.service";


export const restrictionSearch = (country, affiliate, advertiser, status, pageNumber) => (dispatch) => {
    return RestrictionService.getFilter(country?.iso,  affiliate?.id,  advertiser?.id, status, pageNumber).then(
        (response) => {
            dispatch({
                type: RESTRICTION_FILTER_SUCCESS,
                payload: {
                    restrictions: response.data.data,
                    meta: response.data.meta,
                    country: country,
                    affiliate: affiliate,
                    advertiser: advertiser,
                    status: status,
                    pageNumber: pageNumber
                },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: RESTRICTION_FILTER_FAIL,
            });
            


            return Promise.reject();
        }
    );
};

export const setRestrictions = (restrictions) => (dispatch) => {
    dispatch({
        type: SET_RESTRICTION_SUCCESS,
        payload: {
            restrictions: restrictions,
        },
    });

    return Promise.resolve();
}



import {
    MANUAL_CONVERSION_FILTER_FAIL,
    MANUAL_CONVERSION_FILTER_SUCCESS, SET_MANUAL_CONVERSION_LEADS_SUCCESS,
    SET_MANUAL_CONVERSION_PERIOD_SUCCESS,
} from "./types";
import ManualConversionService from "../services/manual-conversion.service";
import { refreshPeriod } from "../utils/period/period";


export const manualConversionSearch = (country, period, affiliate, email, pageNumber) => (dispatch) => {
    const updatedPeriod = refreshPeriod(period)
    return ManualConversionService.getFilter(
        country?.iso,
        updatedPeriod,
        affiliate?.id,
        email.length ? email.split(',') : undefined,
        pageNumber
    ).then(
        (response) => {

            dispatch({
                type: MANUAL_CONVERSION_FILTER_SUCCESS,
                payload: {
                    leads: response.data.data,
                    meta: response.data.meta,
                    country: country,
                    affiliate: affiliate,
                    email: email,
                    period: updatedPeriod,
                },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: MANUAL_CONVERSION_FILTER_FAIL,
            });




            return Promise.reject();
        }
    );
};
export const setManualConversionPeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_MANUAL_CONVERSION_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}
export const setManualConversionLeads = (leads) => (dispatch) => {
    dispatch({
        type: SET_MANUAL_CONVERSION_LEADS_SUCCESS,
        payload: {
            leads: leads,
        },
    });

    return Promise.resolve();
}


import MakeAnimated from "react-select/animated";
import AdvertiserService from "../../../../services/advertiser.service";
import React, {Fragment} from "react";
import {Controller} from "react-hook-form";
import AsyncSelect from "react-select/async";

const AdvertiserPlatformSelect = ({control, error, label = true, ...props}) => {
    const fetchData = () => {
        return AdvertiserService.getPlatform().then(result => {
            return result.data.data;
        });
    }

    return (
        <Fragment>
            <div className="form-group">
                {label && (
                    <label className="form-label">Advertiser Platform</label>
                )}

                <Controller
                    {...props}
                    as={AsyncSelect}
                    defaultValue={null}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.name}
                    placeholder={"Advertiser Platform"}
                    defaultOptions
                    componets={MakeAnimated()}
                    cacheOptions
                    name="platform"
                    isClearable
                    control={control}
                />
                <span className={'text-danger-light'}>{error}</span>

            </div>
        </Fragment>

    );
}

export default AdvertiserPlatformSelect
import React, {useState} from 'react';
import { useForm} from 'react-hook-form';
import {
    Create
} from '../../../../constant';
import {Button, Modal} from "react-bootstrap";
import IsoSelected from "../../../country/IsoSelected";
import CommissionService from "../../../../services/advertiser/commission.service";
import MakeAnimated from "react-select/animated";
import { toast } from 'react-toastify';
import AffiliatesSelected from "../../../affiliate/AffiliatesSelected";

const ModalCreateCommission = ({advertiserID, getCommissionsData}) => {
    const {register, handleSubmit, reset, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.country_iso = data.country?.iso;
        data.affiliate_id = data.affiliate?.id;

        CommissionService.create(advertiserID, data)
            .then(
                async response => {
                    await getCommissionsData(1, {});

                    handleClose()
                    toast.success("Advertiser Commission Created");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);
                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }
                })
            .finally(() => {
                setLoading(false);

            })
    }

    return (
        <>
            <button className="btn btn-primary btn-sm"
                    onClick={handleShow}
            >
                Add Commission
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Advertiser Commission
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">

                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="event">Event:</label>
                                            <select name="event" className="custom-select" id="event" ref={register}>
                                                <option value="1" selected >First Conversion</option>
                                                <option value="2">Lead</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="amount">Amount:</label>
                                            <div className="input-group">
                                                <input className="form-control" type="number" min={0} name="amount" id="amount"
                                                       ref={register}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">{"$"}</span>
                                                </div>
                                            </div>

                                            <span className={"text-danger-light"}>{errors?.amount}</span>

                                        </div>
                                    </div>

                                    <div className="col-md-10 ">
                                        <AffiliatesSelected control={control} error={errors?.affiliate_id} showTitle={true} />
                                    </div>

                                    <div className="col-md-10 ">
                                        <IsoSelected control={control} error={errors?.country_iso} showTitle={true} />
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );

}


export default ModalCreateCommission;

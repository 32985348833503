import React, {Fragment, useState} from 'react';
import AsyncSelect from 'react-select/async';
import MakeAnimated from 'react-select/animated';
import {Controller} from "react-hook-form";
import AffiliateService from "../../services/affiliate.service";
import {connect} from "react-redux";

function AffiliatesSelected({control, error, user, showTitle = false, rules = {}}) {
    const animatedComponent = MakeAnimated()

    const isManager = user?.data?.roles?.includes('manager')

    const fetchData = (inputValue) => {
        if (isManager) {
            return AffiliateService.getByWordsManager(inputValue).then(result => {
                return result.data.data;
            });
        }
        else
            return AffiliateService.getByWords(inputValue).then(result => {
                return result.data.data;
            });
    }

    return (
        <Fragment>
            <div className="form-group">
                { showTitle && (
                    <label className="form-label">{"Affiliate:"}</label>
                )}

                <Controller
                    as={AsyncSelect}
                    defaultValue={null}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.name + ' - ' + e.email }
                    getOptionValue={e => e.id}
                    placeholder={"Affiliate"}
                    defaultOptions
                    componets={animatedComponent}
                    rules={rules}
                    cacheOptions
                    name="affiliate"
                    isClearable
                    control={control}
                />
                <span className={'text-danger-light'}>{error}</span>

            </div>
        </Fragment>

    );
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliatesSelected)
import {connect} from "react-redux";

import {
    Fragment,
} from "react";
import {Button} from "react-bootstrap";
import leadService
    from "../../../services/lead.service";
import {toast} from "react-toastify";
import {applyPeriodTimeTimezone, applyTimezone} from "../../../utils/period/period";
import {useTimezoneStore} from "../../../store/timezone";

const ExportConversions = ({columns ,period , periodTime, country, advertiser, affiliate, manager, filtering}) => {

    const {timezone} = useTimezoneStore()

    const sendExport = () => {
        let visibleColumns = columns.filter(el => el.isVisible === true && el.id !== 'btns').map(el => el.id)
        leadService.exportConversions(manager?.id, country?.iso, applyTimezone(period, timezone), applyPeriodTimeTimezone(periodTime, timezone), affiliate?.id, advertiser?.id, filtering, visibleColumns).then(res => {
            toast.success('The export has been generated successfully. You will receive an email with a download link.');
        }).catch(err => console.log(err))
    }

    return (
        <Fragment>
            <Button size="sm" onClick={() => sendExport()}>
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                xls
            </Button>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {period, periodTime, country, advertiser, affiliate, manager, filtering} = state.conversion;
    return {
        country,
        period,
        periodTime,
        advertiser, affiliate, manager, filtering
    };
}

export default connect(mapStateToProps)(ExportConversions);

import {connect} from "react-redux";
import React, {
    Fragment,
    useEffect,
    useState
} from 'react'

const AffiliateSettingsPostbacks = ({user}) => {

    const [postbacks, setPostbacks] = useState(false)

    useEffect(()=> {
        setPostbacks(user?.data?.postbacks)
    }, [])

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className={"card"}>
                        <div className="card-header">
                            <div className="d-flex justify-content-between">
                                <h3>Postbacks & Pixels:</h3>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">{"Title"}</th>
                                    <th scope="col">{"Type"}</th>
                                    <th scope="col">{"Url"}</th>
                                    <th scope="col">{"Date of Creation"}</th>
                                    <th scope="col">{"Status"}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {postbacks &&
                                postbacks.map((postback, index) => (
                                    <tr key={index}>
                                        <th scope="row">
                                            {postback?.title}
                                        </th>
                                        <th scope="row">{postback?.type?.title}</th>
                                        <th scope="row">
                                            {postback?.url}
                                        </th>
                                        <th scope="row">
                                            {postback?.created_at}
                                        </th>
                                        <th scope="row">
                                                <span
                                                    className={`badge ${postback?.is_active ? "badge-success" : "badge-danger"}`}>{postback?.is_active ? "Active" : "Inactive"}</span>
                                        </th>
                                    </tr>

                                ))}
                                {!postbacks.length &&
                                <tr>
                                    <td colSpan="20" className="text-center">
                                        <div className="p-2">No data To Display</div>
                                    </td>
                                </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliateSettingsPostbacks)

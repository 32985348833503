import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button, Modal} from "react-bootstrap";
import AffiliateCapService from "../../../../services/stream-affiliate-cap.service";
import {LimitDailyLeads, LimitLeads, Update} from "../../../../constant";
import {toast} from "react-toastify";
import AffiliatesSelected from "../../../affiliate/AffiliatesSelected";

const ModalEditAffiliateCap = ({stream, streams, affiliateCap, setStreams}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(( ) => {
        if (affiliateCap) {
            const fields = [
                "limit_daily_leads",
                "limit_leads",
                "affiliate",
            ];
            fields.forEach(field => setValue(field, affiliateCap[field]));
        }
    }, [ show])

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.affiliate_id = data.affiliate?.id;

        AffiliateCapService.update(affiliateCap.id, data)
            .then(
                response => {
                    setStreams(streams.map(x => {
                        if (x.id === stream.id) {
                            x.affiliate_caps = stream.affiliate_caps.map(x => {
                                if (x.id === affiliateCap.id) {
                                    x = response.data.data
                                }
                                return x;
                            })
                        }
                        return x;
                    }));
                    handleClose()

                    toast.success("Affiliate Cap Updated");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message)

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }
                })
            .finally(() => {
                setLoading(false);

            })
    }

    return (
        <>

            <button onClick={handleShow}
                    className="btn btn-link p-0">
                <i className="fa fa-pencil text-success fa-2x"></i>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Affiliate Caps
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="form-row justify-content-center">

                                    <div className="col-md-10 ">
                                        <AffiliatesSelected control={control}
                                                            error={errors?.affiliate_id}
                                                            showTitle={true}
                                        />
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="form-label">{LimitLeads}</label>
                                            <input className="form-control" type="number" name="limit_leads"
                                                   ref={register}
                                                   placeholder="limit leads"/>
                                            <span>{errors?.limit_leads}</span>

                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label className="form-label">{LimitDailyLeads}</label>
                                            <input className="form-control" type="number" name="limit_daily_leads"
                                                   ref={register}
                                                   placeholder="limit daily leads"/>
                                            <span>{errors?.limit_daily_leads}</span>

                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Update}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}


export default ModalEditAffiliateCap;

import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";
import Pagination from "react-js-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableReact from "react-bootstrap/Table";
import Title from "../common/custom-title";
import ModalDeleteInvoice from "./parts/modal/ModalDeleteInvoice";
import {connect} from "react-redux";
import {invoiceSearch, setInvoices} from "../../actions/invoice";
import ModalCreateInvoice from "./parts/modal/ModalCreateInvoice";
import ModalEditInvoice from "./parts/modal/ModalEditInvoice";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import {CAN_DELETE_INVOICE, CAN_UPDATE_INVOICE} from "../../constant/permissions";
import SearchInvoice from "./parts/SearchInvoice";

const InvoicesList = ({dispatch, invoices, meta, filter, period, match, total}) => {
    const {type} = match.params;

    useEffect(() => {
        dispatch(setInvoices(null));
        dispatch(invoiceSearch({type, period, status : 1}));
    }, [type])

    const getPageUrl = (pageNumber) => {
        return `${process.env.PUBLIC_URL}/invoices/${type}/?page=${pageNumber}`
    }

    return (
        <Fragment>
            <Title title={"Invoice " + type}/>
            <Breadcrumb title={"Invoice " + type}/>
            <div className="container-fluid">

                <SearchInvoice
                    type={type}
                />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">

                                <ModalCreateInvoice
                                    type={type}
                                />
                            </div>
                            <div className="table-responsive">
                                <TableReact bordered striped size={"sm"}>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"ID"}</th>
                                            <th scope="col">{"Partner"}</th>
                                            <th scope="col">{"Status"}</th>
                                            <th scope="col">{"Start Date"}</th>
                                            <th scope="col">{"End Date"}</th>
                                            <th scope="col">{"Invoice total"}</th>
                                            <th scope="col">{"Pay Date"}</th>
                                            <th scope="col">{"Note"}</th>
                                            <th scope="col">{"Create Date"}</th>
                                            <th scope="col">{"Action"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {invoices &&
                                        invoices.map((invoice, index) => (
                                            <tr key={invoice.id}>
                                                <td scope="row">
                                                    {invoice.id}
                                                </td>
                                                <td scope="row">
                                                    {invoice.partner.name} ({invoice.partner.id})
                                                </td>
                                                <td scope="row">
                                                      <span
                                                          className={`badge ${invoice.status == 1 ? "badge-danger" : "badge-success"}`}>{invoice.status == 1 ? "Unpaid" : "Paid"}</span>
                                                </td>
                                                <td scope="row">
                                                    {invoice.from_date ? invoice.from_date + " (+00:00)" : ""}
                                                </td>
                                                <td scope="row">
                                                    {invoice.to_date ? invoice.to_date + " (+00:00)" : ""}
                                                </td>
                                                <td scope="row">
                                                    {invoice.amount} $
                                                </td>
                                                <td scope="row">
                                                    {invoice.pay_date}
                                                </td>
                                                <td scope="row">
                                                    {invoice.note}
                                                </td>
                                                <td scope="row">
                                                    {invoice.created_at}
                                                </td>

                                                <td scope="row">
                                                    <div className="d-flex">
                                                        <ShowForPermission permmission={CAN_DELETE_INVOICE}>
                                                            <div className="mr-3">
                                                                <ModalDeleteInvoice
                                                                    invoice={invoice}
                                                                />
                                                            </div>
                                                        </ShowForPermission>

                                                        <ShowForPermission permmission={CAN_UPDATE_INVOICE}>
                                                            <div className="">
                                                                <ModalEditInvoice
                                                                    invoice={invoice}
                                                                />
                                                            </div>
                                                        </ShowForPermission>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    {!invoices &&
                                        <tr key={'invoice-loader'}>
                                            <td colSpan="13" className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {invoices && !invoices.length &&
                                        <tr key='invoice-not-found'>
                                            <td colSpan="13" className="text-center">
                                                <div className="p-2">No Invoices To Display</div>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                    {
                                        total && invoices && invoices.length ? <tfoot>
                                        <tr>
                                            <td scope="row">Total</td>
                                            <td scope="row"></td>
                                            <td scope="row"></td>
                                            <td scope="row"></td>
                                            <td scope="row"></td>
                                            <td scope="row">{total.amount} $</td>
                                            <td scope="row"></td>
                                            <td scope="row"></td>
                                            <td scope="row"></td>
                                            <td scope="row"></td>
                                        </tr>
                                        </tfoot> : <></>
                                    }
                                </TableReact>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    {(meta && meta?.last_page > 1) &&
                                        <Pagination
                                            activePage={meta.current_page}
                                            totalItemsCount={meta.total}
                                            itemsCountPerPage={meta.per_page}
                                            onChange={(pageNumber) => dispatch(invoiceSearch(filter, pageNumber))}
                                            getPageUrl={(pageNumber) => getPageUrl(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {invoices, meta, filter, period, total} = state.invoice;
    return {
        invoices, meta, filter, period, total
    };
}

export default connect(mapStateToProps)(InvoicesList);

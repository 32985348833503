import React, {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {ConfirmPassword, Create, CreateAdmin, EmailAddress, Name, Password} from '../../constant';
import Breadcrumb from "../common/breadcrumb";
import AdminService from "../../services/admin.service";

import EventBus from "../../common/EventBus";

import {useAlert} from "react-alert";
import {toast} from "react-toastify";
import Title from "../common/custom-title";

const AdminsCreate = ({history, dispatch}) => {
    const {register, handleSubmit, reset} = useForm();
    const alert = useAlert();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);

        AdminService.create(data)
            .then(
                response => {

                    toast.success('Admin created');

                    history.push('.');
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })


    }

    return (
        <Fragment>
            <Title title="Create Admin"/>
            <Breadcrumb parent="Admins" title="Create Admin"/>
            <div className="container-fluid">
                <div className="create-profile">
                    <div className="row justify-content-center">

                        <div className="col-md-10">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{CreateAdmin}</h4>
                                </div>
                                <div className="card-body">

                                    <div className="form-row">

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input className="form-control" type="text" name="name"
                                                       ref={register}
                                                       placeholder={Name}/>
                                                <span>{errors?.name}</span>

                                            </div>
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input className="form-control" type="text" name="email"
                                                       ref={register} autoComplete="off"
                                                       placeholder={EmailAddress}/>
                                                <span>{errors?.email}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Password}</label>
                                                <input className="form-control" type="password" name="password"
                                                       ref={register} autoComplete="new-password"
                                                       placeholder={Password}/>
                                                <span>{errors?.password}</span>

                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{ConfirmPassword}</label>
                                                <input className="form-control" type="password"
                                                       name="password_confirmation"
                                                       ref={register} autoComplete="new-password"
                                                       placeholder={ConfirmPassword}/>
                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Role</label>
                                                <select className="form-control" name={"role"}  ref={register}>
                                                    <option value={"admin"}>Admin</option>
                                                    <option value={"admin_jr"}>Junior Admin</option>
                                                    <option value={"tech_admin"}>Tech Admin</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                        {Create}
                                    </button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default AdminsCreate;

import {
    MANUAL_CONVERSION_FILTER_FAIL,
    MANUAL_CONVERSION_FILTER_SUCCESS, SET_MANUAL_CONVERSION_LEADS_SUCCESS,
    SET_MANUAL_CONVERSION_PERIOD_SUCCESS,
} from "../actions/types";
import moment from "moment";

const initialState = {
    leads: null,
    meta: null,
    country: null,
    affiliate: null,
    email: null,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },

};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case MANUAL_CONVERSION_FILTER_SUCCESS:
            return {
                ...state,
                leads: payload.leads,
                meta: payload.meta,
                country: payload.country,
                affiliate: payload.affiliate,
                email: payload.email,
                period: payload.period,

            };

        case SET_MANUAL_CONVERSION_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };

        case SET_MANUAL_CONVERSION_LEADS_SUCCESS:
            return {
                ...state,
                leads: payload.leads,
            };


        case MANUAL_CONVERSION_FILTER_FAIL:
            return {
                ...state,
                ...initialState
            };


        default:
            return state;
    }
}

import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import {EditDistribution, Save, Traffic} from "../../../../constant";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import EventBus from "../../../../common/EventBus";
import DistributionService from "../../../../services/distribution.service";
import {connect} from "react-redux";
import {streamSearch} from "../../../../actions/stream";
import {toast} from "react-toastify";
import {useAdBlock} from "../../../../utils/hooks/useAdBlock";

const ModalEditDistribution = props => {
    const {distribution, country, dispatch, affiliate, period} = props;
    const {register, handleSubmit, reset, setValue} = useForm();
    const alert = useAlert();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const adBlock = useAdBlock();

    useEffect(() => {
        setValue('traffic', distribution.traffic);

    }, [distribution]);

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);
        distribution.traffic = data?.traffic;
        distribution.affiliate_id = affiliate?.id;

        DistributionService.update(distribution.id, distribution)
            .then(
                async response => {
                    await dispatch(streamSearch(country, period, affiliate, adBlock));

                    props.onHide();
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })

    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {EditDistribution}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row">
                                    <div className=" col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{Traffic}</label>
                                            <input className="form-control" type="text" name="traffic" ref={register}
                                                   placeholder="%"/>
                                            <span>{errors?.traffic}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Save}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}

function mapStateToProps(state) {
    const {country, affiliate, period} = state.stream;
    return {
        country,
        period,
        affiliate
    };
}

export default connect(mapStateToProps)(ModalEditDistribution);

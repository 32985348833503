import React, {useState, useEffect} from 'react'
import {connect} from "react-redux";
import moment from "moment/moment";
import dailyReport from "../../../services/daily-reports.service";
import ReportTableExpanded from "../../table/report/ReportTableExpanded";
import {useDailyReportColumn} from "../../../store/useDailyReportColumn";

const APPEND_HEADERS = [
    {
        header: 'Country',
        accessor: 'country.title',
    }
]

const InnerTableCountry = ({row, country, affiliate, advertiser, manager}) => {
    const columns = useDailyReportColumn((state) => state.columns)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const date = moment.tz(row.original.date, "DD/MM/YYYY", "UTC")
        const period = {
            from_date: date.format("YYYY-MM-DD HH:mm:ss"),
            to_date: date.endOf('day').format("YYYY-MM-DD HH:mm:ss")
        }
        dailyReport.getAllReportsGroupedByCountries(manager?.id, country, period, affiliate, advertiser).then(res => {
            setData(res.data.data)
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }, []);

    return (
       <ReportTableExpanded
           data={data}
           columns={columns}
           loading={loading}
           appendHeaders={APPEND_HEADERS}
       />
    )
}

function mapStateToProps(state) {
    const {country, affiliate, advertiser, manager} = state.dailyReports;
    return {
        country,
        affiliate,
        advertiser,
        manager
    };
}

export default connect(mapStateToProps)(InnerTableCountry);
import TableReact from "react-bootstrap/Table";

import React from 'react';
import Pagination from "react-js-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from "react-redux";
import {manualConversionSearch} from "../../../actions/manual-conversion";
import ModalCreateManualConversion from "./ModalCreateManualConversion";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_CREATE_MANUAL_CONVERSION} from "../../../constant/permissions";

const Table = ({leads, meta, dispatch, country, period, affiliate, email}) => {

    return (
        <>
            <div className="table-responsive">
                <TableReact bordered striped size={"sm"}>
                    <thead>
                    <tr className="position-sticky top-0">
                        <ShowForPermission permission={CAN_CREATE_MANUAL_CONVERSION}>
                          <th scope="col"></th>
                        </ShowForPermission>
                        <th scope="col">{"Signup ID"}</th>
                        <th scope="col">{"Email"}</th>
                        <th scope="col">{"Signup Date"}</th>
                        <th scope="col">{"Affiliate Name"}</th>
                        <th scope="col">{"Advertiser Name"}</th>
                        <th scope="col">{"Custom"}</th>
                        <th scope="col">{"Custom1"}</th>
                        <th scope="col">{"Custom2"}</th>
                        <th scope="col">{"Custom3"}</th>
                        <th scope="col">{"Custom4"}</th>
                        <th scope="col">{"Custom5"}</th>
                        <th scope="col">{"Sale Status"}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {leads  && (
                        leads.map((lead, index) => (
                            <tr key={index}>
                                <ShowForPermission permission={CAN_CREATE_MANUAL_CONVERSION}>
                                    <td scope="row">
                                        <ModalCreateManualConversion
                                            lead={lead}
                                        />
                                    </td>
                                </ShowForPermission>
                                <td scope="row">{lead.id}</td>
                                <td scope="row">{lead.email}</td>
                                <td scope="row">{lead.signup_date}</td>
                                <td scope="row">{lead.affiliate?.name}</td>
                                <td scope="row">{lead.advertiser?.name}</td>

                                <td scope="row">{lead.custom}</td>
                                <td scope="row">{lead.custom1}</td>
                                <td scope="row">{lead.custom2}</td>
                                <td scope="row">{lead.custom3}</td>
                                <td scope="row">{lead.custom4}</td>
                                <td scope="row">{lead.custom5}</td>
                                <td scope="row">{lead.sale_status ?? "Unknown"}</td>
                            </tr>
                        ))
                    )}
                    {(!leads || (leads && !leads.length)) &&
                        <tr>
                            <td colSpan="20" className="text-center">
                                <div className="p-2">No Leads To Display</div>
                            </td>
                        </tr>
                    }
                    </tbody>
                </TableReact>

            </div>
            {meta &&
            <div className="card-footer">
                <div className="d-flex justify-content-center">

                        <Pagination
                            activePage={meta?.current_page}
                            totalItemsCount={meta?.total}
                            itemsCountPerPage={meta?.per_page}
                            onChange={(pageNumber) => dispatch(manualConversionSearch(country, period,  affiliate, email, pageNumber))}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                </div>
            </div>
            }

        </>

    );

}

function mapStateToProps(state) {
    const {leads, meta, country, period, affiliate, email  } = state.manualConversion;
    return {
        leads,
        meta,
        country,
        period,
        affiliate,
        email
    };
}

export default connect(mapStateToProps)(Table);
import React from "react";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../constant";
import advertiserService from "../../../services/advertiser.service";

const ModalDeleteAdvertiser = ({advertiser, advertisers, setAdvertisers}) => {


    const deleteAdvertiser = (id) => {
        setAdvertisers(advertisers.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        advertiserService.delete(id).then(() => {
            setAdvertisers(advertisers => advertisers.filter(x => x.id !== id));
        });
    }

    const submitDeleteAdvertiser = (id) => {

        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteAdvertiser(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button
                onClick={() => submitDeleteAdvertiser(advertiser.id)}
                className="btn btn-link p-0"
                disabled={advertiser.isDeleting}>
                {advertiser.isDeleting
                    ? <span
                        className="spinner-border spinner-border-lg "></span>
                    : <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    )
}

export default ModalDeleteAdvertiser
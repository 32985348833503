import {
    RESTRICTION_FILTER_FAIL, RESTRICTION_FILTER_SUCCESS, SET_RESTRICTION_SUCCESS
} from "../../actions/types";

const initialState = {
    restrictions: null,
    meta: null,
    country: null,
    affiliate: null,
    advertiser: null,
    status: null,
    pageNumber: 1,
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case RESTRICTION_FILTER_SUCCESS:
            return {
                ...state,
                restrictions: payload.restrictions,
                meta: payload.meta,
                country: payload.country,
                affiliate: payload.affiliate,
                advertiser: payload.advertiser,
                status: payload.status,
                pageNumber: payload.pageNumber,
            };

        case RESTRICTION_FILTER_FAIL:
            return {
                ...state,
                restrictions: null,
                meta: null,
                country: null,
                affiliate: null,
                status: null,
                advertiser: null,
            };

        case SET_RESTRICTION_SUCCESS:
            return {
                ...state,
                restrictions: payload.restrictions,

            };

        default:
            return state;
    }
}

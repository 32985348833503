import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../common/breadcrumb";
import 'react-confirm-alert/src/react-confirm-alert.css';
import AdvertisersDistributionSearch from "./parts/AdvertisersDistributionSearch";
import {connect} from "react-redux";
import BucketStreamList from "./bucket/BucketStreamList";
import ModalBucketCreate from "./bucket/parts/ModalCreateBucket";
import {getAffiliateData, getBucketData} from "../../../actions/stream";
import TableReact from "react-bootstrap/Table";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_CREATE_ROTATION_BUCKET} from "../../../constant/permissions";
import Title from "../../common/custom-title";

const AdvertisersDistributionList = props => {

    const {streamFilters, country, affiliate, dispatch} = props;

    const [streams, setStreams] = useState(undefined);

    useEffect(() => {
        setStreams(streamFilters)

        dispatch(getBucketData(country?.iso, affiliate?.id))

    }, [streamFilters, country, affiliate])

    useEffect(() => {
        if(affiliate)
            dispatch(getAffiliateData(affiliate?.id))

    }, [streamFilters])


    return (
        <Fragment>
            <Title title="Leads Distribution"/>
            <Breadcrumb title="Leads Distribution"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header ">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <AdvertisersDistributionSearch/>
                                    </div>
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <ShowForPermission permission={CAN_CREATE_ROTATION_BUCKET} >
                                            <div>
                                                <ModalBucketCreate/>
                                            </div>
                                        </ShowForPermission>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <TableReact bordered striped size="sm">
                                        <thead>
                                        <tr>
                                            <th scope="col">{"Advertiser"}</th>
                                            <th scope="col">{"WH"}</th>
                                            <th scope="col">{"Info"}</th>
                                            <th scope="col">{"Advert payin"}</th>
                                            <th scope="col">{"Aff payout"}</th>
                                            <th scope="col">{"UTC Lead/ Conv"}</th>
                                            <th scope="col">{"Failed Reg."}</th>
                                            <th scope="col">{"Rotation bucket"}</th>
                                            <th scope="col">{"Daily count/ limit"}</th>
                                            <th scope="col">{"UTC Daily count/ limit"}</th>
                                            <th scope="col">{"Global count/ limit"}</th>
                                            <th scope="col">{"Traffic % / Effective %"}</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <BucketStreamList/>

                                        {(!streams || (streams && !streams.length)) &&
                                            <tr>
                                                <td colSpan={12} className="text-center">
                                                    <div className="p-2">No Streams To Display</div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </TableReact>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {streamFilters, country, affiliate} = state.stream;
    return {
        streamFilters,
        country,
        affiliate,
    };
}

export default connect(mapStateToProps)(AdvertisersDistributionList);

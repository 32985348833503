import React from "react";

export const ReportTableBody = ({getTableBodyProps, rows, prepareRow, renderDownHeader}) => {
    return (
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
                <React.Fragment key={rowProps.key}>
                    <tr {...rowProps}>
                        {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            );
                        })}
                    </tr>
                    {renderDownHeader(row)}
                </React.Fragment>
            );
        })}
        </tbody>
    )
}

export default ReportTableBody
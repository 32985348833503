import { useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Overlay, Tooltip } from "react-bootstrap";

const createOption = (label) => ({
    label,
    value: label,
});

export const EmailSelect = ({className, value, placeholder, onChange, isMulti = false}) => {
    const [options, setOptions] = useState([]);

    const target = useRef(null)
    const [showToolTip, setShowToolTip] = useState(false)

    const uniqueOption = (arrayOption) => [...new Map(arrayOption.map(item =>
        [item['value'], item])).values()];

    const handleCreate = (inputValue) => {
        const emails = inputValue.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
        if (emails) {
            const options = uniqueOption(emails.map((el) => createOption(el)))
            console.log(options)
            setOptions((prev) => uniqueOption([...prev, ...options]));
            if (isMulti) {
                value ? onChange(uniqueOption([...value, ...options]))
                    : onChange(options)
                return
            }
            onChange(options[0])
        }
    };

    return (
        <>
            <div ref={target}>
                <CreatableSelect
                    isClearable
                    isMulti={isMulti}
                    onChange={(newValue) => onChange(newValue)}
                    onCreateOption={handleCreate}
                    options={options}
                    value={value}
                    className={className}
                    placeholder={placeholder}
                    onFocus={() => setShowToolTip(true)}
                    onBlur={() => setShowToolTip(false)}
                    styles={{control:  (base, state) => ({
                            ...base,
                            border: state.isFocused ? 0 : 0,
                            // This line disable the blue border
                            boxShadow: state.isFocused ? 0 : 0,
                            '&:hover': {
                                border: state.isFocused ? 0 : 0
                            }
                        })}}
                />
                <Overlay target={target.current} show={showToolTip} placement="right">
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            keyword - extract match,
                            %keyword% - contains,
                            keyword% - start with,
                            %keyword - end with
                        </Tooltip>
                    )}
                </Overlay>
            </div>

        </>
    );
}

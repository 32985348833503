import {Helmet} from "react-helmet";

const Title = ({title}) => {
    const env_title = process.env.REACT_APP_TITLE;
    return (
        <Helmet>
            <title>
                {`${title} | ${env_title}`}
            </title>
        </Helmet>
    )
}

export default Title;
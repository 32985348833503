import {
    BUCKET_FAIL, BUCKET_SUCCESS,
    SET_PERIOD_SUCCESS,
    SET_STREAM_FILTER_SUCCESS, STREAM_AFFILIATE_FAIL, STREAM_AFFILIATE_SUCCESS,
    STREAM_FILTER_FAIL,
    STREAM_FILTER_SUCCESS,
} from "../actions/types";
import moment from "moment/moment";

const initialState = {
    streamFilters: null,
    country: null,
    affiliate: null,
    buckets: null,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case STREAM_FILTER_SUCCESS:
            return {
                ...state,
                streamFilters: payload.streamFilters,
                country: payload.country,
                affiliate: payload.affiliate,
                period: payload.period,
            };

        case SET_STREAM_FILTER_SUCCESS:
            return {
                ...state,
                streamFilters: payload.streamFilters,
            };

        case SET_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,
            };

        case STREAM_FILTER_FAIL:
            return {
                ...state,
                streamFilters: null,
                country: payload.country,
                affiliate: payload.affiliate,
                period: payload.period,
            };

        case BUCKET_SUCCESS:
            return {
                ...state,
                buckets: payload.buckets,
            };

        case STREAM_AFFILIATE_SUCCESS:
            return {
                ...state,
                affiliate: payload.affiliate,
            };

        case STREAM_AFFILIATE_FAIL:
            return {
                ...state,
            };

        case BUCKET_FAIL:
            return {
                ...state,
                buckets: null,
            };

        default:
            return state;
    }
}

import React, {Fragment} from 'react';
import AsyncSelect from 'react-select/async';
import MakeAnimated from 'react-select/animated';
import {Controller} from "react-hook-form";
import ManagerService from "../../services/manager.service";

function ManagerSelected({control, error}) {
    const animatedComponent = MakeAnimated()

    const fetchData = (inputValue) => {
        return ManagerService.getByWords(inputValue).then(result => {
            return result.data.data;
        });
    }

    return (<Fragment>
            <div className="form-group">

                <Controller
                    as={AsyncSelect}
                    loadOptions={fetchData}
                    getOptionLabel={e => e.name + ' - ' + e.id}
                    getOptionValue={e => e.id}
                    placeholder={"Manager"}
                    defaultOptions
                    componets={animatedComponent}
                    cacheOptions
                    name="manager"
                    isClearable
                    control={control}
                />
                <span className={'text-danger-light'}>{error}</span>
            </div>
        </Fragment>

    );
}

export default ManagerSelected
import React, {Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {connect} from "react-redux";
import SearchRestriction from "./parts/SearchRestriction";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_DELETE_AFFILIATE_RESTRICTION, CAN_UPDATE_AFFILIATE_RESTRICTION} from "../../../constant/permissions";
import Pagination from "react-js-pagination";
import ModalCreateRestriction from "./parts/ModalCreateRestriction";
import {restrictionSearch} from "../../../actions/restriction";
import ModalEditRestriction from "./parts/ModalEditRestriction";
import TableReact from "react-bootstrap/Table";
import ModalDeleteRestriction from "./parts/ModalDeleteRestriction";
import Title from "../../common/custom-title";


const RestrictionList = ({dispatch, restrictions, meta, country, affiliate, advertiser, status}) => {

    return (
        <Fragment>
            <Title title="Affiliate Restriction"/>
            <Breadcrumb title="Affiliate Restriction"/>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <SearchRestriction/>
                    </div>

                        <div className="col-sm-12 mt-2">
                            <div className="card">
                                <div className="card-header d-flex justify-content-end">
                                    <ShowForPermission
                                        permission={CAN_UPDATE_AFFILIATE_RESTRICTION}>
                                        <ModalCreateRestriction />
                                    </ShowForPermission>
                                </div>
                                <div className="table-responsive">
                                    <TableReact bordered striped size={"sm"}>
                                        <thead>
                                        <tr>
                                            <th scope="col">{"Advertiser"}</th>
                                            <th scope="col">{"Affiliate"}</th>
                                            <th scope="col">{"Country"}</th>
                                            <th scope="col">{"Comment"}</th>
                                            <th scope="col">{"Status"}</th>
                                            <th scope="col">{"Active"}</th>
                                            <th scope="col">{"Date"}</th>
                                            <ShowForPermission permission={[CAN_DELETE_AFFILIATE_RESTRICTION, CAN_UPDATE_AFFILIATE_RESTRICTION]}>
                                               <th scope="col">{"Actions"}</th>
                                            </ShowForPermission>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {restrictions &&
                                            restrictions.map((restriction, index) => (
                                                <tr key={index}>
                                                    <td scope="row">
                                                        {restriction.advertiser?.name}
                                                    </td>
                                                    <td scope="row">
                                                        {restriction.affiliate?.name}
                                                    </td>
                                                    <td scope="row">{restriction.country?.title}</td>
                                                    <td scope="row">{restriction.comment}</td>
                                                    <td scope="row">{restriction.status === 1 ? "Included" : "Excluded"}</td>
                                                    <td scope="row">{restriction.is_active ? "Active" : "Deactive"}</td>
                                                    <td scope="row">{restriction.date}</td>

                                                    <ShowForPermission permission={[CAN_DELETE_AFFILIATE_RESTRICTION, CAN_UPDATE_AFFILIATE_RESTRICTION]}>
                                                        <td scope="row">
                                                            <div className="d-flex">
                                                                <ShowForPermission
                                                                    permission={CAN_DELETE_AFFILIATE_RESTRICTION}>
                                                                    <div className="mr-3">
                                                                        <ModalDeleteRestriction
                                                                            restriction={restriction}
                                                                        />
                                                                    </div>
                                                                </ShowForPermission>

                                                                <ShowForPermission
                                                                    permission={CAN_UPDATE_AFFILIATE_RESTRICTION}>
                                                                    <div className="">
                                                                        <ModalEditRestriction
                                                                            restriction={restriction}
                                                                        />
                                                                    </div>
                                                                </ShowForPermission>
                                                            </div>
                                                        </td>
                                                    </ShowForPermission>

                                                </tr>
                                            ))}

                                        {(!restrictions || restrictions && !restrictions.length) &&
                                            <tr>
                                                <td colSpan="13" className="text-center">
                                                    <div className="p-2">No Restrictions To Display</div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </TableReact>
                                </div>
                                {meta && meta.total > 1 &&
                                    <div
                                        className="card-footer">
                                        <div
                                            className="d-flex justify-content-center">

                                            <Pagination
                                                activePage={meta.current_page}
                                                totalItemsCount={meta.total}
                                                itemsCountPerPage={meta.per_page}
                                                onChange={(pageNumber) => dispatch(restrictionSearch(country, affiliate, advertiser, status, pageNumber))}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                </div>
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const {restrictions, meta, country, affiliate,  advertiser, status} = state.affiliateAdvertiserRestriction;
    return {
        restrictions,
        meta,
        country,
        affiliate,
        advertiser,
        status,
    };
}

export default connect(mapStateToProps)(RestrictionList);
import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/report-columns`;

class ReportColumnsService{
    getAll(filter = {}){
        return axios.get(`${baseUrl}`, {headers: authHeader(), params: { filter }})
    }

    toggleSelectedColumn(column_id){
        return axios.post(`${baseUrl}/${column_id}/toggle`, {},{headers: authHeader()})
    }

    toggleAll(columns){
        let data = {ids: columns}
        return axios.post(`${baseUrl}/toggle-all`, data,{headers: authHeader()})
    }
}

export default new ReportColumnsService();

import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/geo-restrictions`;

class AffiliateGeoRestrictionService {

    create(affiliate_id, data) {
        return axios.post(`/affiliates/${affiliate_id}${baseUrl}`, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

}

export default new AffiliateGeoRestrictionService();
import axios from "../../utils/api";
import authHeader from "../auth-header";

const baseUrl = `/adv-commissions`;

class CommissionService {
    getAll(advertiser_id, filter, pageNumber = 1) {
        return axios.get(`advertisers/${advertiser_id}${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filter,
                },
            }
        });
    }

    create(advertiser_id, data) {
        return axios.post(`advertisers/${advertiser_id}${baseUrl}`, data, {headers: authHeader()});
    }

    update(commission_id, data) {
        return axios.put(`${baseUrl}/${commission_id}`, data, {headers: authHeader()});
    }

    delete(commission_id) {
        return axios.delete(`${baseUrl}/${commission_id}`, {headers: authHeader()});
    }

}

export default new CommissionService();
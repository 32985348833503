import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/optimize`;

class OptimizeService {
    getFilter(geo = "", affiliate_id = "", advertiser_id = "", pageNumber = 1) {

        return axios.get(`${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo: geo,
                    affiliate: affiliate_id,
                    advertiser: advertiser_id,
                },
            }
        });
    }

    create( data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

}

export default new OptimizeService();
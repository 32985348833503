import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/invoices`;

class AffiliateService {
    getAll(filter = {}, pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filter,
                },
            }
        });
    }

    getById(id) {
        return axios.get(`${baseUrl}/${id}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(baseUrl, data, {headers: authHeader()});
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`, {headers: authHeader()});
    }

}

export default new AffiliateService();
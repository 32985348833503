import React from 'react';

export const AffiliatesInfo = ({affiliate, otherText = null}) => {

    return (
      <>
          {affiliate ? (
              <>
                  ID:&nbsp;{affiliate.id} <br/>
                  Name:&nbsp;{affiliate.name} <br/>
                  Email:&nbsp;{affiliate.email} <br/>
                  Hash:&nbsp;{affiliate.hash} <br/>
              </>
          ) : otherText
          }
      </>

    );
}



import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/reports/advertiser-performance`;

const baseUrlExport = '/reports'

class advertiserPerformanceReportsService {

    getAdvertiserPerformanceReports(manager = "",geo = "", period = {}, affiliate_id = "", advertiser_id = "") {
        return axios.get(`${baseUrl}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo: Array.isArray(geo) ? geo.map(el => el.iso) : geo?.iso,
                    affiliate: Array.isArray(affiliate_id) ? affiliate_id.map(el => el.id) : affiliate_id?.id,
                    advertiser: Array.isArray(advertiser_id) ? advertiser_id.map(el => el.id) : advertiser_id?.id,
                    manager: manager,
                    period: {
                        from: period.from_date,
                        to: period.to_date,
                        time: period.time
                    },
                },
            }
        });
    }

    getAdvertiserPerformanceReportsById(manager = "",geo = "", period = "", affiliate_id = "", advertiser_id = "", id) {
        return axios.get(`${baseUrl}/${id}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo: Array.isArray(geo) ? geo.map(el => el.iso) : geo?.iso,
                    affiliate: Array.isArray(affiliate_id) ? affiliate_id.map(el => el.id) : affiliate_id?.id,
                    advertiser: Array.isArray(advertiser_id) ? advertiser_id.map(el => el.id) : advertiser_id?.id,
                    manager: manager,
                    period: {
                        from: period.from_date,
                        to: period.to_date,
                        time: period.time
                    },
                },
            }
        });
    }

    getAdvertiserPerformanceReportsByAdvertiserIdAndCountryId(manager = "",geo = "", period = "", affiliate_id = "", advertiser_id = "", id, country_id) {
        return axios.get(`${baseUrl}/${id}/country/${country_id}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo: Array.isArray(geo) ? geo.map(el => el.iso) : geo?.iso,
                    affiliate: Array.isArray(affiliate_id) ? affiliate_id.map(el => el.id) : affiliate_id?.id,
                    advertiser: Array.isArray(advertiser_id) ? advertiser_id.map(el => el.id) : advertiser_id?.id,
                    manager: manager,
                    period: {
                        from: period.from_date,
                        to: period.to_date,
                        time: period.time
                    },
                },
            }
        });
    }

    export(manager = "",geo = "", period = "", affiliate_id = "", advertiser_id = "", type, columns){
        return axios.get(`${baseUrlExport}/${type}/export`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo: Array.isArray(geo) ? geo.map(el => el.iso) : geo?.iso,
                    affiliate: Array.isArray(affiliate_id) ? affiliate_id.map(el => el.id) : affiliate_id?.id,
                    advertiser: Array.isArray(advertiser_id) ? advertiser_id.map(el => el.id) : advertiser_id?.id,
                    manager: manager,
                    period: {
                        from: period.from_date,
                        to: period.to_date,
                        time: period.time
                    },
                },
                visibleColumns: JSON.stringify(columns)
            }
        });
    }

}

export default new advertiserPerformanceReportsService();
import {Button} from "reactstrap";
import React from "react";
import styled from "styled-components";
import {useDailyReportColumn} from "../../../store/useDailyReportColumn";
const Styles = styled.div`
  .onhover-show-div {
    top:50px
  }
`

const VisibleColumns = ({}) => {
    const {columns, changeVisible} = useDailyReportColumn()

    return (
        <Styles>
        <div className='onhover-dropdown'>
            <Button className="dropbtn" color={'primary'}>Visible columns<span><i
                className="icofont icofont-arrow-down"></i></span></Button>
            <ul className="onhover-show-div p-20 top-50">
                {columns.map((column, i) => (
                    <li key={i}>
                        <label>
                            <input type="checkbox" defaultChecked={column.is_visible} onClick={() => {changeVisible(column.accessor)}} />{' '}
                            {column.header}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
        </Styles>
    )
}

export default VisibleColumns
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Create} from '../../../../constant';
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import InvoiceService from "../../../../services/invoice.service";
import {toast} from "react-toastify";
import {setInvoices} from "../../../../actions/invoice";
import {connect} from "react-redux";
import AffiliatesSelected from "../../../affiliate/AffiliatesSelected";
import AdvertiserSelected from "../../../advertiser/AdvertiserSelected";
import InvoiceDates from "./InvoiceDates";
import {endOfLastMonth, endOfLastWeek, startOfLastMonth, startOfLastWeek} from "../../../../constant/date";

const ModalCreateInvoice = ({invoices, dispatch, type}) => {
    const {register, handleSubmit, reset, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.affiliate_id = data.affiliate?.id;
        data.advertiser_id = data.advertiser?.id;

        InvoiceService.create(data)
            .then(response => {
                dispatch(setInvoices([
                    response.data.data,
                    ...invoices
                ]));
                handleClose()
                toast.success("Invoice Created");

            }, error => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                toast.error(message);

                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }


            })
            .finally(() => {
                setLoading(false);

            })
    }


    return (<>
            <button className="btn btn-primary"
                    onClick={handleShow}
            >Create
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Create Invoice"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">

                                    <div className="col-md-10 ">
                                        {type === "affiliate" && (
                                            <AffiliatesSelected control={control}
                                                                error={errors?.affiliate_id}
                                                                showTitle={true}
                                            />
                                        )}

                                        {type === "advertiser" && (
                                            <AdvertiserSelected
                                                control={control}
                                                error={errors?.advertiser_id}
                                            />
                                        )}

                                    </div>
                                    <div className="col-md-10 ">

                                        <Tabs
                                            defaultActiveKey={'date'}
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            unmountOnExit={true}
                                        >
                                            <Tab eventKey={'date'} title={'Custom date'} >
                                                <InvoiceDates register={register}/>
                                            </Tab>
                                            <Tab eventKey={'custom'} title={'Custom'} >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="amount">Amount:</label>
                                                            <div className="input-group">
                                                                <input className="form-control" type="number" min={0} name="amount" id="amount"
                                                                       ref={register}/>
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">{"$"}</span>
                                                                </div>
                                                            </div>

                                                            <span className={"text-danger-light"}>{errors?.amount}</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'last-week'} title={'Last week'} >
                                                <InvoiceDates
                                                    register={register}
                                                    from={startOfLastWeek()}
                                                    to={endOfLastWeek()}
                                                />
                                            </Tab>
                                            <Tab eventKey={'last-month'} title={'Last month'} >
                                                <InvoiceDates
                                                    register={register}
                                                    from={startOfLastMonth()}
                                                    to={endOfLastMonth()}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </div>

                                    <div className="col-md-10 ">
                                        <div className="form-group ">
                                            <label className=" col-form-label">Note</label>
                                            <textarea className="form-control" name="note" id="note"
                                                      ref={register}></textarea>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (<span
                                                className="spinner-border spinner-border-sm"></span>)}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>);

}


function mapStateToProps(state) {
    const {invoices} = state.invoice;
    return {
        invoices
    };
}

export default connect(mapStateToProps)(ModalCreateInvoice);
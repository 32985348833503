import {From, To} from "../../../../constant";
import {getMaxDate} from "../../../../constant/date";
import React from "react";

const InvoiceDates = ({register, from, to}) => {

    return <>
        <div className="row">
            <div className="col-md-12 ">
                <div className="form-group">
                    <label className=" col-form-label">{From}</label>
                    <input className="form-control digits" name="from_date" id="from-datetime"
                           ref={register} max={getMaxDate()} value={from}
                           type="date"/>
                </div>
            </div>
            <div className="col-md-12 ">
                <div className="form-group">
                    <label className=" col-form-label">{To}</label>
                    <input className="form-control digits" name="to_date" id="from-to" ref={register}
                           type="date" max={getMaxDate()} value={to}
                    />
                </div>
            </div>
        </div>
    </>
}

export default InvoiceDates
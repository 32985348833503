import {useMemo} from "react";
import {useExpanded, useSortBy, useTable} from "react-table";
import ReportTableHeader from "./ReportTableHeader";
import ReportTableBody from "./ReportTableBody";
import ReportTableEmptyBody from "./ReportTableEmptyBody";
import {map} from "react-bootstrap/ElementChildren";

const ReportTableExpanded = ({data, visibleTotal, columns, appendHeaders, renderRowSubComponent, loading}) => {
    const headers = useMemo(() => [...appendHeaders, ...columns.filter((el) => el.is_visible)], [columns, appendHeaders]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable({columns: headers, data}, useSortBy, useExpanded)

    const isTotal = useMemo(() => !!(data.length > 1 && visibleTotal && columns), [data, visibleTotal, columns]);

    const renderDownHeader = (row) => (
        row.isExpanded ? <tr>
            <td colSpan={10}>
                {renderRowSubComponent({row})}
            </td>
        </tr> : <></>
    )

    if (loading) {
        return <span className="spinner-border spinner-border-lg align-center"></span>
    }

    console.log()
    return (
        <table {...getTableProps()} className='table table-striped table-bordered'>
            <ReportTableHeader
                headerGroups={headerGroups}
            />
            {
                rows.length ?
                    <ReportTableBody
                        rows={rows}
                        prepareRow={prepareRow}
                        getTableBodyProps={getTableBodyProps}
                        renderDownHeader={renderDownHeader}
                    />
                    : <ReportTableEmptyBody/>
            }
            <tfoot>
            {isTotal ?
                footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))
                : <tr></tr>
            }
            </tfoot>
        </table>
    )
}

export default ReportTableExpanded
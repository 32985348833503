import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/histories`;

class PostbackHistoryService {

    getAll(postback_id, pageNumber = 1 , filtering = {}) {
        return axios.get(`postbacks/${postback_id}${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    ...filtering,
                },
            }
        });
    }
}

export default new PostbackHistoryService();
import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/moderation`;

class ModerationService {
    getFilter(manager = "",geo = "", period = {}, affiliate_id = "",status = "", pageNumber = 1) {
        return axios.get(`${baseUrl}?page=${pageNumber}`, {
            headers: authHeader(),
            params: {
                filter: {
                    geo: geo,
                    manager: manager,
                    affiliate: affiliate_id,
                    status: status,
                    period: {
                        from: period.from_date,
                        to: period.to_date
                    },
                },
            }
        });
    }

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data, {headers: authHeader()});
    }

    pendingCount() {
        return axios.get(`${baseUrl}/pending/count`,  {headers: authHeader()});
    }


}

export default new ModerationService();

import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";

import EventBus from "../../common/EventBus";
import countryService from "../../services/country.service";

import Pagination from "react-js-pagination";
import {useAlert} from "react-alert";
import {toast} from "react-toastify";
import Title from "../common/custom-title";


const CountryList = (props) => {


    const [countries, setCountries] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const alert = useAlert();

    useEffect(() => {
        getCountriesData();
    }, [])


    const getPageUrl = (pageNumber) => {
        return `${process.env.PUBLIC_URL}/countries?page=${pageNumber}`
    }

    const getCountriesData = (pageNumber) => {
        countryService.getAll(pageNumber).then(
            response => {
                if (response.data) {
                    setCountries(response.data?.data);
                    setMeta(response.data?.meta);
                }
            },
            error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(message);

                
            }
        )
    };

    return (
        <Fragment>
            <Title title="Countries"/>
            <Breadcrumb title="Countries"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">{"#"}</th>
                                        <th scope="col">{"Title"}</th>
                                        <th scope="col">{"Iso"}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {countries &&
                                        countries.map((country, index) => (
                                            <tr key={index}>
                                                <th scope="row">{country.id}</th>
                                                <td>{country.title}</td>
                                                <td>{country.iso}</td>
                                            </tr>
                                        ))}

                                    {!countries &&
                                        <tr>
                                            <td colSpan="13" className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {countries && !countries.length &&
                                        <tr>
                                            <td colSpan="13" className="text-center">
                                                <div className="p-2">No Countries To Display</div>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    {meta &&
                                        <Pagination
                                            activePage={meta.current_page}
                                            totalItemsCount={meta.total}
                                            itemsCountPerPage={meta.per_page}
                                            onChange={(pageNumber) => getCountriesData(pageNumber)}
                                            getPageUrl={(pageNumber) => getPageUrl(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}



export default CountryList;

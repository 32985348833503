import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";
import IsoSelected
    from "../../../country/IsoSelected";

const SearchDowntime = ({getDowntimesData, countries, setCountries}) => {
    const {handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValue("country", countries)
    }, [countries])

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            setCountries(data?.country)
            let countries_iso = data?.country?.map(el => el.iso)
            getDowntimesData(1, countries_iso)
        } finally {
              setLoading(false)
        }
    }

    return (<Fragment>
            <form noValidate className="needs-validation"
                  onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                    <div className={"row"}>
                        <div className={"col-md-9"}>
                            <IsoSelected control={control}  showTitle={false}
                                isMulti closeMenuOnSelect={false}
                            />

                        </div>
                        <div className={"col-md-3"}>
                            <button className="btn btn-primary " type="submit"
                                    disabled={loading}
                            >
                                                    <span><i className="fa fa-search"
                                                             aria-hidden="true"></i>&nbsp;</span>
                                {loading && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </button>
                        </div>
                    </div>
            </form>
        </Fragment>

    );

}

export default SearchDowntime;

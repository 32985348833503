import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IsoSelected from "../../../country/IsoSelected";
import {useForm} from "react-hook-form";
import {Search} from "../../../../constant";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../../affiliate/ReportAffiliatesSelected";
import {setStreamPeriod, streamSearch} from "../../../../actions/stream";
import PeriodFilter from "../../../../utils/period/PeriodFilter";
import {useAdBlock} from "../../../../utils/hooks/useAdBlock";

const AdvertisersDistributionSearch = ({dispatch, country, period, affiliate}) => {
    const {handleSubmit, reset, control, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const adBlock = useAdBlock();


    useEffect(async () => {
        setValue("country", country);
        setValue("affiliate", affiliate);

    }, [country, affiliate]);

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(streamSearch(data?.country, period, data?.affiliate, adBlock)).catch(error => {

                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }

            })
        } finally {
            setLoading(false);
        }
    }

    return (<Fragment>
            <div className="row">
                <div className="col-md-12">

                    <PeriodFilter
                        setPeriod={(data) => dispatch(setStreamPeriod(data))}
                        period={period}
                    />

                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            <div className="col-md-5 ">
                                <IsoSelected control={control} error={errors?.country_iso}/>
                            </div>
                            <div className="col-md-3">
                                <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary " type="submit"
                                        disabled={loading}
                                >
                                    <span>{Search}&nbsp;</span>
                                    {loading && (<span
                                        className="spinner-border spinner-border-sm"></span>)}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {country, period, affiliate} = state.stream;
    return {
        country, period, affiliate
    };
}

export default connect(mapStateToProps)(AdvertisersDistributionSearch);

import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../common/breadcrumb";

import 'react-confirm-alert/src/react-confirm-alert.css';
import SearchAffiliate from "./parts/details/SearchAffiliate";
import {Tab, Tabs} from "react-bootstrap";
import Postbacks from "./parts/details/Postbacks";
import TableReact from "react-bootstrap/Table";
import {FileText, List, Settings} from "react-feather";
import AffiliateService from "../../services/affiliate.service";
import { saveAs } from 'file-saver';
import ModalEditColumns
    from "./parts/ModalEditColumns";
import ReportColumnsService
    from "../../services/report-columns.service";
import {ShowForPermission} from "../../helpers/ShowForPermission";
import {CAN_DOWNLOAD_API_AFFILIATE} from "../../constant/permissions";
import Title from "../common/custom-title";
import {Link} from "react-router-dom";

const AffiliatesDetails = ({ location }) => {

    const [affiliate, setAffiliate] = useState(undefined);
    const [showToken, setShowToken] = useState(false);
    const [modalEditColumns, setModalEditColumns] = useState(false)
    const [reportColumns, setReportColumns] = useState([])

    const showAffiliateToken = () => {
        setShowToken(!showToken)
    }

    const affiliateApiDocDownload = async (e) => {
        e.preventDefault();
        await AffiliateService.downloadAPIDoc(affiliate?.id).then((response) => {
            saveAs(response.data, affiliate.name + '-API.pdf');
        });
    }

    useEffect(() => {
        //Analog filter is_admin: 0, because laravel removed empty value...
        ReportColumnsService.getAll({is_admin: 2})
            .then(res => {
                setReportColumns(res.data.data)
            })
            .catch(err => console.log(err))
    }, [])

    const showModal = (state) => {
        setModalEditColumns(state)
    }

    return (
        <Fragment>
            <Title title="Affiliate Details"/>
            <Breadcrumb title="Affiliate Details"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card ">
                            <div className="card-header ">
                                <SearchAffiliate
                                    setAffiliate={(data) => setAffiliate(data)}
                                />
                            </div>
                            {affiliate && (
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <TableReact bordered striped size={"sm"}>
                                            <thead>
                                            <tr>
                                                <th scope="col">{"Affiliate Details"}</th>
                                                <th scope="col">{"Balances"}</th>
                                                <th scope="col">{"Actions"}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td scope="row">
                                                    Name: {affiliate.name} <br/>
                                                    ID: {affiliate.id} <br/>
                                                    Email: {affiliate.email} <br/>
                                                    Manager: {affiliate.manager?.email ?? "N/A"}<br/>
                                                    Hash: {affiliate?.hash ?? "N/A"} <br/>
                                                    Token: <i onClick={showAffiliateToken} aria-hidden="true" className={showToken ? 'fa fa-eye-slash' : 'fa fa-eye'}/> <p>{showToken && affiliate?.token}</p>
                                                </td>
                                                <td scope="row">
                                                    Affiliate Wallet:
                                                    <div className="d-flex ">
                                                        <span className="badge badge-success">$ {Number(affiliate?.balance?.balance)} (Balance)</span>
                                                        <span className="badge badge-success">$ {Number(affiliate?.balance?.paid)} (Paid)</span>
                                                        <span className="badge badge-success">$ {Number(affiliate?.balance?.remain)} (Remains)</span>
                                                    </div>
                                                </td>
                                                <td scope="row">
                                                    <div className={"row d-flex justify-content-start"}>
                                                        <ShowForPermission permission={CAN_DOWNLOAD_API_AFFILIATE} >
                                                            <div className='mr-2'>
                                                                <button className="btn btn-primary btn-sm" onClick={event => affiliateApiDocDownload(event)}>
                                                                    <FileText size={"16"}/>
                                                                </button>
                                                            </div>
                                                        </ShowForPermission>
                                                        <div className='mr-2'>
                                                            <button className="btn btn-primary btn-sm" onClick={() => setModalEditColumns(true)}>
                                                                <Settings size={"16"}/>
                                                            </button>
                                                        </div>
                                                        <div >
                                                            <Link
                                                                to={{
                                                                    pathname: `/affiliates/${ affiliate.id }`,
                                                                    state: { from: location.pathname, label: 'Affiliate Details'}
                                                                }}
                                                                class={"btn btn-primary btn-sm"}
                                                            >
                                                                <List size='16'/>
                                                            </Link>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </TableReact>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-md-12">
                                            <Tabs defaultActiveKey="postbacks" id="uncontrolled-tab-example">
                                                <Tab eventKey="postbacks" title="Postbacks & Pixels">
                                                    <Postbacks
                                                        affiliateID={affiliate.id}
                                                    />
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>

                </div>
                {affiliate && <ModalEditColumns
                    affiliate = {affiliate}
                    columns = {reportColumns}
                    show={modalEditColumns}
                    showmodal={showModal}
                    onHide={() => setModalEditColumns(false)}
                />}
            </div>
        </Fragment>

    );

}


export default AffiliatesDetails;

import {connect} from "react-redux";

import {Fragment} from "react";
import {Button} from "react-bootstrap";
import dailyReportsService
    from "../../../services/daily-reports.service";
import {toast} from "react-toastify";
import {useDailyReportColumn} from "../../../store/useDailyReportColumn";

const ExportReport = ({type, country, period, advertiser, affiliate, manager}) => {

    const columns = useDailyReportColumn((state) => state.columns)

    const sendExport = (type) => {
        dailyReportsService.export(manager?.id, country, period, affiliate, advertiser, type, columns).then(() => {
            toast.success('The export has been generated successfully. You will receive an email with a download link.');
        }).catch(err => console.log(err))
    }

    const variants = {
        'daily' : 'primary',
        'daily-countries' : 'secondary',
    };

    return (
        <Fragment>
            <Button variant={variants[type]} size="sm" onClick={() => sendExport(type)}>
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                xls
            </Button>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {country, period, advertiser, affiliate, manager} = state.dailyReports;
    return {
        country,
        period,
        advertiser, affiliate, manager
    };
}

export default connect(mapStateToProps)(ExportReport);
import React, {Fragment, useEffect, useState} from 'react';
import ModalEditAffiliateCap from "./parts/ModalEditAffiliateCap";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import {CAN_DELETE_STREAM_AFFILIATE_CAP, CAN_UPDATE_STREAM_AFFILIATE_CAP} from "../../../constant/permissions";
import ModalDeleteAffiliateCap from "./parts/ModalDeleteAffiliateCap";

const AffiliateCapList = ({streams, stream, setStreams}) => {
    const [affiliateCaps, setAffiliateCaps] = useState(undefined);

    useEffect(() => {
        setAffiliateCaps(stream.affiliate_caps);
    }, [stream, streams]);

    return (
        <Fragment>
            {affiliateCaps &&
                affiliateCaps?.map((affiliateCap, index) => (
                    <tr key={index}>
                        <th scope="row"></th>
                        <th scope="row">{index === 0 && ("Affiliate:")}</th>
                        <th scope="row" colSpan="2">
                            <div className="text-center">
                                {affiliateCap.affiliate?.name} ({affiliateCap.affiliate?.email})
                            </div>
                        </th>
                        <th scope="row">
                            <div className="text-center">
                                {affiliateCap.limit_daily_leads > 0 ? affiliateCap.limit_daily_leads : "Unlim"}
                                /
                                {affiliateCap.limit_leads > 0 ? affiliateCap.limit_leads : "Unlim"}
                            </div>
                        </th>
                        <th scope="row">
                            <div className="d-flex justify-content-center">
                                <ShowForPermission permission={CAN_UPDATE_STREAM_AFFILIATE_CAP} >
                                    <div className="">
                                        <ModalEditAffiliateCap
                                            affiliateCap={affiliateCap}
                                            stream={stream}
                                            setStreams={setStreams}
                                            streams={streams}
                                        />
                                    </div>
                                </ShowForPermission>

                                <ShowForPermission permission={CAN_DELETE_STREAM_AFFILIATE_CAP} >
                                    <div className="ml-3">
                                        <ModalDeleteAffiliateCap
                                            setAffiliateCaps={setAffiliateCaps}
                                            affiliateCaps={affiliateCaps}
                                            affiliateCap={affiliateCap}
                                            stream={stream}
                                            setStreams={setStreams}
                                            streams={streams}
                                        />
                                    </div>
                                </ShowForPermission>
                            </div>
                        </th>
                        <th scope="row" colSpan="8"></th>
                    </tr>
                ))}

        </Fragment>

    );

}


export default AffiliateCapList;

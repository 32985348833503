import React, {Fragment, useEffect, useState} from 'react';
import ModalEditCommission from "./parts/ModalEditCommission";
import {
    CAN_CREATE_ADVERTISER_COMMISSION,
    CAN_UPDATE_ADVERTISER_COMMISSION,
    CAN_DELETE_ADVERTISER_COMMISSION,
} from "../../../constant/permissions";
import {ShowForPermission} from "../../../helpers/ShowForPermission";
import ModalDeleteCommission from "./parts/ModalDeleteCommission";
import Pagination from "react-js-pagination";
import CommissionService
    from "../../../services/advertiser/commission.service";
import SearchCommission
    from "./parts/SearchCommission";
import ModalCreateCommission
    from "./parts/ModalCreateCommission";

const CommissionList = ({advertiser}) => {

    const [commissions, setCommissions] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const [filter, setFilter] = useState({});


    useEffect(() => {
            getCommissionsData()
    }, [])


    const getCommissionsData = (page = 1, filterData = filter) => {
        setFilter(filterData)

        CommissionService.getAll(advertiser?.id, filterData, page).then(res => {
            setCommissions(res.data.data)
            setMeta(res.data.meta)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <Fragment>
            <div className={"card"}>
                <div className={"card-header"}>
                    <div className={"row"}>

                        <div className={"col-md-8"}>
                            <SearchCommission
                                filter={filter}
                                getCommissionsData = {getCommissionsData}
                            />
                        </div>

                        <div className={"col-md-4 d-flex justify-content-end"}>
                            <ShowForPermission permission={CAN_CREATE_ADVERTISER_COMMISSION} >
                                <ModalCreateCommission
                                    getCommissionsData = {getCommissionsData}
                                    commissions = {commissions}
                                    advertiserID={advertiser?.id}
                                />
                            </ShowForPermission>
                        </div>


                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">{"ID"}</th>
                                <th scope="col">{"Event"}</th>
                                <th scope="col">{"Country"}</th>
                                <th scope="col">{"Affiliate"}</th>
                                <th scope="col">{"Amount"}</th>
                                <th scope="col">{"Date"}</th>
                                <ShowForPermission permission={[CAN_UPDATE_ADVERTISER_COMMISSION, CAN_DELETE_ADVERTISER_COMMISSION]} >
                                    <th scope="col">{"Actions"}</th>
                                </ShowForPermission>
                            </tr>
                            </thead>
                            <tbody>
                            {commissions &&
                                commissions?.map((commission, index) => (
                                    <tr key={index}>
                                        <td scope="row">{commission.id}</td>
                                        <td scope="row">{commission.event === 1 ? "First Conversion" : "Lead"}</td>
                                        <td scope="row">{commission.country?.title}</td>
                                        <td scope="row">
                                            {
                                                commission.affiliate
                                                    ? (
                                                        <>
                                                            Name: {commission.affiliate?.name} <br/>
                                                            Email: {commission.affiliate?.email} <br/>
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            All
                                                        </>
                                                    )
                                            }

                                        </td>
                                        <td scope="row">{commission.amount} USD</td>
                                        <td scope="row">{commission.date}</td>

                                        <ShowForPermission permission={[CAN_UPDATE_ADVERTISER_COMMISSION, CAN_DELETE_ADVERTISER_COMMISSION]} >
                                            <th scope="row">
                                                <div className="d-flex">

                                                    <ShowForPermission permission={CAN_UPDATE_ADVERTISER_COMMISSION} >
                                                        <div className="">

                                                            <ModalEditCommission
                                                                setCommissions={(data) => setCommissions(data)}
                                                                commission={commission}
                                                                commissions={commissions}
                                                            />
                                                        </div>
                                                    </ShowForPermission>

                                                    <ShowForPermission permission={CAN_DELETE_ADVERTISER_COMMISSION} >
                                                        <div className="ml-3">
                                                            <ModalDeleteCommission
                                                                setCommissions={setCommissions}
                                                                commission={commission}
                                                                commissions={commissions}
                                                            />
                                                        </div>
                                                    </ShowForPermission>
                                                </div>
                                            </th>
                                        </ShowForPermission>

                                    </tr>
                                ))}
                            {!commissions &&
                                <tr>
                                    <td colSpan="13" className="text-center">
                                        <div className="spinner-border spinner-border-lg align-center"></div>
                                    </td>
                                </tr>
                            }
                            {commissions && !commissions.length &&
                                <tr>
                                    <td colSpan="13" className="text-center">
                                        <div className="p-2">No Commissions To Display</div>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>

                    {(meta && meta?.last_page > 1) && (
                        <div className={"d-flex justify-content-center mt-3"}>
                            <Pagination
                                activePage={meta?.current_page}
                                totalItemsCount={meta?.total}
                                itemsCountPerPage={meta?.per_page}
                                onChange={(pageNumber) => getCommissionsData(pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    )}
                </div>

            </div>

        </Fragment>

    );

}


export default CommissionList;

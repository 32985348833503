import axios from "../utils/api";
import authHeader from "./auth-header";
const baseUrl = `/profile`;

class ProfileService {

    update(data) {
        return axios.put(`${baseUrl}`, data, {headers: authHeader()});
    }

    downloadAPIDoc(){
        return axios.get(`${baseUrl}/download/pdf`, {headers: authHeader(), responseType: 'blob' });
    }
}

export default new ProfileService();
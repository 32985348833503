import React, {Fragment, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {setModerations} from "../../../actions/moderation";
import ModerationService from "../../../services/moderation.service";
import EventBus from "../../../common/EventBus";
import {connect} from "react-redux";
import ModalModerationUpdateStatus
    from "./ModalModerationUpdateStatus";
import {toast} from "react-toastify";

const ModerationUpdateStatus = ({dispatch, moderation, moderations}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [modalShowModerationUpdateStatus, setModalShowModerationUpdateStatus] = useState(false);


    const onSubmit = async (status) => {

        if (status === 1)
            setModalShowModerationUpdateStatus(true);
        else {
            try {
                ModerationService.update(moderation.id, {status})
                    .then(
                        response => {

                            dispatch(setModerations(
                                moderations.map(x => {
                                if (x.id === moderation.id) {
                                    x.status = response.data.data.status;
                                    x.user_updated = response.data.data.user_updated;
                                }
                                return x;
                            })));

                        }, error => {
                            const message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            toast.error(message);

                            if (error.response && error.response.status === 422) {
                                setErrors(error.response.data.errors);
                            }

                            if (error.response && error.response.status === 401) {
                                EventBus.dispatch("logout");
                            }
                        })
                    .finally(() => {
                        setLoading(false);

                    })
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <Fragment>
            {moderation.status.title === "Pending" && (
                <div className="row">
                    <div className="col-md-12">

                        <div className="row">
                            <button className="btn btn-primary btn-block" type="submit" onClick={() => onSubmit(1)}
                                    disabled={loading}
                            >
                                <span>{"Approve"}&nbsp;</span>
                                {loading && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </button>
                        </div>

                        <div className="row mt-1">

                            <button className="btn btn-primary btn-block" type="submit" onClick={() => onSubmit(2)}
                                    disabled={loading}
                            >
                                <span>{"Decline"}&nbsp;</span>
                                {loading && (<span
                                    className="spinner-border spinner-border-sm"></span>)}
                            </button>
                        </div>
                    </div>
                </div>

            )}
            <ModalModerationUpdateStatus
                show={modalShowModerationUpdateStatus}
                onHide={() => setModalShowModerationUpdateStatus(false)}
                moderation = {moderation}
                moderations = {moderations}

            />
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {moderations} = state.moderation;
    return {
        moderations
    };
}

export default connect(mapStateToProps)(ModerationUpdateStatus);

import {
    GET_ME_FAIL,
    GET_ME_SUCCESS,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const token = localStorage.getItem("token");

const initialState = token
    ? {isLoggedIn: true, user, token}
    : {isLoggedIn: false, user: null, token: null};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                token: payload.token,
            };
        case GET_ME_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                token: payload.token,
            };
        case GET_ME_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                token: null,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                token: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                token: null,
            };
        default:
            return state;
    }
}

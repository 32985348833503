import {
    INVOICE_FILTER_FAIL,
    INVOICE_FILTER_SUCCESS,
    SET_INVOICE_PERIOD_SUCCESS,
    SET_INVOICE_SUCCESS,
} from "./types";
import InvoiceService from "../services/invoice.service";
import {toast} from "react-toastify";


export const invoiceSearch = (filter, pageNumber) => (dispatch) => {
    return InvoiceService.getAll(filter, pageNumber).then(
        (response) => {
            dispatch({
                type: INVOICE_FILTER_SUCCESS,
                payload: {
                    invoices: response.data.data,
                    meta: response.data.meta,
                    filter: filter,
                    total: response.data.total
                },
            });

            return Promise.resolve();
        },
        (error) => {

            dispatch({
                type: INVOICE_FILTER_FAIL,
            });
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            toast.error(message);


            return Promise.reject();
        }
    );
};

export const setInvoices = (invoices) => (dispatch) => {
    dispatch({
        type: SET_INVOICE_SUCCESS,
        payload: {
            invoices: invoices,
        },
    });

    return Promise.resolve();
}

export const setInvoicePeriod = (period) => (dispatch) => {
    dispatch({
        type: SET_INVOICE_PERIOD_SUCCESS,
        payload: {
            period: period,
        },
    });

    return Promise.resolve();
}

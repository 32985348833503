import React, {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
    EmailAddress,
    Manager,
    Name,
    NewPassword,
    Save,
    Status,
} from '../../constant';
import Breadcrumb from "../common/breadcrumb";

import AffiliateService from "../../services/affiliate.service";
import ManagerSelected from "../manager/ManagerSelected";
import {toast} from "react-toastify";
import GeoRestrictionList from "./parts/geo-restriction/GeoRestrictionList";
import AffiliateCommissionList from "./parts/comission/AffiliateCommissionList";
import Title from "../common/custom-title";
import AffiliateWhiteListIpList from "./parts/white-list-ip/AffiliateWhiteListIpList";
import {connect} from "react-redux";
import { useHistory } from "react-router-dom";
import {ManagerInfo} from "../manager/ManagerInfo";

const AffiliatesEdit = ({history, match, user}) => {
    const {affiliate_id} = match.params;
    const {register, handleSubmit, reset, setValue, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);

    const [affiliate, setAffiliate] = useState(undefined);
    const isManager = user?.data?.roles?.includes('manager');
    const { location } = useHistory()


    const checkedManager = () => {
        return !isManager  || affiliate?.manager_id === user?.data?.id
    }

    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        data.manager_id = data.manager ? data.manager.id : null;

        AffiliateService.update(affiliate_id, data)
            .then(
                response => {

                    toast.success('Affiliate updated');

                    history.push('.');
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }


                })
            .finally(() => {
                setLoading(false);

            })


    }

    useEffect(() => {

        AffiliateService.getById(affiliate_id).then((res) => {
            let affiliateList = res.data.data;

            setAffiliate(affiliateList);

            const fields = [
                'name',
                'email',
                'is_active',
                'manager',
            ];
            fields.forEach(field => setValue(field, affiliateList[field]));
        });
    }, []);

    return (
        <Fragment>
            <Title title="Edit Affiliate"/>
            <Breadcrumb
                parent={location.state?.label ?? 'Manage Affiliates'}
                title="Edit Affiliate"
                parentUrl={location.state?.from ?? '/affiliates'}
            />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row justify-content-center">

                        <div className="col-md-12">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="card-header">
                                    <p className={"mb-0"}>
                                        ID: {affiliate?.id} <br/>
                                        Hash: {affiliate?.hash} <br/>
                                        Api Token: {affiliate?.token}
                                    </p>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-5">

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">{Name}:</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" name="name"
                                                           ref={register}
                                                           placeholder={Name}/>
                                                    <span>{errors?.name}</span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">{EmailAddress}:</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" name="email"
                                                           ref={register}
                                                           placeholder={EmailAddress}/>
                                                    <span>{errors?.email}</span>
                                                </div>
                                            </div>

                                            {!isManager ? (
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">{Manager}:</label>
                                                    <div className="col-sm-9">
                                                        <ManagerSelected control={control} error={errors?.manager_id}/>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">{Manager}:</label>
                                                    <div className="col-sm-9">
                                                        <ManagerInfo manager={affiliate?.manager} otherText="Not manager" />
                                                    </div>
                                                </div>
                                            )}
                                            {checkedManager() && (
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">{NewPassword}:</label>
                                                    <div className="col-sm-9">
                                                        <input className="form-control" type="password" name="password"
                                                               ref={register} autoComplete="new-password"
                                                               placeholder={NewPassword}/>
                                                        <span>{errors?.password}</span>

                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="col-md-12">
                                                        <span>{Status}:</span>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="switch-green">
                                                            <input type="checkbox" name="is_active" disabled={!checkedManager()}
                                                                   ref={register}/>
                                                            <div className="slider"></div>
                                                        </label>
                                                        <span>{errors?.is_active}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {checkedManager()  && (
                                            <div className="col-md-3 text-right">
                                                <button className="btn btn-primary " type="submit"
                                                        disabled={loading}
                                                >
                                                    <span>{Save}&nbsp;</span>
                                                    {loading && (
                                                        <span
                                                            className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row justify-content-center">

                         <div className={"col-md-6"}>
                            <GeoRestrictionList
                                affiliate={affiliate}
                                />
                        </div>

                        <div className={"col-md-6"}>
                            <AffiliateCommissionList
                                affiliate={affiliate}
                                />
                        </div>

                    </div>

                    <div className="row justify-content-center">
                        <div className={"col-md-12"}>
                            <AffiliateWhiteListIpList
                                affiliate={affiliate}
                                checkedManager={checkedManager}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliatesEdit);

import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Login, LOGIN, Password, YourName} from '../../constant';

import {connect} from "react-redux";
import {getMe, login} from "../../actions/auth";
import EventBus from "../../common/EventBus";

const ErrorLogin = (props) => {

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h2 className={"font-danger"}>{"Login session ended"}</h2>
                                                    <h6>{"Please login to dashboard"} </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default ErrorLogin;

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button, Modal} from "react-bootstrap";
import IsoSelected from "../../../country/IsoSelected";
import {daysOfWeek} from "../../../../constant/date";
import TimezoneSelect from "react-timezone-select";
import DowntimeService from "../../../../services/advertiser/downtime.service";
import {Update} from "../../../../constant";
import {toast} from "react-toastify";
import moment from "moment";

const ModalEditDowntime = ({downtimes, downtime, setDowntimes}) => {
    const {register, handleSubmit, reset, control, setValue} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [selectedTimezone, setSelectedTimezone] = useState({})
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (downtime) {
            const fields = [
                'country',
                "from",
                "to",
                "day_of_week",
            ];
            fields.forEach(field => setValue(field, downtime[field]));

            setSelectedTimezone(downtime.timezone)

        }

    }, [show])

    const onSubmit = (data) => {
        setLoading(true);
        setErrors(undefined);

        data.country_iso = data.country?.iso;
        data.timezone = selectedTimezone === "Etc/GMT" ? "UTC" : selectedTimezone;
        data.from = moment(data.from, "H:mm").format("HH:mm:ss")
        data.to = moment(data.to, "H:mm").format("HH:mm:ss")

        DowntimeService.update(downtime.id, data)
            .then(
                response => {
                    setDowntimes(downtimes.map(x => {
                        if (x.id === downtime.id) {
                            x = response.data.data;
                        }
                        return x;
                    }));
                    handleClose()

                    toast.success("Advertiser Working Hours Updated");

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message)

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }
                })
            .finally(() => {
                setLoading(false);

            })
    }

    return (
        <>
            <button onClick={handleShow}
                    className="btn btn-link p-0">
                <i className="fa fa-pencil text-success fa-2x"></i>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Advertiser Working Hours
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>

                                <div className="form-row justify-content-center">


                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="from">From Time:</label>
                                            <input className="form-control" type="time" name="from" id={"from"} ref={register} step="1" />
                                            <span>{errors?.from}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="to">To Time:</label>
                                            <input className="form-control" type="time" name="to" id="to"  ref={register} step="1" />
                                            <span>{errors?.to}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="to">Day of Week:</label>

                                            <select name="day_of_week" className="custom-select" ref={register}>
                                                <option value="" disabled selected></option>
                                                {daysOfWeek.map((dayOfWeek,index) => (
                                                    <option key={index} value={dayOfWeek}>{dayOfWeek}</option>
                                                ))}
                                            </select>
                                            <span>{errors?.day_of_week}</span>
                                        </div>
                                    </div>

                                    <div className=" col-md-10">
                                        <div className="form-group">
                                            <label htmlFor="to">GTM Timezone:</label>

                                            <div className="select-wrapper">
                                                <TimezoneSelect
                                                    value={selectedTimezone}
                                                    onChange={(data) => setSelectedTimezone(data.value)}
                                                />
                                            </div>
                                            <span className="text-danger-light">{errors?.timezone}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-10 ">
                                        <IsoSelected
                                            control={control}
                                            error={errors?.country_iso}
                                            showTitle={true}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Update}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>

    );

}


export default ModalEditDowntime;

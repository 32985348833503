import {
    SET_MENU_SUCCESS
} from "../actions/types";

import {MENUITEMS} from "../constant/menu";

const initialState = {
    menuitems: MENUITEMS
}

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_MENU_SUCCESS:
            return {
                menuitems: payload.menuitems
            };
        default:
            return state;
    }
}
import React, {Fragment, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import ReportAffiliatesSelected from "../../affiliate/ReportAffiliatesSelected";
import AdvertiserSelected from "../../advertiser/AdvertiserSelected";
import {invoiceSearch, setInvoicePeriod} from "../../../actions/invoice";
import PeriodFilter from "../../../utils/period/PeriodFilter";
import { refreshPeriod } from "../../../utils/period/period";

const SearchInvoice = ({dispatch, period, filter, type}) => {
    const {handleSubmit, reset, control, register} = useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);
        try {
            await dispatch(invoiceSearch({
                type,
                period: refreshPeriod(period),
                affiliate: data.affiliate?.id,
                advertiser: data.advertiser?.id,
                status: data?.status,
            }));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <PeriodFilter
                        setPeriod={(data) => dispatch(setInvoicePeriod(data))}
                        period={period}
                    />

                    <form noValidate className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <div className="form-row">
                            {type === "affiliate" && (
                                <div className="col-md-3">
                                    <ReportAffiliatesSelected control={control} error={errors?.affiliate_id}/>
                                </div>
                            )}
                            {type === "advertiser" && (
                                <div className="col-md-3">
                                    <AdvertiserSelected label={false} control={control} error={errors?.advertiser_id}/>
                                </div>
                            )}
                            <div className="col-md-2 ">
                                <div className="form-group">
                                    <select name="status" className="custom-select" id="" ref={register}>
                                        <option value="" >All</option>
                                        <option value="1" selected>Unpaid</option>
                                        <option value="2">Paid</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-flex">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span><i className="fa fa-search" aria-hidden="true"></i>&nbsp;</span>
                                        {loading && (<span
                                            className="spinner-border spinner-border-sm"></span>)}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>

    );

}

function mapStateToProps(state) {
    const {period, filter} = state.invoice;
    return {
        period,filter
    };
}

export default connect(mapStateToProps)(SearchInvoice);

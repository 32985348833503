
export const optionalParameters = [
    "email",
    "name",
    "firstName",
    "lastName",
    "phonenumber",
    "affiliateName",
    "custom",
    "custom1",
    "custom2",
    "custom3",
    "custom4",
    "custom5",
    "country",
    "countrycode",
    "signtime",
    "timestamp",
    "affiliatepostbackamount",
    "payout",
]


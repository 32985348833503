import React from "react";
import {useTimezoneStore} from "../../../store/timezone";
import TimezoneSelect from "react-timezone-select";
export const TimezoneSelectStore = () => {
    const {timezone, setTimezone} = useTimezoneStore()

    return (
        <>
            <div className="d-flex flex-row-reverse">
                <div className="col-md-4">
                    <div className="form-group row">
                        <label htmlFor="to" className="col-sm-3 col-form-label">GTM Timezone:</label>
                        <div className="col-sm-9">
                            <TimezoneSelect
                                value={timezone}
                                onChange={(data) => setTimezone(data.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";
import AffiliateWhiteListIpService from "../../../../../services/affiliate.white-list-ip.service";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmDelete, DeleteIrreversible, No, Yes} from "../../../../../constant";

const ModalDeleteWhiteListIp = ({whiteListIp, whiteListIps, setWhiteListIps}) => {

    const deleteWhiteListIps = (id) => {
        setWhiteListIps(whiteListIps.map(x => {
            if (x.id === id) {
                x.isDeleting = true;
            }
            return x;
        }));
        AffiliateWhiteListIpService.delete(id).then(() => {
            setWhiteListIps(whiteListIps => whiteListIps.filter(x => x.id !== id));
        });
    }

    const submitDeleteWhiteListIps = (id) => {
        confirmAlert({
            title: ConfirmDelete,
            message: DeleteIrreversible,
            buttons: [
                {
                    label: Yes,
                    onClick: () => deleteWhiteListIps(id)
                },
                {
                    label: No,
                }
            ]
        });
    }

    return (
        <>
            <button
                onClick={() => submitDeleteWhiteListIps(whiteListIp.id)}
                className="btn btn-link p-0"
                disabled={whiteListIp.isDeleting}>
                {whiteListIp.isDeleting
                    ? <span
                        className="spinner-border spinner-border-lg "></span>
                    :
                    <i className="fa fa-trash text-danger fa-2x "></i>
                }
            </button>
        </>
    )
}

export default ModalDeleteWhiteListIp;
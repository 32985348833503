import axios from 'axios';
import EventBus from "../common/EventBus";
import {history} from '../helpers/history';

const baseUrl = localStorage.getItem('url')

const http = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-type": "application/json"
    },
});

//Response interceptor
http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
        let loginUrl = localStorage.getItem('loginUrl')
        let redirectUrl = '/login';
        if (loginUrl){
            let s = new URL(loginUrl)
            redirectUrl  = s.pathname
        }
        history.push(redirectUrl);
        window.location.reload();

        return
    }

    return Promise.reject(error);
});

export default http
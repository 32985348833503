import React, {useCallback, useMemo} from "react";
import {ChevronDown, ChevronRight} from "react-feather";
import ReportTableExpanded from "../table/report/ReportTableExpanded";
import ImportLeadTable from "./ImportLeadTable";

const ImportTable = ({queues}) => {
    const renderRowSubComponent = useCallback(({row}) => {
        return (
            <ImportLeadTable
                row={row.original.children}
            />
        )
    }, [])

    const columns = useMemo(() => [
        {
            header: () => null,
            id: 'expander',
            Cell: ({row}) => (
                <span {...row.getToggleRowExpandedProps()} className="chevron">
                            {row.isExpanded ? <ChevronDown/> : <ChevronRight/>}
                        </span>
            ),
            width: 50,
        },
        {
            header: 'Queue',
            accessor: 'name',
            Cell: ({row}) => (
                <span># {row.values.name}</span>
            )
        }
    ], [])

    return (
        <ReportTableExpanded
            data={queues}
            columns={[]}
            appendHeaders={columns}
            renderRowSubComponent={renderRowSubComponent}
        />
    )
}

export default ImportTable
import axios from "../utils/api";
import authHeader from "./auth-header";

const baseUrl = `/affiliate-cap`;

class StreamAffiliateCapService {

    create(stream_id, data) {
        return axios.post(`streams/${stream_id}${baseUrl}`, data, {headers: authHeader()});
    }

    update(affiliate_cap_id, data) {
        return axios.put(`${baseUrl}/${affiliate_cap_id}`, data, {headers: authHeader()});
    }

    delete(affiliate_cap_id) {
        return axios.delete(`${baseUrl}/${affiliate_cap_id}`, {headers: authHeader()});
    }

}

export default new StreamAffiliateCapService();
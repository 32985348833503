import React, {
    Fragment, useState,
} from 'react';
import Breadcrumb from "../common/breadcrumb";
import Title from "../common/custom-title";
import {Create} from "../../constant";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import AffiliatesSelected from "../affiliate/AffiliatesSelected";
import AdvertiserSelected from "../advertiser/AdvertiserSelected";
import IsoSelected from "../country/IsoSelected";
import TestLeadService from "../../services/test-lead.service";
import {Tab, Tabs} from "react-bootstrap";

const leadTestDataOptions = {
    offer_name: "test offerName",
    offer_website: "https://test.example.com",
    comment: "test comment",
};

const RegisterTestLead = () => {
    const {register, handleSubmit, reset, control} = useForm();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [eventKey, setEventKey] = useState({
        key: "close",
        showTabs: false
    });

    const setKey = (key) => {
        setEventKey({
            key,
            showTabs: key !== "close"
        })
    }

    const onSubmit = async (data) => {
        setLoading(true);
        setErrors(undefined);

        if(!data?.agree_terms){
            setErrors({
                "agree_terms": "Please agree with Terms And Conditions"
            })
            setLoading(false);
            return;
        }

        data.country_iso = data.country?.iso;
        data.affiliate_id = data.affiliate?.id;
        data.advertiser_id = data.advertiser?.id;

        TestLeadService.register(data)
            .then(
                response => {

                    toast.success('Test Lead Created');

                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                        setKey("options")
                    }


                })
            .finally(() => {
                setLoading(false);

            })

    }


    return (
        <Fragment>
            <Title title="Register Test Lead"/>
            <Breadcrumb title="Register Test Lead"/>

            <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Register Test Lead</h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className=" col-md-12">
                                            <AffiliatesSelected control={control}
                                                                error={errors?.affiliate_id}
                                                                showTitle={true}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <AdvertiserSelected control={control}
                                                                error={errors?.advertiser_id}
                                            />
                                        </div>

                                        <div className=" col-md-12">
                                            <IsoSelected control={control}
                                                         error={errors?.country_iso}
                                                         showTitle={true}
                                            />
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="ignore_user_restrictions" ref={register} value="1"
                                                       id="ignore_user_restrictions"/>
                                                <label className="form-check-label" htmlFor="ignore_user_restrictions">
                                                    Ignore affiliate restrictions
                                                </label>
                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="ignore_user_limits"  ref={register} value="1"
                                                       id="ignore_user_limits" />
                                                <label className="form-check-label" htmlFor="ignore_user_limits">
                                                    Ignore affiliate geos
                                                </label>
                                            </div>
                                        </div>
                                        <div className=" col-md-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="agree_terms"
                                                       ref={register} value="1"
                                                       id="agree_terms" />
                                                <label className="form-check-label" htmlFor="agree_terms">
                                                    I agree with <a href="#"> Terms And Conditions</a>
                                                </label>

                                            </div>
                                            <span className="text-danger-light">{errors?.agree_terms}</span>
                                        </div>

                                        <div className=" col-md-12 mt-3">
                                            <Tabs
                                                activeKey={eventKey?.key}
                                                onSelect={(k) => setKey(k)}
                                                className="mb-3"
                                            >
                                                <Tab eventKey="options" title={
                                                    <>
                                                        <i className="fa fa-angle-down mr-1"></i> Custom Options
                                                    </>
                                                } >
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">First name:</label>
                                                            <input className="form-control" type="text" name="first_name" ref={register} />
                                                            <span>{errors?.first_name}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Last name:</label>
                                                            <input className="form-control" type="text" name="last_name" ref={register} />
                                                            <span>{errors?.last_name}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Email:</label>
                                                            <input className="form-control" type="text" name="email" ref={register} />
                                                            <span>{errors?.email}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">IP:</label>
                                                            <input className="form-control" type="text" name="ip" ref={register} />
                                                            <span>{errors?.ip}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Phone:</label>
                                                            <input className="form-control" type="text" name="phone" {...register("phone")} ref={register} />
                                                            <span>{errors?.phone}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Locale:</label>
                                                            <input className="form-control" type="text" name="locale" ref={register} />
                                                            <span>{errors?.locale}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Offer name:</label>
                                                            <input className="form-control" type="text" name="offer_name" ref={register} defaultValue={leadTestDataOptions?.offer_name}/>
                                                            <span>{errors?.offer_name}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Offer website:</label>
                                                            <input className="form-control" type="text" name="offer_website" ref={register} defaultValue={leadTestDataOptions?.offer_website}/>
                                                            <span>{errors?.offer_website}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Comment:</label>
                                                            <input className="form-control" type="text" name="comment" ref={register} defaultValue={leadTestDataOptions?.comment}/>
                                                            <span>{errors?.comment}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{"Custom"}</label>
                                                            <input className="form-control" type="text" name="custom" ref={register}
                                                                   placeholder=""/>
                                                            <span>{errors?.custom}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{"Custom1"}</label>
                                                            <input className="form-control" type="text" name="custom1" ref={register}
                                                                   placeholder=""/>
                                                            <span>{errors?.custom1}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{"Custom2"}</label>
                                                            <input className="form-control" type="text" name="custom2" ref={register}
                                                                   placeholder=""/>
                                                            <span>{errors?.custom2}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{"Custom3"}</label>
                                                            <input className="form-control" type="text" name="custom3" ref={register}
                                                                   placeholder=""/>
                                                            <span>{errors?.custom3}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{"Custom4"}</label>
                                                            <input className="form-control" type="text" name="custom4" ref={register}
                                                                   placeholder=""/>
                                                            <span>{errors?.custom4}</span>

                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{"Custom5"}</label>
                                                            <input className="form-control" type="text" name="custom5" ref={register}
                                                                   placeholder=""/>
                                                            <span>{errors?.custom5}</span>

                                                        </div>
                                                    </div>
                                                </Tab>
                                                {eventKey.showTabs && (
                                                    <Tab eventKey={"close"} title={
                                                        <><i className='fa fa-close mr-1'></i>CLOSE</>
                                                    }/>
                                                    )
                                                }

                                            </Tabs>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
            </div>

        </Fragment>
    );

}



export default RegisterTestLead;

import {
    MODERATION_FILTER_FAIL,
    MODERATION_FILTER_SUCCESS, MODERATION_PENDING_COUNT_FAIL,
    MODERATION_PENDING_COUNT_SUCCESS,
    SET_MODERATION_PERIOD_SUCCESS,
    SET_MODERATION_SUCCESS
} from "../actions/types";
import moment from "moment";

const initialState = {
    moderations: null,
    meta: null,
    country: null,
    affiliate: null,
    manager: null,
    status: null,
    pendingCount: 0,
    period: {
        from_date: moment().startOf("day").format('YYYY-MM-DD H:mm'),
        to_date: moment().endOf("day").format('YYYY-MM-DD H:mm'),
        time: "today",
    },

};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case MODERATION_FILTER_SUCCESS:
            return {
                ...state,
                moderations: payload.moderations,
                meta: payload.meta,
                country: payload.country,
                affiliate: payload.affiliate,
                manager: payload.manager,
                period: payload.period,
                status: payload.status,

            };

        case MODERATION_FILTER_FAIL:
            return {
                ...state,
                moderations: null,
                meta: null,
                country: null,
                affiliate: null,
                status: null,
            };

        case SET_MODERATION_SUCCESS:
            return {
                ...state,
                moderations: payload.moderations,

            };

        case SET_MODERATION_PERIOD_SUCCESS:
            return {
                ...state,
                period: payload.period,

            };

        case MODERATION_PENDING_COUNT_SUCCESS:
            return {
                ...state,
                pendingCount: payload.pendingCount,

            };

        case MODERATION_PENDING_COUNT_FAIL:
            return {
                ...state,
                pendingCount: 0,

            };

        default:
            return state;
    }
}

import React, {Fragment, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {From, To} from "../../constant";
import moment from 'moment';
import DatePicker from "react-datepicker"
import ModalPeriodTimeFilter from "./ModalPeriodTimeFilter";
import { calculatePeriod } from "./period";

const PeriodFilter = (props) => {
    const {period, setPeriod, showPeriodTime = false} = props;
    const [showCustom, setShowCustom] = useState(period?.time === "custom");
    const [from, setFrom] = useState(moment().startOf('month'))
    const [to, setTo] = useState(moment().endOf('month'))

    const setDate = (time) => {
        if (time === "custom") {
            setShowCustom(true);
            handleUpdate(from, to)
            return true;
        }

        setShowCustom(false);
        const {from_date, to_date, time: type} = calculatePeriod(time, moment().utc())
        setPeriod({
            from_date,
            to_date,
            time: type,
        })

    }

    const handleUpdate = async (from, to, time = "custom") => {
        await setPeriod({
            from_date: moment(from).format('YYYY-MM-DD H:mm:ss'),
            to_date: moment(to).format('YYYY-MM-DD H:mm:ss'),
            time: time,
        });
    }

    useEffect(() => {
        if(showCustom){
            handleUpdate(from,to)
        }

    }, [from, to])

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <ul className="nav nav-pills nav-fill mb-2">
                        <li className="nav-item">
                            <a className={period.time === "today" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("today")}
                            >
                                Today
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={period.time === "yesterday" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("yesterday")}
                            >
                                Yesterday
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={period.time === "this_week" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("this_week")}
                            >
                                This Week
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={period.time === "last_week" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("last_week")}
                            >
                                Last Week
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={period.time === "this_month" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("this_month")}
                            >
                                This Month
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={period.time === "last_month" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("last_month")}
                            >
                                Last Month
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={period.time === "custom" ? 'active nav-link' : "nav-link"} href="#"
                               onClick={() => setDate("custom")}
                            >
                                Custom
                            </a>
                        </li>

                        { showPeriodTime && (
                            <>
                                <li className="nav-item">
                                    <ModalPeriodTimeFilter
                                        {...props}
                                        />
                                </li>

                            </>
                        )}
                    </ul>

                    <div className={showCustom ? "" : "d-none"}>
                            <div className="row my-2">

                                <div className="col-md-6 ">
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">{From}</label>
                                        <div className="col-sm-10">
                                            <DatePicker
                                                className="form-control digits "
                                                selected={new Date(from)}
                                                onChange={setFrom}
                                                maxDate={new Date(to)}
                                                timeFormat="HH:mm"
                                                timeInterval="15"
                                                showTimeSelect
                                                dateFormat="dd.MM.yyyy HH:mm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">{To}</label>
                                        <div className="col-sm-10">
                                            <DatePicker
                                                className="form-control digits "
                                                selected={new Date(to)}
                                                minDate={new Date(from)}
                                                onChange={setTo}
                                                timeFormat="HH:mm"
                                                timeInterval="15"
                                                showTimeSelect
                                                dateFormat="dd.MM.yyyy HH:mm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </Fragment>

    );

}

export default  PeriodFilter;

import React, {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
    ConfirmPassword,
    Create,
    EmailAddress,
    Manager,
    Name,
    Password, Status
} from '../../constant';
import Breadcrumb from "../common/breadcrumb";

import AffiliateService from "../../services/affiliate.service";
import ManagerSelected from "../manager/ManagerSelected";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import Title from "../common/custom-title";

const AffiliatesCreate = ({history, user}) => {
    const {register, handleSubmit, reset, control} = useForm();
    const isManager = user?.data?.roles?.includes('manager')

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(undefined);


    const onSubmit = async (data) => {

        setLoading(true);
        setErrors(undefined);

        data.manager_id = data.manager?.id;

        AffiliateService.create(data)
            .then(
                response => {

                    toast.success('Affiliate created');

                    history.push('.');
                }, error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(message);

                    if (error.response && error.response.status === 422) {
                        setErrors(error.response.data.errors);
                    }

              
                })
            .finally(() => {
                setLoading(false);

            })


    }


    return (
        <Fragment>
            <Title title="Create Affiliate"/>
            <Breadcrumb parent="Affiliates" title="Create Affiliate" parentUrl={"/affiliates"}/>
            <div className="container-fluid">
                <div className="create-profile">
                    <div className="row justify-content-center">

                        <div className="col-md-10">
                            <form noValidate className="card needs-validation" onSubmit={handleSubmit(onSubmit)}
                                  onReset={reset}>
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 ">

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">{Name}:</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" name="name"
                                                           ref={register}
                                                           placeholder={Name}/>
                                                    <span>{errors?.name}</span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">{EmailAddress}:</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" name="email"
                                                           ref={register}
                                                           placeholder={EmailAddress}/>
                                                    <span>{errors?.email}</span>
                                                </div>
                                            </div>
                                            {!isManager && (
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">{Manager}:</label>
                                                    <div className="col-sm-9">
                                                        <ManagerSelected control={control} error={errors?.manager_id}/>
                                                    </div>
                                                </div>
                                                )}
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">{Password}:</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="password" name="password"
                                                           ref={register} autoComplete="new-password"
                                                           placeholder={Password}/>
                                                    <span>{errors?.password}</span>

                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">{ConfirmPassword}:</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="password"
                                                           name="password_confirmation"
                                                           ref={register} autoComplete="new-password"
                                                           placeholder={ConfirmPassword}/>
                                                    <span>{errors?.password}</span>

                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-3">
                                                    <span>{Status}:</span>
                                                </div>
                                                <div className="col-md-9">
                                                    <label className="switch-green">
                                                        <input type="checkbox" name="is_active" defaultChecked
                                                               ref={register}/>
                                                        <div className="slider"></div>
                                                    </label>
                                                    <span>{errors?.is_active}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="card-footer text-center">
                                    <button className="btn btn-primary " type="submit"
                                            disabled={loading}
                                    >
                                        <span>{Create}&nbsp;</span>
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"></span>
                                        )}
                                    </button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>

    );

}
function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(AffiliatesCreate);
